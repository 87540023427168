import React from 'react';

export function useIntervalFn(disabled: boolean, fn: VoidFunction, ms: number = 0) {

  const intervalFn = React.useRef(fn);

  intervalFn.current = fn;

  React.useEffect(() => {
    if (disabled) return;

    const interval = setInterval(intervalFn.current, ms);
    return () => {
      clearInterval(interval);
    };
  }, [disabled, fn, ms]);
}
