import inNextServer from '@/src/utils/next';
import { MobileOS } from '../mobile.types';

const detectiOS = (): boolean => {
  return (
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
      navigator.platform,
    ) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document) ||
    (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)
  );
};

const detectAndroid = (): boolean => {
  return /Android/i.test(navigator.userAgent);
};

const detectMobile = (acceptUnknown = false): MobileOS | false => {
  if (inNextServer()) return false;

  // make sure it's mobile by using a CSS media query, check if (pointer:coarse) and width < 768
  if (!window.matchMedia('(pointer:coarse) and (max-width: 768px)').matches) {
    return false;
  }

  if (detectiOS()) return 'iOS';

  if (detectAndroid()) return 'Android';

  if (acceptUnknown) return 'Unknown';

  return false;
};

export default Object.assign(detectMobile, {
  iOS: detectiOS,
  Android: detectAndroid,
});
