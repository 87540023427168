import { useDebouncedCallback } from '@/src/hooks/useDebouncedCallback';
import { useIntervalFn } from '@/src/hooks/useInterval';
import { useRunOnce } from '@/src/hooks/useRunOnce';
import { useUnmount } from '@/src/hooks/useUnmount';
import { useWindowEvent } from '@/src/hooks/useWindowEvent';
import { database } from '@/src/lib/storage/global';
import { pick } from '@/src/lib/store';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { EVERYTHING_CONTEXT } from '@/src/modules/assistant/constants';
import { useContextFinder } from '@/src/modules/assistant/hooks/useContextFinder';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import {
  AssistantContextOption,
  AssistantContextSubType,
  ChatbotConversation,
  ChatbotConversationMessage,
  ChatbotConversationMessageAssistant,
  ChatbotConversationMessageUser,
  ChatbotConversationUserAction,
  ChatbotConversationUserActionType,
} from '@/src/modules/assistant/types';
import { isFileBasedContext } from '@/src/modules/assistant/utils/context';
import { convertAssistantContextToAPIScope } from '@/src/modules/assistant/utils/convertAssistantContextToAPIScope';
import { populateActionPrompt } from '@/src/modules/assistant/utils/populateActionPrompt';
import { useMutationCheckListItemState } from '@/src/modules/onboarding/mutations/useMutationCheckListItemState';
import { OnboardingCheckListActionId } from '@/src/modules/onboarding/onboarding.config';
import {
  ChatbotErrorCodes,
  ChatbotMessage,
  ChatbotMessageRole,
  UsageLimit,
} from '@fabric/woody-client';
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 } from 'uuid';
import { shallow } from 'zustand/shallow';
import { useWoody } from '../services/woody/woody';

export const assistantStoreName = 'chat';

const MAX_CHAT_INACTIVITY = 1000 * 60 * 10; // 10 minutes
// After MAX_CHAT_INACTIVITY, the chat will be cleared automatically

function findFabricUUIDs(s: string): string[] {
  const regex =
    /fabric:\/\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}/g;
  const matches = s.match(regex);
  const uuids = matches?.map((match) => match.replace('fabric://', '').trim()) || [];
  return [...new Set(uuids)];
}

interface ContextProps {
  messages: ChatbotConversationMessage[];
  addMessage: (content: string) => Promise<void>;
  retry: () => Promise<void>;
  clearMessages: () => void;
  isLoading: boolean;
  isStreamingAnswer: boolean;
  streamingAnswer: ChatbotConversationMessage | null;
  lastInteraction: number;
  focusedResourceId: string | null;
  error: ChatbotErrorCodes | null;
  usage: UsageLimit | null;
  activeContext: AssistantContextOption;
  setActiveContext: (context: AssistantContextOption) => void;
  resetSelection: () => void;
  contexts: AssistantContextOption[];

  summarizeActiveContext: (message: string) => void;
}

const ChatsContext = createContext<Partial<ContextProps>>({});

// At the moment switching context is not desirable, but this could be useful in the future.
const contextKey = 'default-conversation';

export function MessagesProvider({
  children,
  chatAssistantOpen,
  expandedFdocId,
}: {
  children: ReactNode;
  chatAssistantOpen: boolean;
  expandedFdocId?: string;
}) {
  const { client } = useWoody();
  const [usage, setUsage] = useState<UsageLimit | null>(null);
  const [error, setError] = useState<ChatbotErrorCodes | null>(null);
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({});
  const [streamingAnswers, setStreamingAnswers] = useState<
    Record<string, ChatbotConversationMessage | null>
  >({});
  const hasUnmounted = useRef(false);

  const { mutate: mutateCheckListItemState } = useMutationCheckListItemState();

  const {
    lastContextSubtype,
    setLastContextSubtype,
    messages,
    setMessages,
    setAssistantSelectedIds,
    setSummarizeText,
    summarizeText,
    setHydratedKey,
    hydratedKey,
  } = useAssistantStore(
    (s) =>
      pick(s, [
        'lastContextSubtype',
        'setLastContextSubtype',
        'messages',
        'setMessages',
        'setAssistantSelectedIds',
        'summarizeText',
        'setSummarizeText',
        'hydratedKey',
        'setHydratedKey',
      ]),
    shallow,
  );

  const contexts = useContextFinder(expandedFdocId);
  const [activeContext, _setActiveContext] = useState<AssistantContextOption>(EVERYTHING_CONTEXT);

  const setActiveContext = useCallback(
    (context: AssistantContextOption) => {
      _setActiveContext(context);
      setLastContextSubtype(context.subType);
    },
    [setLastContextSubtype],
  );

  /*******************************************************************************
   * Set active context on mount
   */
  useRunOnce(() => {
    const fileContext = contexts.find((c) => c.subType === AssistantContextSubType.File);

    if (lastContextSubtype !== AssistantContextSubType.Everything && !!lastContextSubtype) {
      const context = contexts.find((c) => c.subType === lastContextSubtype);
      if (context) {
        setActiveContext(context);
      }
    } else if (fileContext) {
      setActiveContext(fileContext);
    }
  });
  /*******************************************************************************/

  const [lastInteraction, setLastInteraction] = useState(Date.now());

  const resetSelection = useCallback(() => {
    setAssistantSelectedIds([]);
    setActiveContext(EVERYTHING_CONTEXT);
  }, [setActiveContext, setAssistantSelectedIds]);

  const currentContextRef = useRef(contextKey);
  currentContextRef.current = contextKey;

  /*******************************************************************************
   * Autosave
   * save either on unmount, or when chat assistant is closed
   * save fn is debounced to prevent multiple saves (unmount and close)
   */
  const save = useCallback(
    async (options?: { shouldSaveEmpty: boolean }) => {
      if (messages.length === 0 && !options?.shouldSaveEmpty) {
        // don't save empty conversations by default
        // this is prevention for the race condition when conversation is not hydrated yet and the use effect for saving is triggered
        // if you want to reset the store
        return;
      }

      await database?.setItem<ChatbotConversation>(assistantStoreName, contextKey, {
        messages,
        lastInteraction,
        contextKey,
      });
    },
    [messages, lastInteraction],
  );

  const saveDebounced = useDebouncedCallback(save, 200);

  useUnmount(saveDebounced);

  useEffect(() => {
    if (!database || (hydratedKey && hydratedKey !== contextKey) || chatAssistantOpen) return;
    saveDebounced();
  }, [saveDebounced, chatAssistantOpen, hydratedKey]);

  /*******************************************************************************/

  const reset = useCallback(() => {
    setMessages([]);
    setLastInteraction(Date.now());
    setStreamingAnswers({});
    setHydratedKey(contextKey);
    setTimeout(() => {
      save({ shouldSaveEmpty: true });
    }, 200);
  }, [setMessages, save, setHydratedKey]);

  const chatResetRef = useRef(reset);
  chatResetRef.current = reset;

  /*******************************************************************************
   * Hydration
   * The hydration runs once per mount and only when the zustand store is not hydrated yet
   * We check that against the last hydrated key
   */
  useRunOnce(() => {
    /**
     * we make sure that the hydration happen only once per contextKey change
     */
    if (!database || hydratedKey === contextKey) return;

    const hydrate = async () => {
      const conversation = await database?.getItem<ChatbotConversation>(
        assistantStoreName,
        contextKey,
      );

      if (!conversation) {
        chatResetRef.current();
        return;
      }

      setLastInteraction(conversation.lastInteraction);
      setMessages(conversation.messages);
      setHydratedKey(contextKey);
    };

    hydrate();
  }, chatAssistantOpen);
  /*******************************************************************************/

  const { track } = useAnalytics();

  const streamResponse = useCallback(
    async (
      pastMessages: ChatbotConversationMessage[],
      action?: ChatbotConversationUserAction,
    ): Promise<void> => {
      if (!usage) return;

      const contextAtStart = currentContextRef.current;

      mutateCheckListItemState({
        actionId: OnboardingCheckListActionId.ASK_AI_SOMETHING,
        state: true,
      });

      try {
        const [actionedPastMessages] = populateActionPrompt(pastMessages, contexts);

        const temporaryStreamingAnswer: ChatbotConversationMessageAssistant = {
          id: v4(),
          role: ChatbotMessageRole.assistant,
          streaming: true,
          content: '',
          resourceIds: [],
          resources: [],
          context: activeContext,
          action,
        };

        setError(null);

        // this is the context window that we send to the assistant
        const history: ChatbotMessage[] = actionedPastMessages.map((message) => {
          return {
            role: message.role,
            content: message.content,
          };
        });

        const previousCount = usage.used || 0;
        let tracked = false;

        const iterable = await client.v2('/v2/chatbot', {
          method: 'post',
          body: {
            history,
            ...convertAssistantContextToAPIScope(activeContext),
          },
        });

        for await (const chunk of iterable) {
          setUsage({ ...usage, used: previousCount + 1 });

          if (previousCount + 1 >= (usage.limit ?? 3) && !tracked) {
            tracked = true;
            // if we are now at the limit let's track it
            track(AnalyticsEvents.ChatbotQuotaLimitHit, {
              fromMessage: true,
              creditsUsed: usage.used,
              creditsLimit: usage.limit,
              historySize: history.length,
            });
          }

          setLoadingStates((prev) => ({ ...prev, [contextAtStart]: false }));
          temporaryStreamingAnswer.content += chunk;

          setStreamingAnswers((prev) => ({
            ...prev,
            [contextAtStart]: { ...temporaryStreamingAnswer },
          }));
        }

        const finalAnswer = {
          ...temporaryStreamingAnswer,
          streaming: false,
          resourceIds: findFabricUUIDs(temporaryStreamingAnswer.content),
        };

        setMessages((prevMessages) => [...prevMessages, finalAnswer]);
        if (hasUnmounted.current) {
          saveDebounced();
        }

        setStreamingAnswers((prev) => ({ ...prev, [contextAtStart]: null }));
      } catch (error) {
        console.error('error being caught', error);
        setError(
          ((error as Error).message as ChatbotErrorCodes) ||
            ChatbotErrorCodes.INTERNAL_SERVER_ERROR,
        );
        setStreamingAnswers((prev) => ({ ...prev, [contextAtStart]: null }));
      }
    },
    [usage, activeContext, contexts, client, track, setMessages, saveDebounced],
  );

  useEffect(() => {
    if (!client || !chatAssistantOpen) return;

    // if usage quota is exceeded, we disable the chatbot
    const checkUsageQuota = async () => {
      const usage = await client.getChatbotUsage();
      setUsage(usage.data);
    };

    checkUsageQuota();
  }, [client, chatAssistantOpen]);

  useEffect(() => {
    if (!usage || error === ChatbotErrorCodes.USAGE_LIMIT_EXCEEDED) return;

    const used = usage.used || 0;
    const limit = usage.limit || 0;
    if (used >= limit) {
      track(AnalyticsEvents.ChatbotQuotaLimitHit, {
        fromMessage: false,
        creditsUsed: usage.used,
        creditsLimit: usage.limit,
        historySize: 0,
      });

      setError(ChatbotErrorCodes.USAGE_LIMIT_EXCEEDED);
    }
  }, [usage, error, track]);

  const clearMessages = useCallback(() => {
    setMessages([]);
    setError(null);
    localStorage.removeItem('assistant-messages');
    localStorage.removeItem('assistant-lastInteraction');
  }, [setMessages]);

  const sendMessage = useCallback(
    async (content: string, action?: ChatbotConversationUserAction) => {
      if (loadingStates[currentContextRef.current]) return;

      setLoadingStates((prev) => ({ ...prev, [currentContextRef.current]: true }));
      setLastInteraction(Date.now());

      try {
        const newMessage: ChatbotConversationMessageUser = {
          id: v4(),
          role: ChatbotMessageRole.user,
          content,
          context: activeContext,
          action,
        };

        const newMessages = [...messages, newMessage];

        // Add the user message to the state so we can see it immediately
        setMessages(newMessages);

        // pick the last few messages (the current message and the previous message from the assistant)
        const contextWindow: ChatbotConversationMessage[] = [];
        // add 5 last messages of the active context
        for (let i = newMessages.length - 1; i >= 0; i--) {
          const forMsg = newMessages[i];
          if (forMsg.context?.type === activeContext.type) {
            contextWindow.unshift(forMsg);
          }
          if (contextWindow.length >= 5) {
            break;
          }
        }

        // Track the message
        track(AnalyticsEvents.ChatbotChatMessage, {
          creditsUsed: usage?.used,
          creditsLimit: usage?.limit,
          historySize: contextWindow.length,
          contextType: activeContext.type,
          contextName: activeContext.name,
          contextValue: activeContext.value,
        });

        // Send the user message to the assistant
        await streamResponse(contextWindow, action);
      } catch (error) {
        // Show error when something goes wrong
        // addToast({ title: 'An error occurred', type: 'error' });
      } finally {
        setLoadingStates((prev) => ({ ...prev, [currentContextRef.current]: false }));
      }
    },
    [
      loadingStates,
      activeContext,
      messages,
      setMessages,
      track,
      usage?.used,
      usage?.limit,
      streamResponse,
    ],
  );

  const retry = useCallback(async () => {
    if (!error) return;
    setError(null);

    const lastMessage = messages[messages.length - 1];
    await sendMessage(lastMessage.content);
  }, [error, messages, sendMessage]);

  useEffect(() => {
    if (!summarizeText || !!loadingStates[contextKey] || !usage) return;
    const timeout = setTimeout(() => {
      setSummarizeText(undefined);
      sendMessage(summarizeText, {
        // This prevents multiple summarizes from sharing the same info tag UI.
        id: v4(),
        type: ChatbotConversationUserActionType.SUMMARIZE_TEXT,
      });
    }, 50);

    return () => {
      clearTimeout(timeout);
    };
  }, [summarizeText, sendMessage, setSummarizeText, loadingStates, usage]);

  const summarizeActiveContext = useCallback(
    (message: string) => {
      if (!!loadingStates[contextKey] || !usage || !isFileBasedContext(activeContext)) return;

      sendMessage(message, {
        id: v4(),
        type:
          activeContext.subType === AssistantContextSubType.File
            ? ChatbotConversationUserActionType.SUMMARIZE_DOCUMENT
            : ChatbotConversationUserActionType.SUMMARIZE_SELECTION,
      });
    },
    [activeContext, loadingStates, sendMessage, usage],
  );

  const value = useMemo(
    () => ({
      messages,
      contexts,
      addMessage: sendMessage,
      clearMessages,
      retry,
      isLoading: !!loadingStates[contextKey],
      isStreamingAnswer: !!streamingAnswers[contextKey],
      streamingAnswer: streamingAnswers[contextKey],
      lastInteraction,
      error,
      usage,
      activeContext,
      setActiveContext,
      resetSelection,
      summarizeActiveContext,
    }),
    [
      messages,
      contexts,
      sendMessage,
      clearMessages,
      retry,
      loadingStates,
      streamingAnswers,
      lastInteraction,
      error,
      usage,
      activeContext,
      setActiveContext,
      resetSelection,
      summarizeActiveContext,
    ],
  );

  useUnmount(() => {
    setLastInteraction(Date.now());
    hasUnmounted.current = true;
  }, true);

  useWindowEvent('beforeunload', () => setLastInteraction(Date.now()), {
    enabled: chatAssistantOpen,
  });
  useWindowEvent('focus', () => setLastInteraction(Date.now()), { enabled: chatAssistantOpen });
  /**
   * update the activity time while open in interval
   * to prevent auto reset
   */
  useIntervalFn(!chatAssistantOpen, () => setLastInteraction(Date.now()), 1000 * 30);

  useEffect(() => {
    if (!lastInteraction) {
      return;
    }
    /**
     * reset chat if the inactivity time is greater than the max chat inactivity
     */
    if (Date.now() - lastInteraction > MAX_CHAT_INACTIVITY) {
      chatResetRef.current();
    }
  }, [lastInteraction]);

  return <ChatsContext.Provider value={value}>{children}</ChatsContext.Provider>;
}

export const useChabot = () => {
  return useContext(ChatsContext) as ContextProps;
};
