import { isDefined } from '@/src/lib/utils';
import {
  BaseFdoc,
  FolderFdoc,
  NotepadFdoc,
  PageFdoc,
  StoredFileFdoc,
  TextFdoc,
} from '@/src/types/api';

export const isFolderFdoc = (fdoc?: BaseFdoc): fdoc is FolderFdoc =>
  isDefined(fdoc) && 'type' in fdoc && fdoc.type === 'folder';
export const isStoredFileFdoc = (fdoc?: BaseFdoc): fdoc is StoredFileFdoc =>
  isDefined(fdoc) && 'type' in fdoc && fdoc.type === 'stored_file';
export const isPageFdoc = (fdoc?: BaseFdoc): fdoc is PageFdoc =>
  isDefined(fdoc) && 'type' in fdoc && fdoc.type === 'page';
export const isNotepadFdoc = (fdoc?: BaseFdoc): fdoc is NotepadFdoc =>
  isDefined(fdoc) && 'type' in fdoc && fdoc.type === 'notepad';
export const isImageFdoc = (fdoc?: BaseFdoc): fdoc is StoredFileFdoc =>
  isDefined(fdoc) && 'type' in fdoc && fdoc.type === 'image';
export const isTextFdoc = (fdoc?: BaseFdoc): fdoc is TextFdoc =>
  isDefined(fdoc) && 'type' in fdoc && fdoc.type === 'text';
