import useAuthStore from '@/src/hooks/auth';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { Button } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import Section from '@/src/modules/ui/components/Section';
import { Spinner } from '@/src/modules/ui/components/Spinner';
import { P, TypographyContainer } from '@/src/modules/ui/components/Typography';
import { useWoody } from '@/src/services/woody/woody';
import {
  FeatureType,
  ItemList,
  ItemType,
  getPlanByName,
  isSubscribedPlan,
} from '@/src/types/pricing';
import SectionBox from '@/src/views/Settings/components/SettingsSectionBox';
import { Usage } from '@fabric/woody-client';
import dayjs from 'dayjs';
import Link from 'next/link';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { shallow } from 'zustand/shallow';
import { mapPlanItem } from '../../../../components/Pricing/PricePlan';
import Meter from './Meter';
import styles from './PlanCard.module.scss';

export const marketingItems: ItemList = [
  {
    type: ItemType.Feature,
    featureType: FeatureType.Regular,
    has: true,
    description: 'Up to 2TB storage',
  },
  {
    type: ItemType.Feature,
    featureType: FeatureType.Regular,
    has: true,
    description: 'No file size limit',
  },
  {
    type: ItemType.Feature,
    featureType: FeatureType.Regular,
    has: true,
    description: 'Unlimited spaces',
  },
  {
    type: ItemType.Feature,
    featureType: FeatureType.Regular,
    has: true,
    description: 'Unlimited items',
  },
  {
    type: ItemType.Feature,
    featureType: FeatureType.Regular,
    has: true,
    description: 'AI assistant',
  },
  {
    type: ItemType.Feature,
    featureType: FeatureType.Regular,
    has: true,
    description: 'Get AI Recap emails',
  },
  {
    type: ItemType.Feature,
    featureType: FeatureType.Regular,
    has: true,
    description: 'Unlock data connections',
  },
  {
    type: ItemType.Feature,
    featureType: FeatureType.Regular,
    has: true,
    description: 'Get new features first',
  },
  {
    type: ItemType.Feature,
    featureType: FeatureType.Regular,
    has: true,
    description: 'Be an early supporter ♥️',
  },
];

const PlanActions = styled(Flex).attrs((props) => {
  return {
    gap: 'elementsContainer',
    direction: 'column',
    ...props,
  };
})`
  a {
    width: 144px;
  }
`;

const PlanCard: React.FC = () => {
  const { client } = useWoody();
  const user = useAuthStore((state) => state.user, shallow);

  const activePlan = useMemo(
    () => getPlanByName(user?.subscription.tier ?? 'free', true),
    [user?.subscription.tier],
  );

  // The current feature list being shown to the user:
  const featureList = useMemo(
    () => (user?.subscription.tier === 'free' ? marketingItems : activePlan.items),
    [user?.subscription.tier, activePlan.items],
  );

  const dateEndDiscount = useMemo(() => {
    // 72 hours after createdAt of user
    if (!user?.createdAt) return null;

    const createdAt = dayjs(user.createdAt);
    const endDiscount = createdAt.add(72, 'hour');

    return endDiscount;
  }, [user?.createdAt]);

  const isDiscountActive = useMemo(() => {
    if (!dateEndDiscount) return false;

    return dayjs().isBefore(dateEndDiscount);
  }, [dateEndDiscount]);

  const [discountTimeLeft, setDiscountTimeLeft] = useState<string | null>(null);

  useEffect(() => {
    if (!dateEndDiscount) return;

    const interval = setInterval(() => {
      const now = dayjs();
      const diff = dateEndDiscount.diff(now, 'second');

      if (diff <= 0) {
        setDiscountTimeLeft(null);
        clearInterval(interval);
        return;
      }

      const duration = dayjs.duration(diff, 'second');

      const hours = duration.asHours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      const hoursString = `${Math.floor(hours)}`.padStart(2, '0');
      const minutesString = `${minutes}`.padStart(2, '0');
      const secondsString = `${seconds}`.padStart(2, '0');

      setDiscountTimeLeft(`${hoursString}:${minutesString}:${secondsString}`);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [dateEndDiscount]);

  const isSubscribed = useMemo(
    () => isSubscribedPlan(user?.subscription.tier ?? 'free'),
    [user?.subscription.tier],
  );

  const { track } = useAnalytics();
  const onClickManage = () => {
    track(AnalyticsEvents.ClickedManagePlan, {
      type: 'button',
      action: 'settings account page',
      tier: user?.subscription.tier,
    });
  };

  const billingPeriod = useMemo(() => {
    if (!user) return 'Monthly';
    return user.subscription.billingCycle === 'month' ? 'Monthly' : 'Yearly';
  }, [user]);

  const planName = useMemo(() => {
    if (user?.subscription.tier === 'lifetime') return 'Believer (Lifetime)';
    if (user?.subscription.tier === 'pro') return `Pro (${billingPeriod})`;
    if (user?.subscription.tier === 'max') return `Max (${billingPeriod})`;
    if (user?.subscription.tier === 'starter') return `Basic (${billingPeriod})`;
    return 'Free';
  }, [billingPeriod, user?.subscription.tier]);

  const [manageUrl, setManageUrl] = useState<string | null>(null);

  useEffect(() => {
    if (!isSubscribed || manageUrl) return;

    client
      .getStripePortalSessionUrl()
      .then((resp) => (!resp.error ? setManageUrl(resp.data) : null));
  }, [client, isSubscribed, manageUrl]);

  const [meterData, setMeterData] = useState<Usage | null>(null);

  useEffect(() => {
    const updateMeterData = async () => {
      const response = await client.getUsage();

      if (response.error || !response.data) return;

      setMeterData(response.data);
    };

    updateMeterData();
  }, [client]);

  const storageMeterUnit = useMemo(() => {
    if (!meterData || !meterData.storage.limit) return 'GB';
    if (meterData.storage.limit < 1000) return 'MB';
    if (meterData.storage.limit < 1000000) return 'GB';
    return 'TB';
  }, [meterData]);

  const storageMeterTotal = useMemo(() => {
    if (!meterData || !meterData.storage.limit) return 0;
    if (storageMeterUnit === 'MB') return meterData.storage.limit;
    if (storageMeterUnit === 'GB') return meterData.storage.limit / 1000;
    return meterData.storage.limit / 1000000;
  }, [meterData, storageMeterUnit]);

  const storageMeterUsed = useMemo(() => {
    if (!meterData || !meterData.storage.used) return 0;
    if (storageMeterUnit === 'MB') return meterData.storage.used;
    if (storageMeterUnit === 'GB') return meterData.storage.used / 1000;
    return meterData.storage.used / 1000000;
  }, [meterData, storageMeterUnit]);

  return (
    <SectionBox id="plan">
      <Flex direction="column" gap="sections">
        <Section.Title color="tertiary">
          Your plan:{' '}
          <P color="app-primary" as="span" weight={700}>
            {planName}
          </P>
        </Section.Title>

        {meterData && isSubscribed ? (
          <>
            <Meter
              name="Total storage"
              amount={storageMeterUsed}
              total={storageMeterTotal}
              totalString="storage"
              valueType={storageMeterUnit}
              isLoading={!meterData}
            />
            <P size="xl" weight={600}>
              We’re a tiny team, designing and building our way towards better productivity and
              collaboration for everyone.
            </P>
            <P size="xl" weight={600}>
              Thanks for being a supporter and helping us to keep building Fabric.
            </P>
          </>
        ) : meterData ? (
          <>
            <Flex direction="column" gap="sectionsShort">
              <Meter
                name="Spaces"
                amount={meterData?.spaces.used ?? 0}
                total={meterData?.spaces.limit ?? 10}
                totalString="free spaces"
                isLoading={!meterData}
              />
              <Meter
                name="Total storage"
                amount={storageMeterUsed}
                total={storageMeterTotal}
                totalString="free storage"
                valueType={storageMeterUnit}
                isLoading={!meterData}
              />
              <Meter
                name="Total items"
                amount={meterData?.items.used ?? 0}
                total={meterData?.items.limit ?? 1000}
                totalString="free items"
                isLoading={!meterData}
              />
            </Flex>
            <TypographyContainer>
              <P color="tertiary">
                One home for everything. Your AI-native universal storage & multiplayer workspace.
              </P>
              <P color="tertiary">
                Save hours every week with the fastest way to save, share or find anything.
              </P>
            </TypographyContainer>

            <hr />
            <P as="h3" weight={500}>
              Upgrade and be more productive:
            </P>
          </>
        ) : (
          <Flex justifyContent="center" alignItems="center" style={{ padding: '3rem' }}>
            <Spinner />
          </Flex>
        )}

        {!isSubscribed && isDiscountActive && (
          <>
            <div className={styles.promo_bar}>
              For new users – Get 20% off for 1 year with code:
              <span className={styles.promo_code}>
                <span>WELCOME</span>
              </span>
            </div>
          </>
        )}
        {!isSubscribed && isDiscountActive && (
          <P className={styles.timeout_offer}>Offer expires in: {discountTimeLeft}</P>
        )}

        <div className={styles.upgrade_bar}>
          <div className={styles.pro_features}>
            <P size="md" color="tertiary" weight={600}>
              {isSubscribed ? 'You currently have:' : 'Take it to the next level with Pro:'}
            </P>
            <ul>{featureList.map(mapPlanItem)}</ul>
          </div>

          {user?.subscription.tier !== 'lifetime' && (
            <PlanActions>
              <Button as={Link} href="/pricing">
                See plans
              </Button>
              {manageUrl && (
                <Button
                  variant="outline-secondary"
                  as={Link}
                  href={manageUrl}
                  onClick={onClickManage}
                >
                  Manage plan
                </Button>
              )}
            </PlanActions>
          )}
        </div>
      </Flex>
    </SectionBox>
  );
};

export default PlanCard;
