import { pick } from '@/src/lib/store';
import { isString } from '@/src/lib/utils';
import { EVERYTHING_CONTEXT } from '@/src/modules/assistant/constants';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import {
  AssistantContextOption,
  AssistantContextSubType,
  AssistantContextType,
} from '@/src/modules/assistant/types';
import { useQueryInbox } from '@/src/modules/connections/queries/useQueryInbox';
import { useQueryResourceRoot } from '@/src/modules/resource-roots/queries/useQueryResourceRoot';
import { useQueryFolder } from '@/src/modules/resources/queries/useQueryFolder';
import useUIStore from '@/src/store/ui';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { shallow } from 'zustand/shallow';

const useContextFinder = (resourceId?: string) => {
  const router = useRouter();

  const { expandedFdocId } = useUIStore((state) => pick(state, ['expandedFdocId']), shallow);
  const { assistantSelectedIds } = useAssistantStore(
    (state) => pick(state, ['assistantSelectedIds']),
    shallow,
  );

  const { inboxRoot } = useQueryInbox();
  const isInbox = router.pathname === '/saved';
  const folderId = isString(router.query.folderId) ? router.query.folderId : undefined;

  const { folder } = useQueryFolder(folderId, {
    placeholderData: undefined,
  });

  const spaceId =
    (isInbox && inboxRoot?.id) ||
    (isString(router.query.listId) && router.query.listId) ||
    folder?.listData?.id;

  const { asLegacySpace: space } = useQueryResourceRoot(spaceId);

  const contextOptions = useMemo(() => {
    const options: AssistantContextOption[] = [EVERYTHING_CONTEXT];

    if (space && ['SPACE', 'SYSTEM'].includes(space.type)) {
      options.push({
        name: space.title,
        subType: AssistantContextSubType.Space,
        value: space.id,
        type: AssistantContextType.Ancestor,
      });
    }

    if (folder) {
      options.push({
        name: folder.data.name,
        subType: AssistantContextSubType.Folder,
        value: folder.id,
        type: AssistantContextType.Ancestor,
      });
    }

    if (space && space.type === 'INTEGRATION') {
      options.push({
        name: space.title,
        subType: AssistantContextSubType.Connection,
        value: space.id,
        type: AssistantContextType.Ancestor,
      });
    }

    if (expandedFdocId || resourceId) {
      options.push({
        name: undefined,
        subType: AssistantContextSubType.File,
        value: [expandedFdocId ?? resourceId!],
        type: AssistantContextType.Resource,
      });
    }

    if (assistantSelectedIds.length > 0) {
      options.push({
        name: undefined,
        subType: AssistantContextSubType.Selection,
        value: assistantSelectedIds,
        type: AssistantContextType.Selection,
      });
    }

    return options;
  }, [space, folder, expandedFdocId, assistantSelectedIds, resourceId]);

  return contextOptions;
};

export { useContextFinder };
