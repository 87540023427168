import { isFabricDev } from '@/src/constants/env';
import { BaseStorage } from '@/src/lib/storage/BaseStorage';
import { migrateSchema, SQLITE_DB_VERSION } from '@/src/lib/storage/SQLiteMigrations';
import { KeyValStorageInterface, ValueType } from '@/src/lib/storage/types';
import { isError, isObject } from '@/src/lib/utils';
import { CapacitorSQLite, SQLiteConnection, SQLiteDBConnection } from '@capacitor-community/sqlite';

class SQLiteDBStorage extends BaseStorage<SQLiteDBConnection> implements KeyValStorageInterface {
  private sqlite: SQLiteConnection;
  private migrationMutex: { [key: string]: Promise<void> } = {};

  private TTL = 1000 * 60 * 60 * 24 * 1; // 1 day

  protected BATCH_DELAY = 300;

  private readCache: { [storeName: string]: { [key: string]: unknown } } = {};

  constructor(dbName: string) {
    super(dbName);
    this.sqlite = new SQLiteConnection(CapacitorSQLite);
  }

  private async displayTableStructure(db: SQLiteDBConnection, info: string): Promise<void> {
    const queryTables = db.query(`
      SELECT 
        m.name AS table_name,
        p.name AS column_name,
        p.type AS data_type,
        p.\`notnull\` AS is_nullable,
        p.dflt_value AS default_value,
        p.pk AS is_primary_key
      FROM 
        sqlite_master m
      LEFT OUTER JOIN 
        pragma_table_info((m.name)) p
      ON 
        m.name <> p.name
      WHERE 
        m.type = 'table'
      ORDER BY 
        m.name,
        p.cid
    `);

    const tables = await queryTables;

    const dbStructure: { [key: string]: Record<string, unknown> } = {};

    tables.values?.forEach((row) => {
      if (!dbStructure[row.table_name]) {
        dbStructure[row.table_name] = {};
      }

      dbStructure[row.table_name][row.column_name] = {
        type: row.data_type,
        nullable: row.is_nullable ? false : true,
        default: row.default_value || null,
        primaryKey: row.is_primary_key ? true : false,
      };
    });

    console.log(`\n=== Database Structure - ${info} ===\n`);
    console.log(dbStructure);
    console.log('\n=== End of Database Structure ===\n');
  }

  protected async checkConnection(): Promise<boolean> {
    try {
      const db = await this.getConnection();
      const readDb = await this.getReadConnection();
      return Boolean((await db.isDBOpen()).result && (await readDb.isDBOpen()).result);
    } catch (error) {
      return false;
    }
  }

  protected async reconnect(): Promise<void> {
    this.connection = null;
    await this.initializeConnection();
    await this.initializeReadConnection();
  }

  private async initializeWithErrorHandling(cb: () => Promise<void>, retryCount = 0) {
    try {
      await cb();
    } catch (error: unknown) {
      if (retryCount < this.MAX_RETRIES) {
        console.warn(
          `Error initializing SQLite connection. Retrying... (Attempt ${retryCount + 1}/${this.MAX_RETRIES})`,
        );

        // this forces the native app to update the JS side connection info and
        // disconnects any stuck connections
        await this.sqlite.checkConnectionsConsistency();
        // Wait 100ms before retrying
        await new Promise((resolve) => setTimeout(resolve, 100 * retryCount));
        await cb();
        return;
      } else {
        console.error(
          `Failed to initialize SQLite connection after ${this.MAX_RETRIES} attempts:`,
          error,
        );
        throw error;
      }
    }
  }

  protected async initializeConnection(): Promise<void> {
    await this.initializeWithErrorHandling(async () => {
      let db: SQLiteDBConnection;

      try {
        db = await this.sqlite.createConnection(this.dbName, false, 'no-encryption', 1, false);
        await db.open();
      } catch (error: unknown) {
        // if it's the 'Connection X already exists' error, try to retrieve the connection
        if (isError(error) && error.message.includes('already exists')) {
          db = await this.sqlite.retrieveConnection(this.dbName, false);
          if (!(await db.isDBOpen()).result) {
            await db.open();
          }
        } else {
          throw error;
        }
      }

      // Apply optimizations
      await db.query(
        [
          'PRAGMA journal_mode = WAL2',
          'PRAGMA synchronous = NORMAL',
          'PRAGMA temp_store = MEMORY',
          'PRAGMA page_size = 4096', // 4KB
          'PRAGMA cache_size = -25000', // 50MB
          'PRAGMA mmap_size = 268435456', // 256MB
          'PRAGMA read_uncommitted = 1', // Allow reading while writing
          'PRAGMA busy_timeout = 5000', // Set a busy timeout of 5 seconds
          'PRAGMA threading_mode = MULTI_THREAD',
          'PRAGMA optimize',
        ].join(';\n'),
      );

      // Create version table if it doesn't exist
      await db.execute(`
          CREATE TABLE IF NOT EXISTS schema_version (
            store_name TEXT,
            version INTEGER,
            PRIMARY KEY (store_name)
          )
      `);

      const versions = (await (
        await db.query('SELECT store_name, version FROM schema_version')
      ).values) as {
        store_name: string;
        version: number;
      }[];

      for (const { store_name, version } of versions) {
        if (version >= SQLITE_DB_VERSION)
          this.migrationMutex[store_name] = new Promise((resolve) => resolve());
      }

      this.connection = Promise.resolve(db);
      this.initializing = false;
      this.initializingPromise = null;
    });
  }

  protected async initializeReadConnection(): Promise<void> {
    await this.initializeWithErrorHandling(async () => {
      let db: SQLiteDBConnection;

      try {
        db = await this.sqlite.createConnection(this.dbName, false, 'no-encryption', 1, true);
        await db.open();
      } catch (error: unknown) {
        // if it's the 'Connection X already exists' error, try to retrieve the connection
        if (isError(error) && error.message.includes('already exists')) {
          db = await this.sqlite.retrieveConnection(this.dbName, true);
          if (!(await db.isDBOpen()).result) {
            await db.open();
          }
        } else {
          throw error;
        }
      }

      await db.query(
        [
          'PRAGMA temp_store = MEMORY',
          'PRAGMA cache_size = -100000', // 100MB
          'PRAGMA busy_timeout = 5000',
          'PRAGMA read_uncommitted = 1',
          'PRAGMA mmap_size = 268435456', // 256MB
          'PRAGMA query_only = ON',
          'PRAGMA locking_mode = EXCLUSIVE',
          'PRAGMA foreign_keys = OFF',
          'PRAGMA threading_mode = MULTI_THREAD',
          'PRAGMA optimize',
        ].join(';\n'),
      );

      this.readConnection = Promise.resolve(db);
      this.initializingRead = false;
      this.initializingReadPromise = null;
    });
  }

  protected async initializeStore(storeName: string): Promise<void> {
    const migrationKey = `${this.dbName}_${storeName}`;

    if (!this.migrationMutex[migrationKey]) {
      this.migrationMutex[migrationKey] = this.performMigration(storeName);
    }

    await this.migrationMutex[migrationKey];
  }

  private async performMigration(storeName: string): Promise<void> {
    const db = await this.getConnection();
    if (isFabricDev) await this.displayTableStructure(db, 'Before Migration');
    await migrateSchema(db, storeName);
    if (isFabricDev) await this.displayTableStructure(db, 'After Migration');
    this.garbageCollection(storeName);
    this.loadKeysToMemory(storeName);
  }

  private jsonSerializer(key: string, value: unknown) {
    if (value instanceof Uint8Array || value instanceof Buffer)
      return {
        $__type: 'Buffer',
        data: Array.from(value),
      };
    if (value === null)
      return {
        $__type: 'Null',
      };

    return value;
  }

  private serializeValue(value: unknown): { serializedValue: string; type: ValueType } {
    const time = Date.now();

    let result: { serializedValue: string; type: ValueType };
    if (value instanceof Uint8Array) {
      result = {
        serializedValue: Buffer.from(value).toString('base64'),
        type: ValueType.BINARY,
      };
    } else if (typeof value === 'string') {
      result = {
        serializedValue: Buffer.from(new TextEncoder().encode(value)).toString('base64'),
        type: ValueType.BASE64,
      };
    } else {
      result = {
        serializedValue: Buffer.from(
          new TextEncoder().encode(JSON.stringify(value, this.jsonSerializer)),
        ).toString('base64'),
        type: ValueType.JSON,
      };
    }

    this.updatePerformanceStats('sqliteSerialize', Date.now() - time);
    return result;
  }

  private jsonDeserializer(key: string, value: unknown) {
    if (value && isObject(value) && value.$__type === 'Buffer' && Array.isArray(value.data)) {
      return new Uint8Array(value.data);
    }
    if (value && isObject(value) && value.$__type === 'Null') {
      return null;
    }

    return value;
  }

  private deserializeValue(value: string, type: ValueType): unknown {
    const time = Date.now();
    const uint8Array = new Uint8Array(Buffer.from(value, 'base64'));

    let result: unknown;
    switch (type) {
      case ValueType.BINARY:
        result = uint8Array;
        break;
      case ValueType.BASE64:
        result = new TextDecoder().decode(uint8Array);
        break;
      case ValueType.JSON:
        result = JSON.parse(new TextDecoder().decode(uint8Array), this.jsonDeserializer);
        break;
      default:
        throw new Error(`Unknown value type: ${type}`);
    }

    this.updatePerformanceStats('sqliteDeserialize', Date.now() - time);

    return result;
  }

  protected async flushReadBatchImpl(batchesToProcess: {
    [storeName: string]: Set<string>;
  }): Promise<void> {
    const db = await this.getReadConnection();

    for (const [storeName, keys] of Object.entries(batchesToProcess)) {
      await this.initializeStore(storeName);

      const placeholders = Array(keys.size).fill('?').join(',');
      const query = `SELECT key, value, type FROM ${storeName} WHERE key IN (${placeholders})`;
      const result = await db.query(query, Array.from(keys));

      if (!this.readCache[storeName]) {
        this.readCache[storeName] = {};
      }

      if (result.values) {
        for (const row of result.values) {
          this.readCache[storeName][row.key] = this.deserializeValue(
            row.value,
            row.type as ValueType,
          );
        }
      }
    }
  }

  protected async getItemImpl<T>(storeName: string, key: string): Promise<T | null> {
    if (this.readCache[storeName] && this.readCache[storeName][key] !== undefined) {
      const value = this.readCache[storeName][key] as T;
      delete this.readCache[storeName][key];
      return value;
    }
    return null;
  }

  protected async flushBatchImpl(batchesToProcess: {
    [x: string]: Map<string, unknown>;
  }): Promise<void> {
    const db = await this.getConnection();

    const storeNames = Object.keys(batchesToProcess);
    // initialize all stores before starting the transaction
    for (const storeName of storeNames) {
      await this.initializeStore(storeName);
    }

    if ((await db.isTransactionActive()).result === true) {
      // transaction occurring we delay batch for later
      for (const [storeName, items] of Object.entries(batchesToProcess)) {
        for (const [key, value] of items) {
          this.setItem(storeName, key, value);
        }
      }
      return;
    }

    db.beginTransaction();

    try {
      for (const [storeName, items] of Object.entries(batchesToProcess)) {
        await this.setItemBatchImpl(storeName, items);
        for (const key of items.keys()) {
          this.addKeyToCache(storeName, key);
        }
      }

      db.commitTransaction();
    } catch (error) {
      db.rollbackTransaction();
      throw error;
    }
  }

  protected async setItemBatchImpl(storeName: string, items: Map<string, unknown>): Promise<void> {
    await this.initializeStore(storeName);
    const db = await this.getConnection();

    const placeholders = Array(items.size).fill('(?, ?, ?, ?)').join(', ');
    const query = `INSERT OR REPLACE INTO ${storeName} (key, value, type, last_updated) VALUES ${placeholders}`;

    const now = Date.now();
    const values = Array.from(items).flatMap(([key, value]) => {
      const { serializedValue, type } = this.serializeValue(value);
      return [key, serializedValue, type, now];
    });

    await db.run(query, values, false);
  }

  protected async removeItemImpl(storeName: string, key: string): Promise<void> {
    await this.initializeStore(storeName);
    const db = await this.getConnection();
    await db.run(`DELETE FROM ${storeName} WHERE key = ?`, [key]);
  }

  protected async clearImpl(storeName: string): Promise<void> {
    await this.initializeStore(storeName);
    const db = await this.getConnection();
    await db.run(`DELETE FROM ${storeName}`);
  }

  protected async keysImpl(storeName: string): Promise<string[]> {
    await this.initializeStore(storeName);
    const db = await this.getReadConnection();
    const result = await db.query(`SELECT key FROM ${storeName}`);
    return result.values ? result.values.map((row) => row.key) : [];
  }

  protected async *getAllItemsIteratorImpl<T>(storeName: string): AsyncIterableIterator<T> {
    await this.initializeStore(storeName);
    const db = await this.getReadConnection();

    const query = 'SELECT key, value, type FROM ' + storeName;
    const result = await db.query(query);

    if (result.values) {
      for (const row of result.values) {
        yield this.deserializeValue(row.value, row.type as ValueType) as T;
      }
    }
  }

  protected async getAllItemsImpl<T>(storeName: string): Promise<T[]> {
    await this.initializeStore(storeName);
    const db = await this.getReadConnection();

    const query = 'SELECT key, value, type FROM ' + storeName;
    const result = await db.query(query);

    if (result.values) {
      return result.values.map((row) =>
        this.deserializeValue(row.value, row.type as ValueType),
      ) as T[];
    }

    return [];
  }

  private async garbageCollection(storeName: string): Promise<void> {
    const db = await this.getConnection();
    const query = `DELETE FROM ${storeName} WHERE last_updated < ?`;
    const threshold = Date.now() - this.TTL;
    await db.run(query, [threshold]);
  }
}

export default SQLiteDBStorage;
