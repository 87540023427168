'use client';

import React from 'react';
import inNextServer from '@/src/utils/next';
import UpgradePromptModal from '../UpgradePromptModal/UpgradePromptModal';

const LimitSpacesModal: React.FC<{
  listAmount: number;
  onClose: () => void;
}> = ({ listAmount, onClose }) => {
  if (inNextServer()) return null;

  return (
    <UpgradePromptModal
      title="Upgrade for unlimited spaces"
      limitBadge={`${listAmount}/10 free spaces used`}
      onClose={onClose}
    />
  );
};

export default LimitSpacesModal;
