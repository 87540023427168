import { isInDesktop } from '@/src/hooks/todesktop';
import { App, AppInfo } from '@capacitor/app';
import { app } from '@todesktop/client-core';
import { compareVersions } from 'compare-versions';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import { shallow } from 'zustand/shallow';
import { logTrackerEvent, TrackerEventKind, useTracker } from '../lib/or';
import { pick } from '../lib/store';
import { useExtensionVersionVerification } from '../services/extension';
import { useWoody } from '../services/woody/woody';
import { warning } from '../store/alerts';
import useUIStore from '../store/ui';
import { isInMobile } from './mobile';

export type VersionInfo = {
  webVersion?: string;
  appVersion?: string;
  desktopVersion?: string;
  appInfo?: AppInfo;
};

/**
 * issue: this promise can take up to 10 seconds to resolve...
 * app is initialized with loading the info
 * use useDeviceInfoStore to get the version info so you don't have to wait for this promise
 */
export const getVersions = async (): Promise<VersionInfo> => {
  const appInfo = isInMobile() ? await App.getInfo() : undefined;
  const webVersion = process.env.NEXT_PUBLIC_APP_VERSION;
  const appVersion = appInfo?.version;
  const appBuild = appInfo?.build;
  const desktopVersion = isInDesktop() ? app.getVersion() : undefined;

  return {
    webVersion,
    appVersion: !appVersion ? undefined : `${appVersion}-build.${appBuild}`,
    desktopVersion,
    appInfo,
  };
};

const useVersionCheck = () => {
  const { client } = useWoody();
  const currentVersion = process.env.NEXT_PUBLIC_APP_VERSION;
  const [lastVersionCheck, setLastVersionCheck] = useState<number>(0);
  const { setRequiredUpdateModalOpen } = useUIStore(
    (state) => pick(state, ['setRequiredUpdateModalOpen']),
    shallow,
  );

  const tracker = useTracker();
  const [runOnce, setRunOnce] = useState(false);
  useEffect(() => {
    if (runOnce || !tracker) return;

    setRunOnce(true);
    getVersions().then((versions) => {
      logTrackerEvent({
        kind: TrackerEventKind.INIT_VERSION_INFO,
        payload: versions,
      });
    });
  }, [runOnce, tracker]);

  const { data, isLoading } = useSWR(
    'version',
    async () => {
      const { data, error } = await client.getVersionInfo();

      if (error) throw error;

      return data;
    },
    {
      // we want this to be checked every so often, to avoid users lingering on old versions for too long (5 minutes should be fine)
      refreshInterval: 5 * 60 * 1000,
      focusThrottleInterval: 5 * 60 * 1000,
      revalidateIfStale: false,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      onSuccess: () => {
        setLastVersionCheck(Date.now());
      },
    },
  );

  // This use effect checks the version against the latest version and prompts the user to optionally reload the app
  useEffect(() => {
    if (
      isLoading ||
      !data ||
      // If we don't have a current version the user needs urgently so we leave it to the other use effect
      !currentVersion ||
      compareVersions(currentVersion, data.clientVersions.web.latest) >= 0 ||
      // we also ignore if the minimum is higher than our current, since it will be handled by the other use effect
      compareVersions(currentVersion, data.clientVersions.web.minimum) < 0
    )
      return;

    warning({
      id: 'app-is-out-of-date-warning',
      disableTimeout: true,
      content: (
        <p>
          App updated.{' '}
          <a
            href=""
            onClick={() => window.location.reload()}
            style={{
              textDecoration: 'underline',
            }}
          >
            Reload to get the latest version
          </a>
        </p>
      ),
    });
  }, [currentVersion, isLoading, data, lastVersionCheck]);

  // This use effects checks the version agaisnt the mininum version and prompts the user with a non-dismissible warning to reload the app
  useEffect(() => {
    if (
      isLoading ||
      !data ||
      // in this case we only compare (and escape) if we have a current version, otherwise we always show the warning
      // because that means the user has a very old version (before implementing version checks)
      (currentVersion && compareVersions(currentVersion, data.clientVersions.web.minimum) >= 0)
    )
      return;

    setRequiredUpdateModalOpen(true);
  }, [currentVersion, isLoading, data, lastVersionCheck, setRequiredUpdateModalOpen]);

  useExtensionVersionVerification(data?.clientVersions.extension.chrome.latest, lastVersionCheck);
};

export default useVersionCheck;
