import { confirm, ConfirmOptions } from '@/src/store/alerts';
import {
  InfiniteData,
  MutateOptions,
  Query,
  QueryFilters,
  QueryKey,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

type UpdatedItem<T extends { id: string }> = Pick<T, 'id'> & Partial<Omit<T, 'id'>>;

export type SimpleMutationOptions = {
  onError?: () => void;
  onSuccess?: () => void;
  onSettled?: () => void;
};

export const upsertItemInArrayById = <T extends { id: string }>(
  data: T[],
  item: UpdatedItem<T>,
  options?: {
    /**
     * default is true
     */
    createIfNotFound?: boolean;
  },
) => {
  const shouldUpsert = options?.createIfNotFound ?? true;

  let updated = false;
  const result = data.map((existingItem) => {
    if (existingItem.id === item.id) {
      updated = true;
      return {
        ...existingItem,
        ...item,
      };
    }
    return existingItem;
  });

  /**
   * no item found for update, we insert it
   */
  if (!updated && shouldUpsert) {
    result.push(item as T);
  }

  return result;
};

/**
 * exposes a function which reduces the code to perform an optimistic update
 * this function returns the current data, the optimistic data and a reset function
 * This shouhld be used for queries storing arrays of items
 */
const _useOptimisticallyUpdateQueryListData = <T extends { id: string }>() => {
  const queryClient = useQueryClient();
  return (listQueryKey: QueryKey, updatedItem: UpdatedItem<T>) => {
    const currentListData = queryClient.getQueryData<T[]>(listQueryKey) || [];
    const optimisticListData = upsertItemInArrayById(currentListData, updatedItem);
    // update list state
    queryClient.setQueryData<T[]>(listQueryKey, optimisticListData);

    return {
      listQueryKey,
      currentListData,
      optimisticListData,
      resetListData: () => queryClient.setQueryData<T[]>(listQueryKey, currentListData),
    };
  };
};

type MutationCallback<D> = (currentData: D | undefined, key: QueryKey) => D | undefined;

/**
 * Exposes a function which reduces the code to perform an optimistic update
 * This function takes a predicate function to determine the lists to update and
 * a callback that will be called with the current list data and expected optimistic data returned
 * This should be used for queries storing arrays of items, e.g. different pages of different filters
 */
export const useOptimisticallyUpdateQueriesWithPredicate = () => {
  const queryClient = useQueryClient();
  return <D>(
    predicate: (query: Query) => boolean,
    callback: MutationCallback<D>,
    options?: {
      type?: QueryFilters['type'];
    },
  ) => {
    const mutate = (callback: MutationCallback<D>) => {
      const currentQueriesData = queryClient.getQueriesData<D>({ predicate, ...options });

      for (const [key, data] of currentQueriesData) {
        const optimisticData = callback(data, key);
        queryClient.setQueryData(key, optimisticData);
      }

      return {
        currentQueriesData,
        resetQueriesData: () => {
          for (const [key, data] of currentQueriesData) {
            queryClient.setQueryData(key, data);
          }
        },
        invalidateQueries: () => {
          queryClient.invalidateQueries({ predicate, ...options });
        },
        remutate: mutate,
      };
    };

    return mutate(callback);
  };
};

type PageUpdater<P> = (page: P, pageNumber: number, key: QueryKey) => P;
export const useOptimisticallyUpdateInfiniteQueriesWithPredicate = () => {
  const queryClient = useQueryClient();
  return <P>(args: {
    predicate: (query: Query) => boolean;
    pageUpdater: PageUpdater<P>;
    options?: {
      type?: QueryFilters['type'];
    };
  }) => {
    const { predicate, options } = args;

    const mutate = (pageUpdater: PageUpdater<P>) => {
      const currentQueriesData = queryClient.getQueriesData<InfiniteData<P>>({
        predicate,
        ...options,
      });

      for (const [key, data] of currentQueriesData) {
        const optimisticData = data && {
          ...data,
          pages: data.pages.map((page, index) => pageUpdater(page, index, key)),
        };
        queryClient.setQueryData(key, optimisticData);
      }

      return {
        currentQueriesData,
        resetQueriesData: () => {
          for (const [key, data] of currentQueriesData) {
            queryClient.setQueryData(key, data);
          }
        },
        invalidateQueries: () => {
          queryClient.invalidateQueries({ predicate, ...options });
        },
        remutate: mutate,
      };
    };

    return mutate(args.pageUpdater);
  };
};

/**
 * exposes a function which reduces the code to perform an optimistic update
 * this function returns the current data, the optimistic data and a reset function
 * This shouhld be used for queries storing an object item
 */
export const useOptimisticallyUpdateQueryDetailData = <T>() => {
  const queryClient = useQueryClient();
  return (detailQueryKey: QueryKey, updatedItem: Partial<T>) => {
    const currentDetailData = queryClient.getQueryData<T>(detailQueryKey);
    const optimisticDetailData = {
      ...currentDetailData,
      ...updatedItem,
    } as T;
    // update list state
    queryClient.setQueryData<T>(detailQueryKey, optimisticDetailData);

    return {
      detailQueryKey,
      currentDetailData,
      optimisticDetailData,
      resetDetailData: () => queryClient.setQueryData<T>(detailQueryKey, currentDetailData),
      invalidateQueries: () => {
        queryClient.invalidateQueries({
          queryKey: detailQueryKey,
        });
      },
    };
  };
};

type GetConfirmationOptions<T> = (data: T) => Omit<ConfirmOptions, 'onConfirm' | 'onCancel'>;
const defaultConfirmationOptions: Omit<ConfirmOptions, 'onConfirm' | 'onCancel'> = {
  title: 'Are you sure?',
  content: 'This action cannot be undone.',
  confirmLabel: 'Yes, Delete',
  cancelLabel: 'Cancel',
};

/**
 * Modifies a mutation to include a confirmation dialog
 */
export const withConfirmation = <T>(
  mutation: UseMutationResult<unknown, unknown, T, unknown>,
  baseGetConfirmationOptions?: GetConfirmationOptions<T>,
) => {
  return {
    ...mutation,
    confirmAndMutateAsync: async (data: T, getConfirmationOptions?: GetConfirmationOptions<T>) => {
      const options =
        getConfirmationOptions?.(data) ??
        baseGetConfirmationOptions?.(data) ??
        defaultConfirmationOptions;

      const confirmed = await confirm(options);

      if (!confirmed) return;

      return mutation.mutateAsync(data);
    },
    confirmAndMutate: (
      data: T,
      options?: MutateOptions<unknown, unknown, T, unknown> & {
        onCanceled?: () => void;
        getConfirmationOptions?: GetConfirmationOptions<T>;
      },
    ) => {
      const confirmationOptions =
        options?.getConfirmationOptions?.(data) ??
        baseGetConfirmationOptions?.(data) ??
        defaultConfirmationOptions;

      confirm(confirmationOptions).then((confirmed) => {
        if (!confirmed) {
          options?.onCanceled?.();
          return;
        }

        mutation.mutate(data, options);
      });
    },
  };
};

type ConditionalMutationOptions<T> = {
  /**
   * Prevents the mutation from happening if the predicate returns true
   * @param data the same data the mutation would receive
   * @returns true if the mutation should be prevented
   */
  ignorePredicate?: (data: T) => boolean;
};

/**
 * Modifies a mutation to include some logic that can prevent the mutation from happening before the actual mutation
 * occurs, this prevents the `onMutate`/`onSettled`/etc from being called if the mutation is prevented
 */
export const withConditionalMutation = <T, X, Y, Z>(
  mutation: UseMutationResult<X, Y, T, Z>,
  conditionOptions?: ConditionalMutationOptions<T>,
) => {
  return {
    ...mutation,
    mutate: (data: T, options?: MutateOptions<X, Y, T, Z>) => {
      if (conditionOptions?.ignorePredicate && conditionOptions.ignorePredicate(data)) return;

      return mutation.mutate(data, options);
    },
    mutateAsync: (data: T) => {
      if (conditionOptions?.ignorePredicate && conditionOptions.ignorePredicate(data)) return;

      return mutation.mutateAsync(data);
    },
  };
};

/**
 * Use this utility fn directly in infinite queries if the response matches the P type
 * example useQueryResourceRootList
 *
 *
 * @param lastPage
 * @param pages
 * @param lastPageParam
 * @returns
 */
export const getNextPageParam = <
  P extends { count: number },
  R extends { limit: number; offset: number },
>(
  lastPage: P,
  pages: P[],
  lastPageParam: R,
) => {
  if (lastPage.count < lastPageParam.limit * (lastPageParam.offset + 1)) {
    return undefined;
  }
  return {
    ...lastPageParam,
    offset: lastPageParam.offset + lastPageParam.limit,
  };
};
