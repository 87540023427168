import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import WoodyClient from '@fabric/woody-client';
import { debounce } from 'lodash';

const MAX_UNAUTHORIZED_ERRORS = 4;
const ERROR_WINDOW_MS = 10000; // 10 seconds

/**
 * A custom hook to handle authentication errors from a WoodyClient instance.
 *
 * This hook sets up an event listener for 'unauthorized' events on the provided client.
 * It tracks the number of unauthorized errors within a specified time window.
 * If the number of errors exceeds a threshold within this window, it triggers a logout.
 *
 * @param client - An instance of WoodyClient to monitor for unauthorized errors.
 * @param logout - A function to call when the unauthorized error threshold is exceeded.
 *
 * The hook uses the following constants:
 * - MAX_UNAUTHORIZED_ERRORS: The maximum number of unauthorized errors allowed before triggering a logout.
 * - ERROR_WINDOW_MS: The time window (in milliseconds) within which errors are counted.
 *
 * The hook cleans up the event listener when the component unmounts or when the client or logout function changes.
 */
export function useAuthErrorHandler(client: WoodyClient, logout: () => void) {
  useClientLayoutEffect(() => {
    let unauthorizedCount = 0;
    let firstErrorTimestamp: number | null = null;

    const resetCounter = debounce(() => {
      unauthorizedCount = 0;
      firstErrorTimestamp = null;
    }, ERROR_WINDOW_MS);

    const onUnauthorized = () => {
      const now = Date.now();
      if (!firstErrorTimestamp) {
        firstErrorTimestamp = now;
      }

      unauthorizedCount++;

      if (
        unauthorizedCount >= MAX_UNAUTHORIZED_ERRORS &&
        now - firstErrorTimestamp < ERROR_WINDOW_MS
      ) {
        logout();
        resetCounter.cancel();
      } else {
        resetCounter();
      }
    };

    client.on('unauthorized', onUnauthorized);

    return () => {
      client.off('unauthorized', onUnauthorized);
      resetCounter.cancel();
    };
  }, [client, logout]);
}
