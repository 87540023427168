import React from 'react';
import { shallow } from 'zustand/shallow';

import styles from './AlertInterface.module.scss';
import useAlertStore, {
  Alert,
  ConfirmAlert,
  ToastAlert,
  UpgradePromptAlert,
  WarningAlert,
} from '@/src/store/alerts';
import ToastDisplay from './ToastDisplay/ToastDisplay';
import WarningDisplay from './WarningDisplay/WarningDisplay';
import BannerDisplay from './BannerDisplay/BannerDisplay';
import { AnimatePresence } from 'framer-motion';
import ConfirmDisplay from './ConfirmDisplay/ConfirmDisplay';
import UpgradePromptDisplay from './UpgradePromptDisplay/UpgradePromptDisplay';

const isToast = (alert: Alert): alert is ToastAlert => alert.type === 'toast';
const isWarning = (alert: Alert): alert is WarningAlert => alert.type === 'warning';
const isConfirm = (alert: Alert): alert is ConfirmAlert => alert.type === 'confirm';
const isUpgradePrompt = (alert: Alert): alert is UpgradePromptAlert =>
  alert.type === 'upgrade_prompt';

const mapToElements = (alert: Alert): React.ReactElement | null => {
  if (isToast(alert)) {
    return <ToastDisplay key={alert.id} alert={alert} />;
  } else if (isWarning(alert)) {
    return <WarningDisplay key={alert.id} alert={alert} />;
  } else if (isUpgradePrompt(alert)) {
    return <UpgradePromptDisplay key={alert.id} alert={alert} />;
  } else if (isConfirm(alert)) {
    return <ConfirmDisplay key={alert.id} alert={alert} />;
  } else {
    return null;
  }
};

const AlertInterface: React.FC = () => {
  const clampedAlerts = useAlertStore(
    (state) =>
      state.alerts.filter(
        (alert) =>
          alert.type !== 'banner' && alert.type !== 'confirm' && alert.type !== 'upgrade_prompt',
      ),
    shallow,
  );

  const modalAlerts = useAlertStore(
    (state) => state.alerts.filter((alert) => isUpgradePrompt(alert) || isConfirm(alert)),
    shallow,
  );

  const banner = useAlertStore((state) => state.banner);
  return (
    <>
      {banner && (
        <div className={styles.banner}>
          <BannerDisplay alert={banner} />
        </div>
      )}
      <div className={styles.container}>{clampedAlerts.slice(0, 5).map(mapToElements)}</div>
      <AnimatePresence>{modalAlerts.map((alert) => mapToElements(alert))}</AnimatePresence>
    </>
  );
};

export default AlertInterface;
