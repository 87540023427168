import Modal from '@/src/components/Modal/Modal';
import React from 'react';

const DowngradeConfirmationModal: React.FC<{
  onClose: () => void;
  onConfirm: () => void;
}> = ({ onClose, onConfirm }) => {
  return (
    <Modal
      usePortal
      style={{
        maxWidth: '400px',
        border: 0,
      }}
      title="Confirm downgrade?"
      confirmText="Yes, continue"
      onConfirm={onConfirm}
      onClose={onClose}
      showCancel={true}
      showX={false}
      confirmButtonProps={{
        variant: 'danger',
      }}
      size="small"
      contentStyle={{
        fontSize: 15,
        fontWeight: 400,
      }}
    >
      Your plan will be immediately downgraded
    </Modal>
  );
};

export default DowngradeConfirmationModal;
