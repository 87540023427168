import React, { useCallback, useEffect } from 'react';

import styles from './WarningDisplay.module.scss';
import useAlertStore, { AlertElement } from '@/src/store/alerts';
import { shallow } from 'zustand/shallow';
import { gsap } from 'gsap';
import CloseIcon from '@/src/icons/CloseIcon';

const WarningDisplay: AlertElement<'warning'> = ({ alert: warning }) => {
  const removeAlert = useAlertStore((state) => state.removeAlert, shallow);
  const elementId = `warning-${warning.id}`;
  const [showing, setShowing] = React.useState(false);

  useEffect(() => {
    gsap.fromTo(
      `#${elementId}`,
      {
        opacity: 0,
        y: -10,
      },
      {
        duration: 0.5,
        opacity: 1,
        y: 0,
        onComplete: () => {
          setShowing(true);
        },
      },
    );
  }, [elementId]);

  const hideAlert = useCallback(() => {
    gsap.to(`#${elementId}`, {
      duration: 0.5,
      opacity: 0,
      onComplete: () => {
        setShowing(false);
        removeAlert(warning.id);
      },
    });
  }, [warning.id, removeAlert, elementId]);

  useEffect(() => {
    if (!showing || warning.disableTimeout) return;

    const timeout = setTimeout(hideAlert, warning.timeout);

    return () => {
      clearTimeout(timeout);
    };
  }, [warning.timeout, hideAlert, showing, warning.disableTimeout]);

  return (
    <div
      id={elementId}
      className={styles.warning}
      style={{
        opacity: showing ? 1 : 0,
      }}
    >
      {warning.content}
      <button className={styles.button} onClick={hideAlert}>
        <CloseIcon size={13} />
      </button>
    </div>
  );
};

export default WarningDisplay;
