import { ResourceRootListFilter } from '@/src/modules/resource-roots/resource-roots.types';

const rootQueryKeys = {
  resourceRoot: (resourceRootId?: string | null) => ['resource-root', resourceRootId || null],
  resourceRootList: (filter?: ResourceRootListFilter) => {
    if (!filter || Object.keys(filter).length === 0) {
      return ['resource-root-list', null];
    }

    return ['resource-root-list', filter];
  },
};

export { rootQueryKeys };
