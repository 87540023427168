import { CURRENT_URL } from '@/src/constants/env';
import { isInMobile } from '@/src/hooks/mobile';
import { isInDesktop } from '@/src/hooks/todesktop';
import { getVersions } from '@/src/hooks/useVersionCheck';
import { getDevice } from '@/src/lib/capacitor/device';
import { pick } from '@/src/lib/store';
import WoodyClient, { WoodyLogLevel } from '@fabric/woody-client';
import { app } from '@todesktop/client-core';
import process from 'process';
import { useEffect } from 'react';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

const woodyLogLevel: WoodyLogLevel =
  process.env.REACT_APP_ENV === 'production' ? WoodyLogLevel.ERROR : WoodyLogLevel.LOG;

// Defined here because it depends on CURRENT_URL, and sometimes the CURRENT_URL is not defined when importing this file
export const REDIRECTOR_URL = process.env.NEXT_PUBLIC_LINK_WEBSITE_URL ?? CURRENT_URL;

const isLocalhost = (url: string) => {
  try {
    const urlObject = new URL(url);
    return urlObject.hostname === 'localhost';
  } catch (e) {
    return false;
  }
};

const isSameHost = (url: string, url2: string) => {
  try {
    const urlObject = new URL(url);
    const urlObject2 = new URL(url2);
    return urlObject.hostname === urlObject2.hostname;
  } catch (e) {
    return false;
  }
};

export const WOODY_API_URL =
  (process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'develop') &&
  !isLocalhost(process.env.REACT_APP_WOODY_API_URL ?? '') &&
  !isSameHost(process.env.REACT_APP_WOODY_API_URL ?? '', CURRENT_URL)
    ? `${CURRENT_URL}/api/woody-proxy`
    : process.env.REACT_APP_WOODY_API_URL;

if (!WOODY_API_URL) {
  throw new Error('REACT_APP_WOODY_API_URL ENV variable is undefined');
}

export const getWoodyClient = () => useWoodyStore.getState().client;

interface WoodyStore {
  client: WoodyClient;

  setClient: (client: WoodyClient) => void;
}

const useWoodyStore = create<WoodyStore>()((set) => {
  const client = new WoodyClient(WOODY_API_URL, undefined, woodyLogLevel, {
    device: getDevice(),
    webVersion: process.env.NEXT_PUBLIC_APP_VERSION,
    appVersion: isInDesktop() ? app.getVersion() : isInMobile() ? 'Loading...' : undefined,
  });

  return {
    client,
    setClient: (client: WoodyClient) => set({ client }),
  };
});

export const useWoody = () => {
  const { client } = useWoodyStore((state) => ({ client: state.client }), shallow);

  return { client };
};

export const useWoodyInitializer = () => {
  const { client } = useWoodyStore((state) => pick(state, ['client']), shallow);

  useEffect(() => {
    if (!isInMobile()) return;

    getVersions().then((versions) => {
      client.setAppVersion(versions?.appVersion);
    });
  }, [client]);
};

export default useWoodyStore;
