import UpsellCrossIcon from '@/public/images/icons/UpsellCross.svg';
import UpsellTickIcon from '@/public/images/icons/UpsellTick.svg';
import useAuthStore from '@/src/hooks/auth';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { P } from '@/src/modules/ui/components/Typography';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import {
  BillingPeriod,
  BillingType,
  Feature,
  Item,
  ItemType,
  Plan,
  Prices,
  getCurrentStripePlanId,
  getFullTierFromPlan,
  getFullTierFromUser,
  isPlanWithDurationBelow,
} from '@/src/types/pricing';
import DowngradeConfirmationModal from '@/src/views/Pricing/DowngradeConfirmationModal';
import { usePricing } from '@/src/views/Pricing/PricingContext';
import UpgradeConfirmationModal from '@/src/views/Pricing/UpgradeConfirmationModal';
import clsx from 'clsx';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useSWR, { mutate } from 'swr';
import styles from './PricePlan.module.scss';

const formatPriceForDisplay = (prices: Prices, billingPeriod?: BillingPeriod) => {
  switch (prices.type) {
    case BillingType.Recurring:
      return `$${
        billingPeriod === BillingPeriod.Monthly ? prices.billedMonthly : prices.billedYearly
      }/mo`;
    case BillingType.Lifetime:
      return `$${prices.billedOnce}`;
  }
};

const formatCaptionForDisplay = (prices: Prices, billingPeriod?: BillingPeriod) => {
  switch (prices.type) {
    case BillingType.Recurring: {
      if (prices.billedMonthly === prices.billedYearly && prices.billedMonthly === 0)
        return 'Get started for free';
      if (billingPeriod === BillingPeriod.Monthly) return 'Billed monthly';

      // Billed yearly, price is billedYearly (monthly price) * 12
      return `Billed yearly at $${prices.billedYearly * 12}/year`;
    }
    case BillingType.Lifetime:
      return null;
  }
};

const PlanFeature: React.FC<{
  feature: Feature;
}> = ({ feature }) => {
  return (
    <li
      className={clsx(styles.feature)}
      key={feature.description}
      data-feature-type={feature.featureType}
      data-has={feature.has}
    >
      {feature.has ? <UpsellTickIcon /> : <UpsellCrossIcon />}
      <P color="app-primary" weight={600}>
        {feature.description}
      </P>
    </li>
  );
};

const PlanSeparator: React.FC = () => {
  return <li className={styles.separator} data-item-separator />;
};

export const mapPlanItem = (item: Item, index: number) => {
  switch (item.type) {
    case ItemType.Feature:
      return <PlanFeature key={`${index}-${item.description}`} feature={item} />;
    case ItemType.Separator:
      return <PlanSeparator key={index} />;
  }
};

const PricePlan: React.FC<{
  plan: Plan;
  billingPeriod?: BillingPeriod;
  wide?: boolean;
  className?: string;
}> = ({ plan, billingPeriod, wide, className }) => {
  const { name, items, prices, title } = plan;
  const [showingUpgradeConfirmationModal, setShowingUpgradeConfirmationModal] = useState(false);
  const [showingDowngradeConfirmationModal, setShowingDowngradeConfirmationModal] = useState(false);
  const { loading, setLoading } = usePricing();

  const { client } = useWoody();

  const { user, setUser } = useAuthStore((state) => {
    return {
      user: state.user,
      setUser: state.setUser,
    };
  });

  const price = formatPriceForDisplay(prices, billingPeriod);
  const caption = formatCaptionForDisplay(prices, billingPeriod);

  const userTier = useMemo(() => (user ? getFullTierFromUser(user) : 'free'), [user]);
  const planTier = useMemo(() => getFullTierFromPlan(plan, billingPeriod), [plan, billingPeriod]);

  const planId = useMemo(
    () => getCurrentStripePlanId(prices, billingPeriod),
    [prices, billingPeriod],
  );

  const [manageUrl, setManageUrl] = useState<string | null>(null);

  const { data: upgradeUrl } = useSWR(
    planId && userTier === 'free' ? ['stripe-checkout-session-url', planId] : null,
    async ([_, planId]) => {
      const response = await client.getStripeCheckoutSessionUrl(planId);

      if (response.error) {
        throw response.error;
      }

      return response.data;
    },
    {
      // cache as long as possible
      revalidateOnFocus: true,
      focusThrottleInterval: 1000 * 60 * 60, // 1 hour
      revalidateOnReconnect: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
      keepPreviousData: true,
      dedupingInterval: 400,
      revalidateIfStale: false,
    },
  );

  const previousUserTierRef = useRef<string | undefined>(userTier);
  useEffect(() => {
    previousUserTierRef.current = userTier;
  }, [userTier]);

  useEffect(() => {
    if (userTier === 'free' || (userTier !== planTier && planTier !== 'free') || manageUrl) return;

    client
      .getStripePortalSessionUrl()
      .then((resp) => (!resp.error ? setManageUrl(resp.data) : null));
  }, [client, planTier, userTier, manageUrl]);

  const upgradeButtonText = useMemo(() => {
    if (userTier === 'free' && planTier === userTier) return 'Current plan'; // no subscription
    if (userTier === planTier) return 'Manage subscription';
    if (isPlanWithDurationBelow(planTier, userTier)) return 'Downgrade';
    return 'Upgrade';
  }, [planTier, userTier]);

  const isDowngrade = useMemo(() => {
    return (
      userTier !== 'free' && userTier !== planTier && isPlanWithDurationBelow(planTier, userTier)
    );
  }, [planTier, userTier]);

  const upgradeButtonDisabled = useMemo(() => {
    if (!user || loading) return true;
    return userTier === 'free' && planTier === 'free';
  }, [planTier, user, userTier, loading]);

  // allow changing plans
  const changePlan = useCallback(
    async (confirmed = false) => {
      setShowingDowngradeConfirmationModal(false);
      setShowingUpgradeConfirmationModal(false);

      if (!user || !planId || userTier === 'free' || userTier === planTier) return;

      if (!confirmed)
        return isDowngrade
          ? setShowingDowngradeConfirmationModal(true)
          : setShowingUpgradeConfirmationModal(true);

      setLoading(true);
      const resp = await client.changeSubscription(planId);
      setLoading(false);

      if (resp.error || resp.status !== 200 || !resp.data.success) {
        toast({
          content: 'Something went wrong. Please try again later.',
        });
        return;
      }

      toast({
        content: 'Your subscription has been updated.',
      });

      setUser({
        ...user,
        subscription: {
          billingCycle: billingPeriod ?? null,
          tier: name,
        },
      });

      /**
       * unfortunately server returns old value, probably takes time to process (update stripe and what not)
       * so we need to wait a bit before revalidating the woody-user
       */
      setTimeout(() => {
        mutate('woody-user');
      }, 1000);
    },
    [billingPeriod, client, isDowngrade, name, planId, planTier, user, userTier, setLoading],
  );

  const { track } = useAnalytics();
  const onClick = () => {
    if (upgradeButtonDisabled) return;
    if (planTier !== userTier && planTier !== 'free' && userTier !== 'free') return changePlan();
    if (!upgradeUrl && !manageUrl) return;

    if (upgradeUrl)
      track(AnalyticsEvents.ClickedUpgrade, {
        type: 'button',
        action: 'pricing page',
        tier: planTier,
        billingPeriod: prices.type === BillingType.Lifetime ? 'lifetime' : billingPeriod,
        price,
      });

    if (manageUrl)
      track(AnalyticsEvents.ClickedManagePlan, {
        type: 'button',
        action: 'pricing page',
        tier: planTier,
        billingPeriod: prices.type === BillingType.Lifetime ? 'lifetime' : billingPeriod,
        price,
      });

    // open new tab on the upgrade url
    window.open(upgradeUrl ?? manageUrl!, '_blank');
  };

  return (
    <div className={clsx(styles.plan, className, wide && styles.wide)} data-name={name}>
      {showingUpgradeConfirmationModal && (
        <UpgradeConfirmationModal
          onClose={() => setShowingUpgradeConfirmationModal(false)}
          onConfirm={() => changePlan(true)}
        />
      )}

      {showingDowngradeConfirmationModal && (
        <DowngradeConfirmationModal
          onClose={() => setShowingDowngradeConfirmationModal(false)}
          onConfirm={() => changePlan(true)}
        />
      )}

      <h1>
        {title} {userTier === planTier && <span className={styles.badge}>Current plan</span>}
      </h1>
      <hr />
      {!wide && (
        <div className={styles.price}>
          <h2>{price}</h2>
          {caption && <small>{caption}</small>}
        </div>
      )}
      <div className={styles.content} data-content>
        <ul className={styles.featureList}>{items.map(mapPlanItem)}</ul>
        <div className={styles.actionBlock}>
          {wide && <h2>{price}</h2>}
          <button
            className={styles.button}
            disabled={upgradeButtonDisabled}
            onClick={onClick}
            data-downgrade={isDowngrade}
          >
            {upgradeButtonText}
          </button>
        </div>
      </div>
      {prices.type !== BillingType.Lifetime && <p>Cancel anytime</p>}
    </div>
  );
};

export default PricePlan;
