/**
 * Extracts specified properties from an object and returns a new object
 * with only the extracted properties.
 *
 * @template T The type of the source object from which properties are extracted.
 * @template K The type representing the keys of properties to extract.
 *
 * @param {T} object The source object from which to extract properties.
 * @param {K[]} keys An array of strings representing property names to extract.
 *
 * @returns {Pick<T, K>} A new object containing only the properties specified by the `keys` parameter.
 *
 * @example
 *
 * // Given an object:
 * const user = {
 *    id: 1,
 *    name: "John Doe",
 *    email: "john.doe@example.com"
 * };
 *
 * // To extract only `id` and `name` properties:
 * const pickedUser = pick(user, ["id", "name"]);
 * // pickedUser will be: { id: 1, name: "John Doe" }
 *
 * // With TypeScript, it offers type-safety:
 * type User = {
 *    id: number,
 *    name: string,
 *    email: string
 * };
 * const tsUser: User = {
 *    id: 1,
 *    name: "John Doe",
 *    email: "john.doe@example.com"
 * };
 * const tsPickedUser = pick(tsUser, ["id", "name"]);
 * // tsPickedUser's type will be inferred as: { id: number, name: string }
 */
export const pick = <T, K extends keyof T>(object: T, keys: K[]): Pick<T, K> => {
  return keys.reduce(
    (acc, key) => {
      acc[key] = object[key];
      return acc;
    },
    {} as Pick<T, K>,
  );
};

/**
 * Similar to pick but removes specified properties from an object and returns a new object with only the remaining properties.
 *
 * @template T The type of the source object from which properties are removed.
 * @template K The type representing the keys of properties to remove.
 *
 * @param {T} object The source object from which to remove properties.
 * @param {K[]} keys An array of strings representing property names to remove.
 *
 * @returns {Omit<T, K>} A new object containing only the properties not specified by the `keys` parameter.
 */
export const omit = <T, K extends keyof T>(object: T, keys: K[]): Omit<T, K> => {
  const clone = { ...object };
  keys.forEach((key) => delete clone[key]);
  return clone;
};

/**
 * Similar to omit but removes properties that are `undefined` from an object and returns a new object with only the remaining properties.
 * @see omit
 *
 * @template T The type of the source object from which properties are removed.
 * @template K The type representing the keys of properties to remove.
 *
 * @param {T} object The source object from which to remove properties.
 * @param {K[]} keys An array of strings representing property names to remove.
 *
 * @returns {Omit<T, K>} A new object containing only the properties not specified by the `keys` parameter.
 */
export const omitUndefined = <T extends object>(object: T): Required<T> => {
  const clone = { ...object };

  Object.entries(clone).forEach(([key, value]) => {
    if (value === undefined) {
      delete clone[key as keyof T];
    }
  });

  return clone as Required<T>;
};
