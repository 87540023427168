export enum SearchBarFilterType {
  TAG = 'tag',
  LOCATION = 'location',
  CONTENT_TYPE = 'content_type',
}

export type SearchBarFilterOption = {
  label: string;
  value: string;
  type?: 'space' | 'folder';
  canHaveChildren?: boolean;
};

export type SearchBarFilterConfig = {
  name: string;
  commandRegex: RegExp;
};

export type SearchBarFilter = {
  $key: string;
  type: SearchBarFilterType;
  value: SearchBarFilterOption | null;
};

export type SearchBarValue = {
  query: string;
  filters: SearchBarFilter[];
};

export type SearchBarCommandCreatedInfo = {
  $key: string;
  match: string;
  position: number;
};
