import { PluginListenerHandle } from '@capacitor/core';

export enum PHAuthorizationStatus {
  NotDetermined = 0,
  Restricted,
  Denied,
  Authorized,
  Limited,
}

export enum PHAccessLevel {
  AddOnly = 1,
  ReadWrite = 2,
}

// Image Access Response contains:
// If status === authorized, it will contain the status, accessLevel, deviceName and uniqueId.
// Any other status will only contain the status and accessLevel.
export type ImageAccessResponse =
  | {
      status: PHAuthorizationStatus.Authorized | PHAuthorizationStatus.Limited;
      accessLevel: PHAccessLevel;
      deviceName: string;
      uniqueId: string;
    }
  | {
      status: Exclude<
        PHAuthorizationStatus,
        PHAuthorizationStatus.Authorized | PHAuthorizationStatus.Limited
      >;
      accessLevel: PHAccessLevel;
    };

export type ImageInfo = {
  base64String: string;
  localIdentifier: string;
  creationDate: string;
  name: string;
};

export type ImageInfoResponse = {
  image: ImageInfo | null;
};

interface ScreenshotsInitializationOptions {
  userId: string | null;
  registered: boolean | null;
  lastUploadedPhotoDate: string | null;
  lastUploadedPhotoCursor: string | null;
  lastUploadParamsLoadedForUserID: string | null;
}

export enum ManagerState {
  Standby = 0,
  Active,
  Repopulating,
  Reconciling,
}
export interface ScreenshotManagerState {
  state: ManagerState;
  totalCount: number;
  activeCount: number;
  processedCount: number;
}

export interface ScreenshotUpdateCursorOptions {
  userId: string;
  cursor: string;
  date: string;
}

export interface ScreenshotConnectionPlugin {
  initialize(options: ScreenshotsInitializationOptions): Promise<void>;
  updateCursor(options: ScreenshotUpdateCursorOptions): Promise<void>;

  reset(): Promise<void>;

  hasImageAccess(): Promise<ImageAccessResponse>;
  requestImageAccess(): Promise<ImageAccessResponse>;

  getNextImage(): Promise<ImageInfoResponse>;

  addListener(
    eventName: 'screenshotManagerState',
    listenerFunc: (state: ScreenshotManagerState) => void,
  ): Promise<PluginListenerHandle> | PluginListenerHandle;

  removeAllListeners(): Promise<void>;
}
