import { useQueryClient } from '@tanstack/react-query';
import { useOptimisticallyUpdateQueriesWithPredicate } from '@/src/lib/react-query/utilities';
import { useAuthUser } from '@/src/hooks/auth';
import { memberQueryPredicates } from '../queries/membersQueryPredicates';
import { SpaceRole } from '../../spaces/spaces.types';
import { userToMember } from './userToMember';
import { Member } from '../members.types';

export const useQueryCacheMemberHelpers = () => {
  const user = useAuthUser();
  const queryClient = useQueryClient();
  const optimisticallyUpdateQueries = useOptimisticallyUpdateQueriesWithPredicate();

  return {
    addSelfToCachedSpace: (spaceId: string, role: SpaceRole) => {
      if (!user) return;

      queryClient.cancelQueries({
        predicate: (q) => memberQueryPredicates.byListId(q, spaceId),
      });

      const optimisticallyUpdateSpaceMembersQueries = optimisticallyUpdateQueries<Member[]>(
        (q) => memberQueryPredicates.byListId(q, spaceId),
        (data) => [userToMember(user, role), ...(data ?? [])],
      );

      return {
        optimisticallyUpdateSpaceMembersQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateSpaceMembersQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateSpaceMembersQueries.invalidateQueries();
        },
      };
    },
    removeSelfFromCachedSpace: (spaceId: string) => {
      if (!user) return;

      queryClient.cancelQueries({
        predicate: (q) => memberQueryPredicates.byListId(q, spaceId),
      });

      const optimisticallyUpdateSpaceMembersQueries = optimisticallyUpdateQueries<Member[]>(
        (q) => memberQueryPredicates.byListId(q, spaceId),
        (data) => data?.filter((member) => member.id !== user.id) ?? [],
      );

      return {
        optimisticallyUpdateSpaceMembersQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateSpaceMembersQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateSpaceMembersQueries.invalidateQueries();
        },
      };
    },
  };
};
