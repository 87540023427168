import { useQueryResourceRootSpaceSystemList } from '@/src/modules/spaces/queries/useQueryResourceRootSpaceSystemList';
import { useMemo } from 'react';

/**
 * Special case of root item
 */
export const useQueryInbox = () => {
  const query = useQueryResourceRootSpaceSystemList();

  const inboxRoot = useMemo(() => {
    return query.roots.find((root) => root.type === 'SYSTEM' && root.subtype === 'inbox');
  }, [query.roots]);

  return {
    isLoading: query.isLoading,
    inboxRoot,
  };
};
