import { RuleSet, css } from 'styled-components';

export const renderCssBasedOnColorScheme = ({
  light,
  dark,
}: {
  light: RuleSet<object>;
  dark: RuleSet<object>;
}) => {
  return css`
    ${(p) => {
      switch (p.theme.userColorScheme) {
        case 'dark':
          return css`
            ${light}
            ${dark}
          `;
        case 'system':
          return css`
            ${light}
            @media (prefers-color-scheme: dark) {
              /** auto theme switch disabled ATM */
              ${dark}
            }
          `;
        /**
         * default is light so we make sure we don't enable dark theme by default
         * for users that have not set a preference during the testing phase
         */
        case 'light':
        default:
          return css`
            ${light}
          `;
      }
    }}
  `;
};
