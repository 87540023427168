interface Options {
  unit?: string;
}

const DEFAULT_OPTIONS: Options = {
  unit: 'px',
};

export const cssPropertySizeValue = (value?: string | number, optionsArgs?: Options) => {
  const options = { ...DEFAULT_OPTIONS, ...optionsArgs };

  if (typeof value === 'number') {
    return `${value}${options.unit}`;
  }

  return value;
};

/**
 * Given a CSS property it will return a string to be placed on the styled component css, with the correct fallbacks
 * e.g. if it's the maxWidth and a number is passed, it will return a string with the number and the 'px' suffix
 * @param property the CSS property to be returned
 * @param value the value of the CSS property
 */
export const cssProperty = (property: string, value: string | number) => {
  return `${property}: ${cssPropertySizeValue(value)};`;
};
