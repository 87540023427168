import { useDebouncedCallback } from '@/src/hooks/useDebouncedCallback';
import { useColorScheme } from '@/src/modules/ui/theme/useColorScheme';
import React from 'react';

export const useColorSchemeToggleKbd = () => {
  const { toggleColorScheme } = useColorScheme();
  const toggleColorSchemeDebounced = useDebouncedCallback(toggleColorScheme, 200);

  const toggleColorSchemeDebouncedRef = React.useRef(toggleColorSchemeDebounced);
  toggleColorSchemeDebouncedRef.current = toggleColorSchemeDebounced;

  React.useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.code === 'KeyT' && event.shiftKey && event.altKey && event.ctrlKey) {
        toggleColorSchemeDebouncedRef.current(true);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
};
