import { exportQueryKeys } from '@/src/modules/export/queries/exportQueryKeys';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

export const useInvalidateQueryExportRequests = () => {
  const queryClient = useQueryClient();
  return useCallback(
    () =>
      queryClient.invalidateQueries({
        queryKey: [exportQueryKeys.exportRequests()[0]],
      }),
    [queryClient.invalidateQueries],
  );
};
