import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { createLogger } from '@/src/lib/logger/createLogger';
import { SOCKET_ORIGIN } from '@/src/lib/socket.io/config';
import { SocketIOContext } from '@/src/lib/socket.io/SocketIOContext';
import { FabricSocket } from '@/src/lib/socket.io/types';
import { useInvalidateQueryExportRequests } from '@/src/modules/export/queries/useInvalidateQueryExportRequests';
import { membersQueryKeys } from '@/src/modules/members/queries/membersQueryKeys';
import { toast } from '@/src/store/alerts';
import { useQueryClient } from '@tanstack/react-query';
import React, { useRef } from 'react';
import { io } from 'socket.io-client';

const logger = createLogger('useSocketIO');

export const SocketIOProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const isLoggedIn = useAuthIsLoggedIn();

  const [isConnected, setIsConnected] = React.useState(false);

  const [socket] = React.useState<FabricSocket>(() => {
    const socketInstance = io(SOCKET_ORIGIN, {
      reconnectionDelayMax: 10000,
      forceNew: true,
      autoConnect: false,
      withCredentials: true,
      transports: ['polling'],
    });

    return socketInstance;
  });

  const invalidateQueryExportRequests = useInvalidateQueryExportRequests();
  const invalidateQueryExportRequestsRef = useRef(invalidateQueryExportRequests);
  invalidateQueryExportRequestsRef.current = invalidateQueryExportRequests;

  /**
   * programatic connect
   */
  React.useEffect(() => {
    if (isLoggedIn && !socket.connected) {
      socket.connect();
    } else if (!isLoggedIn && socket.connected) {
      socket.disconnect();
    }
  }, [socket, isLoggedIn]);

  React.useEffect(() => {
    socket.on('connect', () => {
      logger.logDevOnly('Connected');
      setIsConnected(true);
    });
    socket.on('disconnect', () => {
      logger.logDevOnly('Disconnected');
      setIsConnected(false);
    });

    socket.on('connect_error', (error) => {
      logger.error('Connection error:', error);
    });

    socket.io.on('reconnect', (attempt) => {
      logger.logDevOnly('Recconected after', attempt, 'attempts');
    });

    if (process.env.NEXT_PUBLIC_SOCKETIO_LOG_EVENTS === 'true') {
      socket.onAny((event, ...args) => {
        logger.logDevOnly('Event:', event, 'Args:', args);
      });
    }

    /********************************************************************************************
     * ******************************************************************************************
     * Handling other events
     * Maybe we should separate this when it grows too big and starts to become unmanageable
     */

    /********************************************************************************************
     * member joining or leaving space
     */
    socket.on('MEMBER:JOINED', (data) => {
      queryClient.invalidateQueries({
        queryKey: membersQueryKeys.byListId(data.spaceId),
      });
    });

    socket.on('MEMBER:LEFT', (data) => {
      queryClient.invalidateQueries({
        queryKey: membersQueryKeys.byListId(data.spaceId),
      });
    });

    /********************************************************************************************
     * Notification when user exports data
     * useMutationExportResources
     */
    const notifiedAsEmailDownload: string[] = [];
    socket.on('RESOURCE:EXPORT:UPDATE', (data) => {
      /**
       * check whether the info about the export has shouldSendEmail
       * this means that the export can take a while and user will receive an email
       * once the download links are ready
       *
       * We notify them via toast.
       * We need to save that a toast has been displayed for specific request id to avoid
       * displaying it multiple times
       */
      if (
        data.shouldSendEmail &&
        !notifiedAsEmailDownload.includes(data.exportRequestId) &&
        !data.entireAppExport
      ) {
        notifiedAsEmailDownload.push(data.exportRequestId);
        toast({
          id: 'data-export',
          replace: true,
          content: "Preparing your data. We'll email you when it's ready.",
        });
      }

      if (data.status === 'SUCCESS') {
        /**
         * refetch lists of export requests
         */
        invalidateQueryExportRequestsRef.current();

        if (data.shouldSendEmail) {
          toast({
            id: 'data-export',
            replace: true,
            content:
              "Exported files are ready for download. We've sent you an email with download links.",
          });
        } else {
          /**
           * Open download links in new tabs
           * when shouldSendEmail is false. In case of true, BE sends email with download links
           * assuming it would take long to process the data resulting in UI without feedback
           */
          toast({
            id: 'data-export',
            replace: true,
            content: 'Downloading...',
          });
          setTimeout(() => {
            for (let i = 0; i < data.downloadLinks.length; i++) {
              window.open(data.downloadLinks[i], '_blank');
            }
          }, 400);
        }
      }
    });

    /********************************************************************************************/

    return () => {
      socket.removeAllListeners();
      socket.disconnect();
    };
  }, [socket, queryClient]);

  const providedValue = React.useMemo(() => {
    return {
      socket,
      isConnected,
    };
  }, [socket, isConnected]);

  return <SocketIOContext.Provider value={providedValue}>{children}</SocketIOContext.Provider>;
};
