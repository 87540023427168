import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface InviteStore {
  listId: string | null;
  setListId: (listId: string) => void;
  clearListId: () => void;
}

const useInviteStore = create<InviteStore>()(
  persist(
    (set) => ({
      listId: null,
      setListId: (listId: string) => set({ listId }),
      clearListId: () => set({ listId: null }),
    }),
    {
      name: 'invite',
      version: 1,
    },
  ),
);

export default useInviteStore;
