interface QuestLabsAuthResponse {
  userId: string;
  token: string;
}

enum CheckListItemType {
  LINK = 'link',
  TASK = 'task',
}

interface CheckListItem {
  type: CheckListItemType;
  actionId: string;
  content: string;
  completed?: boolean;
}

interface CheckListItemLink extends CheckListItem {
  type: CheckListItemType.LINK;
  url?: string;
}

interface CheckListItemTask extends CheckListItem {
  type: CheckListItemType.TASK;
  actionUrl?: string;
}

export { CheckListItemType };
export type { CheckListItem, CheckListItemLink, CheckListItemTask, QuestLabsAuthResponse };
