import { ApiUserMe } from '@/src/modules/user/user.types';
import { BaseFdoc, CreateResourceData, Fdoc, ResourceState } from '@/src/types/api';
import { OptimisticDraft } from '@/src/types/draftable';
import { FabricResourceTypes, PrivateTag } from '@fabric/woody-client';
import { v4 } from 'uuid';
import { Comment } from '../../comments/comments.types';
import { Space } from '../../spaces/spaces.types';

export const createOptimisticResource = (
  user: ApiUserMe,
  createResourceData: CreateResourceData,
  comment?: string | Comment,
  space?: Space,
  tags: PrivateTag[] = [],
): OptimisticDraft<Fdoc> => {
  const optimisticId = v4();

  const baseFdoc: OptimisticDraft<BaseFdoc> = {
    id: optimisticId,
    draftId: optimisticId,
    isDraft: true,

    commentCount: comment ? 1 : 0,
    parentResourceId: createResourceData.parentResourceId,
    label: null,
    isDirectShared: false,
    isShared: false,
    stateProcessing: ResourceState.PENDING,
    commenters: [],
    commentPinned: null,
    originUrl: null,
    slug: null,
    user: {
      id: user.id,
      name: user.name || '',
      picture: user.pictureUrlCdn || null,
    },
    list: space?.id ?? null,
    listData: space,

    personal: {
      tags,
    },

    createdAt: new Date().toISOString(),
    modifiedAt: new Date().toISOString(),
  };

  switch (createResourceData.type) {
    case FabricResourceTypes.PAGE:
      return {
        ...baseFdoc,
        type: FabricResourceTypes.PAGE,
        data: {
          pageTitle: createResourceData.pageTitle,
          pageUrl: createResourceData.pageUrl,
          noteType: 'page',
          statePreview: ResourceState.PENDING,
        },
        isWebnote: true,
      };
    case FabricResourceTypes.NOTEPAD:
      return {
        ...baseFdoc,
        type: FabricResourceTypes.NOTEPAD,
        data: {
          title: createResourceData.title,
          editorjs: createResourceData.editorjs,
          content: createResourceData.content,
          isYjsEnabled: true,
          createdAt: new Date(),
          modifiedAt: new Date(),
        },
        isWebnote: false,
      };
    case FabricResourceTypes.FOLDER:
      return {
        ...baseFdoc,
        type: FabricResourceTypes.FOLDER,
        data: {
          name: createResourceData.folderName,
        },
        isWebnote: false,
      };
    case FabricResourceTypes.IMAGE:
      return {
        ...baseFdoc,
        type: FabricResourceTypes.IMAGE,
        data: {
          noteType: 'image',
          closestPath: createResourceData.context.closestPath,
          domImageLSH: createResourceData.context.domImageLSH,
          domImageUrl: createResourceData.context.domImageUrl,
          pageTitle: createResourceData.pageTitle,
          pageUrl: createResourceData.pageUrl,
          statePreview: ResourceState.PENDING,
        },
        isWebnote: true,
      };
    case FabricResourceTypes.STORED_FILE:
      return {
        ...baseFdoc,
        type: FabricResourceTypes.STORED_FILE,
        data: {
          title: createResourceData.title,
          contentLength: createResourceData.optimistic.contentLength,
          contentType: createResourceData.optimistic.contentType,
          thumbnail: createResourceData.optimistic.thumbnail,
          url: createResourceData.optimistic.url,
          extension: createResourceData.optimistic.extension,
        },
        isWebnote: false,
      };
    case FabricResourceTypes.TEXT:
      return {
        ...baseFdoc,
        type: FabricResourceTypes.TEXT,
        data: {
          noteType: 'text',
          pageTitle: createResourceData.pageTitle,
          pageUrl: createResourceData.pageUrl,
          statePreview: ResourceState.PENDING,
          text: createResourceData.context.text,
          textRange: createResourceData.context.textRange,
        },
        isWebnote: true,
      };
  }
};
