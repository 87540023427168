import { components } from '@fabric/woody-client';

export enum UserGroup {
  ADMIN = 'admin',
  GOOGLE_DRIVE_BETA = 'google-drive-beta',
  FABRIC_INTERNAL = 'fabric-internal',
}

export type ApiUserMe = components['schemas']['MineProfile'];
export type ApiUserPublic = components['schemas']['UserProfilePublic'];

export type SubscritionTier = ApiUserMe['subscription']['tier'];

export type UserPreferences = {
  timezone: string | null;
  frontend: {
    onboardingChecklistShow?: boolean;
    onboardingChecklist?: {
      [key: string]: boolean;
    };
  };
};
