import { AnimationProps } from 'framer-motion';

export const framerAnimationFade: AnimationProps = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { delay: 0.05, duration: 0.2 },
};

export const framerAnimationSlideUp: AnimationProps = {
  initial: { opacity: 0, y: '100%' },
  exit: { opacity: 0, y: '100%' },
  animate: { opacity: 1, y: 0 },
  transition: { delay: 0.05, duration: 0.2 },
};
