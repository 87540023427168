import React from 'react';

/**
 * Takes a default event handler and a optional custom event handler,
 * runs the custom event handler if it exists, then if the event is not
 * defaultPrevented, runs the default event handler.
 */
export const useDefaultedEvent = <E extends React.SyntheticEvent>(
  defaultHandler: (e: E) => void,
  customHandler?: (e: E) => void,
) => {
  return React.useCallback(
    (e: E) => {
      if (customHandler) {
        customHandler(e);
        if (e.defaultPrevented) return;
      }
      defaultHandler(e);
    },
    [defaultHandler, customHandler],
  );
};
