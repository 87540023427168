import * as Sentry from '@sentry/nextjs';
import React, { PropsWithChildren } from 'react';

import InternalError from './InternalError';

const InternalErrorPageWrapper: Sentry.FallbackRender = ({ error, componentStack }) => {
  return (
    <InternalError
      errorDescription={error?.message}
      errorStack={componentStack ?? undefined}
      error={error ?? undefined}
    />
  );
};

const SentryErrorBoundary: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={InternalErrorPageWrapper}>{children}</Sentry.ErrorBoundary>
  );
};

export default SentryErrorBoundary;
