import { OnboardingCheckListActionId } from '@/src/modules/onboarding/onboarding.config';
import { useMutationUserPreferencesUpdate } from '@/src/modules/user/mutations/useMutationUserPreferencesUpdate';
import { userQueryKeys } from '@/src/modules/user/queries/userQueryKeys';
import { UserPreferences } from '@/src/modules/user/user.types';
import { useMutation, useQueryClient } from '@tanstack/react-query';

type MutationVariables = {
  actionId: OnboardingCheckListActionId | string;
  state: boolean;
};

export const useMutationCheckListItemState = () => {
  const mutationUserPreferencesUpdate = useMutationUserPreferencesUpdate();
  const queryClient = useQueryClient();

  return useMutation({
    /**
     * if the actions is already completed, mutation will ignore the update automatically
     */
    mutationFn: async ({ actionId, state }: MutationVariables) => {
      const queryState = queryClient.getQueryState<UserPreferences>(
        userQueryKeys.userPreferences(),
      );
      if (queryState) {
        const { status, data: preferences } = queryState;
        if (
          ['pending'].includes(status) ||
          preferences?.frontend?.onboardingChecklist?.[actionId] === state
        ) {
          return;
        }
        await mutationUserPreferencesUpdate.mutateAsync({
          data: {
            frontend: {
              ...preferences?.frontend,
              onboardingChecklist: {
                ...preferences?.frontend?.onboardingChecklist,
                [actionId]: state,
              },
            },
          },
          showToast: false,
        });
      }
    },
    scope: {
      id: 'checklist-item-state',
    },
  });
};
