import { isDefined } from '@/src/lib/utils';
import { cssProperty } from '@/src/modules/ui/utils/cssProperties';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { mediaMobile } from '../styled-utils';

import { componentBaseConfig } from '@/src/lib/styled-components/componentBaseConfig';

const gaps = {
  /**
   * usually for container for text elements
   */
  text: css`
    gap: 0.25rem;
  `,
  /**
   * small element with flexbox, such as button with text and icon
   */
  element: css`
    gap: 0.5rem;
  `,
  /**
   * small element with flexbox, such as button with text and icon
   */
  12: css`
    gap: 0.75rem;
  `,
  /**
   * e.g. element with text and buttons with a slightly bigger gap
   */
  24: css`
    gap: 1.5rem;
  `,
  /**
   * examples:
   * - container with large icon or avatar and text
   * - resource tile list
   */
  elementsContainer: css`
    gap: 1rem;
  `,
  /**
   * e.g. container of page sections
   */
  sections: css`
    gap: 1.875rem;
  `,
  /**
   * e.g. container of bigger boxed elements which are placed visually closer together
   */
  sectionsShort: css`
    gap: 1.25rem;
  `,
  /**
   * e.g. container of page sections with larger gap to visually clearly separate each block
   * The gap is shorter on mobile to avoid too much empty vertical space compared to the horizontal white spacing
   */
  sectionsLarge: css`
    gap: 4rem;
    ${mediaMobile} {
      gap: 3rem;
    }
  `,
  /**
   * e.g. container of page sections with a medium gap to visually separate each block
   */
  sectionsMedium: css`
    gap: 2.5rem;
  `,
};

type FlexProps = {
  direction?: 'row' | 'column';
  gap?: keyof typeof gaps;
  alignItems?: CSSProperties['alignItems'];
  justifyContent?: CSSProperties['justifyContent'];
  flexWrap?: CSSProperties['flexWrap'];
  flexGrow?: CSSProperties['flexGrow'] | boolean | number;
  textAlign?: CSSProperties['textAlign'];

  maxWidth?: CSSProperties['maxWidth'];
  maxHeight?: CSSProperties['maxHeight'];
  minWidth?: CSSProperties['minWidth'];
  minHeight?: CSSProperties['minHeight'];
  overflow?: CSSProperties['overflow'];
};

export const Flex = styled.div.withConfig(componentBaseConfig)<FlexProps>`
  display: flex;
  ${(p) => css`
    flex-direction: ${p.direction || 'row'};
    ${p.gap && gaps[p.gap]};
  `}
  ${(p) => p.alignItems && `align-items: ${p.alignItems};`}
  ${(p) => p.justifyContent && `justify-content: ${p.justifyContent};`}
  ${(p) => p.flexWrap && `flex-wrap: ${p.flexWrap};`}
  ${(p) => p.textAlign && `text-align: ${p.textAlign};`}

  // Using 'isDefined' prevents 0 being ignored
  ${(p) =>
    isDefined(p.flexGrow) && `flex-grow: ${typeof p.flexGrow === 'boolean' ? 1 : p.flexGrow};`}

  ${(p) => isDefined(p.maxWidth) && cssProperty('max-width', p.maxWidth)}
  ${(p) => isDefined(p.maxHeight) && cssProperty('max-height', p.maxHeight)}
  ${(p) => p.overflow && `overflow: ${p.overflow};`}

  ${(p) => isDefined(p.minWidth) && cssProperty('min-width', p.minWidth)}
  ${(p) => isDefined(p.minHeight) && cssProperty('min-height', p.minHeight)}
`;
