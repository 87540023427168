import { QueryKey } from '@tanstack/react-query';
import { resourceQueryKeys } from '../queries/resourceQueryKeys';

export const isDirectQueryKey = (
  queryKey: QueryKey,
): queryKey is ReturnType<typeof resourceQueryKeys.resource> => {
  return (
    Array.isArray(queryKey) &&
    queryKey.length > 0 &&
    resourceQueryKeys.resource()[0] === queryKey[0] &&
    queryKey[1] !== undefined &&
    typeof queryKey[1] === 'string'
  );
};

export const isFilterQueryKey = (
  queryKey: QueryKey,
): queryKey is ReturnType<typeof resourceQueryKeys.filter> => {
  return (
    Array.isArray(queryKey) && queryKey.length > 0 && resourceQueryKeys.filter()[0] === queryKey[0]
  );
};

export const isSearchQueryKey = (
  queryKey: QueryKey,
): queryKey is ReturnType<typeof resourceQueryKeys.search> => {
  return (
    Array.isArray(queryKey) && queryKey.length > 0 && resourceQueryKeys.search()[0] === queryKey[0]
  );
};
