import Link from 'next/link';
import React, { ErrorInfo, useEffect } from 'react';

import PcCrashIcon from '@/public/images/icons/pc/crash.svg';
import useAuthStore from '@/src/hooks/auth';
import { useAnalyticsStore } from '@/src/modules/analytics/analytics.store';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { ApiUserMe } from '@/src/modules/user/user.types';
import FabricError from '../../templates/FabricError';
import styles from './InternalError.module.scss';

const InternalError: React.FC<{
  errorDescription?: string;
  errorStack?: string;
  errorInfo?: ErrorInfo;
  error?: Error;
}> = ({ errorDescription, errorStack, errorInfo, error }) => {
  const reload = () => {
    window.location.href = '/';
  };

  useEffect(() => {
    try {
      let user: ApiUserMe | null = null;
      let userError: Error | null = null;

      try {
        user = useAuthStore.getState().user;
      } catch (error: unknown) {
        if (error instanceof Error) userError = error;
      }

      const { identify, track } = useAnalyticsStore.getState();

      if (user)
        identify({
          id: user?.id,
          email: user?.email,
          name: user?.name,
        });

      track(AnalyticsEvents.Error, {
        error: error,
        errorInfo: errorInfo,
        userError: userError?.message,
        userErrorInfo: userError?.stack,
        userInfo: user,
        errorDescription,
        errorStack,
        pathname: window.location.pathname,
        search: window.location.search,
        hash: window.location.hash,
      });
    } catch (error) {}
  }, [error, errorInfo, errorDescription, errorStack]);

  return (
    <FabricError>
      <PcCrashIcon className={styles.crashIcon} />
      <h4>Hmm... something’s not working</h4>
      <p>We’re sorry about that.</p>
      <Link href="/" onClick={reload}>
        Click here to return to your dashboard
      </Link>

      {(errorDescription || errorStack) && (
        <pre>
          {errorDescription}
          {'\n'}
          {errorStack}
        </pre>
      )}
    </FabricError>
  );
};

export default InternalError;
