import produce from 'immer';
import { create } from 'zustand';
import { UploadableFile } from './../types/file';

// 10mb
export const MAX_UPLOAD_SIZE_FREE = 10 * 1024 * 1024;

interface FileUploadStore {
  files: UploadableFile[];
  tooDeep: boolean;
  tooBigFiles: number;

  setTooDeep: (tooDeep: boolean) => void;

  addFile: (file: File, path?: string) => UploadableFile;
  addFiles: (files: { file: File; path?: string }[]) => void;
  incrementTooBigFiles: () => void;
  setFiles: (files: UploadableFile[]) => void;
  clearFiles: () => void;
}

const useFileUploadStore = create<FileUploadStore>()((set, get) => ({
  files: [],
  tooDeep: false,
  tooBigFiles: 0,

  setTooDeep: (tooDeep) => set({ tooDeep }),

  addFile: (file, path) => {
    const newFile = {
      file,
      metadata: {
        path,
      },
    };

    const newState = produce(get(), (draft) => {
      draft.files.push(newFile);
    });

    set(newState);

    return newFile;
  },

  addFiles: (files) => {
    const newState = produce(get(), (draft) => {
      draft.files.push(
        ...files.map((f) => ({
          file: f.file,
          metadata: {
            path: f.path,
          },
        })),
      );
    });

    set(newState);
  },

  setFiles: (files) => set({ files }),
  incrementTooBigFiles: () => set((state) => ({ tooBigFiles: state.tooBigFiles + 1 })),
  clearFiles: () => set({ files: [], tooDeep: false, tooBigFiles: 0 }),
}));

export default useFileUploadStore;
