// https://docs.sentry.io/platforms/javascript/enriching-events/identify-user/#id

import { ApiUserMe } from '@/src/modules/user/user.types';
import * as Sentry from '@sentry/nextjs';

const clear = () => {
  Sentry.setUser({
    ip_address: '{{auto}}',
  });
};

const set = (data: ApiUserMe | null | undefined) => {
  if (!data) {
    clear();
  } else {
    Sentry.setUser({
      id: data.id,
      email: data.email,
      ip_address: '{{auto}}',
    });
  }
};

export const SentryUser = {
  // use this e.g. on logout
  clear,
  set,
};
