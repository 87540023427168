import { createLogger, PrefixedLogger } from '@/src/lib/logger/createLogger';
import { KeyValStorageInterface } from './types';

abstract class BaseStorage<T> implements KeyValStorageInterface {
  protected dbName: string;

  protected keyCache: { [storeName: string]: Set<string> } = {};
  protected keysLoaded: boolean = false;

  protected connection: Promise<T> | null = null;
  protected initializing: boolean = false;
  protected initializingPromise: Promise<void> | null = null;

  protected readConnection: Promise<T> | null = null;
  protected initializingRead: boolean = false;
  protected initializingReadPromise: Promise<void> | null = null;

  protected readonly MAX_RETRIES = 5;
  protected logger: PrefixedLogger;

  private batchQueue: { [storeName: string]: Map<string, unknown> } = {};
  private batchTimeout: NodeJS.Timeout | null = null;
  protected readonly BATCH_DELAY: number = 100;

  private readBatchQueue: { [storeName: string]: Set<string> } = {};
  private readBatchTimeout: NodeJS.Timeout | null = null;
  protected readonly READ_BATCH_DELAY: number = 10;

  private performanceStats: {
    [operation: string]: {
      times: number[];
      sum: number;
      count: number;
      high: number;
      low: number;
    };
  } = {};
  private readonly PERFORMANCE_LOG_INTERVAL = 10000;
  private lastPerformanceLog: number = 0;

  protected updatePerformanceStats(operation: string, time: number): void {
    if (!this.performanceStats[operation]) {
      this.performanceStats[operation] = { times: [], sum: 0, count: 0, high: time, low: time };
    }
    const stats = this.performanceStats[operation];
    stats.times.push(time);
    stats.sum += time;
    stats.count++;
    stats.high = Math.max(stats.high, time);
    stats.low = Math.min(stats.low, time);

    // Keep only the last 50 times to avoid memory issues
    if (stats.times.length > 50) {
      const removed = stats.times.shift()!;
      stats.sum -= removed;
      stats.count--;
    }

    this.logPerformanceIfNeeded();
  }

  private logPerformanceIfNeeded(): void {
    const now = Date.now();
    if (now - this.lastPerformanceLog > this.PERFORMANCE_LOG_INTERVAL) {
      0;
      this.lastPerformanceLog = now;

      for (const [operation, stats] of Object.entries(this.performanceStats)) {
        const average = stats.sum / stats.count;
        const median = this.calculateMedian(stats.times);
        this.logger.info(
          `PERFTIME - ${operation} - Average: ${average.toFixed(2)}ms, Median: ${median.toFixed(2)}ms, High: ${stats.high}ms, Low: ${stats.low}ms`,
        );
      }
    }
  }

  private calculateMedian(numbers: number[]): number {
    const sorted = numbers.slice().sort((a, b) => a - b);
    const middle = Math.floor(sorted.length / 2);
    if (sorted.length % 2 === 0) {
      return (sorted[middle - 1] + sorted[middle]) / 2;
    }
    return sorted[middle];
  }

  protected abstract checkConnection(): Promise<boolean>;
  protected abstract reconnect(): Promise<void>;

  protected async getConnection(): Promise<T> {
    if (!this.connection) await this.ensureSingleInitialization();

    return this.connection!;
  }

  protected async getReadConnection(): Promise<T> {
    if (!this.readConnection) await this.ensureReadInitialization();
    return this.readConnection!;
  }

  protected async ensureSingleInitialization(): Promise<void> {
    this.logger.debug(`ensureSingleInitialization: Starting for ${this.dbName}`);

    if (!this.initializing) {
      this.initializing = true;
      this.initializingPromise = this.initializeConnection();
    }

    if (this.initializingPromise) {
      await this.initializingPromise;
    }

    this.logger.debug(`ensureSingleInitialization: Completed for ${this.dbName}`);
  }

  protected async ensureReadInitialization(): Promise<void> {
    this.logger.debug(`ensureReadInitialization: Starting for ${this.dbName}`);

    if (!this.initializingRead) {
      this.initializingRead = true;
      this.initializingReadPromise = this.initializeReadConnection();
    }

    if (this.initializingReadPromise) {
      await this.initializingReadPromise;
    }

    this.logger.debug(`ensureReadInitialization: Completed for ${this.dbName}`);
  }

  protected async withErrorHandling<R>(operation: string, func: () => Promise<R>): Promise<R> {
    const start = Date.now();
    try {
      const result = await func();
      const time = Date.now() - start;
      this.updatePerformanceStats(operation, time);
      if (time > this.performanceStats[operation].sum / this.performanceStats[operation].count) {
        this.logger.warn(`${operation} took longer than average: ${time}ms`);
      }
      return result;
    } catch (error) {
      this.logger.warn(`${operation} failed:`, error);
      const isConnected = await this.checkConnection();
      if (!isConnected) {
        this.logger.info('Attempting to reconnect...');
        await this.reconnect();
        this.logger.debug(`${operation}: Retrying operation after reconnection`);
        return func();
      }
      throw error;
    }
  }

  protected async *withErrorHandlingIterator<R>(
    operationName: string,
    operation: () => AsyncIterableIterator<R>,
  ): AsyncIterableIterator<R> {
    const start = Date.now();
    try {
      yield* operation();
      const time = Date.now() - start;
      this.updatePerformanceStats(operationName, time);
      if (
        time >
        this.performanceStats[operationName].sum / this.performanceStats[operationName].count
      ) {
        this.logger.warn(`${operationName} took longer than average: ${time}ms`);
      }
    } catch (error) {
      this.logger.warn(`${operationName} failed:`, error);
      const isConnected = await this.checkConnection();
      if (!isConnected) {
        this.logger.info('Attempting to reconnect...');
        await this.reconnect();
        this.logger.debug(
          `withErrorHandlingIterator: Retrying ${operationName} after reconnection`,
        );
        const retryStart = Date.now();
        yield* operation();
        const retryTime = Date.now() - retryStart;
        this.updatePerformanceStats(`${operationName}_retry`, retryTime);
      } else {
        throw error;
      }
    }
  }

  protected async loadKeysToMemory(storeName: string): Promise<void> {
    this.keyCache[storeName] = new Set(await this.keys(storeName));
    this.keysLoaded = true;
  }

  protected keyExists(storeName: string, key: string): boolean {
    if (!this.keysLoaded) return true;

    return this.keyCache[storeName]?.has(key) ?? false;
  }

  protected addKeyToCache(storeName: string, key: string): void {
    if (!this.keyCache[storeName]) {
      this.keyCache[storeName] = new Set();
    }
    this.keyCache[storeName].add(key);
  }

  protected removeKeyFromCache(storeName: string, key: string): void {
    this.keyCache[storeName]?.delete(key);
  }

  protected clearKeyCache(storeName: string): void {
    delete this.keyCache[storeName];
  }

  constructor(dbName: string) {
    this.dbName = dbName;
    this.logger = createLogger(dbName);
  }

  async getItem<T>(storeName: string, key: string): Promise<T | null> {
    if (!this.keyExists(storeName, key)) {
      return null;
    }

    this.logger.debug(`getItem: Queueing item for batch read. Store: ${storeName}, Key: ${key}`);
    if (!this.readBatchQueue[storeName]) {
      this.readBatchQueue[storeName] = new Set();
    }
    this.readBatchQueue[storeName].add(key);

    if (!this.readBatchTimeout) {
      this.logger.debug(`getItem: Setting batch read flush timeout`);
      this.readBatchTimeout = setTimeout(() => this.flushReadBatch(), this.READ_BATCH_DELAY);
    }

    return new Promise((resolve) => {
      const checkResult = () => {
        const result = this.getItemImpl<T>(storeName, key);
        if (result !== undefined) {
          resolve(result);
        } else {
          setTimeout(checkResult, 1);
        }
      };
      setTimeout(checkResult, this.READ_BATCH_DELAY + 1);
    });
  }

  async setItem<T>(storeName: string, key: string, value: T): Promise<void> {
    this.addKeyToCache(storeName, key);

    this.logger.debug(`setItem: Queueing item for batch. Store: ${storeName}, Key: ${key}`);
    if (!this.batchQueue[storeName]) {
      this.batchQueue[storeName] = new Map();
    }
    this.batchQueue[storeName].set(key, value);

    if (!this.batchTimeout) {
      this.logger.debug(`setItem: Setting batch flush timeout`);
      this.batchTimeout = setTimeout(() => this.flushBatch(), this.BATCH_DELAY);
    }
  }

  private async flushBatch(): Promise<void> {
    this.logger.debug(`flushBatch: Starting batch flush`);

    const batchesToProcess = { ...this.batchQueue };
    this.batchQueue = {};
    this.batchTimeout = null;

    await this.withErrorHandling('flushBatch', () => this.flushBatchImpl(batchesToProcess));
  }

  async removeItem(storeName: string, key: string): Promise<void> {
    this.removeKeyFromCache(storeName, key);

    this.logger.debug(`removeItem: Attempting to remove item. Store: ${storeName}, Key: ${key}`);
    return this.withErrorHandling('removeItem', () => this.removeItemImpl(storeName, key));
  }

  async clear(storeName: string): Promise<void> {
    this.clearKeyCache(storeName);

    this.logger.debug(`clear: Attempting to clear store: ${storeName}`);
    return this.withErrorHandling('clear', () => this.clearImpl(storeName));
  }

  async keys(storeName: string): Promise<string[]> {
    this.logger.debug(`keys: Retrieving keys for store: ${storeName}`);
    return this.withErrorHandling('keys', () => this.keysImpl(storeName));
  }

  async *getAllItemsIterator<T>(storeName: string): AsyncIterableIterator<T> {
    this.logger.debug(
      `getAllItemsIterator: Starting retrieval of all items from store: ${storeName}`,
    );
    yield* this.withErrorHandlingIterator('getAllItemsIterator', () =>
      this.getAllItemsIteratorImpl<T>(storeName),
    );
  }

  async getAllItems<T>(storeName: string): Promise<T[]> {
    this.logger.debug(`getAllItems: Starting retrieval of all items from store: ${storeName}`);
    return this.withErrorHandling('getAllItems', () => this.getAllItemsImpl<T>(storeName));
  }

  protected async flushBatchImpl(batchesToProcess: {
    [x: string]: Map<string, unknown>;
  }): Promise<void> {
    for (const [storeName, items] of Object.entries(batchesToProcess)) {
      await this.setItemBatchImpl(storeName, items);
      for (const key of items.keys()) {
        this.addKeyToCache(storeName, key);
      }
    }
  }

  private async flushReadBatch(): Promise<void> {
    this.logger.debug(`flushReadBatch: Starting batch read flush`);
    const batchesToProcess = { ...this.readBatchQueue };
    this.readBatchQueue = {};
    this.readBatchTimeout = null;

    await this.withErrorHandling('flushReadBatch', () => this.flushReadBatchImpl(batchesToProcess));
  }

  protected abstract getItemImpl<T>(storeName: string, key: string): Promise<T | null>;
  protected abstract setItemBatchImpl(
    storeName: string,
    items: Map<string, unknown>,
  ): Promise<void>;
  protected abstract flushReadBatchImpl(batchesToProcess: {
    [storeName: string]: Set<string>;
  }): Promise<void>;
  protected abstract removeItemImpl(storeName: string, key: string): Promise<void>;
  protected abstract clearImpl(storeName: string): Promise<void>;
  protected abstract keysImpl(storeName: string): Promise<string[]>;
  protected abstract getAllItemsIteratorImpl<T>(storeName: string): AsyncIterableIterator<T>;
  protected abstract getAllItemsImpl<T>(storeName: string): Promise<T[]>;

  protected abstract initializeConnection(retryCount?: number): Promise<void>;
  protected abstract initializeReadConnection(retryCount?: number): Promise<void>;
  protected abstract initializeStore(storeName: string): Promise<void>;
}

export { BaseStorage };
