import { cssVar } from '@/src/modules/ui/theme/variables';
import { css } from 'styled-components';

type AppThemeBackground =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'transparent'
  | 'contrast'
  | 'contrast-reverse';

const backgrounds: Record<AppThemeBackground, string> = {
  primary: cssVar['color-bg-primary'],
  secondary: cssVar['color-bg-secondary'],
  tertiary: cssVar['color-bg-tertiary'],
  contrast: cssVar['color-bg-contrast'],
  ['contrast-reverse']: cssVar['color-bg-contrast-reverse'],
  transparent: 'transparent',
};

export interface CssBackgroundProp {
  background?: AppThemeBackground;
}

export const getCssBackground = (defaultBackground?: AppThemeBackground) => css<CssBackgroundProp>`
  ${(p) => {
    const bgKey = p.background || defaultBackground;
    if (bgKey) {
      return css`
        background: ${backgrounds[bgKey]};
      `;
    }
    return ``;
  }}
`;
