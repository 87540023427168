import { SQLiteDBConnection } from '@capacitor-community/sqlite';

export const SQLITE_DB_VERSION = 5;

export const migrateSchema = async (db: SQLiteDBConnection, storeName: string): Promise<void> => {
  // Get current schema version for the specific store
  const versionResult = await db.query('SELECT version FROM schema_version WHERE store_name = ?', [
    storeName,
  ]);
  const storedVersion =
    versionResult.values && versionResult.values.length > 0 ? versionResult.values[0].version : 0;

  if (storedVersion < SQLITE_DB_VERSION) {
    // Run migrations sequentially
    for (let version = storedVersion + 1; version <= SQLITE_DB_VERSION; version++) {
      await runMigration(db, version, storeName);
    }

    // Update stored version
    await db.run('INSERT OR REPLACE INTO schema_version (store_name, version) VALUES (?, ?)', [
      storeName,
      SQLITE_DB_VERSION,
    ]);
  }
};

const runMigration = async (
  db: SQLiteDBConnection,
  version: number,
  storeName: string,
): Promise<void> => {
  console.log(`Running migration to version ${version} for store ${storeName}`);
  switch (version) {
    case 1:
      await migrateToVersion1(db, storeName);
      break;
    case 2:
      await migrateToVersion2(db, storeName);
      break;
    case 3:
      await migrateToVersion3(db, storeName);
      break;
    case 4:
    case 5:
      await migrateToVersion4And5(db, storeName);
      break;
    case 6:
      await migrateToVersion6(db, storeName);
      break;
    default:
      console.warn(`No migration found for version ${version} and store ${storeName}`);
  }
};

const migrateToVersion1 = async (db: SQLiteDBConnection, storeName: string): Promise<void> => {
  await db.execute(`
    CREATE TABLE IF NOT EXISTS ${storeName} (
      key TEXT PRIMARY KEY,
      value TEXT
    )
  `);
};

const migrateToVersion2 = async (db: SQLiteDBConnection, storeName: string): Promise<void> => {
  await db.execute(`DROP TABLE IF EXISTS ${storeName}`);
  await db.execute(`
    CREATE TABLE IF NOT EXISTS ${storeName} (
      key TEXT PRIMARY KEY,
      value BLOB,
      type TEXT NOT NULL
    )
  `);
};

const migrateToVersion3 = async (db: SQLiteDBConnection, storeName: string): Promise<void> => {
  await db.execute(`DELETE FROM ${storeName}`);
};

const migrateToVersion4And5 = async (db: SQLiteDBConnection, storeName: string): Promise<void> => {
  // include a last updation date, for Garbage Collection
  await db.execute(`DROP TABLE IF EXISTS ${storeName}`);
  await db.execute(`
    CREATE TABLE IF NOT EXISTS ${storeName} (
      key TEXT PRIMARY KEY,
      value BLOB,
      type TEXT NOT NULL,
      last_updated INTEGER NOT NULL
    )
  `);
  // indexes, indexes, indexes
  await db.execute(
    `CREATE INDEX IF NOT EXISTS idx_${storeName}_last_updated ON ${storeName} (last_updated)`,
  );
};

const migrateToVersion6 = async (db: SQLiteDBConnection, storeName: string): Promise<void> => {
  // Due to an iOS bug with JSON parsing arrays, we are switching from storing a BLOB to instead
  // store a base64 encoded string, this should improve JSON parsing since there are no big
  // binary arrays.
  // Github issue: https://github.com/ionic-team/capacitor/issues/4308
  // And profiling showed that JSON parsing was a bottleneck, unsure if it's specific to arrays
  // like the github issue suggests, but it's definitely a problem.

  await db.execute(`DROP TABLE IF EXISTS ${storeName}`);
  await db.execute(`
    CREATE TABLE IF NOT EXISTS ${storeName} (
      key TEXT PRIMARY KEY,
      value TEXT,
      type TEXT NOT NULL,
      last_updated INTEGER NOT NULL
    )
  `);
  // indexes, indexes, indexes
  await db.execute(
    `CREATE INDEX IF NOT EXISTS idx_${storeName}_last_updated ON ${storeName} (last_updated)`,
  );
};
