import { CheckListItem, CheckListItemType } from '@/src/modules/onboarding/onboarding.types';
import { checkListItemTaskFactory } from '@/src/modules/onboarding/onboarding.utils';
import { isTruthy } from '@/src/utils/guards';

const envConfig = {
  FABRIC_QUEST_APIKEY: process.env.NEXT_PUBLIC_QUEST_APIKEY,
  FABRIC_QUEST_ENTITYID: process.env.NEXT_PUBLIC_QUEST_ENTITYID,
  FABRIC_FEEDBACK_QUESTID: process.env.NEXT_PUBLIC_FEEDBACK_QUESTID,
  FABRIC_GETSTARTED_QUESTID: process.env.NEXT_PUBLIC_GETSTARTED_QUESTID,
  FABRIC_WALKTHROUGH_QUESTID: process.env.NEXT_PUBLIC_WALKTHROUGH_QUESTID,
  BACKEND_URL: 'https://api.questlabs.ai/',
} as const;

const allTruthy = Object.values(envConfig).every(isTruthy);

const questConfig = allTruthy
  ? (envConfig as {
      [K in keyof typeof envConfig]: NonNullable<(typeof envConfig)[K]>;
    })
  : null;

enum OnboardingCheckListActionId {
  WATCH_YOUTUBE_VIDEO = 'watch-youtube-video',
  INSTALL_NATIVE_APPS = 'install-native-apps',
  UPLOAD_FIRST_FILE = 'upload-first-file',
  CREATE_FIRST_NOTEPAD = 'create-first-notepad',
  SAVE_FIRST_LINK = 'save-first-link',
  ASK_AI_SOMETHING = 'ask-ai-something',
  ADD_FIRST_CONNECTION = 'add-first-connection',
  TRY_AI_SEARCH = 'try-ai-search',
  CHECKOUT_FABRIC_GUIDE = 'checkout-fabric-guide',
}

const onboardingCheckList: CheckListItem[] = [
  {
    type: CheckListItemType.LINK,
    actionId: OnboardingCheckListActionId.WATCH_YOUTUBE_VIDEO,
    content:
      'Watch the Fabric video [here](https://www.youtube.com/watch?v=rfoyy1Bwow8) ![Youtube Video](/images/onboarding/watch-youtube-video.svg)',
  },
  {
    type: CheckListItemType.LINK,
    actionId: OnboardingCheckListActionId.INSTALL_NATIVE_APPS,
    content:
      'Get the Fabric desktop and mobile apps, and browser extensions [here](/settings/apps-extensions) ![Fabric Apps](/images/onboarding/install-native-apps.svg#no-mobile)',
  },
  checkListItemTaskFactory({
    type: CheckListItemType.TASK,
    actionId: OnboardingCheckListActionId.UPLOAD_FIRST_FILE,
    content: 'Upload your first file ![Upload File](/images/onboarding/upload-first-file.svg)',
    actionUrl: '?new=stored_file',
  }),
  checkListItemTaskFactory({
    type: CheckListItemType.TASK,
    actionId: OnboardingCheckListActionId.CREATE_FIRST_NOTEPAD,
    content: 'Create your first note ![Upload File](/images/onboarding/create-first-notepad.svg)',
    actionUrl: '?new=notepad',
  }),
  checkListItemTaskFactory({
    type: CheckListItemType.TASK,
    actionId: OnboardingCheckListActionId.SAVE_FIRST_LINK,
    content: 'Save your first link ![Upload File](/images/onboarding/save-first-link.svg)',
    actionUrl: '?new=page',
  }),
  checkListItemTaskFactory({
    type: CheckListItemType.TASK,
    actionId: OnboardingCheckListActionId.ASK_AI_SOMETHING,
    content:
      'Ask the AI assistant ![Upload File](/images/onboarding/ask-ai-something.svg#no-mobile) to find something for you, or about a file/note',
    actionUrl: '?assistant=true',
  }),
  checkListItemTaskFactory({
    type: CheckListItemType.TASK,
    actionId: OnboardingCheckListActionId.ADD_FIRST_CONNECTION,
    content:
      'Add your first data connection ![Upload File](/images/onboarding/add-first-connection.svg)',
    actionUrl: '/add-connection',
  }),
  checkListItemTaskFactory({
    type: CheckListItemType.TASK,
    actionId: OnboardingCheckListActionId.TRY_AI_SEARCH,
    content: 'Try the AI search ![Upload File](/images/onboarding/try-ai-search.svg)',
    actionUrl: '?openSearch=global',
  }),
  {
    type: CheckListItemType.LINK,
    actionId: OnboardingCheckListActionId.CHECKOUT_FABRIC_GUIDE,
    content: 'Take a look at the Fabric help guide [here](https://docs.fabric.so/)',
  },
] as const;

export { onboardingCheckList, OnboardingCheckListActionId, questConfig };
