import Section from '@/src/modules/ui/components/Section';
import styled from 'styled-components';

const SectionBox = styled(Section)`
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
`;

export default Object.assign(SectionBox, { Title: Section.Title });
