import FabricApp from '@/src/components/FabricApp';
import { useInitializeTracker } from '@/src/lib/or';
import { createDefaultQueryClient } from '@/src/lib/react-query/defaultQueryClient';
import { NextPageWithAuth } from '@/src/types/page';
import ErrorBoundary from '@/src/views/errors/ErrorBoundary';
import '@/styles/index.scss';
import { AppProps } from 'next/app';
import Head from 'next/head';
import favicon from '../public/favicon.ico';

export const defaultQueryClient = createDefaultQueryClient();

interface Props extends AppProps {
  pageProps: {
    origin: string | undefined;
    referer: string | undefined;

    title: string;
    image: string;
    description: string;
    url: string;
    bigCard: boolean;
  };

  Component: NextPageWithAuth;
}

export default function NextApp({ Component, pageProps, router, __N_SSG, __N_SSP }: Props) {
  useInitializeTracker();

  return (
    <>
      <Head>
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="viewport"
          content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
        <link rel="icon" href={favicon.src} />
      </Head>
      <ErrorBoundary>
        <FabricApp
          Component={Component}
          pageProps={pageProps}
          router={router}
          __N_SSG={__N_SSG}
          __N_SSP={__N_SSP}
        />
      </ErrorBoundary>
    </>
  );
}
