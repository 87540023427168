export enum ErrorType {
  EXCEEDS_STORAGE_LIMIT = 'exceeds_storage_limit', // User exceeded their storage limit
  EXCEEDS_ITEM_LIMIT = 'exceeds_item_limit', // User exceeded their item limit
  FEATURE_NOT_AVAILABLE = 'feature_not_available', // This feature is not available to this user
  GOOGLE_DRIVE_TOO_MANY_MIRRORS = 'google_drive_too_many_mirrors', // User has too many mirrors
  GOOGLE_DRIVE_MIRROR_ALREADY_EXISTS = 'google_drive_mirror_already_exists', // User already has a mirror for this drive
  IMPORT_ALREADY_EXISTS = 'import_already_exists', // User already has an import for this file
  NOT_ENOUGH_CONTENT = 'not_enough_content', // Not enough content to generate a summary

  // Frontend only
  WOODY_NOT_READY = 'woody_not_ready', // Woody is not ready

  INTEGRATION_AUTHORIZATION_FAILED = 'err_authorization_failed',
}
