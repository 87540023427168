/**
 * @deprecated Use Radix Portal instead
 */
export const getModalPortal = () => {
  return document.getElementById('modal-portal') as HTMLElement;
};

/**
 * @deprecated Use Radix Portal instead
 */
export const getOffCanvasPortal = () => {
  return document.getElementById('off-canvas-portal') as HTMLElement;
};
