import { ImageAccessResponse, ImageInfoResponse } from '@/mobile/ScreenshotConnection';
import { createLogger } from '@/src/lib/logger/createLogger';
import { deviceType } from '@/src/modules/connections/connections.constants';
import WoodyClient from '@fabric/woody-client';

interface UploadConfig {
  client: WoodyClient;
  imageAccess: ImageAccessResponse;
}

const logger = createLogger('Screenshot Image Sync');

type UploadStatusCode =
  | 'UPLOADED'
  | 'ALREADY_UPLOADED'
  | 'ERROR'
  | 'SKIP_MISSING_IMAGE_IN_RESPONSE';

enum UploadErrorStatusCode {
  'PRESIGNED_ERROR' = 'PRESIGNED_ERROR',
  'FAILED_TO_UPLOAD' = 'FAILED_TO_UPLOAD',
  'FAILED_TO_CREATE_RECORD' = 'FAILED_TO_CREATE_RECORD',
}

/**
 * sync image response with server
 *
 * returns fulfilled promise with status code
 * either
 *    * the image is already synced
 *    * image has been uploaded and registered to the folder
 */
export const syncImageWithServer = async (
  imageResponse: ImageInfoResponse,
  config: UploadConfig,
): Promise<{
  code: UploadStatusCode;
}> => {
  const { client, imageAccess } = config;

  if (!('uniqueId' in imageAccess)) {
    throw new Error('Missing unique id in image access');
  }

  if (deviceType === null) {
    throw new Error('Unknown device type');
  }

  if (imageResponse.image === null) {
    logger.debug('Missing image in image response, skipping upload');
    return Promise.resolve({
      code: 'SKIP_MISSING_IMAGE_IN_RESPONSE',
    });
  }

  /*************************************************************************************************
   * Check if the image is already uploaded
   */
  const isUploadedResponse = await client.v2(
    {
      endpoint: '/v2/integrations/{integrationType}/external/{integrationExternalId}/hash',
      params: {
        integrationExternalId: imageAccess.uniqueId,
        integrationType: deviceType,
      },
    },
    {
      method: 'post',
      body: {
        hashes: [imageResponse.image.localIdentifier],
      },
    },
  );

  if (isUploadedResponse[imageResponse.image.localIdentifier] === true) {
    logger.debug('Image already uploaded, skipping');
    return Promise.resolve({
      code: 'ALREADY_UPLOADED',
    });
  }

  /*************************************************************************************************
   * START UPLOADING
   */

  const type = imageResponse.image.name.split('.').pop();
  const nameWithoutExtension = imageResponse.image.name.replace(/\.[^/.]+$/, '');

  /** GET TOKEN */
  const presignedResponse = await client.getPresignedPost(imageResponse.image.name);
  if (presignedResponse.error) {
    return Promise.reject({
      code: UploadErrorStatusCode.PRESIGNED_ERROR,
      error: presignedResponse.error,
    });
  }

  /** UPLOAD FILE */
  let key: string | null = null;

  const file = new File(
    [Buffer.from(imageResponse.image.base64String, 'base64')],
    imageResponse.image.name,
    {
      type: `image/${type ?? 'png'}`,
    },
  );

  try {
    key = await client.uploadFileR2(presignedResponse.data, file);
    logger.debug('File uploaded');
  } catch (error) {
    logger.error('Error uploading image', error);
    return Promise.reject({
      code: UploadErrorStatusCode.FAILED_TO_UPLOAD,
      error,
    });
  }

  /** CREATE RECORD */
  try {
    await client.v2(
      {
        endpoint: '/v2/integrations/{integrationType}/external/{integrationExternalId}/item',
        params: {
          integrationExternalId: imageAccess.uniqueId,
          integrationType: deviceType,
        },
      },
      {
        method: 'post',
        body: {
          sourceType: 'SCREENSHOT',
          data: {
            hash: imageResponse.image.localIdentifier,
            key,
            originUrl: null,
            takenAt: imageResponse.image.creationDate,
            title: nameWithoutExtension || 'Untitled',
          },
        },
      },
    );
  } catch (error) {
    return Promise.reject({
      code: UploadErrorStatusCode.FAILED_TO_CREATE_RECORD,
      error,
    });
  }

  return Promise.resolve({
    code: 'UPLOADED',
  });
};
