import EnterIcon from '@/public/images/icons/Enter.svg';
import { Kbd } from '@/src/modules/ui/components/Kbd';
import Modal from '@/src/modules/ui/components/Modal';
import { P } from '@/src/modules/ui/components/Typography';
import { ConfirmAlert } from '@/src/store/alerts';
import { isInteractiveElement } from '@/src/utils/elements';
import isHotkey from 'is-hotkey';

type ConfirmDisplayProps = {
  alert: ConfirmAlert;
};
const ConfirmDisplay: React.FC<ConfirmDisplayProps> = ({ alert }) => {
  const onConfirmEnter = (e: React.KeyboardEvent) => {
    // we check for interactive in case the user is focused on the cancel button and presses enter
    if (!isHotkey('enter', e) || isInteractiveElement(e.target)) return;

    e.preventDefault();
    alert.onConfirm();
  };

  return alert.fullScreen ? (
    <Modal open={true} onOpenChange={alert.onCancel}>
      <Modal.Portal>
        <Modal.Content width="full" height="full" dynamicHeight={false} onKeyDown={onConfirmEnter}>
          <Modal.Header padding="sm" variant="default" mobileOnly>
            {alert.title}
          </Modal.Header>
          <Modal.Body padding="sm">{alert.content}</Modal.Body>
          <Modal.Footer gap="lg" variant="fullscreen">
            <Modal.CancelButton onClick={alert.onCancel} withKbd>
              {alert.cancelLabel} <Kbd>ESC</Kbd>
            </Modal.CancelButton>
            <Modal.Button {...alert.confirmButtonProps} onClick={alert.onConfirm} withKbd>
              {alert.confirmLabel}{' '}
              <Kbd>
                <EnterIcon />
              </Kbd>
            </Modal.Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Portal>
    </Modal>
  ) : (
    <Modal open={true} onOpenChange={alert.onCancel}>
      <Modal.Portal>
        <Modal.Overlay visibleOnMobileViewport />
        <Modal.Content width="xs" onKeyDown={onConfirmEnter} disableMobileFullscreen>
          <Modal.Body padding="sm" style={{ gap: 20 }}>
            <Modal.Title size="xs" style={{ marginTop: '.125rem' }}>
              {alert.title}
            </Modal.Title>
            <P size="md">{alert.content}</P>

            <Modal.DoubleButtonContainer>
              <Modal.CancelButton onClick={alert.onCancel} withKbd>
                {alert.cancelLabel} <Kbd>ESC</Kbd>
              </Modal.CancelButton>
              <Modal.Button {...alert.confirmButtonProps} onClick={alert.onConfirm} withKbd>
                {alert.confirmLabel}{' '}
                <Kbd>
                  <EnterIcon />
                </Kbd>
              </Modal.Button>
            </Modal.DoubleButtonContainer>
          </Modal.Body>
        </Modal.Content>
      </Modal.Portal>
    </Modal>
  );
};

export default ConfirmDisplay;
