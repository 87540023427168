import { ErrorType } from '@/src/core/types/errors';
import { upgradePrompt } from '@/src/store/alerts';
import { WoodyError } from '@fabric/woody-client';

/**
 * Given an error, error type, or string, show the appropriate upgrade prompt if necessary.
 * @param error - The error to check for upgrade prompt.
 * @returns Whether an upgrade prompt was shown.
 * @example
 * showUpgradePrompt('exceeds_storage_limit');
 * showUpgradePrompt(ErrorType.EXCEEDS_ITEM_LIMIT);
 * showUpgradePrompt(new Error('exceeds_storage_limit'));
 * showUpgradePrompt({ detail: 'exceeds_storage_limit' });
 */
export const showUpgradePrompt = (error?: ErrorType | string | WoodyError | Error) => {
  const errorType =
    typeof error === 'string'
      ? error
      : typeof error === 'object' && 'detail' in error
        ? error.detail
        : typeof error === 'object' && 'message' in error
          ? error.message
          : typeof error === 'object' && 'type' in error
            ? error.type
            : undefined;

  switch (errorType) {
    case ErrorType.EXCEEDS_ITEM_LIMIT:
      upgradePrompt({
        id: 'quota-items-limit',
        title: 'Upgrade to store more than 1000 items',
      });
      return true;
    case ErrorType.EXCEEDS_STORAGE_LIMIT:
      upgradePrompt({
        id: 'quota-storage-limit',
        title: 'Upgrade to store more than 250mb',
      });
      return true;
    default:
      return false;
  }
};
