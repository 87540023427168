import { isDebugEnabled, isFabricDev } from '@/src/constants/env';
import { noop } from '@/src/utils/noop';
import * as Sentry from '@sentry/react';

/* eslint-disable @typescript-eslint/no-explicit-any */
type Log = (...params: any[]) => void;

const log: Log = (...params) => {
  console.log(...params);
};

/**
 * logs when isFabricDev is true
 * process.env.REACT_APP_ENV === 'develop'
 */
const logDevOnly: Log = (...params) => {
  if (isFabricDev) {
    console.log(...params);
  }
};

const warn: Log = (...params) => {
  console.warn(...params);
};

const error: Log = (...params) => {
  console.error(...params);
};

const info: Log = (...params) => {
  console.info(...params);
};

const debug: Log = (...params) => {
  console.debug(...params);
};

const debugAndCapture: Log = (...params) => {
  Sentry.captureEvent({
    logentry: {
      params,
    },
  });
  console.debug(...params);
};

const logger = {
  log,
  logDevOnly,
  warn,
  error,
  info,
  debug,
  debugAndCapture,
};

const createPrefixedLogger =
  (prefix: string, logger: Log): Log =>
  (...params) => {
    logger(`[${prefix}]`, ...params);
  };

export type PrefixedLogger = {
  [K in keyof typeof logger]: Log;
};

export const createLogger = (prefix: string, enabled: boolean = isDebugEnabled): PrefixedLogger => {
  return Object.entries(logger).reduce((res, val) => {
    const [key, logFunction] = val as [keyof typeof logger, Log];
    return {
      ...res,
      // error always enabled
      [key]: enabled || key === 'error' ? createPrefixedLogger(prefix, logFunction) : noop,
    };
  }, {} as PrefixedLogger);
};
