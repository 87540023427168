import { Fdoc } from '@/src/types/api';
import { QueryKey } from '@tanstack/react-query';
import { ResourceFilterOptions, ResourceSearchOptions } from '../resources.types';
import { isFilterQueryKey, isSearchQueryKey } from './queryKeyGuards';

// type ResourceFilterType = 'webnote' | 'notepad' | 'file' | 'folder';
// family?: 'highlight' | 'page' | 'generic_file' | 'image' | 'notepad';
// ResourceType = 'page' | 'image' | 'text' | 'notepad' | 'stored_file' | 'folder';

const filterOptionsTypeToResourceTypes = (type: string) => {
  switch (type) {
    case 'webnote':
      return ['page', 'text', 'image'];
    case 'notepad':
      return ['notepad'];
    case 'file':
      return ['stored_file'];
    case 'folder':
      return ['folder'];
    default:
      return [];
  }
};

const resourceMatchesFamilyFilter = (resource: Fdoc, family: string): boolean => {
  switch (family) {
    case 'highlight':
      return ['page', 'text', 'image'].includes(resource.type);
    case 'page':
      return ['page'].includes(resource.type);
    case 'notepad':
      return ['notepad'].includes(resource.type);
  }

  if (
    family === 'image' &&
    (resource.type === 'image' ||
      (resource.type === 'stored_file' && resource.data.contentType?.startsWith('image')))
  ) {
    return true;
  }

  return family === 'generic_file' && resource.type === 'stored_file';
};

const resourceMatchesFilterQuery = (resource: Fdoc, options?: ResourceFilterOptions): boolean => {
  if (!options) return true;

  if (options.filters?.label && resource.label !== options.filters.label) return false;
  if (options.filters?.list && options.filters.list !== resource.list) return false;
  if (
    options.filters?.tags &&
    !options.filters.tags.some((tag) =>
      resource.personal.tags.some((resourceTag) => resourceTag.id === tag),
    )
  )
    return false;

  if (
    options.filters?.type &&
    !options.filters.type.flatMap(filterOptionsTypeToResourceTypes).includes(resource.type)
  )
    return false;

  if (options.filters?.family && !resourceMatchesFamilyFilter(resource, options.filters.family))
    return false;

  if (options.filters?.hasSlug && !resource.slug) return false;

  return true;
};

const resourceMatchesSearchQuery = (resource: Fdoc, options?: ResourceSearchOptions): boolean => {
  if (!options) return true;

  if (
    options.filters?.family &&
    !options.filters.family.some((family) => resourceMatchesFamilyFilter(resource, family))
  )
    return false;
  if (
    options.filters?.tags &&
    !options.filters.tags.some((tag) =>
      resource.personal.tags.some((resourceTag) => resourceTag.id === tag),
    )
  )
    return false;
  if (
    options.filters?.type &&
    !options.filters.type.flatMap(filterOptionsTypeToResourceTypes).includes(resource.type)
  )
    return false;
  if (options.filters?.hasSlug && !resource.slug) return false;
  if (options.filters?.list && !options.filters.list.some((list) => resource.list === list))
    return false;
  if (options.filters?.label && !options.filters.label.some((label) => resource.label === label))
    return false;

  return true;
};

/**
 * Given a resource and a query key, determine if the resource matches the query filters.
 * This might be heavy so use with caution, this is useful for optimistic created resources which we want to avoid going into the wrong cached queries.
 */
export const resourceMatchesQueryFilters = (resource: Fdoc, queryKey: QueryKey) => {
  if (isFilterQueryKey(queryKey)) {
    return resourceMatchesFilterQuery(resource, queryKey[1]);
  }

  if (isSearchQueryKey(queryKey)) {
    return resourceMatchesSearchQuery(resource, queryKey[1]);
  }

  return false;
};
