import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import { omit, pick } from '@/src/lib/store';
import { useAssistantStore } from '@/src/modules/assistant/stores/assistantStore';
import { useRouter } from 'next/router';

const useAssistantParamIntent = () => {
  const router = useRouter();
  const { assistant } = router.query;
  const { setChatAssistantOpen } = useAssistantStore((s) => pick(s, ['setChatAssistantOpen']));

  useClientLayoutEffect(() => {
    if (!assistant || !router.isReady) return;

    const params = omit(router.query, ['assistant']);
    router.replace({ query: params }, undefined, { shallow: true });

    if (assistant === 'true') setChatAssistantOpen(true);
  }, [assistant, router.isReady]);
};

export { useAssistantParamIntent };
