import { FC } from 'react';
import { IconProps } from './types';
import clsx from 'clsx';

const CloseIcon: FC<IconProps> = ({ color = 'currentColor', size = 18, className, ...props }) => {
  return (
    <svg
      viewBox="0 0 18 18"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2729 3.18353C16.6746 2.78191 16.6746 2.13076 16.2729 1.72915C15.8713 1.32753 15.22 1.32753 14.8184 1.72915L1.72766 14.8186C1.32601 15.2202 1.32601 15.8714 1.72766 16.273C2.12932 16.6746 2.78053 16.6746 3.18219 16.273L16.2729 3.18353Z"
        fill={color}
      />
      <path
        d="M14.818 16.2711C15.2196 16.6728 15.8708 16.6728 16.2725 16.2711C16.6741 15.8695 16.6741 15.2184 16.2725 14.8168L3.18176 1.72729C2.78011 1.32568 2.1289 1.32568 1.72724 1.7273C1.32558 2.12891 1.32558 2.78006 1.72724 3.18168L14.818 16.2711Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
