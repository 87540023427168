import { create } from 'zustand';

import {
  ImageAccessResponse,
  ImageInfoResponse,
  ManagerState,
  PHAccessLevel,
  PHAuthorizationStatus,
  ScreenshotConnectionPlugin,
  ScreenshotManagerState,
} from '@/mobile/ScreenshotConnection';
import { useDebouncedCallback } from '@/src/hooks/useDebouncedCallback';
import { createLogger } from '@/src/lib/logger/createLogger';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { deviceType as deviceTypeImport } from '@/src/modules/connections/connections.constants';
import { useQueryConnectionByExternalId } from '@/src/modules/connections/queries/useQueryConnectionByExternalId';
import { syncImageWithServer } from '@/src/modules/connections/utils/syncImageWithServer';
import { promiseRetry } from '@/src/utils/promiseRetry';
import { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { useAuthUser } from '../hooks/auth';
import { isInMobile } from '../hooks/mobile';
import { pick } from '../lib/store';
import { useWoody } from '../services/woody/woody';

interface ScreenshotsStore {
  // Hash is a safe string to use to avoid rerenders,
  // it will be set once when the store is created or reset.
  hash: string | null;
  managerState: ScreenshotManagerState;
  imageAccess: ImageAccessResponse;
  isRegistered: boolean;
  uploading: boolean;

  // Sync folder represents the folder that is currently syncing to
  // if it changes it's safe to re-initialize the native side
  syncFolder: string | null;

  setUploading: (uploading: boolean) => void;
  setManagerState: (managerState: ScreenshotManagerState) => void;
  setImageAccess: (imageAccess: ImageAccessResponse) => void;
  setIsRegistered: (isRegistered: boolean) => void;

  setSyncFolder: (syncFolder: string | null) => void;

  reset: () => void;

  // listen to reset calls, returns a function to remove the listener
  onReset: (callback: () => void) => () => void;
  resetListenerCallbacks: (() => void)[];
}

const useScreenshotsStore = create<ScreenshotsStore>()(
  persist(
    (set, get) => ({
      hash: v4(),
      uploading: false,
      managerState: {
        state: ManagerState.Standby,
        totalCount: 0,
        activeCount: 0,
        processedCount: 0,
      },

      imageAccess: {
        accessLevel: PHAccessLevel.ReadWrite,
        status: PHAuthorizationStatus.NotDetermined,
      },
      isRegistered: false,
      syncFolder: null,

      setManagerState: (managerState: ScreenshotManagerState) => set({ managerState }),
      setUploading: (uploading: boolean) => set({ uploading }),

      setImageAccess: (imageAccess: ImageAccessResponse) => set({ imageAccess }),
      setIsRegistered: (isRegistered: boolean) => set({ isRegistered }),
      setSyncFolder: (syncFolder: string | null) => set({ syncFolder }),

      resetListenerCallbacks: [] as (() => void)[],
      onReset: (callback: () => void) => {
        logger.debug('on reset');
        set((state) => ({ resetListenerCallbacks: [...state.resetListenerCallbacks, callback] }));
        return () => {
          set((state) => ({
            resetListenerCallbacks: state.resetListenerCallbacks.filter((cb) => cb !== callback),
          }));
        };
      },

      reset: () => {
        logger.debug('reset');
        set({
          hash: v4(),
          uploading: false,
          managerState: {
            state: ManagerState.Standby,
            totalCount: 0,
            activeCount: 0,
            processedCount: 0,
          },

          imageAccess: {
            accessLevel: PHAccessLevel.ReadWrite,
            status: PHAuthorizationStatus.NotDetermined,
          },
          isRegistered: false,
          syncFolder: null,
        });

        if (isInMobile()) ScreenshotConnectionPlugin.reset();

        // Call all reset listeners
        get().resetListenerCallbacks.forEach((cb) => cb());
      },
    }),
    {
      name: 'fabric-mobile-screenshots-plugin',
      version: 1.1,
      partialize: (keys) => pick(keys, ['imageAccess', 'isRegistered']),
    },
  ),
);

const logger = createLogger('Use screenshot store');

export const useScreenshotsSync = () => {
  const { client } = useWoody();
  const deviceType = deviceTypeImport; // if it's not declared here, TS throws issues (doesn't infer when e.g. filtered out with if (deviceType))
  const user = useAuthUser();
  const {
    imageAccess,
    isRegistered,
    setIsRegistered,
    setImageAccess,
    setManagerState,
    setUploading,
    managerState,
    reset,
    syncFolder,
    setSyncFolder,
    hash,
    onReset,
  } = useScreenshotsStore(
    (state) =>
      pick(state, [
        'imageAccess',
        'isRegistered',
        'setIsRegistered',
        'setImageAccess',
        'setManagerState',
        'setUploading',
        'managerState',
        'reset',
        'syncFolder',
        'setSyncFolder',
        'hash',
        'onReset',
      ]),
    shallow,
  );

  const setManagerStateDebounced = useDebouncedCallback(setManagerState, 300);

  useEffect(() => {
    if (!isInMobile()) return;

    logger.debug('Adding listener for screenshotManagerState');
    const event = ScreenshotConnectionPlugin.addListener('screenshotManagerState', (state) => {
      logger.debug('Manager updated', state);
      setManagerStateDebounced(state);
    });

    return () => {
      Promise.resolve(event).then((e) => e.remove());
    };
  }, [setManagerStateDebounced, hash]);

  useEffect(() => {
    if (!isInMobile() || !user) return;

    const getImageAccess = async () => {
      const response = await ScreenshotConnectionPlugin.hasImageAccess();
      logger.debug('Get image access', response);
      setImageAccess(response);
    };

    getImageAccess();
  }, [setImageAccess, user, hash]);

  const initializedSyncFolder = useRef<string | null>(null);

  const uniqueId = 'uniqueId' in imageAccess ? imageAccess.uniqueId : null;
  const { data: integration, isLoading: integrationLoading } = useQueryConnectionByExternalId(
    uniqueId ?? undefined,
    deviceType ?? undefined,
    {
      enabled: isQueryEnabled([
        !!uniqueId,
        !!deviceType,
        imageAccess.status === PHAuthorizationStatus.Authorized ||
          imageAccess.status === PHAuthorizationStatus.Limited,
      ]),
    },
  );

  useEffect(() => {
    if (integrationLoading) return;

    setIsRegistered(integration?.status === 'ACTIVE');
    setSyncFolder(integration?.id ?? null);
  }, [
    client,
    imageAccess.status,
    imageAccess,
    setIsRegistered,
    setSyncFolder,
    integration,
    integrationLoading,
  ]);

  // Always try at least once, so by default it's true.
  const [hasNext, setHasNext] = useState(true);
  const [hasCursor, setHasCursor] = useState(false);

  // Run screenshot manager initialization as soon as the app is ready, once.
  useEffect(() => {
    if (
      !isRegistered ||
      (imageAccess?.status !== PHAuthorizationStatus.Authorized &&
        imageAccess?.status !== PHAuthorizationStatus.Limited) ||
      !user ||
      !deviceType ||
      initializedSyncFolder.current === syncFolder
    )
      return;

    const initialize = async () => {
      logger.debug('Initializing screenshot');
      try {
        initializedSyncFolder.current = syncFolder;

        const response = await client.v2('/v2/integrations/cursor', {
          method: 'get',
          query: {
            type: deviceType,
            externalId: imageAccess.uniqueId,
          },
        });

        setHasCursor(!!response.cursor?.hash);

        console.log('Screenshot: initialize');
        await ScreenshotConnectionPlugin.initialize({
          userId: user.id,
          registered: isRegistered,
          lastUploadedPhotoDate: response.cursor?.userCreatedAt ?? null,
          lastUploadedPhotoCursor: response.cursor?.hash ?? null,
          lastUploadParamsLoadedForUserID: user.id,
        });
        setHasNext(true);
      } catch (e) {
        console.error('Screenshot: initialize error', e);
        // Ignore errors here.
      }
    };

    initialize();
  }, [client, imageAccess?.status, imageAccess, isRegistered, user, syncFolder, deviceType]);

  useEffect(() => {
    if (!managerState || managerState.activeCount === 0) return;

    // If there are active screenshots, we have to assume there are more.
    logger.debug('Setting has next to true');
    setHasNext(true);
  }, [managerState]);

  // This prevents the `useEffect` below to re-mount multiple times when it's uploading.
  const managerStateRef = useRef(managerState);
  managerStateRef.current = managerState;

  useEffect(() => {
    if (
      !isRegistered ||
      (imageAccess?.status !== PHAuthorizationStatus.Authorized &&
        imageAccess?.status !== PHAuthorizationStatus.Limited) ||
      !hasNext ||
      managerState.state !== ManagerState.Active ||
      !user ||
      !deviceType
    )
      return;

    // Added aborted here to prevent multiple sync processes from running at the same time if the `useEffect` rerenders.
    let aborted = false;

    const syncV2 = async () => {
      if (aborted) return;

      const stopSync = () => {
        aborted = true;
        setUploading(false);
        setHasNext(false);
      };

      /**
       * @TODO add max retry count
       */
      const retrySync = () => {
        setTimeout(() => {
          syncV2();
        }, 10000);
      };

      /**
       * do not continue if there are no active items
       */
      if (managerStateRef.current.activeCount === 0) {
        logger.debug('No image to sync');
        stopSync();
        return;
      }

      let imageResponse: ImageInfoResponse | null = null;

      /**
       * get next image
       */
      try {
        imageResponse = await ScreenshotConnectionPlugin.getNextImage();
        /**
         * there's no new image to upload
         * this should happen as it's guarded by managerStateRef.current.activeCount
         * but maybe multiple syncs are running? Could happen if multiple hooks are mounted probably
         */
        if (imageResponse.image === null) {
          logger.debug('No image to sync');
          stopSync();
          return;
        }
      } catch (error) {
        logger.error('Failed to retrieve image response', error);
        retrySync();
      }

      /**
       * try upload
       */
      if (imageResponse && imageResponse.image) {
        try {
          logger.debug('Image sync started', imageResponse.image);
          await promiseRetry(
            () =>
              syncImageWithServer(imageResponse, {
                client,
                imageAccess,
              }),
            {
              delayFn: promiseRetry.increasedDelayWithEachAttempt(1000),
              loggerKey: 'syncImageWithServer',
              maxRetries: 10,
            },
          );

          logger.debug('Image sync finished');
        } catch (e) {
          /**
           * either presigned key or upload failed, try again after some time
           */

          logger.error('Error uploading image. SKIPPING', e);
        } finally {
          /**
           * we skip the image if it's not uploaded in max retries
           */
          await ScreenshotConnectionPlugin.updateCursor({
            userId: user.id,
            cursor: imageResponse.image.localIdentifier,
            // creation date has to be in ISO 8601 format
            date: new Date(imageResponse.image.creationDate).toISOString(),
          });

          /**
           * image is synced, update cursor
           */
          setHasCursor(true);
          logger.debug('Cursor moved');
          syncV2();
        }
      }
    };

    syncV2();

    return () => {
      aborted = true;
    };
  }, [
    client,
    imageAccess?.status,
    imageAccess,
    isRegistered,
    hasNext,
    setUploading,
    managerState.state,
    user,
    deviceType,
  ]);

  useEffect(() => {
    if (
      !isRegistered ||
      (imageAccess?.status !== PHAuthorizationStatus.Authorized &&
        imageAccess?.status !== PHAuthorizationStatus.Limited) ||
      !hasNext ||
      managerState.state !== ManagerState.Active ||
      !user ||
      !isInMobile() ||
      !syncFolder
    )
      return;

    // if the manager.activeCount is negative, it means something went wrong
    // we run a reset followed by a initialize to try and fix it
    // also if the activeCount is 0 and the processed/total > 0 and the cursor returned by
    // the server is null it also means something went wrong and we need to reset as well
    // this can also occur when deleting an integration, it doesn't process the newer screenshots then

    if (
      managerState.activeCount < 0 ||
      (managerState.activeCount <= 0 &&
        (managerState.processedCount > 0 || managerState.totalCount > 0) &&
        !hasCursor)
    ) {
      setSyncFolder(null);
      initializedSyncFolder.current = null;
      setHasNext(true);
      reset();
    }
  }, [
    client,
    hasCursor,
    imageAccess?.status,
    imageAccess,
    isRegistered,
    hasNext,
    setUploading,
    managerState.state,
    user,
    managerState.activeCount,
    managerState.processedCount,
    managerState.totalCount,
    reset,
    syncFolder,
    setSyncFolder,
  ]);

  useEffect(() => {
    const remove = onReset(() => {
      setHasNext(true);
      setHasCursor(false);
      initializedSyncFolder.current = null;
    });

    return remove;
  });
};

export default useScreenshotsStore;
