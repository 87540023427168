import { CURRENT_URL } from '@/src/constants/env';
import { MOBILE_AUTH_DEEPLINK } from '@/src/lib/mobile/oauth';
import { getWoodyClient } from '@/src/services/woody/woody';

export type OAuthTypes = 'google'; // only google for now

export const authApi = {
  /**
   * Retrieves CSFR token from the server to perform auth actions
   * Unsure whether the token persists so it should be retrieved before each action which requires the oken
   * https://discord.com/channels/934045073002364981/1281188775405420575/1281526966817914923
   */
  getCSFRToken: async () => {
    const res = await getWoodyClient().v2('/v2/csrf-token');
    return res['csrf-token'];
  },
  getOauthUrl: async (options: { provider: OAuthTypes; asDeepLink: boolean }) => {
    const asDeepLink = options.asDeepLink ?? false;
    const provider = options.provider ?? 'google';

    const redirectUri = asDeepLink ? `${MOBILE_AUTH_DEEPLINK}://oauth` : `${CURRENT_URL}/oauth`;

    return getWoodyClient().v2(
      {
        endpoint: '/v2/authentication/oauth/{provider}',
        params: {
          provider,
        },
      },
      {
        method: 'get',
        query: {
          redirectUri,
        },
      },
    );
  },
};
