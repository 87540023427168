import Link from 'next/link';
import React from 'react';

import styles from './FabricError.module.scss';

const FabricError: React.FC<React.PropsWithChildren> = ({ children }) => {
  const reload = () => {
    window.location.href = '/';
  };

  return (
    <div className={styles.error}>
      <header>
        <Link href="/" className={styles.dashboard_button} onClick={reload}>
          ← dashboard
        </Link>
      </header>
      <div className={styles.spacer} />
      <div className={styles.error_content}>{children}</div>
      <div className={styles.spacer} />
    </div>
  );
};

export default FabricError;
