import { ResourceRoot } from '@/src/modules/resource-roots/resource-roots.types';
import { Space } from '@/src/modules/spaces/spaces.types';

export const convertResourceRootToSpace = (resourceRoot: ResourceRoot): Space => {
  const integration = 'integration' in resourceRoot ? resourceRoot.integration : null;
  return {
    id: resourceRoot.folder.id,
    type: resourceRoot.type,
    title: resourceRoot.folder.name || 'New space',
    role: resourceRoot.folder.permissions.role,
    integration: integration?.id || null,
    slug: undefined, // @TODO
    isPublic: resourceRoot.folder.isPublic,
    createdAt: resourceRoot.createdAt,
    modifiedAt: resourceRoot.modifiedAt,
    externalId: integration?.externalId || null,
    owner: undefined, // @TODO
    labels: undefined,
    _meta: {
      isDeleting: false,
      isUpdating: false,
    },
  };
};
