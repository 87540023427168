import { useEffect, useRef } from 'react';

/**
 * Runs when a component is unmounted without any dependencies.
 * This is useful for cleanup functions, where it doesn't make sense to run them more than once.
 * @param func - The function to run when the component is unmounted.
 */
export function useUnmount(func: () => void, avoidStrictMode = false) {
  const funcRef = useRef(func);
  const avoidStricModeRef = useRef(avoidStrictMode);
  const strictTimeout = useRef<number>();

  funcRef.current = func;
  avoidStricModeRef.current = avoidStrictMode;

  useEffect(() => {
    clearTimeout(strictTimeout.current);

    return () => {
      /**
       * If we are in a non production environment there is a high chance
       * that strict mode is enabled. This means that the component will
       * be mounted and unmounted twice. This is problematic if unmount function
       * runs any irreversible actions.
       */

      if (avoidStricModeRef.current && process.env.REACT_APP_ENV !== 'production') {
        strictTimeout.current = window.setTimeout(() => {
          funcRef.current();
        }, 0);
      } else {
        funcRef.current();
      }
    };
  }, []);
}
