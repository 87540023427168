import { database } from '@/src/lib/storage/global';
import { isDefined } from '@/src/lib/utils';
import { QueryClient } from '@tanstack/react-query';
import { AsyncStorage, PersistedQuery } from '@tanstack/react-query-persist-client';
import SQLiteDBStorage from '../storage/SQLiteDBStorage';

export const persisterQueriesStoreName = 'persistedQueries' as const;

/**
 * Creates and IndexedDB storage AsyncStorage
 */
export function createIDBStorage(): AsyncStorage<PersistedQuery> {
  return {
    getItem: async (key) => {
      return await database?.getItem(persisterQueriesStoreName, key);
    },
    setItem: async (key, value) => {
      await database?.setItem(persisterQueriesStoreName, key, value);
    },
    removeItem: async (key) => {
      await database?.removeItem(persisterQueriesStoreName, key);
    },
  };
}

export async function restoreQueryClientCache(
  queryClient: QueryClient,
  buster: string,
  restored: () => void,
) {
  if (!database || database instanceof SQLiteDBStorage) {
    return;
  }

  const loadQuery = (queryData: PersistedQuery) => {
    if (queryData?.buster !== buster) {
      return;
    }

    if (!queryData?.state?.data) {
      return;
    }

    const state = queryClient.getQueryState(queryData.queryKey);

    // If the query is already in the memory cache, do not restore it,
    // otherwise we risk overwriting a failure state.
    if (state) return;

    queryClient.setQueryData(
      queryData.queryKey,
      (data: unknown) => {
        return !isDefined(data) ? queryData.state.data : undefined;
      },
      {
        updatedAt: queryData.state.dataUpdatedAt,
      },
    );
  };

  for (const item of await database.getAllItems<PersistedQuery>(persisterQueriesStoreName)) {
    loadQuery(item);
  }

  restored();
}
