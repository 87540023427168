import { css } from 'styled-components';
import { viewportBreakpoints } from './constants/breakpoints';

export const mediaDesktop = `@media screen and (min-width: ${viewportBreakpoints.TABLET + 1}px)`;
export const mediaTablet = `@media screen and (max-width: ${viewportBreakpoints.TABLET}px)`;
export const mediaMobile = `@media screen and (max-width: ${viewportBreakpoints.MOBILE}px)`;
export const mediaNotMobile = `@media screen and (min-width: ${viewportBreakpoints.MOBILE + 1}px)`;
export const mediaSidebarBottom = `@media screen and (max-width: ${viewportBreakpoints.SIDEBAR_BOTTOM}px)`;
export const mediaXS = `@media screen and (max-width: ${viewportBreakpoints.XS}px)`;
export const mediaHover = `@media (hover: hover) and (pointer: fine)`;

export const cssTextEllipsis = css`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const cssLineClamp = (lines: number) => css`
  ${cssTextEllipsis};
  @supports (-webkit-line-clamp: ${lines}) {
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
  }
`;

export const hasHover = `@media (hover: hover)`;
