/**
 * @TODO export woodyClientResponse from Woody client
 */

type WoodyError$1 = {
  title: string;
  status: number;
  detail?: string;
};

type WoodyClientResponse<T> = {
  status: number;
  headers: {
    [k: string]: string;
  };
  error: WoodyError$1 | null;
  data: T;
};

/**
 * If the response is an error, throw an error
 * @param response
 * @returns
 */
export const getWoodyResponseData = <T>(response: WoodyClientResponse<T>) => {
  if (response.error) {
    throw response.error;
  }

  return response.data;
};
