import { Button } from '@/src/modules/ui/components/Button';
import { v4 } from 'uuid';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const TOAST_DURATION = 5000;
const WARNING_DURATION = 20000; // 20 seconds

type BaseAlert = {
  id: string;
};

type Toast = {
  type: 'toast';
  content: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
};

type Warning = {
  type: 'warning';
  content: React.ReactNode;
  timeout?: number;
  disableTimeout?: boolean;
};

type BannerAction =
  | {
      type: 'url';
      url: string;
      label: string;
    }
  | {
      type: 'refresh';
      label: string;
    }
  | {
      type: 'path';
      path: string;
      label: string;
    };

type Banner = {
  type: 'banner';
  severity: 'announcement' | 'danger' | 'warning' | 'info';
  title: string;
  content: React.ReactNode;
  action?: BannerAction | null;
};

type Confirm = {
  type: 'confirm';
  onConfirm: () => void;
  onCancel: () => void;

  fullScreen?: boolean;

  title: string;
  content: React.ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;

  confirmButtonProps?: Omit<React.ComponentProps<typeof Button>, 'color'>;
};

type UpgradePrompt = {
  type: 'upgrade_prompt';
  title: string;
  limitBadge?: string;
};

export type ToastAlert = BaseAlert & Toast;
export type WarningAlert = BaseAlert & Warning;
export type BannerAlert = BaseAlert & Banner;
export type ConfirmAlert = BaseAlert & Confirm;
export type UpgradePromptAlert = BaseAlert & UpgradePrompt;

export type Alert = ToastAlert | WarningAlert | BannerAlert | ConfirmAlert | UpgradePromptAlert;

type BaseAlertOptions = {
  id?: string;
  replace?: boolean;
};

type ToastOptions = Omit<Toast, 'type'> & BaseAlertOptions;
type WarningOptions = Omit<Warning, 'type'> & BaseAlertOptions;
type BannerOptions = Omit<Banner, 'type'> & BaseAlertOptions;
export type ConfirmOptions = Omit<Confirm, 'type'> & BaseAlertOptions;
type UpgradePromptOptions = Omit<UpgradePrompt, 'type'> & BaseAlertOptions;

type AlertOptions = BaseAlertOptions & (Toast | Warning | Banner | Confirm | UpgradePrompt);
export type AlertElement<T extends Alert['type']> = React.FC<{
  alert: Extract<Alert, { type: T }>;
}>;

interface AlertStore {
  alerts: Alert[];

  banner?: BannerAlert;
  lastDismissedBanner?: string;

  addAlert: (alertOptions: AlertOptions) => Alert | undefined;
  removeAlert: (id: string) => void;
  clearAlerts: () => void;
  dismissBanner: () => void;
}

const useToastStore = create<AlertStore>()(
  persist(
    (set, get) => ({
      alerts: [],
      banner: undefined,
      lastDismissedBanner: undefined,

      addAlert: (alertOptions) => {
        // banner is a special alert that can only be one at a time
        if (alertOptions.type === 'banner') {
          // if the id is the last dismissed banner, we don't show it
          if (alertOptions.id === get().lastDismissedBanner) return undefined;

          const banner = { ...alertOptions, id: alertOptions.id ?? v4() };
          set({ banner });
          return banner;
        }

        // if an ID is provided and it already exists, we simply return it
        const existingAlert = get().alerts.find((alert) => alert.id === alertOptions.id);
        if (existingAlert && alertOptions.replace !== true) return existingAlert;

        const alert = { ...alertOptions, id: alertOptions.id ?? v4() };

        // if it's a toast we add it to the end of the array
        // if it's a warning we add it to the beginning of the array

        const alerts = existingAlert ? get().alerts.filter((a) => a.id !== alert.id) : get().alerts;

        set({
          alerts: alert.type === 'toast' ? [...alerts, alert] : [alert, ...alerts],
        });

        return alert;
      },
      removeAlert: (id) =>
        set({
          alerts: get().alerts.filter((alert) => alert.id !== id),
        }),
      clearAlerts: () => set({ alerts: [] }),
      dismissBanner: () => {
        const { banner } = get();
        if (!banner) return;

        set({
          banner: undefined,
          lastDismissedBanner: banner.id,
        });
      },
    }),
    {
      name: 'alerts',
      partialize: (state) => ({
        lastDismissedBanner: state.lastDismissedBanner,
      }),
    },
  ),
);

// a direct toast function that can be used anywhere and isn't a hook, so it doesn't need to be imported first to be used inside effects
export const toast = (toastOptions: ToastOptions) =>
  useToastStore.getState().addAlert({
    ...toastOptions,
    type: 'toast',
  });

export const warning = (warningOptions: WarningOptions) =>
  useToastStore.getState().addAlert({
    ...warningOptions,
    type: 'warning',
    timeout: warningOptions.timeout ?? WARNING_DURATION,
  });

export const banner = (bannerOptions: BannerOptions) =>
  useToastStore.getState().addAlert({
    ...bannerOptions,
    type: 'banner',
  });

export const confirm = async (confirmOptions: Omit<ConfirmOptions, 'onConfirm' | 'onCancel'>) =>
  new Promise<boolean>((resolve) => {
    const id = useToastStore.getState().addAlert({
      ...confirmOptions,
      type: 'confirm',
      onConfirm: () => {
        if (!id) {
          resolve(false);
          return;
        }
        useToastStore.getState().removeAlert(id);
        resolve(true);
      },
      onCancel: () => {
        if (!id) {
          resolve(false);
          return;
        }

        useToastStore.getState().removeAlert(id);
        resolve(false);
      },
    })?.id;
  });

export const upgradePrompt = (upgradePromptOptions: UpgradePromptOptions) =>
  useToastStore.getState().addAlert({
    ...upgradePromptOptions,
    type: 'upgrade_prompt',
  });

export const removeAlert = (id: string) => useToastStore.getState().removeAlert(id);

export default useToastStore;
