import { isInMobile } from '@/src/hooks/mobile';
import IndexedDBStorage from '@/src/lib/storage/IndexedDBStorage';
import SQLiteDBStorage from '@/src/lib/storage/SQLiteDBStorage';
import { KeyValStorageInterface } from '@/src/lib/storage/types';
import inNextServer from '@/src/utils/next';
import { Capacitor } from '@capacitor/core';

class GlobalRef<T> {
  private readonly sym: symbol;

  constructor(uniqueName: string) {
    this.sym = Symbol.for(uniqueName);
  }

  get value(): T | undefined {
    return (globalThis as Record<symbol | string, unknown>)[this.sym] as T | undefined;
  }

  set value(value: T) {
    (global as Record<symbol | string, unknown>)[this.sym] = value;
  }
}

const databaseConn = new GlobalRef<KeyValStorageInterface>('fabric.db');

if (!databaseConn.value && !inNextServer()) {
  databaseConn.value =
    isInMobile() && Capacitor.isPluginAvailable('CapacitorSQLite')
      ? new SQLiteDBStorage('fabric-db')
      : new IndexedDBStorage('fabric-db');
}

export const database = databaseConn.value;
