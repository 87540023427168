import { isInDesktop } from '@/src/hooks/todesktop';
import { Capacitor } from '@capacitor/core';

export const getDevice = (): 'desktop' | 'android' | 'ios' | 'web' => {
  if (isInDesktop()) return 'desktop';
  if (Capacitor.isNativePlatform()) {
    if (Capacitor.getPlatform() === 'ios') return 'ios';
    if (Capacitor.getPlatform() === 'android') return 'android';
  }
  return 'web';
};
