import { useRunOnce } from '@/src/hooks/useRunOnce';
import { getVersions, VersionInfo } from '@/src/hooks/useVersionCheck';
import { getDevice } from '@/src/lib/capacitor/device';
import { createLogger } from '@/src/lib/logger/createLogger';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface DeviceInfoState {
  platform: ReturnType<typeof getDevice>;
  refresh: () => Promise<void>;
  versionInfo: VersionInfo | null;
  isInMobile: boolean;
}

const logger = createLogger('DeviceInfoStore', true);
export const useDeviceInfoStore = create<DeviceInfoState>()(
  persist(
    (set) => {
      const platform = getDevice();
      return {
        platform: getDevice(),
        isInMobile: ['ios', 'android'].includes(platform),
        versionInfo: null,
        refresh: async () => {
          logger.debug('Refreshing device info store');
          return set({ platform: getDevice(), versionInfo: await getVersions() });
        },
      };
    },
    {
      name: 'device-info',
    },
  ),
);

export const useInitializeDeviceInfoStore = () => {
  useRunOnce(useDeviceInfoStore.getState().refresh);
};
