import { useDevelopmentFlag } from '@/src/hooks/useDevelopmentFlag';
import produce from 'immer';
import { share } from 'shared-zustand';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';

export enum FeatureFlag {
  EXPERIMENTAL_ASSISTANT = 'EXPERIMENTAL_ASSISTANT',
  NOTIFICATIONS = 'NOTIFICATIONS',
  REACT_QUERY_DEVTOOLS = 'REACT_QUERY_DEVTOOLS',
  RESOURCE_CARDS_V2 = 'RESOURCE_CARDS_V2',
  GENERATED_SPACES = 'GENERATED_SPACES',
  QUESTLABS_TOOLTIP = 'QUESTLABS_TOOLTIP',
}

interface FeatureFlagsStore {
  flags: Record<FeatureFlag, boolean>;
  setFlag: (flag: FeatureFlag, value: boolean) => void;
  toggleFlag: (flag: FeatureFlag) => void;
}

const initialFlags: Record<FeatureFlag, boolean> = {
  [FeatureFlag.EXPERIMENTAL_ASSISTANT]: false,
  [FeatureFlag.NOTIFICATIONS]: false,
  [FeatureFlag.REACT_QUERY_DEVTOOLS]: false,
  [FeatureFlag.RESOURCE_CARDS_V2]: false,
  [FeatureFlag.GENERATED_SPACES]: false,
  [FeatureFlag.QUESTLABS_TOOLTIP]: false,
};

const useFeatureFlagsStore = create<FeatureFlagsStore>()(
  subscribeWithSelector(
    persist(
      (set, get) => ({
        flags: initialFlags,
        setFlag: (flag, value) =>
          set(
            produce(get(), (draft) => {
              draft.flags[flag] = value;
            }),
          ),
        toggleFlag: (flag) =>
          set(
            produce(get(), (draft) => {
              draft.flags[flag] = !draft.flags[flag];
            }),
          ),
      }),
      {
        name: 'feature-flags',
        version: 1.1,
        migrate: (persistedState) => {
          const state = persistedState as FeatureFlagsStore;

          // remove any flags not in the enum
          const flags = Object.values(FeatureFlag);
          const newState = {
            ...state,
            flags: Object.fromEntries(
              flags.map((flag) => [flag, state.flags[flag] ?? initialFlags[flag]]),
            ) as Record<FeatureFlag, boolean>,
          };

          return newState;
        },
      },
    ),
  ),
);

if ('BroadcastChannel' in globalThis) {
  share('flags', useFeatureFlagsStore);
}

export const useIsFeatureFlagEnabled = (flag: FeatureFlag) => {
  const accessToDevelopmentFlags = useDevelopmentFlag();
  const flagEnabled = useFeatureFlagsStore((state) => state.flags[flag] === true, shallow);

  /**
   * @TODO remove the flag completely and enable v2 cards
   * https://linear.app/futurebrowser/issue/FUT-4664/remove-resource-cards-v2-feature-flag-cleanup-old-resource-cards
   */
  if (flag === FeatureFlag.RESOURCE_CARDS_V2) {
    return true;
  }

  return flagEnabled && accessToDevelopmentFlags;
};

/**
 * This hook is used to get all feature flags to map them to the UI.
 * Also returns the normal setFlag and toggleFlag functions.
 */
export const useFeatureFlags = () => {
  const accessToDevelopmentFlags = useDevelopmentFlag();

  const flags = useFeatureFlagsStore((state) =>
    Object.values(FeatureFlag).map((flag) => ({
      flag,
      value: state.flags[flag],
    })),
  );

  const setFlag = useFeatureFlagsStore((state) => state.setFlag);
  const toggleFlag = useFeatureFlagsStore((state) => state.toggleFlag);

  if (!accessToDevelopmentFlags) {
    return {
      flags: [],
      setFlag: () => {},
      toggleFlag: () => {},
    };
  }

  return {
    flags,
    setFlag,
    toggleFlag,
  };
};
