/**
 * Make sure to only use this in specific cases where you need to know if you are in the server or not.
 * This includes avoiding running code that will not run on the server, like using the window object, or ResizeObserver.
 * So this would be better used in places like useEffect, or in a custom hook.
 * Avoid using this to for example return a different component based on if you are in the server or not. As this will cause
 * the server and client to render different things, which will cause hydration issues.
 * @returns true if the code is running on the server
 */
const inNextServer = () => {
  return typeof window === 'undefined';
};

export default inNextServer;
