import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useEffect } from 'react';
import { share } from 'shared-zustand';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';
import { useAuthStatus } from '../hooks/auth';

const LAST_VISIT_TRACK_DELAY = 1000 * 60 * 10;
const UNLOAD_TRACK_DELAY = 1000 * 60 * 2;

type VisitStore = {
  lastVisitAt: number;
  setLastVisitAt: (time: number) => void;
};

const useVisitStore = create<VisitStore>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        lastVisitAt: 0,
        setLastVisitAt: (time: number) => set({ lastVisitAt: time }),
      }),
      {
        name: 'visit-store',
      },
    ),
  ),
);

if ('BroadcastChannel' in globalThis) {
  share('lastVisitAt', useVisitStore);
}

export const useTrackDashboardVisit = () => {
  const authStatus = useAuthStatus();
  const { lastVisitAt, setLastVisitAt } = useVisitStore();
  const { track } = useAnalytics();

  useEffect(() => {
    const onUnload = () => {
      sessionStorage.setItem('unloadTime', Date.now().toString());
    };

    window.addEventListener('unload', onUnload);
  }, []);

  // Track visits from fresh opens of the dashboard
  useEffect(() => {
    const referrer = document.referrer;

    if (authStatus === 'loading') return;

    const unloadTime = sessionStorage.getItem('unloadTime');
    const lastVisitProcessed = Boolean(sessionStorage.getItem('lastVisitProcessed'));

    // user has lastVisitProceesed but never unloaded
    if (!unloadTime && lastVisitProcessed) return;
    // user has unloaded but was less than UNLOAD_TRACK_DELAY ago, so we don't track
    // if it was longer than UNLOAD_TRACK_DELAY, we track
    if (unloadTime && Number(unloadTime) > Date.now() - UNLOAD_TRACK_DELAY) {
      // remove the unloadTime
      sessionStorage.removeItem('unloadTime');
      return;
    }

    // delete the unloadTime and lastVisitProcessed
    if (unloadTime) {
      sessionStorage.removeItem('unloadTime');
      sessionStorage.removeItem('lastVisitProcessed');
    }

    if (referrer?.includes(window.location.hostname)) return;
    if (lastVisitAt > Date.now() - LAST_VISIT_TRACK_DELAY) return;

    const now = Date.now();

    track(AnalyticsEvents.DashboardVisit, {
      refocus: false,
    });

    setLastVisitAt(now);
  }, [authStatus, lastVisitAt, setLastVisitAt, track]);

  useEffect(() => {
    const onFocus = () => {
      if (authStatus === 'loading') return;
      if (lastVisitAt > Date.now() - LAST_VISIT_TRACK_DELAY) return;

      const now = Date.now();

      track(AnalyticsEvents.DashboardVisit, {
        refocus: true,
      });

      setLastVisitAt(now);
    };

    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, [authStatus, lastVisitAt, setLastVisitAt, track]);
};
