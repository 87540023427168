import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import { omit } from '@/src/lib/store';
import { setNewModalOpen } from '@/src/store/ui';
import { NewFdocType } from '@/src/views/FdocNew/TitleDropdown';
import { useRouter } from 'next/router';

const isNewFdocType = (newParam: unknown): newParam is NewFdocType => {
  if (typeof newParam !== 'string') return false;

  return Object.values(NewFdocType)
    .map((v) => v.toString())
    .includes(newParam);
};

const useFdocNewParamIntent = () => {
  const router = useRouter();
  const { new: newParam } = router.query;

  useClientLayoutEffect(() => {
    if (!newParam || !router.isReady) return;

    const params = omit(router.query, ['new']);
    router.replace({ query: params }, undefined, { shallow: true });

    setNewModalOpen(true, {
      type: isNewFdocType(newParam) ? newParam : undefined,
    });
  }, [newParam, router.isReady]);
};

export { useFdocNewParamIntent };
