import CloseIcon from '@/src/icons/CloseIcon';
import useAlertStore, { AlertElement, BannerAlert } from '@/src/store/alerts';
import Tooltip from '@/src/ui/Tooltip';
import clsx from 'clsx';
import gsap from 'gsap';
import Link from 'next/link';
import React, { useCallback } from 'react';
import { shallow } from 'zustand/shallow';
import styles from './BannerDisplay.module.scss';

const renderBannerAction = (action: BannerAlert['action']) => {
  if (!action) return null;

  switch (action.type) {
    case 'url':
      return (
        <Link href={action.url} target="_blank" rel="noopener noreferrer">
          <button className={styles.action_button}>{action.label}</button>
        </Link>
      );
    case 'path':
      return (
        <Link href={action.path} passHref>
          <button className={styles.action_button}>{action.label}</button>
        </Link>
      );
    case 'refresh':
      return (
        <button className={styles.action_button} onClick={() => window.location.reload()}>
          {action.label}
        </button>
      );
    default:
      return null;
  }
};

const BannerDisplay: AlertElement<'banner'> = ({ alert: banner }) => {
  const dismissBanner = useAlertStore((state) => state.dismissBanner, shallow);
  const elementId = `banner-${banner.id}`;

  const hideAlert = useCallback(() => {
    gsap.to(`#${elementId}`, {
      duration: 0.5,
      opacity: 0,
      onComplete: () => {
        dismissBanner();
      },
    });
  }, [dismissBanner, elementId]);

  return (
    <div className={clsx(styles.banner, styles[banner.severity])} id={elementId}>
      <Tooltip label={banner.content}>
        <span>{banner.title}</span>
      </Tooltip>
      {renderBannerAction(banner.action)}
      <button className={styles.close_button} onClick={hideAlert}>
        <CloseIcon />
      </button>
    </div>
  );
};

export default BannerDisplay;
