import { ApiUserMe } from '@/src/modules/user/user.types';
import { SpaceRole } from '../../spaces/spaces.types';
import { Member } from '../members.types';

export const userToMember = (user: ApiUserMe, role: SpaceRole): Member => {
  return {
    id: user.id,
    name: user.name || '',
    picture: user.pictureUrlCdn || null,
    role,
    createdAt: user.createdAt,
    modifiedAt: user.modifiedAt,
  };
};
