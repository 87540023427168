export interface KeyValStorageInterface {
  getItem<T>(storeName: string, key: string): Promise<T | null>;
  setItem<T>(storeName: string, key: string, value: T): Promise<void>;
  removeItem(storeName: string, key: string): Promise<void>;
  clear(storeName: string): Promise<void>;
  keys(storeName: string): Promise<string[]>;
  getAllItemsIterator<T>(storeName: string): AsyncIterableIterator<T>;
  getAllItems<T>(storeName: string): Promise<T[]>;
}

export enum ValueType {
  JSON = 'json',
  BINARY = 'binary',
  BASE64 = 'base64',
}
