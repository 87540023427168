import { FabricEventsMap } from '@/@types/fabric';
import { useEffect, useRef } from 'react';

/**
 * @param func - The function to run when the event is triggered
 */
export function useWindowEvent<K extends keyof FabricEventsMap>(
  eventname: K,
  func: (e: FabricEventsMap[K]) => void,
  options?: {
    enabled: boolean;
    onUnmount?: () => void;
  },
) {
  const enabled = options?.enabled ?? true;
  const onUnmountRef = useRef(options?.onUnmount);
  onUnmountRef.current = options?.onUnmount;
  const funcRef = useRef(func);
  funcRef.current = func;

  useEffect(() => {
    if (enabled) {
      const onEvent = (e: FabricEventsMap[K]) => {
        funcRef.current(e);
      };

      window.addEventListener(eventname, onEvent);

      return () => {
        window.removeEventListener(eventname, onEvent);
        onUnmountRef.current?.();
      };
    }
  }, [enabled, eventname]);
}
