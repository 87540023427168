import { create } from 'zustand';

interface FdocSwitcherState {
  fdocId: string;
  setFdocId: (fdocId: string) => void;
  loop: boolean;

  // for quick switching within a context
  switcherFdocIds: string[];
  setSwitcherFdocIds: (fdocIds: string[], loop?: boolean) => void;

  goToNextFdoc: () => string;
  goToPreviousFdoc: () => string;
  hasNext: () => boolean;
  hasPrev: () => boolean;
}

const useFdocSwitcher = create<FdocSwitcherState>((set, get) => ({
  loop: false,
  fdocId: '',
  setFdocId: (fdocId: string) => set({ fdocId }),
  switcherFdocIds: [],
  setSwitcherFdocIds: (switcherFdocIds: string[], loop = false) => set({ switcherFdocIds, loop }),

  hasNext: () => {
    const { fdocId, switcherFdocIds, loop } = get();
    if (!loop) {
      const index = switcherFdocIds.indexOf(fdocId);
      // is last
      if (index === switcherFdocIds.length - 1) {
        return false;
      }
    }
    return true;
  },
  goToNextFdoc: () => {
    const { fdocId, switcherFdocIds } = get();
    const index = switcherFdocIds.indexOf(fdocId);
    const nextIndex = (index + 1) % switcherFdocIds.length;
    const nextFdocId = switcherFdocIds[nextIndex];
    set({ fdocId: nextFdocId });

    return nextFdocId;
  },
  hasPrev: () => {
    const { fdocId, switcherFdocIds, loop } = get();
    if (!loop) {
      const index = switcherFdocIds.indexOf(fdocId);
      if (index === 0) {
        return false;
      }
    }
    return true;
  },
  goToPreviousFdoc: () => {
    const { fdocId, switcherFdocIds } = get();
    const index = switcherFdocIds.indexOf(fdocId);
    const nextIndex = (index - 1 + switcherFdocIds.length) % switcherFdocIds.length;
    const nextFdocId = switcherFdocIds[nextIndex];
    set({ fdocId: nextFdocId });

    return nextFdocId;
  },
}));

export default useFdocSwitcher;
