import { defaultQueryClient } from '@/pages/_app';
import {
  FeatureFlag,
  useIsFeatureFlagEnabled,
} from '@/src/modules/feature-flags/feature-flags.store';
import { QueryClientProvider as Provider, QueryClient } from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { PropsWithChildren } from 'react';

interface QueryClientProviderProps extends PropsWithChildren {
  /**
   * Optionally provide a query client to use. If not provided, a default query client will be used.
   * handy for initialization e.g. tests, storybook and other envronments which require different config
   */
  queryClient?: QueryClient;
}

export const QueryClientProvider: React.FC<QueryClientProviderProps> = ({
  children,
  queryClient,
}) => {
  const devToolsEnabled = useIsFeatureFlagEnabled(FeatureFlag.REACT_QUERY_DEVTOOLS);

  return (
    <Provider client={queryClient ?? defaultQueryClient}>
      {devToolsEnabled && <ReactQueryDevtools initialIsOpen={false} buttonPosition="bottom-left" />}
      {children}
    </Provider>
  );
};
