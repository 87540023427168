export type Position = {
  x: number;
  y: number;
};

export const hasMacOSKbd = () => {
  return /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
};

export type Size = {
  width: number;
  height: number;
};

export type Coordinate = {
  latitude: number;
  longitude: number;
};
