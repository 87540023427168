import React from 'react';

/**
 * Simple context so PricePlans can share loading states
 */
export const PricingContext = React.createContext<{
  loading: boolean;
  setLoading: (value: boolean) => void;
}>({
  loading: false,
  setLoading: () => {},
});

export const usePricing = () => React.useContext(PricingContext);
