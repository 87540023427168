import { CreateResourceTypes, FabricResourceTypes } from '@fabric/woody-client';

export const removeFileExtensionFromCreate = (
  createResourceData: CreateResourceTypes,
): CreateResourceTypes => {
  if (createResourceData.type !== FabricResourceTypes.STORED_FILE) {
    return createResourceData;
  }

  const splitTitle = createResourceData.title.split('.');
  if (splitTitle.length === 1) {
    return createResourceData;
  }

  const titleWithoutExtension = splitTitle.slice(0, -1).join('.');

  return {
    ...createResourceData,
    title: titleWithoutExtension,
  };
};
