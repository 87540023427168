import {
  AssistantContextOption,
  AssistantContextSubType,
  ChatbotConversationMessage,
  ChatbotConversationUserActionType,
} from '@/src/modules/assistant/types';
import { isChatbotUserMessage } from '@/src/modules/utils';
import produce from 'immer';

const summarizeText = `Provide a highly condensed summary of the following text:

1. Start with a single, concise sentence (max 20 words) that captures the core essence.

2. For texts longer than 50 words, add up to 3 brief bullet points that:
   - Highlight key ideas or themes
   - Are extremely concise (aim for 8-12 words each)
   - Connect to broader context where relevant

For texts shorter than 50 words, add 0-2 bullet points only if necessary to capture essential additional information.

Use markdown. Prioritize brevity and relevance. The entire output should not exceed 70 words, even for lengthy texts. Start directly with the content, omitting any labels or headers:`;

const summarizeDocumentOrSelection = `Provide a condensed summary for the active document(s):

1. Assess the relatedness of the documents, looking for even abstract thematic connections.

2. If documents are related, share a similar thematic focus (even abstractly), or there is only one document:
   a. Begin with a concise paragraph (2-3 sentences, max 40 words) capturing the overall context.
   b. Follow with 3-5 bullet points that synthesize key themes across all documents.
      - Each bullet should be concise (10-15 words) and highlight main ideas or contrasts.
   c. End the summary here. Do not add any additional sections or bullet points.

3. Only as a last resort, if there are absolutely no possible thematic connections between the documents:
   a. For each document or if possible group of related documents (up to a maximum of 5):
      - Provide a brief subheading (group theme or document title) as a 3rd level heading.
      - Write 1-2 sentences (max 30 words) summarizing the main idea or purpose.
      - List 1-2 bullet points summarizing key points (10-15 words each).
   b. After summarizing all documents, stop. Do not add any concluding remarks or additional themes.

4. Use markdown formatting.

5. Prioritize brevity and relevance. The entire output must not exceed 200 words, even for multiple or lengthy documents.

6. Start directly with the content and end immediately after the last bullet point or document summary. Do not include any introductory or concluding sections.`;

const populateActionPrompt = (
  messages: ChatbotConversationMessage[],
  contexts: AssistantContextOption[],
): [ChatbotConversationMessage[], AssistantContextOption | undefined] => {
  const fileContext = contexts.find((context) => context.subType === AssistantContextSubType.File);
  return [
    produce(messages, (draft) => {
      const lastMessage = draft[messages.length - 1];
      if (!isChatbotUserMessage(lastMessage) || !lastMessage.action) return;

      switch (lastMessage.action.type) {
        case ChatbotConversationUserActionType.SUMMARIZE_TEXT:
          lastMessage.content = `${summarizeText}\n${lastMessage.content}`;
          break;
        case ChatbotConversationUserActionType.SUMMARIZE_DOCUMENT:
        case ChatbotConversationUserActionType.SUMMARIZE_SELECTION:
          lastMessage.content = summarizeDocumentOrSelection;
          break;
      }
    }),
    fileContext,
  ];
};

export { populateActionPrompt };
