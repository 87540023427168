import { ChatbotMessageRole, Resource } from '@fabric/woody-client';

export interface ChatbotConversationMessage {
  id: string;
  role: ChatbotMessageRole;
  content: string;
  action?: ChatbotConversationUserAction;
  context?: AssistantContextOption;
}

export interface ChatbotConversationUserAction {
  id: string;
  type: ChatbotConversationUserActionType;
}

export enum ChatbotConversationUserActionType {
  SUMMARIZE_TEXT = 'summarize_text',
  SUMMARIZE_DOCUMENT = 'summarize_document',
  SUMMARIZE_SELECTION = 'summarize_selection',
}

export interface ChatbotConversationMessageUser extends ChatbotConversationMessage {
  role: ChatbotMessageRole.user;
}

export interface ChatbotConversationMessageAssistant extends ChatbotConversationMessage {
  role: ChatbotMessageRole.assistant;
  streaming: boolean;
  resourceIds: string[];
  resources: Resource[];
}

export interface ChatbotConversation {
  contextKey: string;
  messages: ChatbotConversationMessage[];
  lastInteraction: number;
}

export const MascotStateMachine = 'Main';
export const MascotDarkModeStateMachine = 'DarkMode';

export enum MascotState {
  BROKEN = 'broken',
  CONFUSED = 'confused',
  TIRED = 'tired',
  SLEEP = 'sleep',
  GLASS = 'glass',

  DARK_MODE = 'darkMode',
  LIGHT_MODE = 'lightMode',
}

export enum MascotStateActions {
  AMAZED = 'amazed',
  BLINK2 = 'blink2',
  BLINK1 = 'blink1',
  WINK = 'wink',
  DREAMING = 'dreaming',
  LOVE = 'love',
  HAPPY = 'happy',
  IDLE = 'idle',
}

export enum AssistantContextType {
  None = 'none',
  Ancestor = 'ancestor',
  Resource = 'resource',
  Selection = 'selection',
}

export enum AssistantContextSubType {
  Everything = 'everything',
  Space = 'space',
  Folder = 'folder',
  File = 'file',
  Connection = 'connection',
  Selection = 'selection',
}

export interface AssistantContextOption {
  type: AssistantContextType;
  subType: AssistantContextSubType;
  name?: string;
  value: string | string[];
}
