import { ErrorType } from '@/src/core/types/errors';
import { isInMobile } from '@/src/hooks/mobile';
import { syncResourceQuery } from '@/src/modules/resources/utils/resourceSync';
import { syncSpacesQuery } from '@/src/modules/spaces/utils/spaceSync';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import { PersistedQuery, experimental_createPersister } from '@tanstack/react-query-persist-client';
import { createIDBStorage, restoreQueryClientCache } from './persister';

const REACT_QUERY_MAX_CACHE_DURATION: Readonly<number> = isInMobile() ? 1000 * 60 * 5 : 1000 * 60; // 5 min native apps (sqlite), 1 min web apps (indexedDB)
// const REACT_QUERY_DEFAULT_STALE_IN_MINUTES: Readonly<number> = 1;
const REACT_QUERY_PERSIST_BUSTER: Readonly<string> = '0.1.6';

export const createDefaultQueryClient = () => {
  const storage = createIDBStorage();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        gcTime: REACT_QUERY_MAX_CACHE_DURATION,
        refetchOnWindowFocus: true,
        refetchOnMount: true,

        persister: experimental_createPersister<PersistedQuery>({
          storage: storage,
          serialize(persistedQuery) {
            // indexedDB doesn't need serialization
            return persistedQuery;
          },
          deserialize(cachedString) {
            // indexedDB doesn't need serialization
            return cachedString;
          },
          buster: REACT_QUERY_PERSIST_BUSTER,
        }),
      },
      mutations: {
        gcTime: REACT_QUERY_MAX_CACHE_DURATION,
      },
    },
    queryCache: new QueryCache({
      // https://tkdodo.eu/blog/breaking-react-querys-api-on-purpose
      // Since onSuccess was removed from each query, it's recommended to use the global queryCache instead
      // Altough this feels weird, I believe it's the only proper place to sync individual item queries with list queries,
      // since according to the blog post above:
      // > This callback will only be invoked once per Query. It also exists outside the component that called useQuery, so we don't have a closure problem.
      // This means that we can rest assured the mutations triggered by newer item queries won't be triggered multiple times.
      // The only other alternatives was to include the query invalidation or mutation logic within the fetch function, which feels dirty.
      onSuccess: (data, query) => {
        /**
         * when disableQueryCacheSync is set to true, we don't sync the query cache
         * syncSpacesQuery updates the space details across all queries, appends if missing
         * This is generally desired, however when e.g. visiting a space which user is not a member of
         * then we don't want to add it into the cache, since it's not relevant to the user
         */
        if (!query.meta?.disableQueryCacheSync) {
          syncResourceQuery(data, query.queryKey, queryClient);
          syncSpacesQuery(data, query.queryKey, queryClient);
        }
      },

      // This global error handler makes sure to cause a refetch when the error is caused by woody not being ready
      // Since now we don't make it a part of the enabled check, and is instead a thrown error, it would fail and not refetch
      // for a while, when it should just retry, since usually the ready state changes within a few seconds
      onError: (error, query) => {
        if (error.cause !== ErrorType.WOODY_NOT_READY) return;

        // make sure it refetches when woody is ready
        queryClient.invalidateQueries({
          exact: true,
          queryKey: query.queryKey,
        });
      },
    }),
  });

  restoreQueryClientCache(queryClient, REACT_QUERY_PERSIST_BUSTER, () => {
    // After restoring the cache from indexedDB, we subscribeq to QueryCache updates to persist to the indexedDB
    // This is for optimistic updates, since the persister only persists after the query is successful,
    // however this is just on the experimental persister, the default persister persists on every Fetch, which
    // by pure coincidence would cause optimistic updates to be persisted as well, but not really consistently.
    queryClient.getQueryCache().subscribe((event) => {
      if (event.type !== 'updated' || event.action.type !== 'success' || !event.action.manual)
        return;

      const query = event.query;
      const key = `tanstack-query-${query.queryHash}`;
      const persistedQuery: PersistedQuery = {
        buster: REACT_QUERY_PERSIST_BUSTER,
        queryHash: query.queryHash,
        queryKey: query.queryKey,
        state: {
          ...query.state,
          data: event.action.data,
        },
      };

      storage.setItem(key, persistedQuery);
    });
  });

  return queryClient;
};
