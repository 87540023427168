import FabricIcon from '@/public/images/icons/FabricAppSmall.png';
import { isFabricDev, isNextDev } from '@/src/constants/env';
import { isInMobile } from '@/src/hooks/mobile';
import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import GlobeIcon from '@/src/icons/GlobeIcon';
import { omit } from '@/src/lib/store';
import { mediaHover } from '@/src/modules/ui/styled-utils';
import { REDIRECTOR_URL } from '@/src/services/woody/woody';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Drawer from '../../ui/components/Drawer';
import { Flex } from '../../ui/components/Flex';
import { ListContainer, ListItem, ListItemTitle } from '../../ui/components/List/List';
import { TypographyContainer } from '../../ui/components/Typography';
import { cssVar } from '../../ui/theme/variables';
import { MobileOS } from '../mobile.types';
import detectMobile from '../utils/detectMobile';

const FabricAppIcon = styled.img.attrs({
  src: FabricIcon.src,
  alt: 'Fabric App',
})`
  color: ${cssVar['color-app-primary']};
  width: 24px;
  height: auto;
  object-fit: contain;
  image-rendering: pixelated;
`;

/**
 * @TODO - Possibly make this into a variant of ListItem.
 * This type of list will be used in multiple places when we start switching our existing modals and dialogs on mobile to drawers.
 */
const Item = styled(ListItem)`
  background: ${cssVar['color-bg-secondary']};
  padding: 1rem;
  border: none;

  border-radius: 0px;

  & + & {
    margin-top: 0px;
  }

  &:first-child {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
  }

  &:last-child {
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  &:not(:last-child) {
    border-bottom: 1px solid ${cssVar['color-border-secondary']};
  }
  ${mediaHover} {
    &:hover {
      border-color: ${cssVar['color-border-secondary']};
      opacity: 0.8;
    }
  }
`;

const OpenInNativeAppDrawer = () => {
  const [mobileOS, setMobileOS] = useState<MobileOS | false>(false);
  const [urlPath, setUrlPath] = useState<string>('');

  const router = useRouter();

  useClientLayoutEffect(() => {
    // Already in the mobile app, return
    if (isInMobile()) return;

    // check for ?deeplink=true query param and return if it's present
    if (window.location.search.includes('deeplink=true')) return;

    setMobileOS(detectMobile());

    let path = window.location.pathname;
    // we add search with a deeplink query param, if the user does not have the app installed
    // it will check for the deeplink to avoid showing the drawer again
    if (window.location.search) path += window.location.search + '&deeplink=true';
    else path += '?deeplink=true';

    setUrlPath(path);
  }, []);

  // cleanup deeplink query param
  useEffect(() => {
    if (!router.query.deeplink) return;

    const query = omit(router.query, ['deeplink']);

    router.replace(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true },
    );
  }, [router]);

  const options = [
    {
      icon: <FabricAppIcon />,
      title: `Fabric ${mobileOS} app`,
      props: {
        as: Link,
        href: `${REDIRECTOR_URL}${urlPath}`,
      },
    },
    {
      icon: <GlobeIcon size={24} />,
      title: 'Web browser',
      props: {
        onClick: () => {
          setMobileOS(false);
        },
      },
    },
  ];

  if (isInMobile() || isNextDev || isFabricDev) return null;

  return (
    <Drawer
      open={Boolean(mobileOS)}
      onOpenChange={(open) => (!open ? setMobileOS(false) : undefined)}
    >
      <Drawer.Portal>
        <Drawer.Overlay />
        <Drawer.Content>
          <Drawer.Handle />
          <Drawer.Header>
            <Drawer.Title>Open this in...</Drawer.Title>
          </Drawer.Header>
          <Flex direction="column" gap="sectionsMedium" style={{ padding: '0 1rem 1rem 1rem' }}>
            <ListContainer>
              {options.map((option, index) => (
                <Item key={index} {...option.props}>
                  <Flex
                    gap="elementsContainer"
                    alignItems="center"
                    justifyContent="flex-start"
                    style={{ overflow: 'hidden', flexShrink: 1 }}
                  >
                    {option.icon}
                    <TypographyContainer>
                      <ListItemTitle>{option.title}</ListItemTitle>
                    </TypographyContainer>
                  </Flex>
                </Item>
              ))}
            </ListContainer>
          </Flex>
        </Drawer.Content>
      </Drawer.Portal>
    </Drawer>
  );
};

export default OpenInNativeAppDrawer;
