/**
 * The options for a cookie.
 */
type CookieOptions = {
  path?: string;
  expires?: Date;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: 'lax' | 'strict' | 'none';
};

/**
 * The cookie object.
 * For when parsing the cookie string.
 */
type Cookie = {
  name: string;
  value: string;
  options: CookieOptions;
};

/**
 * Helper to build cookie strings.
 * @param name The name of the cookie.
 * @param value The value of the cookie.
 * @param options The options for the cookie.
 * @returns {string} The cookie string.
 */
export const buildCookieString = (
  name: string,
  value: string | object,
  options: CookieOptions = {},
) => {
  const { path, expires, httpOnly, sameSite, domain } = options;

  // we only set secure if it's not development
  const secure = process.env.NODE_ENV !== 'development' && options.secure;

  const parsedValue = typeof value === 'object' ? JSON.stringify(value) : value;

  const cookieString = `${name}=${parsedValue}; path=${path ?? '/'}; ${
    expires ? `expires=${expires.toUTCString()};` : ''
  } ${secure ? 'secure;' : ''} ${httpOnly ? 'httpOnly;' : ''} ${
    sameSite ? `sameSite=${sameSite};` : ''
  } ${domain ? `domain=${domain};` : ''}`;

  // return and remove any trailing semicolons or newlines
  return cookieString.replace(/;+$/, '').replace(/\n+/g, '');
};

/**
 * Get all cookies from the full cookie string.
 * @param cookieString The full cookie string.
 * @returns {Cookie[]} The cookies.
 */
const getCookies = (cookieString: string): Cookie[] => {
  return cookieString
    .split(';')
    .map((cookie) => cookie.trim())
    .map((cookie) => {
      const [name, ...value] = cookie.split('=');
      return {
        name,
        value: value.join('='),
        options: {},
      };
    });
};

/**
 * Get a cookie from the full cookie string.
 * @param cookieString The full cookie string.
 * @param name The name of the cookie.
 * @returns {Cookie | undefined} The cookie.
 *
 */
export const getCookie = (cookieString: string, name: string): Cookie | undefined => {
  return getCookies(cookieString).find((cookie) => cookie.name === name);
};

/**
 * This will delete all cookies in the browser for the current domain.
 * Client-side only.
 */
export const deleteAllCookies = () => {
  const cookies = getCookies(document.cookie);

  cookies.forEach((cookie) => {
    document.cookie = buildCookieString(cookie.name, '', {
      expires: new Date(0),
    });
  });
};
