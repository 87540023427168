import { Fdoc } from '@/src/types/api';
import { InfiniteData, QueryClient, QueryKey } from '@tanstack/react-query';
import { resourceQueryPredicates } from '../queries/resourceQueryPredicates';
import { FilteredFdocs, SearchedFdocs } from '../resources.types';
import { isDirectQueryKey, isFilterQueryKey } from './queryKeyGuards';

const syncDirectResourceQuery = (data: Fdoc, queryClient: QueryClient) => {
  queryClient.setQueriesData<InfiniteData<FilteredFdocs>>(
    {
      predicate: resourceQueryPredicates.filterAll,
      type: 'active',
    },
    (oldData) => {
      if (!oldData) return oldData;

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          results: page.results.map((result) =>
            result.id === data.id ? { ...result, ...data } : result,
          ),
        })),
      };
    },
  );

  queryClient.setQueriesData<InfiniteData<SearchedFdocs>>(
    {
      predicate: resourceQueryPredicates.searchAll,
      type: 'active',
    },
    (oldData) => {
      if (!oldData) return oldData;

      return {
        ...oldData,
        pages: oldData.pages.map((page) => ({
          ...page,
          results: page.results.map((result) =>
            result.id === data.id ? { ...result, ...data } : result,
          ),
        })),
      };
    },
  );
};

const syncFilterResourceQuery = (data: InfiniteData<FilteredFdocs>, queryClient: QueryClient) => {
  // Only updating the direct queries for now, since updating search queries and other filter queries seem like it would
  // potentially be quite complex and heavy on the client. And even cause a lot of unnecessary re-renders.
  for (const page of data.pages) {
    for (const result of page.results) {
      queryClient.setQueriesData<Fdoc>(
        {
          predicate: (q) => resourceQueryPredicates.resource(q, result.id),
          type: 'active',
        },
        (oldData) => {
          if (!oldData) return oldData;

          return {
            ...oldData,
            ...result,
          };
        },
      );
    }
  }
};

export const syncResourceQuery = (data: unknown, queryKey: QueryKey, queryClient: QueryClient) => {
  if (!Array.isArray(queryKey) || queryKey.length === 0) return;

  if (isDirectQueryKey(queryKey)) {
    syncDirectResourceQuery(data as Fdoc, queryClient);
  }

  if (isFilterQueryKey(queryKey)) {
    syncFilterResourceQuery(data as InfiniteData<FilteredFdocs>, queryClient);
  }

  // Will not care for search queries for now, since usually it has some missing data as it's stored in ES.
};
