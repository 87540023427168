import { isString } from '@/src/lib/utils';
import { AssistantContextOption, AssistantContextSubType } from '@/src/modules/assistant/types';

const folderScope = [
  AssistantContextSubType.Connection,
  AssistantContextSubType.Folder,
  AssistantContextSubType.Space,
];
const resourceScope = [AssistantContextSubType.File, AssistantContextSubType.Selection];

const convertAssistantContextToAPIScope = (contextOption: AssistantContextOption) => {
  if (folderScope.includes(contextOption.subType) && isString(contextOption.value))
    return {
      scope: 'folder' as const,
      folderId: contextOption.value,
    };

  if (resourceScope.includes(contextOption.subType) && Array.isArray(contextOption.value))
    return {
      scope: 'resource' as const,
      resourceIds: contextOption.value,
    };

  return {
    scope: 'user' as const,
  };
};

export { convertAssistantContextToAPIScope };
