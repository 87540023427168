import { ResourceFilterOptions, ResourceSearchOptions } from '../resources.types';

export const resourceQueryKeys = {
  folders: (parentId?: string, fabricUser?: boolean) =>
    ['infinite-resource-folders', parentId, fabricUser ? 'fabricUser' : undefined] as const,

  resource: (resourceId?: string) => ['resource', resourceId] as const,
  resourceDetail: (resourceId?: string) => ['resourceDetail', resourceId] as const,
  resourceBySharedSecret: (secret?: string) => ['shared-resource', secret] as const,
  search: (options?: ResourceSearchOptions) => ['infinite-resources-search', options] as const,
  filter: (options?: ResourceFilterOptions) => ['infinite-resources', options] as const,
  totals: (parentId?: string) => ['resource-totals', parentId] as const,
};
