import { v4 } from 'uuid';

export const MAX_TREE_DEPTH_ALLOWED = 10;
const MAX_AUTO_RETRIES = 3;

/**
 * FileStatus represents the possible states for a file upload.
 * Ready    : The file is ready to be uploaded.
 * Uploading: The file is currently being uploaded.
 * Success  : The file was successfully uploaded.
 * Failed   : The file upload failed.
 */
export enum FileStatus {
  Ready = 'ready',
  Retrying = 'retrying',
  Uploading = 'uploading',
  Finalizing = 'finalizing',
  Success = 'success',
  Failed = 'failed',
}

export enum FileUploadErrorCodes {
  FileTooLarge = 100,
  FileUploadFailed = 101,
  FileUploadCancelled = 102,
  FileTreeTooDeep = 103,
  QuotaExceeded = 104,
  Unknown = 999,
}

export class FileUploadError extends Error {
  code: FileUploadErrorCodes;
  canRetry: boolean = false;

  constructor(code: FileUploadErrorCodes, message: string, canRetry: boolean = false) {
    super(message);
    this.code = code;
    // This allow controlling if the file can be retried or not depending on the error
    // e.g. a user cancelled upload should not be retried
    this.canRetry = canRetry;
  }
}

/**
 * IUploadFile represents a file to be uploaded, along with its associated metadata, progress, status, and a unique ID.
 *
 * @template T The type of the metadata associated with the file.
 */
export interface IUploadFile<T> {
  /**
   * A unique identifier for the file. This can be used to distinguish between files, for example, when retrying or cancelling uploads.
   */
  id: string;

  /**
   * The File object representing the file to be uploaded.
   */
  file: File;

  /**
   * The progress of the file upload, represented as a percentage (0-100).
   */
  progress: number;

  /**
   * The current status of the file upload. This would be one of the values from the FileStatus enum.
   */
  status: FileStatus;

  /**
   * The failure reason for the file upload. This would be set if the file upload failed.
   */
  failureReason?: string;

  /**
   * Auto retries count, this is used to determine if the file should be retried or not
   */
  autoRetries: number;

  /**
   * The error code for the file upload. This would be set if the file upload failed.
   */
  errorCode?: FileUploadErrorCodes;

  /**
   * The metadata associated with the file. This could be any data that you need to associate with the file, such as the user who uploaded it or the folder it should be uploaded to.
   */
  metadata: T;

  /*
   * Was processed for upload, when adding files in bulk, this is used to determine if the file was processed or not
   * This is used in conjuction with the onFilesAdded, which will modify each file to update this property
   * If the file is not processed, it will not be considered for upload
   */
  processed?: boolean;

  /**
   * Abort controller for the file upload. This can be used to cancel the upload while it is in progress.
   */
  abortController?: AbortController;
}

/**
 * Type definition for the upload function. The upload function is provided with an IUploadFile and a callback to update progress.
 * It should return a Promise which resolves when the upload completes, or rejects if the upload fails.
 */
type UploadFunction<T, K> = (
  file: IUploadFile<T>,
  onProgressUpdate: (progress: number, speed: number) => void,
  toggleFinalizing: () => void,
  signal: AbortSignal,
) => Promise<K>;

interface IFileUpdateEvent<T, K> {
  onFileUpdate?: (file: IUploadFile<T>) => void;
  onUploadComplete?: (
    file: IUploadFile<T>,
    response: K,
    updateFile: (file: IUploadFile<T>) => void,
  ) => void;
  onSpeedUpdate?: (speed: number) => void;

  onFilesAdded?: (
    files: IUploadFile<T>[],
    updateFile: (file: IUploadFile<T>) => void,
  ) => void | Promise<void>;
}

interface IUploadFileInput<T> {
  file: File;
  metadata: T;
}

/**
 * Options for the BulkUploader class.
 */
interface IBulkUploaderOptions<T, K> extends IFileUpdateEvent<T, K> {
  concurrency?: number;
  uploadFunction?: UploadFunction<T, K>;
}

class BulkUploader<T, K> {
  private files: IUploadFile<T>[] = [];
  private options: IBulkUploaderOptions<T, K>;
  private uploadSpeed: number = 0;
  private running: boolean = false;

  constructor(options: IBulkUploaderOptions<T, K> = {}) {
    this.options = options;
  }

  addFile(file: IUploadFileInput<T>) {
    const { onFileUpdate } = this.options;

    const uploadFile: IUploadFile<T> = {
      ...file,
      id: v4(),
      autoRetries: 0,
      progress: 0,
      status: FileStatus.Ready,
    };

    this.files.push(uploadFile);
    onFileUpdate?.(uploadFile);

    this.processFiles();
  }

  addFiles(files: IUploadFileInput<T>[]) {
    const { onFileUpdate } = this.options;
    const uploadFiles: IUploadFile<T>[] = files.map((file) => ({
      ...file,
      id: v4(),
      autoRetries: 0,
      progress: 0,
      status: FileStatus.Ready,
      processed: false,
    }));

    this.files.push(...uploadFiles);
    uploadFiles.forEach((file) => onFileUpdate?.(file));

    this.processFiles();

    this.options.onFilesAdded?.(uploadFiles, (file) => {
      const newFile = { ...file, processed: true };
      this.onFileUpdate(newFile);
      this.processFiles();
    });
  }

  private async onSpeedUpdate(speed: number) {
    const { onSpeedUpdate } = this.options;

    this.uploadSpeed = speed;
    onSpeedUpdate?.(speed);
  }

  private async onFileProgress(file: IUploadFile<T>, progress: number, speed: number) {
    const { onFileUpdate } = this.options;

    const newFile = { ...file, progress };

    onFileUpdate?.(newFile);
    this.onSpeedUpdate(speed);
  }

  private async onFileUpdate(file: IUploadFile<T>) {
    const { onFileUpdate } = this.options;

    this.files = this.files.map((f) => (f.id === file.id ? file : f));
    onFileUpdate?.(file);
  }

  private async onUploadComplete(file: IUploadFile<T>, response: K) {
    const { onUploadComplete } = this.options;

    const newFile = { ...file, status: FileStatus.Success, progress: 100 };

    this.onFileUpdate(newFile);
    onUploadComplete?.(newFile, response, this.onFileUpdate.bind(this));
  }

  private async processFiles() {
    const { concurrency = 1 } = this.options;

    this.running = true;

    const uploadingFiles = this.files.filter((file) => file.status === FileStatus.Uploading).length;

    if (uploadingFiles >= concurrency) {
      return;
    }

    const readyFiles = this.files
      .filter(
        (file) =>
          (file.status === FileStatus.Ready || file.status === FileStatus.Retrying) &&
          file.processed,
      )
      // sort by least auto retries
      .sort((a, b) => a.autoRetries - b.autoRetries)
      .slice(0, concurrency - uploadingFiles);

    if (readyFiles.length === 0) {
      return;
    }

    readyFiles.forEach(async (file) => {
      const { uploadFunction } = this.options;

      if (!uploadFunction) {
        throw new Error('No upload function provided');
      }

      try {
        const abortController = new AbortController();
        const newFile = { ...file, status: FileStatus.Uploading, abortController };
        this.onFileUpdate(newFile);

        const response = await uploadFunction(
          newFile,
          this.onFileProgress.bind(this, newFile),
          () => {
            const newFile = { ...file, status: FileStatus.Finalizing, progress: 100 };
            this.onFileUpdate(newFile);
          },
          abortController.signal,
        );
        this.onUploadComplete(newFile, response);
      } catch (error: unknown) {
        if (error instanceof FileUploadError) {
          if (error.canRetry && file.autoRetries < MAX_AUTO_RETRIES) {
            const newFile = {
              ...file,
              status: FileStatus.Retrying,
              progress: 0,
              autoRetries: file.autoRetries + 1,
            };
            this.onFileUpdate(newFile);
          } else {
            const newFile = {
              ...file,
              status: FileStatus.Failed,
              failureReason: error.message,
              errorCode: error.code,
              progress: 0,
              autoRetries: 0,
            };
            this.onFileUpdate(newFile);
          }
        } else if (error instanceof Error) {
          const newFile = {
            ...file,
            status: FileStatus.Failed,
            failureReason: error.message,
            errorCode: FileUploadErrorCodes.Unknown,
            progress: 0,
            autoRetries: 0,
          };
          this.onFileUpdate(newFile);
        } else {
          console.error('Unknown error type', error);
        }
      }

      this.processFiles();
    });
  }

  retryFile(id: string) {
    const file = this.files.find((file) => file.id === id);
    if (!file) {
      throw new Error('File not found');
    }

    if (file.status !== FileStatus.Failed) {
      throw new Error('File is not in a failed state');
    }

    const newFile = {
      ...file,
      status: FileStatus.Ready,
      progress: 0,
      autoRetries: 0,
      failureReason: undefined,
      errorCode: undefined,
    };
    this.onFileUpdate?.(newFile);
    this.processFiles();
  }

  cancelUpload(id: string) {
    const file = this.files.find((file) => file.id === id);
    if (!file) {
      throw new Error('File not found');
    }

    // Call the abort method on the controller
    file.abortController?.abort();

    const newFile = {
      ...file,
      status: FileStatus.Failed,
      failureReason: 'Cancelled by user',
      progress: 0,
    };
    this.onFileUpdate(newFile);
    this.processFiles();
  }

  getFiles(): IUploadFile<T>[] {
    return this.files;
  }
}

export default BulkUploader;
