import { isObject, isString } from '@/src/lib/utils';
import { WoodyError } from '@fabric/woody-client';
import { ErrorType } from '../core/types/errors';

/**
 * Given a possible error and a error type it will determiner if the error is of the specified type.
 * @param errorType
 * @param error
 * @returns
 */
export const isErrorOfType = (errorType: ErrorType, error: unknown): boolean => {
  if (!error || !isObject(error) || !isWoodyError(error)) return false;

  return error.detail === errorType;
};

/**
 * Given a possible error it will check if it's one of the custom error types and return the type.
 * @param error
 * @returns The error type if it's a custom error type.
 */
export const getErrorType = (error: unknown): ErrorType | null => {
  if (!error || !isObject(error) || !isWoodyError(error)) return null;

  return Object.values(ErrorType).find((type) => type === error.detail) || null;
};

/**
 * Given a value (any, but hopefully an error) it will confirm if it's a WoodyError.
 * @param value
 * @returns
 */
export const isWoodyError = (value: unknown): value is WoodyError => {
  if (!value || typeof value !== 'object') return false;

  return 'status' in value && 'title' in value;
};

/**
 * Checks if a given value is a key of the ErrorType enum.
 * @param value
 * @returns
 */
export const isErrorType = (value: unknown): value is ErrorType => {
  return isString(value) && Object.values(ErrorType).includes(value as ErrorType);
};
