import useAlertStore, { AlertElement } from '@/src/store/alerts';
import { shallow } from 'zustand/shallow';
import UpgradePromptModal from '../../UpgradePromptModal/UpgradePromptModal';

const UpgradePromptDisplay: AlertElement<'upgrade_prompt'> = ({ alert: upgradePrompt }) => {
  const removeAlert = useAlertStore((state) => state.removeAlert, shallow);
  const elementId = `upgrade_prompt-${upgradePrompt.id}`;

  return (
    <UpgradePromptModal
      key={elementId}
      title={upgradePrompt.title}
      limitBadge={upgradePrompt.limitBadge}
      onClose={() => removeAlert(upgradePrompt.id)}
    />
  );
};

export default UpgradePromptDisplay;
