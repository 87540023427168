import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { Integration } from '@/src/modules/connections/connections.types';
import { connectionQueryKeys } from '@/src/modules/connections/queries/connectionQueryKeys';
import { useWoody } from '@/src/services/woody/woody';
import { isWoodyError } from '@/src/utils/error';
import { DefinedInitialDataOptions, useQuery } from '@tanstack/react-query';

export const useQueryConnectionByExternalId = (
  externalId?: string,
  type?: 'IOS_DEVICE' | 'ANDROID_DEVICE',
  queryOptions?: Partial<DefinedInitialDataOptions<Integration>>,
) => {
  const { client } = useWoody();

  const query = useQuery({
    queryKey: connectionQueryKeys.integrationByExternalId(externalId),
    queryFn: async () => {
      const integrations = await client.v2('/v2/integrations', {
        method: 'get',
        query: {
          type,
          externalId,
        },
      });

      const integration = integrations.data.integrations.find(
        (integration) => integration.externalId === externalId,
      );

      if (!integration) {
        throw {
          status: 404,
          title: 'Integration not found',
        };
      }

      return integration;
    },
    placeholderData: undefined,
    enabled: isQueryEnabled([!!externalId]),
    retry(failureCount, error) {
      if (isWoodyError(error) && error.status === 404) {
        return false;
      }

      return failureCount < 3;
    },
    refetchOnMount: true,
    refetchInterval: 1000 * 45,
    staleTime: 1000 * 60,
    ...queryOptions,
  });

  return {
    ...query,
    data: query.error ? undefined : query.data,
  };
};
