import { isFunction } from '@/src/lib/utils';
import { Enabled, Query, QueryKey } from '@tanstack/react-query';

/**
 * check if properties enable the query
 * undefined is treated as true
 *
 * This was initially created before Enabled could accept a function, to prevent a lot of unnecessary changes
 * this now returns a function that grabs the query to provide to any Enabled function passed in as a parameter
 */
export const isQueryEnabled =
  <D, E, T, K extends QueryKey>(params: (Enabled<D, E, T, K> | undefined | null)[]) =>
  (query: Query<D, E, T, K>) => {
    return !params.some((param) => (isFunction(param) ? param(query) === false : param === false));
  };
