import ThreeDHammerIcon from '@/public/images/icons/3dHammer.png';
import AndroidIcon from '@/public/images/icons/Android.svg';
import AppleIcon from '@/public/images/icons/Apple.svg';
import DropboxIcon from '@/public/images/icons/Dropbox.svg';
import FigmaIcon from '@/public/images/icons/Figma.svg';
import FolderIcon from '@/public/images/icons/Folder.png';
import GithubIcon from '@/public/images/icons/Github.svg';
import GoogleDriveIcon from '@/public/images/icons/GoogleDrive.svg';
import LinearIcon from '@/public/images/icons/Linear.svg';
import NotionIcon from '@/public/images/icons/Notion.svg';
import OnedriveIcon from '@/public/images/icons/Onedrive.svg';
import ReadwiseIcon from '@/public/images/icons/Readwise.svg';
import ZapierIcon from '@/public/images/icons/Zapier.svg';
import ChromeIcon from '@/public/images/icons/chrome.svg';
import { ApiIntegration, ConnectionInfo } from '@/src/modules/connections/connections.types';
import {
  connectionInfoIconFactory,
  connectionInfoImageFactory,
} from '@/src/modules/connections/connections.utils';
import { cssVar } from '@/src/modules/ui/theme/variables';

import React, { SVGProps } from 'react';

const connectionsSupportedByFe = [
  'IOS_DEVICE',
  'ANDROID_DEVICE',
  'GOOGLE_DRIVE',
  'GITHUB',
] as const;

type ConnectionSupportedByFe = (typeof connectionsSupportedByFe)[number];

type ConnectionConfig = {
  Icon: React.FC<SVGProps<SVGElement>>;
  IconProps?: React.PropsWithoutRef<SVGProps<SVGElement>>;
  integrationName: string;
  successMessage: string;
  successDescription: string;
  getTooltip: (root: { name: string }) => string;
  getToPath: (root: { id: string }) => string;
};

const connectionsConfig: Record<ConnectionSupportedByFe, ConnectionConfig> = {
  IOS_DEVICE: {
    Icon: AppleIcon,
    integrationName: 'iOS Screenshots',
    successMessage: 'Your iOS screenshots are now connected.',
    successDescription: 'Fabric will now start syncing your iOS screenshots.',
    getTooltip: () => 'iOS Screenshots',
    getToPath: (root) => `/folders/${root.id}`,
  },
  ANDROID_DEVICE: {
    Icon: AndroidIcon,
    IconProps: {
      style: {
        color: '#3ddb85',
      },
    },
    integrationName: 'Android Screenshots',
    successMessage: 'Your Android screenshots are now connected.',
    successDescription: 'Fabric will now start syncing your Android screenshots.',
    getTooltip: () => 'Android Screenshots',
    getToPath: (root) => `/folders/${root.id}`,
  },
  GOOGLE_DRIVE: {
    Icon: GoogleDriveIcon,
    integrationName: 'Google Drive',
    successMessage: 'Your Google Drive is now connected.',
    successDescription: 'Fabric will now start syncing your Google Drive files.',
    getTooltip: (root) => `Google Drive - ${root.name}`,
    getToPath: (root) => `/folders/${root.id}`,
  },
  GITHUB: {
    Icon: GithubIcon,
    integrationName: 'GitHub stars',
    successMessage: 'Your GitHub account is now connected.',
    successDescription: 'Fabric will now start syncing your starred repositories.',
    getTooltip: (root) => `GitHub  - ${root.name}`,
    getToPath: (root) => `/folders/${root.id}`,
  },
};

export const getConnectionConfig = (integration: string | null) => {
  const configKey = connectionsSupportedByFe.find(
    (supportedIntegration) => supportedIntegration.toLowerCase() === integration?.toLowerCase(),
  );
  if (configKey) {
    const config = connectionsConfig[configKey];
    return {
      ...config,
      IconProps: {
        ...config.IconProps,
        style: {
          color: cssVar['color-text-primary'],
          ...config.IconProps?.style,
        },
      },
    };
  }
  return null;
};

export const futureConnections: ConnectionInfo[] = [
  connectionInfoIconFactory({
    name: 'Figma',
    description: 'Connect your Figma to see all your Figma files inside Fabric.',
    icon: FigmaIcon,
    iconProps: {
      style: {
        width: 38,
      },
    },
  }),
  connectionInfoIconFactory({
    name: 'Readwise',
    description:
      'Connect your Readwise to sync all your PDFs, EPUBs, bookmarks and more into Fabric.',
    icon: ReadwiseIcon,
    iconProps: {
      style: {
        width: 36,
      },
    },
  }),
  connectionInfoIconFactory({
    name: 'Bookmarks',
    description: 'Connect your Chrome bookmarks and see them inside Fabric.',
    icon: ChromeIcon,
    iconProps: {
      style: {
        width: 36,
      },
    },
  }),
  connectionInfoIconFactory({
    name: 'Notion',
    description: 'Connect your Notion account, and explore all your documents inside Fabric.',
    icon: NotionIcon,
    iconProps: {
      style: {
        width: 32,
      },
    },
  }),
  connectionInfoIconFactory({
    name: 'Linear',
    description: 'See all your Linear issues, live in Fabric.',
    icon: LinearIcon,
    iconProps: {
      style: {
        width: 52,
      },
    },
  }),
  connectionInfoIconFactory({
    name: 'Dropbox',
    description:
      'Connect your Dropbox to Fabric. Any changes you make to files or folders will be live updated in Fabric.',
    icon: DropboxIcon,
    iconProps: {
      style: {
        width: 32,
      },
    },
  }),
  connectionInfoImageFactory({
    name: 'Local file sync',
    description:
      'Connect your local files to Fabric. Any changes you make to files or folders will be live updated in Fabric.',
    image: FolderIcon.src,
    imageProps: {
      width: 36,
      alt: 'Folder',
    },
  }),
  connectionInfoImageFactory({
    name: 'Email',
    description: 'Connect your email account, and explore all your emails inside Fabric.',
    image: '/images/email-connection.png',
    imageProps: {
      width: 36,
      height: 36,
    },
  }),
  connectionInfoIconFactory({
    name: 'History',
    description:
      'Connect your Chrome browser history and get perfect memory over every website you visit.',
    icon: ChromeIcon,
    iconProps: {
      style: {
        width: 36,
      },
    },
  }),
  connectionInfoIconFactory({
    name: 'OneDrive',
    description:
      'Connect your OneDrive to Fabric. Any changes you make to files or folders will be live updated in Fabric.',
    icon: OnedriveIcon,
    iconProps: {
      style: {
        width: 36,
      },
    },
  }),
  connectionInfoIconFactory({
    name: 'iCloud',
    description:
      'Connect your iCloud to Fabric. Any changes you make to files or folders will be live updated in Fabric.',
    icon: AppleIcon,
    iconProps: {
      style: {
        width: 36,
      },
    },
  }),
];

export const lateFutureConnections: ConnectionInfo[] = [
  connectionInfoIconFactory({
    name: 'Zapier',
    description: [
      'Integrate the web applications you use and automate workflows.',
      'Connect to more than 5,000 apps.',
    ],
    icon: ZapierIcon,
    iconProps: {
      style: {
        width: 36,
      },
    },
  }),
  connectionInfoImageFactory({
    name: 'Build your own',
    description: [
      'Use the Fabric API to build your own integration, connecting your favourite app or even an internal company tool.',
      'Share what you build with us and we’ll feature it here.',
    ],
    image: ThreeDHammerIcon.src,
    imageProps: {
      width: 40,
      height: 40,
    },
  }),
];

export interface GetIntegrationViewConfigReturnType {
  displayName: string;
  integrationName: string;
  navigateTo: string;
  successMessage: string;
  successDescription: string;
  tooltipText: string;
  Icon: React.FC<SVGProps<SVGElement>>;
  IconProps: React.PropsWithoutRef<SVGProps<SVGElement>>;
  /**
   * @deprecated use navigateTo instead
   */
  toPath: string;
}

export const getIntegrationViewConfig = (
  integration: ApiIntegration,
): GetIntegrationViewConfigReturnType => {
  const config = getConnectionConfig(integration.type);

  if (config) {
    const getArg = {
      id: integration.id,
      name: integration.displayName,
    };

    const isSingleMirror = integration.mirrorRegistries.length === 1;

    return {
      ...config,
      displayName: isSingleMirror ? integration.mirrorRegistries[0].name : integration.displayName,
      tooltipText: config.getTooltip(getArg),
      toPath: config.getToPath(getArg),
      successMessage: config.successMessage,
      successDescription: config.successDescription,
      navigateTo: isSingleMirror ? `/folders/${integration.mirrorRegistries[0].rootId}` : '',
    };
  } else {
    /**
     * return default placeholder
     */
    return {
      Icon: React.Fragment,
      IconProps: {},
      navigateTo: '#',
      displayName: 'Unknown',
      integrationName: 'Unknown Integration',
      tooltipText: 'Unknown Integration',
      successMessage: 'Unknown Integration',
      successDescription: 'Unknown Integration',
      toPath: '#',
    };
  }
};
