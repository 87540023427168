import { FabricSearchFilters } from '@fabric/woody-client';
import { SearchBarFilterType, SearchBarValue } from '../searchbar.types';

export const searchBarValueToFabricFilters = (
  searchBarValue: SearchBarValue,
): FabricSearchFilters => {
  const tags: FabricSearchFilters['tags'] = [];
  const family: FabricSearchFilters['family'] = [];
  const ancestor: FabricSearchFilters['ancestor'] = [];

  for (const filter of searchBarValue.filters) {
    if (!filter.value?.value) continue;

    if (filter.type === SearchBarFilterType.TAG) {
      tags.push(filter.value.value);
    } else if (filter.type === SearchBarFilterType.CONTENT_TYPE) {
      family.push(filter.value.value);
    } else if (filter.type === SearchBarFilterType.LOCATION) {
      ancestor.push(filter.value.value);
    }
  }

  return {
    tags: tags.length ? tags : undefined,
    family: family.length ? family : undefined,
    ancestor: ancestor.length ? ancestor : undefined,
  };
};

/* 
 * This will not be removed yet just because it might be used in the future.

const fabricSearchFiltersToSearchBarValue = (
  fabricSearch: FabricSearchFilters,
): SearchBarValue['filters'] => {
  const filters: SearchBarValue['filters'] = [];

  if (fabricSearch.tags) {
    for (const tag of fabricSearch.tags) {
      filters.push({
        $key: tag,
        type: SearchBarFilterType.TAG,
        value: { label: tag, value: tag },
      });
    }
  }

  if (fabricSearch.family) {
    for (const family of fabricSearch.family) {
      filters.push({
        $key: family,
        type: SearchBarFilterType.CONTENT_TYPE,
        value: { label: family, value: family },
      });
    }
  }

  if (fabricSearch.parent) {
    for (const parent of fabricSearch.parent) {
      filters.push({
        $key: parent,
        type: SearchBarFilterType.LOCATION,
        value: { label: parent, value: parent },
      });
    }
  }

  return filters;
}; 

*/
