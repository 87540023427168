import { Runtime, StyledOptions } from 'styled-components';

/**
 * Helper function to reduce code duplication in the `forwardProps` function.
 */
const preventForwardProps = (prevented: (string | number | symbol)[]) => {
  return (prop: string) => {
    return !prevented.includes(prop);
  };
};

/**
 * Helper function to reduce code duplication in the `forwardProps` function.
 */
export const preventForwardPropsConfig = <R extends Runtime, Props extends object>(
  prevented: (keyof Props | string)[],
): StyledOptions<R, Props> => ({
  shouldForwardProp: preventForwardProps(prevented),
});
