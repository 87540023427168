import { getDefaultTrackedProperties } from '@/src/modules/analytics/utils/getDefaultTrackedProperties';
import { create } from 'zustand';
import { AnalyticsProvider, AnalyticsUser } from './analytics.types';

/**
 * Interface representing the Analytics Store.
 * This store manages analytics providers and provides methods for tracking events and identifying users.
 */
interface AnalyticsStore {
  /** Array of analytics providers */
  providers: AnalyticsProvider[];

  /**
   * Adds a new analytics provider to the store.
   * @param provider - The analytics provider to be added.
   */
  addProvider: (provider: AnalyticsProvider) => void;

  /**
   * Tracks an event across all registered providers.
   * @param event - The name of the event to track.
   * @param properties - Optional properties associated with the event.
   * @returns A promise that resolves when tracking is complete across all providers.
   */
  track: (event: string, properties?: Record<string, unknown>) => Promise<void>;

  /**
   * Identifies a user across all registered providers.
   * @param user - The user to identify, or null to clear the current user.
   */
  identify: (user: AnalyticsUser | null) => void;
}

/**
 * Creates and returns an analytics store using Zustand.
 * This store manages analytics providers and provides methods for tracking events and identifying users.
 */
export const useAnalyticsStore = create<AnalyticsStore>()((set, get) => ({
  providers: [],
  addProvider: (provider) => set((state) => ({ providers: [...state.providers, provider] })),
  track: async (event, properties) => {
    const { providers } = get();
    const defaultProps = await getDefaultTrackedProperties();
    const mergedProperties = { ...defaultProps, ...properties };
    await Promise.all(providers.map((provider) => provider.track(event, mergedProperties)));
  },
  identify: (user) => {
    const { providers } = get();
    providers.forEach((provider) => provider.identify(user));
  },
}));
