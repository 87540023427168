import styles from './DesktopBar.module.scss';

import React from 'react';

const DesktopBar: React.FC = () => {
  return (
    <div className={styles.desktop_bar}>
      <div className={styles.desktop_bar__button} />
      <div className={styles.desktop_bar__button} />
      <div className={styles.desktop_bar__button} />
    </div>
  );
};

export default DesktopBar;
