import { FabricResourceTypes } from '@fabric/woody-client';
import { MutateOptions } from '@tanstack/react-query';
import { ResourceCreationActions, useMutationCreateResource } from './useMutationCreateResource';

type MutationVariables = {
  parentResourceId: string;
  folderName?: string;
  disableToast?: boolean;
  action?: ResourceCreationActions;
};

export const useMutationCreateSubFolder = () => {
  const createResource = useMutationCreateResource();

  return {
    ...createResource,
    mutate: (
      variables: MutationVariables,
      options?: Parameters<typeof createResource.mutate>[1] & MutateOptions,
    ) => {
      return createResource.mutate(
        {
          createResourceData: {
            type: FabricResourceTypes.FOLDER,
            folderName: variables.folderName ?? 'New Folder',
            parentResourceId: variables.parentResourceId,
          },
          disableToast: variables.disableToast,
          action: variables.action,
        },
        options,
      );
    },
    mutateAsync: (variables: MutationVariables) => {
      return createResource.mutateAsync({
        createResourceData: {
          type: FabricResourceTypes.FOLDER,
          folderName: variables.folderName ?? 'New Folder',
          parentResourceId: variables.parentResourceId,
        },
        disableToast: variables.disableToast,
        action: variables.action,
      });
    },
  };
};
