import { framerAnimationFade } from '@/src/modules/ui/constants/framerAnimations';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { PropsWithChildren } from 'react';

/**
 * automatically triggers fade transition on route change
 * @returns
 */
export const PageOnLoadAnimationContainer: React.FC<PropsWithChildren> = ({ children }) => {
  const { pathname } = useRouter();
  return (
    <motion.div
      className="flex flex-col min-h-[1px] flex-grow"
      key={pathname}
      {...framerAnimationFade}
    >
      {children}
    </motion.div>
  );
};
