import LimitSpacesModal from '@/src/components/LimitSpacesModal/LimitSpacesModal';
import { useAuthUser } from '@/src/hooks/auth';
import { withConditionalMutation } from '@/src/lib/react-query/utilities';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useQueryResourceRootSpaceSystemList } from '@/src/modules/spaces/queries/useQueryResourceRootSpaceSystemList';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { isSubscribedPlan } from '@/src/types/pricing';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useQueryCacheMemberHelpers } from '../../members/utils/useQueryCacheMembersHelpers';
import { resourceQueryPredicates } from '../../resources/queries/resourceQueryPredicates';
import { spaceQueryPredicates } from '../queries/spaceQueryPredicates';
import { SpaceRole } from '../spaces.types';
import { halListToSpace } from '../utils/halListToSpace';

type MutationJoinActions = 'space-invite' | 'login-or-signup-from-space-invite';

type MutationArg = { spaceId: string; role: SpaceRole; action?: MutationJoinActions };

export const useMutationJoinSpace = () => {
  const { client } = useWoody();
  const user = useAuthUser();
  const queryClient = useQueryClient();
  const { track } = useAnalytics();

  const [overLimit, setOverLimit] = React.useState<number | null>(null);
  const { spaceRoots } = useQueryResourceRootSpaceSystemList();

  const { addSelfToCachedSpace } = useQueryCacheMemberHelpers();

  const mutation = useMutation({
    mutationFn: async ({ spaceId, role }: MutationArg) => {
      if (!user) throw new Error('User not authenticated');

      return halListToSpace(getWoodyResponseData(await client.joinList(spaceId, user.id, role)));
    },
    onSuccess: (space, { action = 'space-invite' }) => {
      track(AnalyticsEvents.JoinedCollection, { spaceId: space.id, action });
    },
    onMutate: ({ spaceId, role }) => {
      return addSelfToCachedSpace(spaceId, role);
    },
    onError: (_err, _vars, ctx) => {
      toast({
        content: 'Could not join space',
      });

      ctx?.resetCacheToPreOptimisticState();
      ctx?.invalidateQueries();
    },
    onSettled: (_, _e) => {
      queryClient.invalidateQueries({
        predicate: (q) =>
          // Joining a space means new resources are available
          resourceQueryPredicates.filterAndSearchAll(q) ||
          resourceQueryPredicates.summaryAll(q) ||
          spaceQueryPredicates.spacesAll(q),
      });
    },
  });

  return {
    ...withConditionalMutation(mutation, {
      ignorePredicate: ({ spaceId }) => {
        if (!user) return false; // let the mutation handle the error

        if (!isSubscribedPlan(user.subscription.tier) && spaceRoots.length >= 10) {
          setOverLimit(spaceRoots.length);
          return true;
        }

        return spaceRoots.some((space) => space.id === spaceId);
      },
    }),
    limitModal: overLimit && (
      <LimitSpacesModal listAmount={overLimit} onClose={() => setOverLimit(null)} />
    ),
  };
};
