import {
  app,
  globalShortcut,
  nativeWindow,
  platform,
  screen,
  webContents,
} from '@todesktop/client-core';

export const desktopFloatUrls = ['/desktop-quick-note', '/desktop-global-search'];

import { isObject } from '@/src/lib/utils';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { mutate } from 'swr';
import { shallow } from 'zustand/shallow';
import { pick } from '../lib/store';
import { useWoody } from '../services/woody/woody';
import inNextServer from '../utils/next';
import useAuthStore from './auth';

export function isInDesktop() {
  if (typeof window === 'undefined') return false;

  return platform.todesktop.isDesktopApp();
}

const ports = [23435, 39213];

type CommMessage =
  | {
      type: 'open-session';
      sessionId: string;
    }
  | {
      type: 'authenticate';
      token: string;
    };

const sendCommMessage = async (message: CommMessage): Promise<boolean> => {
  if (isInDesktop()) return false;
  const { checkIfCommServerRunning, createBroadcastService } = await import(
    '@todesktop/client-comm-server'
  );

  const hasDesktop = await checkIfCommServerRunning(ports);

  if (!hasDesktop) return false;

  const { broadcast } = createBroadcastService(ports);

  const response = await broadcast(message);

  return !!response?.success;
};

const _useOpenInDesktop = () => {
  const router = useRouter();

  return useCallback(
    async (sessionId: string) => {
      const success = await sendCommMessage({ type: 'open-session', sessionId });

      if (success) {
        await router.push(`/handoff/${sessionId}`);
      }
    },
    [router],
  );
};

export const useAuthenticateDesktop = () => {
  const { client } = useWoody();
  return useCallback(async () => {
    const response = await client.createClaimableSession();

    if (response.error) return false;

    const token = response.data.token;

    const success = await sendCommMessage({
      type: 'authenticate',
      token,
    });

    return success;
  }, [client]);
};

export const useAuthenticateDesktopDeepLink = () => {
  const { client } = useWoody();

  return useCallback(async () => {
    const response = await client.createClaimableSession();

    if (response.error) return false;

    const token = response.data.token;

    window.location.href = `fabric://authenticate?token=${token}`;
  }, [client]);
};

export const useHandleDeeplink = () => {
  const router = useRouter();
  const { client } = useWoody();
  const { setAuthStatus } = useAuthStore((state) => pick(state, ['setAuthStatus']), shallow);

  useEffect(() => {
    if (!isInDesktop() || desktopFloatUrls.includes(router.pathname)) return;

    const handleAuthenticate = async (token: string) => {
      const response = await client.claimSession(token);

      if (response.error) return;

      try {
        const response = client.v2('/v2/user/me');

        mutate('woody-user', response, false);
        setAuthStatus('authenticated');

        window.location.href = '/'; // forces a refresh
        await nativeWindow.show();
        await app.focus({
          steal: true,
        });
      } catch {
        return;
      }
    };

    const handleOpenProtocolURL = (_: unknown, event: unknown) => {
      if (!isObject(event) || typeof event.url !== 'string') return;

      const url = new URL(event.url);
      // make sure the pathname is /authenticate
      if (url.pathname !== '/authenticate') return;

      if ('preventDefault' in event && event.preventDefault instanceof Function)
        event.preventDefault?.();

      const token = url.searchParams.get('token');
      if (!token) return;

      handleAuthenticate(token);
    };

    const unmounted = false;
    let unsubscribe: () => void;
    app.on('*', handleOpenProtocolURL).then((unsub) => {
      if (unmounted) {
        unsub();
        return;
      }

      unsubscribe = unsub;
    });

    return () => {
      if (!unsubscribe) return;
      unsubscribe();
    };
  }, [client, router.pathname, router.push, setAuthStatus]);
};

export const useHandleBroadcast = () => {
  const router = useRouter();
  const { client } = useWoody();
  const { setAuthStatus } = useAuthStore((state) => pick(state, ['setAuthStatus']), shallow);

  useEffect(() => {
    let unsubscribe: () => void;
    let wasUnmounted = false;
    if (!isInDesktop() || desktopFloatUrls.includes(router.pathname)) return;

    import('@todesktop/client-comm-server').then(({ createBroadcastService }) => {
      if (wasUnmounted) return;

      const { handleBroadcast } = createBroadcastService(ports);

      const handleOpenSession = async (sessionId: string) => {
        await router.push(`/b/${sessionId}`);
        await nativeWindow.show();
        await app.focus({
          steal: true,
        });
      };

      const handleAuthenticate = async (token: string) => {
        const response = await client.claimSession(token);

        if (response.error) return;

        try {
          const response = client.v2('/v2/user/me');

          mutate('woody-user', response, false);
          setAuthStatus('authenticated');

          window.location.href = '/'; // forces a refresh
          await nativeWindow.show();
          await app.focus({
            steal: true,
          });
        } catch {
          return;
        }
      };

      try {
        unsubscribe = handleBroadcast((_message) => {
          // The message is an object, but it's not typed as such. We need to cast it separate from the
          // function parameters to avoid a type error.
          const message = _message as CommMessage;

          if (message.type === 'open-session') {
            handleOpenSession(message.sessionId);
          }

          if (message.type === 'authenticate') {
            handleAuthenticate(message.token);
          }

          return 'acknowledged';
        });
      } catch (error) {
        console.error('Failed to handle broadcast message');
      }
    });

    return () => {
      if (unsubscribe) unsubscribe();
      wasUnmounted = true;
    };
  }, [client, router, router.pathname, router.push, setAuthStatus]);
};

export const useOpenOnBrowser = () => {
  return useCallback(async (url: string) => {
    if (!isInDesktop()) return;

    await platform.os.openURL(url);
  }, []);
};

const openQuickNoteWindow = async () => {
  if (!window.loggedIn || window.authStatus === 'unauthenticated') {
    return;
  }

  // Open /desktop-quick-note
  const url = new URL('/desktop-quick-note', window.location.origin).toString();

  const screenPoint = await screen.getCursorScreenPoint();
  const desktopPoint = await screen.getDisplayNearestPoint(screenPoint);

  // create a floating window with no frame and background
  // in the center of the screen
  const winRef = await nativeWindow.create({
    width: desktopPoint.bounds.width,
    height: desktopPoint.bounds.height,
    x: desktopPoint.bounds.x,
    y: desktopPoint.bounds.y,
    frame: false,
    show: false,
    transparent: true,
    alwaysOnTop: true,
    center: true,
    // fullscreen: true,
    webPreferences: {
      sandbox: false,
    },
  });

  await webContents.loadURL({ ref: winRef }, url);

  // if it loses focus, close it
  nativeWindow.on(
    'blur',
    async () => {
      await nativeWindow.close({ ref: winRef });
    },
    { ref: winRef },
  );

  // open the window
  await nativeWindow.show({ ref: winRef });
};

const openDesktopGlobalSearch = async () => {
  if (!window.loggedIn || window.authStatus === 'unauthenticated') return;

  // Open /desktop-quick-note
  const url = new URL('/desktop-global-search', window.location.origin).toString();

  const screenPoint = await screen.getCursorScreenPoint();
  const desktopPoint = await screen.getDisplayNearestPoint(screenPoint);

  // create a floating window with no frame and background
  // in the center of the screen
  const winRef = await nativeWindow.create({
    width: desktopPoint.bounds.width,
    height: desktopPoint.bounds.height,
    x: desktopPoint.bounds.x,
    y: desktopPoint.bounds.y,
    frame: false,
    show: false,
    transparent: true,
    alwaysOnTop: true,
    hasShadow: false,
    center: true,
    vibrancy: 'hud',
    webPreferences: {
      sandbox: false,
      nodeIntegration: true,
    },
  });

  await webContents.loadURL({ ref: winRef }, url);

  // if it loses focus, close it
  nativeWindow.on(
    'blur',
    async () => {
      await nativeWindow.close({ ref: winRef });
    },
    { ref: winRef },
  );

  // open the window
  await nativeWindow.show({ ref: winRef });
};

const setupDesktopShortcuts = async () => {
  if (!isInDesktop()) return;

  await globalShortcut.unregisterAll();

  if (!(await globalShortcut.isRegistered('CommandOrControl+Alt+N'))) {
    await globalShortcut.register('CommandOrControl+Alt+N', () => {
      openQuickNoteWindow();
    });
  }

  // global search shorcut is CMD+ALT+F on mac and CTRL+ALT+F on windows
  const globalSearchShortcut = 'Alt+CommandOrControl+F';
  if (!(await globalShortcut.isRegistered(globalSearchShortcut))) {
    await globalShortcut.register(globalSearchShortcut, () => {
      openDesktopGlobalSearch();
    });
  }
};

if (isInDesktop() && !desktopFloatUrls.includes(window.location.pathname) && !inNextServer()) {
  setupDesktopShortcuts();
}
