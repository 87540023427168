import React, { useCallback, useEffect, useRef } from 'react';

import styles from './ToastDisplay.module.scss';
import useAlertStore, { AlertElement, TOAST_DURATION } from '@/src/store/alerts';
import { shallow } from 'zustand/shallow';
import { gsap } from 'gsap';

const ToastDisplay: AlertElement<'toast'> = ({ alert: toast }) => {
  const removeAlert = useAlertStore((state) => state.removeAlert, shallow);
  const elementId = `toast-${toast.id}`;
  const [showing, setShowing] = React.useState(false);

  useEffect(() => {
    gsap.fromTo(
      `#${elementId}`,
      {
        opacity: 0,
        y: -10,
      },
      {
        duration: 0.5,
        opacity: 1,
        y: 0,
        onComplete: () => {
          setShowing(true);
        },
      },
    );
  }, [elementId]);

  const timeout = useRef<number | null>(null);
  const gsapAnimation = useRef<gsap.core.Tween | null>(null);
  const dismiss = useCallback(
    (duration: number = TOAST_DURATION) => {
      if (timeout.current) clearTimeout(timeout.current);
      if (gsapAnimation.current) gsapAnimation.current.kill();

      timeout.current = window.setTimeout(() => {
        gsapAnimation.current = gsap.to(`#${elementId}`, {
          duration: 0.5,
          opacity: 0,
          onComplete: () => {
            setShowing(false);
            removeAlert(toast.id);
          },
        });
      }, duration);

      return () => {
        if (timeout.current) clearTimeout(timeout.current);
        if (gsapAnimation.current) gsapAnimation.current.kill();
      };
    },
    [toast.id, removeAlert, elementId],
  );

  useEffect(() => {
    if (!showing) return;

    const cancel = dismiss();

    return () => {
      cancel();
    };
  }, [dismiss, showing]);

  const onClick = (e: React.MouseEvent) => {
    if (toast.onClick) toast.onClick(e);
    if (e.defaultPrevented) return;
    dismiss(0);
  };

  return (
    <div
      id={elementId}
      className={styles.toast}
      onClick={onClick}
      style={{
        opacity: showing ? 1 : 0,
      }}
    >
      {toast.content}
    </div>
  );
};

export default ToastDisplay;
