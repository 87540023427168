import { Fdoc } from '@/src/types/api';

export const getResourceTypeName = (resource: Fdoc): string => {
  switch (resource.type) {
    case 'page':
      return 'Bookmark';
    case 'text':
      return 'Highlight';
    case 'image':
      return 'Image';
    case 'notepad':
      return 'Note';
    case 'stored_file':
      return 'File';
    case 'folder':
      return 'Folder';
    default:
      return 'Item';
  }
};
