import {
  codeSyntaxHighlightDarkMode,
  codeSyntaxHighlightLightMode,
} from '@/src/modules/ui/theme/codeSyntaxHighlight';
import { renderCssBasedOnColorScheme } from '@/src/modules/ui/theme/renderCssBasedOnColorScheme';
import { isTruthy } from '@/src/utils/guards';
import Color from 'color';
import { createGlobalStyle } from 'styled-components';
import { CSS_THEME } from './theme';
import { colorToHSLCssString, colorToRGBCssString } from './utils';

type CssVarColorHex = keyof typeof CSS_THEME;
type CssVarColorRgb = `${keyof typeof CSS_THEME}-rgb`;
type CssVarColorHsl = `${keyof typeof CSS_THEME}-hsl`;

const transformDefinitionToVariables = () => {
  const res = Object.entries(CSS_THEME).reduce((res, [key]) => {
    return {
      ...res,
      [key]: `var(--fabric-${key})`,
      [`${key}-rgb`]: `var(--fabric-${key}-rgb)`,
      [`${key}-hsl`]: `var(--fabric-${key}-hsl)`,
    };
  }, {});
  return res as Record<CssVarColorHex | CssVarColorRgb | CssVarColorHsl, string>;
};

const generateCssVariablesStylesheetDark = () => {
  const darkTheme = Object.entries(CSS_THEME)
    .map(([key, value]) => {
      if (value.definitionType === 'color' && 'dark' in value) {
        const color = new Color(value.dark);
        return `
          --fabric-${key}: ${value.dark};
          --fabric-${key}-rgb: ${colorToRGBCssString(color)};
          --fabric-${key}-hsl: ${colorToHSLCssString(color)};
      `;
      }
      return null;
    })
    .filter(isTruthy);

  return darkTheme;
};

const generateCssVariablesStylesheet = () => {
  const lightTheme = Object.entries(CSS_THEME).map(([key, value]) => {
    if (value.definitionType === 'color') {
      const color = new Color(value.light);
      return `
        --fabric-${key}: ${value.light};
        --fabric-${key}-rgb: ${colorToRGBCssString(color)};
        --fabric-${key}-hsl: ${colorToHSLCssString(color)};
      `;
    } else {
      return `--fabric-${key}: ${value.value};`;
    }
  });

  return lightTheme;
};

export const lightThemeStylesheet = generateCssVariablesStylesheet();
export const darkThemeStylesheet = generateCssVariablesStylesheetDark();

export const cssVar = transformDefinitionToVariables();

const GlobalStylesheetCssVariables = createGlobalStyle`
  hr {
    border-top-color: ${cssVar['color-border-primary']};
  }

  html:not(.todesktop-platform-darwin), html:not(.todesktop-platform-darwin) body {
    background-color: ${cssVar['color-bg-primary']};
  }
  
  :root {
    ${renderCssBasedOnColorScheme({
      light: lightThemeStylesheet,
      dark: darkThemeStylesheet,
    })}
    ${renderCssBasedOnColorScheme({
      light: codeSyntaxHighlightLightMode,
      dark: codeSyntaxHighlightDarkMode,
    })}
  }
`;

export const AppThemeStylesheet = () => {
  return <GlobalStylesheetCssVariables />;
};
