import React from 'react';
import { create } from 'zustand';

/**
 * Interface defining the state and actions for the modifier keys store.
 */
interface ModifierKeysState {
  /** Indicates whether the Meta key is pressed */
  meta: boolean;
  /** Indicates whether the Shift key is pressed */
  shift: boolean;
  /** Indicates whether the Alt key is pressed */
  alt: boolean;
  /** Indicates whether the Control key is pressed */
  ctrl: boolean;
  /**
   * Sets the state of a specific modifier key
   * @param key - The modifier key to update ('meta', 'shift', 'alt', or 'ctrl')
   * @param value - The new state of the modifier key (true for pressed, false for released)
   */
  setModifierKey: (key: 'meta' | 'shift' | 'alt' | 'ctrl', value: boolean) => void;
  /** Resets all modifier keys to their unpressed state */
  clearAllModifiers: () => void;
}

/**
 * Custom hook that creates a store for tracking modifier key states.
 * This is used to prevent having too many event listeners for this same purpose, previously all FdocItems would have their own event listener, which
 * caused an absurd amount of UI lag.
 *
 * This store keeps track of the pressed state of Meta, Shift, Alt, and Control keys,
 * and provides methods to update individual key states or clear all states at once.
 */
const useModifierKeysStore = create<ModifierKeysState>((set) => ({
  meta: false,
  shift: false,
  alt: false,
  ctrl: false,
  setModifierKey: (key, value) => set((state) => ({ ...state, [key]: value })),
  clearAllModifiers: () => set({ meta: false, shift: false, alt: false, ctrl: false }),
}));

/**
 * A custom hook that listens for modifier key events and updates the modifier keys state.
 *
 * This hook adds event listeners for keydown, keyup, and blur events to track the state
 * of modifier keys (Meta, Shift, Alt, Control) and update the global state accordingly.
 *
 * @returns {void}
 */
export const useModifierKeysListener = () => {
  React.useEffect(() => {
    /**
     * Handles the keydown event and updates the modifier key state to true.
     * @param {KeyboardEvent} e - The keyboard event object.
     */
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Meta' || e.metaKey)
        useModifierKeysStore.getState().setModifierKey('meta', true);
      if (e.key === 'Shift' || e.shiftKey)
        useModifierKeysStore.getState().setModifierKey('shift', true);
      if (e.key === 'Alt' || e.altKey) useModifierKeysStore.getState().setModifierKey('alt', true);
      if (e.key === 'Control' || e.ctrlKey)
        useModifierKeysStore.getState().setModifierKey('ctrl', true);
    };

    /**
     * Handles the keyup event and updates the modifier key state to false.
     * @param {KeyboardEvent} e - The keyboard event object.
     */
    const keyUpHandler = (e: KeyboardEvent) => {
      if (e.key === 'Meta' || e.key === 'Shift' || e.key === 'Alt' || e.key === 'Control') {
        useModifierKeysStore
          .getState()
          .setModifierKey(e.key.toLowerCase() as 'meta' | 'shift' | 'alt' | 'ctrl', false);
      }
    };

    /**
     * Handles the blur event and clears all modifier key states.
     */
    const blurHandler = () => {
      useModifierKeysStore.getState().clearAllModifiers();
    };

    // Add event listeners
    window.addEventListener('keydown', keyDownHandler);
    window.addEventListener('keyup', keyUpHandler);
    window.addEventListener('blur', blurHandler);

    // Cleanup function to remove event listeners
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
      window.removeEventListener('keyup', keyUpHandler);
      window.removeEventListener('blur', blurHandler);
    };
  }, []);
};

export default useModifierKeysStore;
