import {
  ChatbotConversationMessage,
  ChatbotConversationMessageAssistant,
  ChatbotConversationMessageUser,
} from '@/src/modules/assistant/types';

const isChatbotUserMessage = (
  message: ChatbotConversationMessage,
): message is ChatbotConversationMessageUser => {
  return message.role === 'user';
};

const isChatbotAssistantMessage = (
  message: ChatbotConversationMessage,
): message is ChatbotConversationMessageAssistant => {
  return message.role === 'assistant';
};

export { isChatbotAssistantMessage, isChatbotUserMessage };
