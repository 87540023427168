import { Announcement } from '@fabric/woody-client';
import { compareVersions } from 'compare-versions';
import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';
import { shallow } from 'zustand/shallow';
import { getDevice } from '../lib/capacitor/device';
import { useWoody } from '../services/woody/woody';
import useAlertStore, { banner } from '../store/alerts';
import { isInMobile } from './mobile';
import { getVersions, VersionInfo } from './useVersionCheck';

const allowedSeverities = ['announcement', 'danger', 'warning', 'info'] as const;

type AnnouncementWithVersion = {
  announcement: Announcement | null;
  version: VersionInfo;
};

// Enhanced utility function to compare current version with minimum and maximum version requirements
// Automatically handles undefined values
function isVersionCompatible(
  currentVersion: string | undefined,
  minVersion: string | undefined,
  maxVersion: string | undefined,
) {
  // If currentVersion is undefined, treat it as always compatible
  if (!currentVersion) return true;

  if (minVersion && compareVersions(currentVersion, minVersion) < 0) {
    return false;
  }
  if (maxVersion && compareVersions(currentVersion, maxVersion) > 0) {
    return false;
  }
  return true;
}

export const useAnnouncementCheck = () => {
  const { client } = useWoody();
  const [lastCheck, setLastCheck] = useState<number | null>(null);
  const dismissBanner = useAlertStore((state) => state.dismissBanner, shallow);

  const previousData = useRef<AnnouncementWithVersion | null>(null);
  const { data, isLoading } = useSWR(
    'announcement-check',
    async () => {
      const { data, error } = await client.getAnnouncement();
      const version = await getVersions();

      if (error) throw error;

      return {
        announcement: data,
        version,
      };
    },
    {
      refreshInterval: 2 * 60 * 1000,
      focusThrottleInterval: 1 * 60 * 1000,
      revalidateIfStale: false,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
      onSuccess: () => {
        setLastCheck(Date.now());
      },
    },
  );

  useEffect(() => {
    const { announcement, version } = data ?? {};

    if (
      (previousData.current?.announcement?.key === announcement?.key && announcement?.visible) ||
      isLoading
    )
      return;
    if (!announcement || !announcement.visible || !announcement.key) {
      // if the previousData is not null, it means we're hiding the banner (or it's not visible anymore)
      if (previousData.current) {
        dismissBanner();
      }

      previousData.current = null;
      return;
    }

    previousData.current = data ?? null;

    const iosVersion = isInMobile('ios') ? version?.appVersion : undefined;
    const androidVersion = isInMobile('android') ? version?.appVersion : undefined;

    if (
      !isVersionCompatible(
        version?.webVersion,
        announcement.client?.web?.version?.min,
        announcement.client?.web?.version?.max,
      ) ||
      !isVersionCompatible(
        iosVersion,
        announcement.client?.ios?.version?.min,
        announcement.client?.ios?.version?.max,
      ) ||
      !isVersionCompatible(
        androidVersion,
        announcement.client?.android?.version?.min,
        announcement.client?.android?.version?.max,
      ) ||
      !isVersionCompatible(
        version?.desktopVersion,
        announcement.client?.desktop?.version?.min,
        announcement.client?.desktop?.version?.max,
      )
    )
      return;

    const device = getDevice();
    const title = announcement.client?.[device]?.title ?? announcement.title;
    const content = announcement.client?.[device]?.content ?? announcement.content;
    const action = announcement.client?.[device]?.action ?? announcement.action;
    const type = announcement.client?.[device]?.type ?? announcement.type;

    // make sure severity is one of the allowed values
    const severity = allowedSeverities.includes(type) ? type : 'info';

    banner({
      id: announcement.key ?? undefined,
      title,
      content,
      action,
      severity,
    });
  }, [isLoading, data, lastCheck, dismissBanner]);
};
