import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import { useRouter } from 'next/router';
import {
  getUserNavigationHistory,
  userNavigationHistoryStore,
} from '../stores/userNavigationHistoryStore';

/**
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 * KNOWN ISSUE
 * The history tracks both push and replace. Currently there's unknown way to distinguish between them
 * @TODO FUT-3613
 * !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
 *
 * !! atach this hook once, to the root of the app
 *
 * tracks route path changes
 * WHY: Unfortunately due to security reasons the previous history is not available from the browser
 * so we have to track it ourselves. This allows us to track history while the user stays on the domain
 * PURPOSE: handling back button behaviour gracefully
 */
export const useTrackUserNavigationHistory = () => {
  const { pathname, ...router } = useRouter();

  /**
   * save changes in path
   */
  useClientLayoutEffect(() => {
    const last = getUserNavigationHistory().lastState;
    // We don't use router.asPath because on initial render it's the same as pathname which is VERY wrong
    const asPath = window.location.pathname + window.location.search;

    /**
     * we save the history change if asPath are different
     * asPath includes search query params (considers hello?search=world hello?search=mars as different)
     */
    if (last?.asPath !== asPath) {
      const lastState = {
        asPath,
        pathname,
      };
      userNavigationHistoryStore.setState((state) => {
        return {
          lastState,
          history: [lastState, ...(state.history || [])],
        };
      });
    }
    // we still re-run for asPath because it better 100% reflects the current state
  }, [router.asPath, pathname, router]);
};
