import { isString } from '@/src/lib/utils';
import { EVERYTHING_CONTEXT } from '@/src/modules/assistant/constants';
import {
  AssistantContextOption,
  AssistantContextSubType,
  ChatbotConversationMessage,
} from '@/src/modules/assistant/types';

const getContextKeyFromMessage = (message?: ChatbotConversationMessage): string => {
  if (!message) return getContextKey(EVERYTHING_CONTEXT);

  const context = message.context;

  return getContextKey(context);
};

const getContextKey = (context?: AssistantContextOption): string => {
  const contextValue = isString(context) ? context : JSON.stringify(context?.value);
  return `context:${context?.subType}:${contextValue}`;
};

const fileBaseContextSubtype = [AssistantContextSubType.File, AssistantContextSubType.Selection];
const isFileBasedContext = (context?: AssistantContextOption): boolean => {
  return fileBaseContextSubtype.includes(context?.subType ?? AssistantContextSubType.Everything);
};

export { getContextKey, getContextKeyFromMessage, isFileBasedContext };
