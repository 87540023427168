import { QueryClient, QueryKey } from '@tanstack/react-query';
import { spaceQueryPredicates } from '../queries/spaceQueryPredicates';
import { spaceQueryKeys } from '../queries/spacesQueryKeys';
import { Space } from '../spaces.types';

// const syncSpaceQuery = (data: Space, queryClient: QueryClient) => {
//   queryClient.setQueriesData<Space[]>(
//     {
//       predicate: spaceQueryPredicates.spacesAll,
//       type: 'active',
//     },
//     (oldData) => {
//       return upsertItemInArrayById(oldData ?? [], data, {
//         createIfNotFound: true,
//       });
//     },
//   );
// };

const syncSpacesAllQuery = (data: Space[], queryClient: QueryClient) => {
  for (const space of data) {
    queryClient.setQueriesData<Space>(
      {
        predicate: (q) => spaceQueryPredicates.space(q, space.id),
        type: 'active',
      },
      space,
    );
  }
};

const isSpaceQueryKey = (
  queryKey: QueryKey,
): queryKey is ReturnType<typeof spaceQueryKeys.space> => {
  return (
    Array.isArray(queryKey) &&
    queryKey.length > 0 &&
    spaceQueryKeys.space()[0] === queryKey[0] &&
    queryKey[1] !== undefined &&
    typeof queryKey[1] === 'string'
  );
};

const isSpacesAllQueryKey = (
  queryKey: QueryKey,
): queryKey is ReturnType<typeof spaceQueryKeys.spacesAll> => {
  return (
    Array.isArray(queryKey) && queryKey.length > 0 && spaceQueryKeys.spacesAll()[0] === queryKey[0]
  );
};

export const syncSpacesQuery = (data: unknown, queryKey: QueryKey, queryClient: QueryClient) => {
  if (!Array.isArray(queryKey) || queryKey.length === 0) return;

  if (isSpaceQueryKey(queryKey)) {
    /**
     * @TODO
     */
    // syncSpaceQuery(data as Space, queryClient);
  }

  if (isSpacesAllQueryKey(queryKey)) {
    syncSpacesAllQuery(data as Space[], queryClient);
  }

  // Will not care for search queries for now, since usually it has some missing data as it's stored in ES.
};
