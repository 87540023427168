import IconEnter from '@/public/images/icons/Enter.svg';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';

const KbdBase = styled.kbd`
  font-family: 'Inter', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  border-radius: 4px;
  padding: 0 4px;
  height: 21px;
  min-width: 21px;

  background: ${cssVar['color-bg-tertiary']};
  color: ${cssVar['color-text-secondary']};

  ${mediaMobile} {
    display: none;
  }
`;

const IconEsc = styled.span.attrs({ children: 'esc' })``;

export const Kbd = Object.assign(KbdBase, {
  IconEnter,
  IconEsc,
});
