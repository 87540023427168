import { isInDesktop } from '@/src/hooks/todesktop';
import { getVersions } from '@/src/hooks/useVersionCheck';
import { Capacitor } from '@capacitor/core';

/**
 * Retrieves default tracked properties including location and version information for analytics.
 * @returns {Promise<Record<string, unknown>>} A promise that resolves to an object containing tracked properties.
 */
export const getDefaultTrackedProperties = async (): Promise<Record<string, unknown>> => {
  const versions = await getVersions();

  return {
    location: getLocation(),
    webVersion: versions.webVersion ?? null,
    appVersion: versions.appVersion ?? null,
    desktopVersion: versions.desktopVersion ?? null,
  };
};

/**
 * Determines the current location/platform of the application.
 * @returns {string} A string representing the current location/platform.
 * Possible values are:
 * - 'server': If running on the server side
 * - 'desktop': If running on desktop application
 * - 'ios': If running on iOS mobile device
 * - 'android': If running on Android mobile device
 * - 'browser': If running on a web browser (default)
 */
const getLocation = (): string => {
  // Not using the isInMobile function because this code seems to break build
  // because it is built before the isInMobile function is defined somehow.
  const capacitorOs = Capacitor.getPlatform();

  if (typeof window === 'undefined') return 'server';
  if (isInDesktop()) return 'desktop';
  if (capacitorOs === 'ios') return 'ios';
  if (capacitorOs === 'android') return 'android';
  return 'browser';
};
