import { useAnalyticsStore } from '../analytics.store';
import { AnalyticsEvents } from '../analytics.types';

/**
 * This hook provides a convenient way to track analytics events via the underlying analytics store.
 *
 * @returns An object containing the track function.
 */
export const useAnalytics = () => {
  const { track } = useAnalyticsStore();

  return {
    /**
     * Tracks an analytics event.
     *
     * @param event - The analytics event to track.
     * @param properties - Optional properties associated with the event.
     */
    track: (event: AnalyticsEvents, properties?: Record<string, unknown>) =>
      track(event, properties),
  };
};
