import { mediaHover } from '@/src/modules/ui/styled-utils';
import { cssVar } from '@/src/modules/ui/theme/variables';
import styled from 'styled-components';
import { Flex } from '../Flex';

export const ListItem = styled.div`
  border: 1px solid ${cssVar['color-border-primary']};
  border-radius: ${cssVar['radius-input']};
  background: ${cssVar['color-bg-primary']};
  padding: 1rem;
  z-index: 1;
  transition:
    z-index 0.1s step-end,
    border-color 0.2s linear;
  width: 100%;
  & + & {
    margin-top: -1px;
  }
  ${mediaHover} {
    &:hover {
      transition:
        z-index 0.1s step-start,
        border-color 0.2s linear;
      z-index: 5;
      border-color: ${cssVar['color-app-primary']};
    }
  }
`;

export const ListContainer = styled(Flex).attrs({
  direction: 'column',
})``;

export const ListItemTitle = styled.div`
  color: ${cssVar['color-text-secondary']};
  font-size: 1rem;
  line-height: 1rem;
`;

export const ListItemDescription = styled.div`
  color: ${cssVar['color-text-tertiary']};
  font-size: 0.875rem;
`;
