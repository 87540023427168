import { ApiUserMe, UserGroup } from '../user.types';

/**
 * Check if the user is in one of the groups
 * @param user
 * @param groups
 * @returns boolean
 */
export const oneOfGroup = (user: ApiUserMe | null | undefined, groups: UserGroup[]) => {
  if (!user || !user.groups) {
    return false;
  }

  return groups.some((group) => (user.groups || []).includes(group));
};
