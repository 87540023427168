import { isInMobile } from '@/src/hooks/mobile';
import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import { useReferencedFn } from '@/src/hooks/useReferencedFn';
import detectMobile from '@/src/modules/mobile/utils/detectMobile';
import { mediaMobile } from '@/src/modules/ui/styled-utils';
import { useEffect, useState } from 'react';
import { createGlobalStyle, css } from 'styled-components';

interface UseVisualViewportOptions {
  disabled?: boolean;
  recalculateKey?: unknown;
  throttleSpeed?: number;
}

interface Viewport {
  width: number;
  height: number;
  offsetLeft: number;
  offsetTop: number;
  pageLeft: number;
  pageTop: number;
  scale: number;
  windowActualWidth: number;
  windowActualHeight: number;
  heightCrop: number;
}

const visualViewportToViewport = (visualViewport: VisualViewport): Viewport => ({
  width: visualViewport.width,
  height: visualViewport.height,
  offsetLeft: visualViewport.offsetLeft,
  offsetTop: visualViewport.offsetTop,
  pageLeft: visualViewport.pageLeft,
  pageTop: visualViewport.pageTop,
  scale: visualViewport.scale,
  windowActualWidth: window.innerWidth,
  windowActualHeight: window.innerHeight,
  heightCrop: Math.max(Math.floor(window.innerHeight - visualViewport.height), 0),
});

function useVisualViewport(options: UseVisualViewportOptions = {}) {
  const disabled = options.disabled ?? false;
  const recalculateKey = options.recalculateKey ?? null;

  const [viewport, setViewport] = useState<Viewport | undefined>(() => {
    if (disabled || typeof window === 'undefined') return;

    return window.visualViewport
      ? {
          ...visualViewportToViewport(window.visualViewport),
          height: Math.min(window.visualViewport.height, window.innerHeight),
          width: Math.min(window.visualViewport.width, window.innerWidth),
        }
      : undefined;
  });

  const updateViewportRef = useReferencedFn(() => {
    const newViewport = window.visualViewport
      ? {
          ...visualViewportToViewport(window.visualViewport),
          /**
           * bug, some mobile browsers report height 0 when keyboard is open
           */
          height: Math.min(window.visualViewport.height || 400, window.innerHeight),
          width: Math.min(window.visualViewport.width, window.innerWidth),
        }
      : undefined;

    setViewport(newViewport);
  });

  useEffect(() => {
    if (disabled || typeof window === 'undefined') return;

    // Update viewport when recalculateKey changes
    updateViewportRef.current();
  }, [recalculateKey, disabled, updateViewportRef]);

  useClientLayoutEffect(() => {
    if (disabled || typeof window === 'undefined') return;
    const updateViewport = updateViewportRef.current;

    window.visualViewport?.addEventListener('resize', updateViewport);
    window.addEventListener('resize', updateViewport);

    const resizeObserver = new ResizeObserver(updateViewport);
    resizeObserver.observe(document.documentElement);

    // Initial update
    updateViewport();

    return () => {
      window.visualViewport?.removeEventListener('resize', updateViewport);
      window.removeEventListener('resize', updateViewport);

      resizeObserver.disconnect();
    };
  }, [disabled, updateViewportRef]);

  return viewport;
}

const GlobalViewportStyle = createGlobalStyle<{ viewport: Viewport }>`
  ${({ viewport }) => css`
    :root {
      --viewport-width: ${viewport.width}px;
      --viewport-height: ${viewport.height}px;
      --viewport-offset-left: ${viewport.offsetLeft}px;
      --viewport-offset-top: ${viewport.offsetTop}px;
      --viewport-page-left: ${viewport.pageLeft}px;
      --viewport-page-top: ${viewport.pageTop}px;
      --viewport-scale: ${viewport.scale};
    }
  `}
`;

export function useVisualViewportWithGlobalStyle() {
  const isMobileWeb = !isInMobile() && detectMobile(true);
  const viewport = useVisualViewport({
    disabled: !isMobileWeb,
  });

  const isViewportAvailable = viewport !== undefined && !isInMobile() && detectMobile(true);

  useClientLayoutEffect(() => {
    if (!isViewportAvailable) return;

    // add a [data-viewport] attribute to the html element this way we can use it to conditionaly apply styles
    document.documentElement.setAttribute('data-viewport', 'true');
    return () => {
      document.documentElement.removeAttribute('data-viewport');
    };
  }, [isViewportAvailable]);

  // If viewport is undefined, or is in the native app, or not in a mobile OS, return null
  if (!isViewportAvailable) return null;

  return <GlobalViewportStyle viewport={viewport} />;
}

export const handleViewportCSS = css`
  ${mediaMobile} {
    html[data-viewport='true'] & {
      transition: none;
      transform: translate(0, var(--viewport-page-top));

      height: var(--viewport-height) !important;
      max-height: var(--viewport-height) !important;
      min-height: var(--viewport-height) !important;
    }
  }
`;

export default useVisualViewport;
