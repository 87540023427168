import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import inNextServer from '@/src/utils/next';
import React, { useMemo } from 'react';
import { viewportBreakpoints } from '../modules/ui/constants/breakpoints';

type Breakpoint = 'xss' | 'xs' | 'mobile' | 'tablet' | 'desktop';

/**
 * Determines the current breakpoint based on the window's inner width.
 * Defaults to 'desktop' if running in a Next.js server environment.
 *
 * @returns {Breakpoint} The current breakpoint ('xs', 'mobile', 'tablet', or 'desktop').
 */
const getBreakpoint = (): Breakpoint => {
  if (inNextServer()) {
    return 'desktop';
  }

  const width = window.innerWidth;
  if (width <= viewportBreakpoints.XSS) {
    return 'xss';
  } else if (width <= viewportBreakpoints.XS) {
    return 'xs';
  } else if (width <= viewportBreakpoints.MOBILE) {
    return 'mobile';
  } else if (width <= viewportBreakpoints.TABLET) {
    return 'tablet';
  } else {
    return 'desktop';
  }
};

interface ResponsiveData {
  breakpoint: Breakpoint;
  windowWidth: number;
  /**
   * @deprecated use isMobileView instead
   */
  isMobile(): boolean;
  isMobileView: boolean;
  /**
   * @deprecated use isTabletView instead
   */
  isTablet(): boolean;
  isTabletView: boolean;
  /**
   * @deprecated use isDesktopView instead
   */
  isDesktop(): boolean;
  /**
   * more than 1024px width
   */
  isDesktopView: boolean;
  /**
   * @deprecated use isXSView instead
   */
  isXS(): boolean;
  /**
   * Less than 412px width
   */
  isXSView: boolean;
  /**
   * Less than 370px width
   */
  isXSSView: boolean;
}

const ResponsiveContext = React.createContext<ResponsiveData>({
  breakpoint: 'desktop',
  windowWidth: 2000,
  isMobile: () => false,
  isMobileView: false,
  isTablet: () => false,
  isDesktop: () => true,
  isDesktopView: true,
  isXS: () => false,
  isXSView: false,
} as ResponsiveData);

export const useResponsive = (options?: {
  onWidthChange?: (args: {
    isMobileView: boolean;
    isTabletView: boolean;
    isDesktopView: boolean;
    windowWidth: number;
  }) => void;
}) => {
  const context = React.useContext(ResponsiveContext);
  const { isMobileView, isTabletView, isDesktopView, windowWidth } = context;

  /**
   * on width change callback
   */
  const onWidthChangeRef = React.useRef(options?.onWidthChange);
  onWidthChangeRef.current = options?.onWidthChange;

  useClientLayoutEffect(() => {
    if (onWidthChangeRef.current) {
      onWidthChangeRef.current({
        isMobileView,
        isTabletView,
        isDesktopView,
        windowWidth,
      });
    }
  }, [isMobileView, isTabletView, isDesktopView, windowWidth]);

  return context;
};

export const ResponsiveProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [breakpoint, setBreakpoint] = React.useState<Breakpoint>(getBreakpoint());

  const isMobile = React.useCallback(
    () => breakpoint === 'mobile' || breakpoint === 'xs' || breakpoint === 'xss',
    [breakpoint],
  );
  const isTablet = React.useCallback(() => breakpoint === 'tablet', [breakpoint]);
  const isDesktop = React.useCallback(() => breakpoint === 'desktop', [breakpoint]);
  const isXS = React.useCallback(() => breakpoint === 'xs', [breakpoint]);

  const [windowWidth, setWindowWidth] = React.useState<number>(2000);

  useClientLayoutEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      setBreakpoint(getBreakpoint());
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const data: ResponsiveData = useMemo(
    () => ({
      breakpoint,
      windowWidth,
      /**
       * @deprecated use isMobileView instead
       */
      isMobile,
      isMobileView: breakpoint === 'mobile' || breakpoint === 'xs' || breakpoint === 'xss',
      /**
       * @deprecated use isTabletView instead
       */
      isTablet,
      isTabletView: breakpoint === 'tablet',
      /**
       * @deprecated use isDesktopView instead
       */
      isDesktop,
      isDesktopView: breakpoint === 'desktop',
      isXS,
      isXSView: breakpoint === 'xs',
      isXSSView: breakpoint === 'xss',
    }),
    [breakpoint, windowWidth, isMobile, isTablet, isDesktop, isXS],
  );

  return <ResponsiveContext.Provider value={data}>{children}</ResponsiveContext.Provider>;
};
