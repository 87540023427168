import debounce from 'lodash.debounce';
import React from 'react';

export const useDebouncedCallback = <
  T extends (...args: Parameters<T>) => ReturnType<T>,
  F extends T | undefined,
>(
  fn: F,
  delay: number = 200,
) => {
  return React.useMemo(() => {
    if (fn && delay) {
      return debounce(fn, delay);
    }
    return fn;
  }, [delay, fn]);
};
