import { addPendingMutation } from '@/src/lib/react-query/pending';
import {
  upsertItemInArrayById,
  useOptimisticallyUpdateInfiniteQueriesWithPredicate,
  useOptimisticallyUpdateQueriesWithPredicate,
} from '@/src/lib/react-query/utilities';
import { mutateResourceMeta } from '@/src/modules/resource-detail/utils/mutateResourceMeta';
import { resourceQueryPredicates } from '@/src/modules/resources/queries/resourceQueryPredicates';
import { FilteredFdocs } from '@/src/modules/resources/resources.types';
import { Fdoc } from '@/src/types/api';
import { isTruthy } from '@/src/utils/guards';
import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import { resourceMatchesQueryFilters } from './resourceMatchesQueryFilters';

type ResourceWithOptionalProperties = Partial<Omit<Fdoc, 'id'>> & Pick<Fdoc, 'id'>;
type ReplaceOptions = {
  createIfNotFound?: boolean;
};

export const useQueryCacheResourceHelpers = () => {
  const queryClient = useQueryClient();
  const optimisticallyUpdateQueries = useOptimisticallyUpdateQueriesWithPredicate();
  const optimisticallyUpdateInfiniteQueries = useOptimisticallyUpdateInfiniteQueriesWithPredicate();

  return {
    updateCachedResource: (resourceWithUpdatedProperties: ResourceWithOptionalProperties) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          resourceQueryPredicates.filterAndSearchAll(q) ||
          resourceQueryPredicates.resource(q, resourceWithUpdatedProperties.id),
        type: 'active',
      });

      /**
       * updating list of resources where the resource COULD be present, both filter queries (direct DB) or search queries (elastic search)
       */
      const optimisticallyUpdateFilterAndSearchAllQueries =
        optimisticallyUpdateInfiniteQueries<FilteredFdocs>({
          predicate: resourceQueryPredicates.filterAndSearchAll,
          pageUpdater: (page) => ({
            ...page,
            results: upsertItemInArrayById(page.results, resourceWithUpdatedProperties, {
              createIfNotFound: false,
            }),
          }),
        });

      /**
       * updating the resource directly
       */
      const optimisticallyUpdateDirectQueries =
        optimisticallyUpdateQueries<ResourceWithOptionalProperties>(
          (q) => resourceQueryPredicates.resource(q, resourceWithUpdatedProperties.id),
          (resource) =>
            resource && {
              ...resource,
              ...resourceWithUpdatedProperties,
            },
        );

      return {
        optimisticallyUpdateFilterAndSearchAllQueries,
        optimisticallyUpdateDirectQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateFilterAndSearchAllQueries.resetQueriesData();
          optimisticallyUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateFilterAndSearchAllQueries.invalidateQueries();
          optimisticallyUpdateDirectQueries.invalidateQueries();
        },
      };
    },
    deleteCachedResources: (resourceIds: string[]) => {
      queryClient.cancelQueries({
        predicate: (q) =>
          resourceQueryPredicates.filterAndSearchAll(q) ||
          resourceQueryPredicates.resourceMultiple(q, resourceIds),
        type: 'active',
      });

      const deletedResources: Fdoc[] = [];
      const deletedResourceIds: Set<string> = new Set(resourceIds);

      /**
       * Need some custom logic here, because we need to delete children and subchildren, so we need to gather all children
       */
      const queries = queryClient.getQueriesData<InfiniteData<FilteredFdocs>>({
        predicate: resourceQueryPredicates.filterAndSearchAll,
        type: 'active',
      });

      const tree = new Map<string, Fdoc[]>();
      for (const [_, data] of queries) {
        if (!data) {
          continue;
        }

        for (const resource of data.pages.flatMap((page) => page.results)) {
          if (!resource.parentResourceId) {
            continue;
          }

          if (!tree.has(resource.parentResourceId)) {
            tree.set(resource.parentResourceId, []);
          }

          tree.get(resource.parentResourceId)!.push(resource);
        }
      }

      // now that we have the tree, we can create a delete list with DFS
      // we want the list of resource IDs that are children of the ids in deletedResourceIds and 1 them to the deletedResourceIds set
      const stack = [...resourceIds];

      while (stack.length > 0) {
        const resourceId = stack.pop()!;
        if (!tree.has(resourceId)) {
          continue;
        }

        for (const resource of tree.get(resourceId)!) {
          if (!deletedResourceIds.has(resource.id)) stack.push(resource.id);

          deletedResourceIds.add(resource.id);
        }
      }

      /**
       * updating list of resources where the resource COULD be present, both filter queries (direct DB) or search queries (elastic search)
       */
      const optimisticallyUpdateFilterAndSearchAllQueries =
        optimisticallyUpdateInfiniteQueries<FilteredFdocs>({
          predicate: resourceQueryPredicates.filterAndSearchAll,
          pageUpdater: (page) => {
            const updatedResults = page.results.filter((r) => {
              if (deletedResourceIds.has(r.id)) {
                deletedResources.push(r);
                return false;
              }

              return true;
            });

            const difference = page.results.length - updatedResults.length;
            return {
              ...page,
              results: updatedResults,
              // we make sure to change the total because this affects how many skeletons are shown
              total: Math.max(0, (page.total ?? 0) - difference),
            };
          },
        });

      /**
       * updating the resource directly, in this case we don't need to update the resource itself, just mark it as deleted on it's meta
       */
      const optimisticallyUpdateDirectQueries = optimisticallyUpdateQueries<Fdoc>(
        (q) => resourceQueryPredicates.resourceMultiple(q, Array.from(deletedResourceIds)),
        (resource) => resource && mutateResourceMeta(resource, { isDeleting: true }),
      );

      return {
        deletedResources: [...deletedResources].filter(
          (resource, index, self) => self.findIndex((r) => r.id === resource.id) === index,
        ),
        optimisticallyUpdateFilterAndSearchAllQueries,
        optimisticallyUpdateDirectQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticallyUpdateFilterAndSearchAllQueries.resetQueriesData();
          optimisticallyUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticallyUpdateFilterAndSearchAllQueries.invalidateQueries();
          optimisticallyUpdateDirectQueries.invalidateQueries();
        },
      };
    },
    addNewResourcesToCache: (newResources: Fdoc[]) => {
      // this is a bit more complex because we are adding more than one resource and they can be in different parents
      // to minimize the amount of query related functions first we map the resources to their parentResourceId
      const resourcesByParent = newResources
        .filter((resource) => !!resource.parentResourceId)
        .reduce(
          (acc, resource) => {
            if (!acc[resource.parentResourceId!]) {
              acc[resource.parentResourceId!] = [];
            }

            acc[resource.parentResourceId!].push(resource);

            return acc;
          },
          {} as Record<string, Fdoc[]>,
        );

      // we cancel all queries that could be affected by the new resources
      Object.keys(resourcesByParent).forEach((parentResourceId) => {
        queryClient.cancelQueries({
          predicate: (q) => resourceQueryPredicates.filterAndSearchByParent(q, parentResourceId),
          type: 'active',
        });
      });

      // we create the optimistic updates for each parent
      const optimisticUpdates = Object.entries(resourcesByParent).map(
        ([parentResourceId, resources]) => {
          const optimisticallyUpdateFilterAndSearchQueries =
            optimisticallyUpdateInfiniteQueries<FilteredFdocs>({
              predicate: (q) =>
                resourceQueryPredicates.filterAndSearchByParent(q, parentResourceId),
              pageUpdater: (page, index, key) => {
                if (index > 0) return page;

                const validResources = resources.filter((r) => resourceMatchesQueryFilters(r, key));

                const results = page.results.concat(validResources);
                const diff = results.length - page.results.length;

                return {
                  ...page,
                  results,
                  total: page.total + diff,
                };
              },
            });

          return {
            parentResourceId,
            optimisticallyUpdateFilterAndSearchQueries,
          };
        },
      );

      // we create the optimistic updates for each resource
      const optimisticUpdateDirectQueries = newResources.map((resource) =>
        optimisticallyUpdateQueries<Fdoc>(
          (q) => resourceQueryPredicates.resource(q, resource.id),
          () => resource,
        ),
      );

      const allQueryKeys = queryClient
        .getQueryCache()
        .findAll({
          predicate: (query) =>
            resourceQueryPredicates.resourceMultiple(
              query,
              newResources.map((r) => r.id),
            ) ||
            resourceQueryPredicates.filterAndSearchByMultipleParents(
              query,
              newResources.map((r) => r.parentResourceId).filter(isTruthy),
            ),
          type: 'active',
        })
        .map((query) => query.queryKey);

      /**
       * Clears the pending state, so if the user refreshes it will not cause all queries to be invalidated
       */
      const clearPendingState = addPendingMutation(allQueryKeys);

      return {
        optimisticUpdates,
        optimisticUpdateDirectQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdates.forEach(({ optimisticallyUpdateFilterAndSearchQueries }) =>
            optimisticallyUpdateFilterAndSearchQueries.resetQueriesData(),
          );
          optimisticUpdateDirectQueries.forEach((optimisticUpdateDirectQuery) =>
            optimisticUpdateDirectQuery.resetQueriesData(),
          );
        },
        invalidateQueries: () => {
          optimisticUpdates.forEach(({ optimisticallyUpdateFilterAndSearchQueries }) =>
            optimisticallyUpdateFilterAndSearchQueries.invalidateQueries(),
          );
          optimisticUpdateDirectQueries.forEach((optimisticUpdateDirectQuery) =>
            optimisticUpdateDirectQuery.invalidateQueries(),
          );
        },
        clearPendingState,
      };
    },
    // this is for replacing optimistic resources with the real counterparts
    replaceResourceInCache: (oldResource: Fdoc, newResource: Fdoc, options?: ReplaceOptions) => {
      const { createIfNotFound = false } = options ?? {};

      queryClient.cancelQueries({
        predicate: (q) =>
          resourceQueryPredicates.resource(q, oldResource.id) ||
          resourceQueryPredicates.filterAndSearchByParent(q, newResource.parentResourceId),
        type: 'active',
      });

      const optimisticUpdateFilterAndSearchQueries =
        optimisticallyUpdateInfiniteQueries<FilteredFdocs>({
          predicate: (q) =>
            resourceQueryPredicates.filterAndSearchByParent(q, newResource.parentResourceId),
          pageUpdater: (page, pageNum) => {
            // if create if not found is true, we will filter the old resource out of the results and add the new one at the start
            // otherwise we just map the old resource to the new one

            if (createIfNotFound) {
              const results = pageNum === 0 ? [newResource, ...page.results] : page.results;

              /**
               * We don't really update totals here because in some cases a race
               * condition is enough to make it add more than wanted to the total
               * which causes the UI to show wrong skeleton loading items.
               */
              return {
                ...page,
                results: results.filter((r) => r.id !== oldResource.id),
              };
            } else {
              const results = page.results.map((r) => (r.id === oldResource.id ? newResource : r));

              return {
                ...page,
                results,
              };
            }
          },
        });

      const optimisticUpdateDirectQueries = optimisticallyUpdateQueries<Fdoc>(
        (q) => resourceQueryPredicates.resource(q, oldResource.id),
        () => newResource,
      );

      return {
        optimisticUpdateFilterAndSearchQueries,
        optimisticUpdateDirectQueries,
        resetCacheToPreOptimisticState: () => {
          optimisticUpdateFilterAndSearchQueries.resetQueriesData();
          optimisticUpdateDirectQueries.resetQueriesData();
        },
        invalidateQueries: () => {
          optimisticUpdateFilterAndSearchQueries.invalidateQueries();
          optimisticUpdateDirectQueries.invalidateQueries();
          queryClient.invalidateQueries({
            predicate: resourceQueryPredicates.summaryAll,
          });
        },
      };
    },
  };
};
