import { Query } from '@tanstack/react-query';
import { ResourceFilterOptions, ResourceSearchOptions } from '../resources.types';
import { resourceQueryKeys } from './resourceQueryKeys';

export const resourceQueryPredicates = {
  resource: (query: Query, resourceId: string) => {
    const resourceKey = resourceQueryKeys.resource(resourceId);

    return query.queryKey[0] === resourceKey[0] && query.queryKey[1] === resourceKey[1];
  },
  resourceDetail: (query: Query, resourceId: string) => {
    const resourceDetailKey = resourceQueryKeys.resourceDetail(resourceId);

    return query.queryKey[0] === resourceDetailKey[0] && query.queryKey[1] === resourceDetailKey[1];
  },
  resourceMultiple: (query: Query, resourceIds: string[]) => {
    for (const resourceId of resourceIds) {
      if (resourceQueryPredicates.resource(query, resourceId)) return true;
    }

    return false;
  },
  resourceDetailMultiple: (query: Query, resourceIds: string[]) => {
    for (const resourceId of resourceIds) {
      if (resourceQueryPredicates.resourceDetail(query, resourceId)) return true;
    }

    return false;
  },
  filterAll: (query: Query) => {
    return Boolean(query.queryKey[0] === resourceQueryKeys.filter()[0]);
  },
  searchAll: (query: Query) => {
    return Boolean(query.queryKey[0] === resourceQueryKeys.search()[0]);
  },
  filterByParent: (query: Query, parentId?: string | null) => {
    if (query.queryKey[0] !== resourceQueryKeys.filter()[0]) return false;
    if (!parentId) return true;

    const options = query.queryKey[1] as ResourceFilterOptions;

    if (options.filters?.parent === undefined) return true;

    return options.filters.parent === parentId;
  },
  searchByParent: (query: Query, parentId?: string | null) => {
    if (query.queryKey[0] !== resourceQueryKeys.search()[0]) return false;
    if (!parentId) return true;

    const options = query.queryKey[1] as ResourceSearchOptions;

    if (options.filters?.parent === undefined) return true;

    return (options.filters.parent ?? []).includes(parentId);
  },
  filterAndSearchByParent: (query: Query, parentId?: string | null) => {
    return (
      resourceQueryPredicates.filterByParent(query, parentId) ||
      resourceQueryPredicates.searchByParent(query, parentId)
    );
  },
  filterAndSearchByMultipleParents: (query: Query, parentIds: string[]) => {
    return parentIds.some((parentId) =>
      resourceQueryPredicates.filterAndSearchByParent(query, parentId),
    );
  },
  filterAndSearchAll: (query: Query) => {
    return resourceQueryPredicates.filterAll(query) || resourceQueryPredicates.searchAll(query);
  },
  summaryAll: (query: Query) => {
    return query.queryKey[0] === resourceQueryKeys.totals()[0];
  },
  summaryByParent: (query: Query, parentId?: string | null) => {
    if (query.queryKey[0] !== resourceQueryKeys.totals()[0]) return false;
    if (!parentId) return true;

    return query.queryKey[1] === parentId;
  },
  summaryByParents: (query: Query, parentIds: string[]) => {
    if (query.queryKey[0] !== resourceQueryKeys.totals()[0]) return false;

    return parentIds.includes(query.queryKey[1] as string);
  },
};
