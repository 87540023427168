import { ApiUserMe, SubscritionTier } from '@/src/modules/user/user.types';

/**
 * The type of feature, if it is a regular feature or a highlighted feature.
 */
export enum FeatureType {
  Regular = 'REGULAR',
  Highlighted = 'HIGHLIGHTED',
}

/**
 * The billing period for a recurring plan.
 */
export enum BillingPeriod {
  Monthly = 'month',
  Yearly = 'year',
}

/**
 * The type of item, if it is a feature or a separator.
 */
export enum ItemType {
  Feature = 'FEATURE',
  Separator = 'SEPARATOR',
}

/**
 * A feature that a plan can have.
 */
export type Feature = {
  type: ItemType.Feature;
  featureType: FeatureType;
  has: boolean;
  description: string;
};

/**
 * A separator between features, to aid UI.
 */
type Separator = {
  type: ItemType.Separator;
};

/**
 * An item that a plan can have.
 */
export type Item = Feature | Separator;

/**
 * A list of items that a plan can have.
 */
export type ItemList = Item[];

/**
 * The type of billing for a plan, if it is recurring or lifetime.
 */
export enum BillingType {
  Recurring = 'RECURRING',
  Lifetime = 'LIFETIME',
}

/**
 * The price structure for a lifetime plan.
 */
type LifetimePrices = {
  type: BillingType.Lifetime;
  billedOnce: number;

  /**
   * The plan ID in Stripe.
   */
  stripePlanId?: string;
};

/**
 * The price structure for a recurring plan.
 */
type RecurringPrices = {
  type: BillingType.Recurring;
  /**
   * The monthly price when a user is billed every month.
   */
  billedMonthly: number;
  /**
   * The effective monthly price when a user is billed once annually.
   * While this represents a monthly breakdown of the annual cost,
   * the user is billed the total annual price upfront.
   */
  billedYearly: number;

  /**
   * The plan ID in Stripe for the monthly plan.
   */
  stripePlanIdMonthly?: string;

  /**
   * The plan ID in Stripe for the annual plan.
   */
  stripePlanIdYearly?: string;
};

/**
 * The prices structure for a plan.
 */
export type Prices = LifetimePrices | RecurringPrices;

/**
 * A plan that a user can subscribe to.
 */
export type Plan = {
  /**
   * The unique system identifier for the plan.
   * Consistent accross backed and front end.
   */
  name: ApiUserMe['subscription']['tier'];
  /**
   * The title of the plan.
   */
  title: string;
  /**
   * The prices for the plan.
   */
  prices: Prices;
  /**
   * The UI items to display for the plan.
   * Which includes the features and separators.
   */
  items: ItemList;
};

export const plans: Plan[] = [
  {
    name: 'free',
    title: 'Free',
    prices: {
      type: BillingType.Recurring,
      billedMonthly: 0,
      billedYearly: 0,
    },
    items: [
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: '250MB storage space',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: false,
        description: 'AI assistant',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: false,
        description: 'Get AI Recap emails',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: false,
        description: 'Data connections (soon)',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: '10MB maximum upload size',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: '10 spaces',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: '1000 items',
      },
    ],
  },
  {
    name: 'starter',
    title: 'Basic',
    prices: {
      type: BillingType.Recurring,
      billedMonthly: 6,
      billedYearly: 5,

      stripePlanIdMonthly: process.env.NEXT_PUBLIC_STRIPE_BASIC_MONTHLY_PLAN_ID,
      stripePlanIdYearly: process.env.NEXT_PUBLIC_STRIPE_BASIC_YEARLY_PLAN_ID,
    },
    items: [
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: '100GB storage space',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'AI assistant (100 answers)',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Get AI Recap emails',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: false,
        description: 'Data connections (soon)',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'No file size limit',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Unlimited spaces',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Unlimited items',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Save websites offline (soon)',
      },
    ],
  },
  {
    name: 'pro',
    prices: {
      type: BillingType.Recurring,
      billedMonthly: 15,
      billedYearly: 12.5,

      stripePlanIdMonthly: process.env.NEXT_PUBLIC_STRIPE_PRO_MONTHLY_PLAN_ID,
      stripePlanIdYearly: process.env.NEXT_PUBLIC_STRIPE_PRO_YEARLY_PLAN_ID,
    },
    title: 'Pro',
    items: [
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: '2TB storage space',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'AI assistant (unlimited)',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Get AI Recap emails',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Data connections (soon)',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'No file size limit',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Unlimited spaces',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Unlimited items',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Save websites offline (soon)',
      },
    ],
  },
  {
    name: 'max',
    prices: {
      type: BillingType.Recurring,
      billedMonthly: 39,
      billedYearly: 32.5,

      stripePlanIdMonthly: process.env.NEXT_PUBLIC_STRIPE_MAX_MONTHLY_PLAN_ID,
      stripePlanIdYearly: process.env.NEXT_PUBLIC_STRIPE_MAX_YEARLY_PLAN_ID,
    },
    title: 'Max',
    items: [
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: '4TB storage space',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'AI assistant (unlimited)',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Get AI Recap emails',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Data connections (soon)',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Browser history sync (soon)',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'No file size limit',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Unlimited spaces',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Unlimited items',
      },
      {
        type: ItemType.Feature,
        featureType: FeatureType.Regular,
        has: true,
        description: 'Save websites offline (soon)',
      },
    ],
  },
];

export const lifetimePlan: Plan = {
  name: 'lifetime',
  title: 'Believer: \nGet Pro for life',
  prices: {
    type: BillingType.Lifetime,
    billedOnce: 400,
    stripePlanId: process.env.NEXT_PUBLIC_STRIPE_LIFETIME_BELIEVER_PLAN_ID,
  },
  items: [
    {
      type: ItemType.Feature,
      featureType: FeatureType.Highlighted,
      has: true,
      description: 'Everything in the Pro plan, forever',
    },
    {
      type: ItemType.Separator,
    },
    {
      type: ItemType.Feature,
      featureType: FeatureType.Regular,
      has: true,
      description: 'Believer badge in profile',
    },
    {
      type: ItemType.Feature,
      featureType: FeatureType.Regular,
      has: true,
      description: 'VIP access to the team',
    },
    {
      type: ItemType.Feature,
      featureType: FeatureType.Regular,
      has: true,
      description: 'Be a founding member of Fabric',
    },
  ],
};

const extensiveLifetimePlan: Plan = {
  ...lifetimePlan,
  // same as pro plan
  items: [
    ...plans
      .find((plan) => plan.name === 'pro')!
      .items.map((item) => {
        if (item.type === ItemType.Feature && item.description.includes('AI assistant')) {
          return {
            ...item,
            description: 'AI assistant',
          };
        }
        return item;
      }, []),
  ],
};

const planOrderWithDuration = [
  'free',
  `starter-${BillingPeriod.Monthly}`,
  `starter-${BillingPeriod.Yearly}`,
  `pro-${BillingPeriod.Monthly}`,
  `pro-${BillingPeriod.Yearly}`,
  'lifetime',
  `max-${BillingPeriod.Monthly}`,
  `max-${BillingPeriod.Yearly}`,
];

const planOrder = ['free', 'starter', 'pro', 'lifetime', 'max'];

/**
 * This function takes 2 plans (with duration) and returns true if the first plan is below the second plan.
 * @param plan1 the name of the first plan to compare.
 * @param plan2 the name of the second plan to compare.
 * @returns True if the first plan is below the second plan.
 */
export const isPlanWithDurationBelow = (plan1: string, plan2: string): boolean => {
  return planOrderWithDuration.indexOf(plan1) < planOrderWithDuration.indexOf(plan2);
};

/**
 * This function takes 2 plans and returns true if the first plan is below the second plan.
 * @param plan1 the name of the first plan to compare.
 * @param plan2 the name of the second plan to compare.
 * @returns True if the first plan is below the second plan.
 */
export const isPlanBelow = (plan1: string, plan2: string): boolean => {
  return planOrder.indexOf(plan1) < planOrder.indexOf(plan2);
};

/**
 * Given the plan it will return true if it's a subscribed user or false if it's a free user.
 * @param plan the plan to check.
 * @returns True if the plan is a subscribed user.
 */
export const isSubscribedPlan = (plan?: SubscritionTier): boolean => {
  return !(plan ?? 'free').startsWith('free');
};

/**
 * Given a user it will return it's tier+period combo, as one of the planOrder values.
 * @param user the user to check.
 * @returns The tier+period combo of the user.
 * @example
 *  getPlanFromUser({tier: 'pro', billingCycle: 'year'}) // returns 'pro-year'
 */
export const getFullTierFromUser = (user: ApiUserMe): string => {
  if (!user.subscription.tier) return 'free';
  if (user.subscription.tier === 'lifetime' || user.subscription.tier === 'free')
    return user.subscription.tier;
  return `${user.subscription.tier}-${user.subscription.billingCycle}`;
};

/**
 * Given the prices of a plan and a billing period it will return the plan ID in Stripe.
 * @param prices
 * @param billingPeriod
 * @returns The plan ID in Stripe.
 */
export const getCurrentStripePlanId = (prices: Prices, billingPeriod?: BillingPeriod) => {
  switch (prices.type) {
    case BillingType.Recurring:
      return billingPeriod === BillingPeriod.Monthly
        ? prices.stripePlanIdMonthly
        : prices.stripePlanIdYearly;
    case BillingType.Lifetime:
      return prices.stripePlanId;
  }
};

/**
 * Given a plan and billing period it will return it's tier+period combo, as one of the planOrder values.
 * @param plan the plan to check.
 * @param billingPeriod the billing period to check.
 * @returns The tier+period combo of the plan.
 */
export const getFullTierFromPlan = (
  { name, prices }: Plan,
  billingPeriod?: BillingPeriod,
): string => {
  const planId = getCurrentStripePlanId(prices, billingPeriod);
  if (!planId) return 'free';
  if (name === 'lifetime' || name === 'free') return name;
  return `${name}-${billingPeriod}`;
};

/**
 * Get a plan by it's name. Supporting the tier+period combo.
 * @param name the name of the plan.
 * @returns The plan.
 */
export const getPlanByName = (name: string, extensive = false): Plan => {
  if (name === 'lifetime') return extensive ? extensiveLifetimePlan : lifetimePlan;
  return plans.find((plan) => plan.name === name.split('-')[0])!;
};
