import Color from 'color';

export const colorToRGBCssString = (color: Color) => {
  const obj = color.rgb().object();
  return `${obj.r}, ${obj.g}, ${obj.b}`;
};

export const colorToHSLCssString = (color: Color) => {
  const obj = color.hsl().object();
  return `${obj.h}, ${obj.s}%, ${obj.l}%`;
};
