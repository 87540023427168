import { questConfig } from '@/src/modules/onboarding/onboarding.config';
import { cssVar } from '@/src/modules/ui/theme/variables';
import dynamic from 'next/dynamic';
import React from 'react';

const SDKQuestProvider = dynamic(
  () => import('@questlabs/react-sdk').then((mod) => mod.QuestProvider),
  {
    ssr: false,
  },
);

const QuestProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  if (!questConfig) return <>{children}</>;

  return (
    <SDKQuestProvider
      apiKey={questConfig.FABRIC_QUEST_APIKEY}
      entityId={questConfig.FABRIC_QUEST_ENTITYID}
      apiSecret=""
      apiType="PRODUCTION"
      themeConfig={{
        backgroundColor: cssVar['color-bg-primary'],
        borderColor: cssVar['color-border-primary'],
        buttonColor: cssVar['color-app-primary'],
        primaryColor: cssVar['color-text-primary'],
        secondaryColor: cssVar['color-text-secondary'],
        fontFamily: 'Inter, sans-serif',
      }}
    >
      {children}
    </SDKQuestProvider>
  );
};

export { QuestProvider };
