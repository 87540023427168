'use client';

import { Button } from '@/src/modules/ui/components/Button';
import Modal, { defaultModalContentProps } from '@/src/modules/ui/components/Modal';
import { P } from '@/src/modules/ui/components/Typography';
import inNextServer from '@/src/utils/next';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { VisuallyHidden } from '@radix-ui/react-visually-hidden';
import clsx from 'clsx';
import Link from 'next/link';
import React, { CSSProperties } from 'react';
import { marketingItems } from '../../views/Settings/components/PlanCard/PlanCard';
import { mapPlanItem } from '../Pricing/PricePlan';
import styles from './UpgradePromptModal.module.scss';

const UpgradePromptModal: React.FC<{
  title: string;
  subtitle?: string;
  limitBadge?: string;

  titleMaxWidth?: CSSProperties['maxWidth'];

  noBottomGap?: boolean;

  onClose: () => void;
}> = ({ title, subtitle, limitBadge, titleMaxWidth, noBottomGap = false, onClose }) => {
  if (inNextServer()) return null;

  return (
    <Modal open={true} onOpenChange={onClose}>
      <Modal.Portal>
        <DialogPrimitive.Overlay asChild>
          <div
            className={clsx(styles.backdrop, noBottomGap && styles.no_bottom_gap)}
            onClick={onClose}
          />
        </DialogPrimitive.Overlay>
        <DialogPrimitive.Content aria-describedby={undefined} {...defaultModalContentProps} asChild>
          <div className={clsx(styles.modal, noBottomGap && styles.no_bottom_gap)}>
            <VisuallyHidden>
              <DialogPrimitive.Title>{title}</DialogPrimitive.Title>
            </VisuallyHidden>

            <header>
              {limitBadge && <span className={styles.limit_badge}>{limitBadge}</span>}
              <Modal.Close as="button" onClick={onClose} />
            </header>

            <h2
              style={{
                maxWidth: titleMaxWidth ?? '253px',
              }}
            >
              {title}
            </h2>
            {subtitle && <h4>{subtitle}</h4>}
            <div className={styles.content}>
              <div className={styles.feature_list}>
                <P weight={600} color="tertiary">
                  You’ll also get:
                </P>
                <ul>{marketingItems.map(mapPlanItem)}</ul>
              </div>

              <div className={styles.actions}>
                <Button
                  onClick={onClose}
                  size="lg"
                  style={{ minWidth: 144 }}
                  as={Link}
                  href="/pricing"
                >
                  Upgrade
                </Button>
                <P weight={600} color="tertiary">
                  Cancel any time.
                </P>
              </div>
            </div>
          </div>
        </DialogPrimitive.Content>
      </Modal.Portal>
    </Modal>
  );
};

export default UpgradePromptModal;
