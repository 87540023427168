import { BaseStorage } from '@/src/lib/storage/BaseStorage';
import { KeyValStorageInterface } from './types';

class IndexedDBStorage extends BaseStorage<IDBDatabase> implements KeyValStorageInterface {
  private readCache: { [storeName: string]: { [key: string]: any } } = {};

  constructor(dbName: string) {
    super(dbName);
  }
  protected async checkConnection(): Promise<boolean> {
    try {
      await this.getConnection();
      return true;
    } catch (error) {
      return false;
    }
  }

  protected async reconnect(): Promise<void> {
    this.connection = null;
    await this.initializeConnection();
  }

  protected async initializeConnection(retryCount = 0): Promise<void> {
    try {
      this.connection = new Promise((resolve, reject) => {
        const request = indexedDB.open(this.dbName);

        request.onsuccess = () => {
          const db = request.result;
          db.onclose = () => {
            console.warn('IndexedDB connection was unexpectedly closed. Attempting to reopen...');
            this.connection = null;
          };
          resolve(db);
        };

        request.onerror = () => {
          reject(new Error(`Failed to open IndexedDB: ${request.error}`));
        };
      });

      await this.connection;
      this.initializing = false;
      this.initializingPromise = null;
    } catch (error) {
      if (retryCount < this.MAX_RETRIES) {
        console.warn(
          `Error initializing IndexedDB connection. Retrying... (Attempt ${retryCount + 1}/${this.MAX_RETRIES})`,
        );
        await this.initializeConnection(retryCount + 1);
      } else {
        console.error(
          `Failed to initialize IndexedDB connection after ${this.MAX_RETRIES} attempts:`,
          error,
        );
        throw error;
      }
    }
  }

  protected initializeReadConnection(): Promise<void> {
    this.initializingRead = false;
    this.initializingReadPromise = null;
    return this.initializeConnection();
  }

  protected async initializeStore(storeName: string): Promise<void> {
    const db = await this.getConnection();
    if (!db.objectStoreNames.contains(storeName)) {
      const version = db.version + 1;
      db.close();
      this.connection = new Promise((resolve, reject) => {
        const request = indexedDB.open(this.dbName, version);
        request.onupgradeneeded = (event) => {
          const db = (event.target as IDBOpenDBRequest).result;
          db.createObjectStore(storeName);
        };
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
        this.loadKeysToMemory(storeName);
      });
    }
  }

  private async getStore(
    storeName: string,
    mode: IDBTransactionMode = 'readonly',
  ): Promise<IDBObjectStore> {
    await this.initializeStore(storeName);
    const db = await this.getConnection();
    return db.transaction(storeName, mode).objectStore(storeName);
  }

  protected async flushReadBatchImpl(batchesToProcess: {
    [storeName: string]: Set<string>;
  }): Promise<void> {
    for (const [storeName, keys] of Object.entries(batchesToProcess)) {
      const store = await this.getStore(storeName);

      if (!this.readCache[storeName]) {
        this.readCache[storeName] = {};
      }

      const promises = Array.from(keys).map(
        (key) =>
          new Promise<void>((resolve, reject) => {
            const request = store.get(key);
            request.onsuccess = () => {
              this.readCache[storeName][key] = request.result;
              resolve();
            };
            request.onerror = () => reject(request.error);
          }),
      );

      await Promise.all(promises);
    }
  }

  protected async getItemImpl<T>(storeName: string, key: string): Promise<T | null> {
    if (this.readCache[storeName] && this.readCache[storeName][key] !== undefined) {
      const value = this.readCache[storeName][key];
      delete this.readCache[storeName][key];
      return value;
    }
    return null;
  }

  protected async setItemBatchImpl(storeName: string, items: Map<string, unknown>): Promise<void> {
    const store = await this.getStore(storeName, 'readwrite');
    return new Promise((resolve, reject) => {
      const transaction = store.transaction;

      for (const [key, value] of items) {
        store.put(value, key);
      }

      transaction.oncomplete = () => resolve();
      transaction.onerror = () => reject(transaction.error);
    });
  }

  protected async removeItemImpl(storeName: string, key: string): Promise<void> {
    const store = await this.getStore(storeName, 'readwrite');
    return new Promise((resolve, reject) => {
      const request = store.delete(key);
      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }

  protected async clearImpl(storeName: string): Promise<void> {
    const store = await this.getStore(storeName, 'readwrite');
    return new Promise((resolve, reject) => {
      const request = store.clear();
      request.onsuccess = () => resolve();
      request.onerror = () => reject(request.error);
    });
  }

  protected async keysImpl(storeName: string): Promise<string[]> {
    const store = await this.getStore(storeName);
    return new Promise((resolve, reject) => {
      const request = store.getAllKeys();
      request.onsuccess = () => resolve(request.result as string[]);
      request.onerror = () => reject(request.error);
    });
  }

  protected async *getAllItemsIteratorImpl<T>(storeName: string): AsyncIterableIterator<T> {
    const store = await this.getStore(storeName);

    if ('getAll' in store) {
      const request = store.getAll();
      const items: T[] = await new Promise((resolve, reject) => {
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      for (const item of items) {
        yield item;
      }
    } else {
      const request = (store as unknown as IDBObjectStore).openCursor();

      while (true) {
        const cursor: IDBCursorWithValue | null = await new Promise((resolve, reject) => {
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });

        if (cursor) {
          yield cursor.value;
          cursor.continue();
        } else {
          break;
        }
      }
    }
  }

  protected async getAllItemsImpl<T>(storeName: string): Promise<T[]> {
    const items: T[] = [];
    for await (const item of this.getAllItemsIteratorImpl<T>(storeName)) {
      items.push(item);
    }
    return items;
  }
}

export default IndexedDBStorage;
