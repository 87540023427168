import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Helper function that is `clsx()` & `twMerge()` combined.
 * This joins multiple classes together and merges them with Tailwind CSS classes so there are no conflicts.
 * @param inputs - The classes to join and merge.
 * @returns The joined and merged classes.
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

/**
 * Will remove undefined values from an object so it can be safely stringified into JSON.
 * @param obj - The object to remove undefined values from.
 * @returns A new object with undefined values removed.
 */
export function removeUndefinedValues<T extends Record<string, unknown>>(obj: T): T {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => value !== undefined)) as T;
}

/**
 * Will return true if the given unknown value is an object.
 */
export function isObject(value: unknown): value is Record<string, unknown> {
  return typeof value === 'object' && value !== null && value?.constructor === Object;
}

/**
 * Will return true if the given value is not undefined or null.
 * @param value - The value to check.
 * @returns True if the value is not undefined or null.
 */
export function isDefined<T>(value: T): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

/**
 * Will return true if the given value is a string.
 * @param value - The value to check.
 * @returns True if the value is a string.
 */
export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

/**
 * Type Function is not safe to use, according to ESLint, so this
 * is an alternative to check if a value is a function.
 */
type isCallableFn = (...args: unknown[]) => unknown;

/**
 * Returns true if the given value is a function and is callable.
 */
export function isFunction(value: unknown): value is isCallableFn {
  return typeof value === 'function' && value instanceof Function;
}

/**
 * Checks if a value is an error.
 */
export function isError(value: unknown): value is Error {
  return value instanceof Error;
}
