import React from 'react';

import clsx from 'clsx';
import styles from './Spinner.module.scss';

/**
 * @deprecated please use @link @/src/modules/ui/components/Spinner
 */
const Spinner: React.FC<{
  color?: string;
  size?: number;
  className?: string;
  opacity?: number;
  thickness?: number;
  style?: React.CSSProperties;
}> = ({ color = '#2A37EE', opacity = 1, size = 48, thickness, className, style }) => (
  <div
    className={clsx(styles.spinner, className)}
    style={
      {
        ...style,
        '--spinner-color': color,
        width: `${size}px`,
        height: `${size}px`,
        opacity,
        borderWidth: `${thickness ?? size * 0.2}px`,
      } as React.CSSProperties
    }
  />
);

export default Spinner;
