import * as RadixProgress from '@radix-ui/react-progress';
import clsx from 'clsx';
import React from 'react';
import Spinner from '../../../../components/Spinner/Spinner';
import styles from './Meter.module.scss';

/**
 * This function will take a number and return a string, with the following rules:
 * - If there are no decimals, return the number as a string as is
 * - If there are decimals, return the number as a string with 2 decimals
 */
const formatNumber = (num: number) => {
  if (num % 1 === 0) return num.toString();
  return num.toFixed(2);
};

const Progress: React.FC<{
  progress: number;
}> = ({ progress }) => {
  return (
    <RadixProgress.Root className={styles.progress} value={progress}>
      <RadixProgress.Indicator
        className={styles.progress__bar}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </RadixProgress.Root>
  );
};

const Meter: React.FC<{
  name: string;

  isLoading?: boolean;
  amount: number;
  total: number;
  valueType?: string;
  totalString: string;
}> = ({ name, amount, isLoading = false, total, valueType, totalString }) => {
  const is75Percent = Math.ceil((amount / total) * 100) >= 70;

  return (
    <div className={styles.meter}>
      <div className={styles.meter__header}>
        <h3>{name}</h3>
        <span className={clsx(is75Percent && styles.red)}>
          {isLoading ? <Spinner size={10} thickness={1.8} /> : formatNumber(amount)}/
          {formatNumber(total)}
          {valueType} {totalString} used
        </span>
      </div>
      <Progress progress={Math.min(Math.max((amount / total) * 100, 0), 100)} />
    </div>
  );
};

export default Meter;
