import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import { omit } from '@/src/lib/store';
import { useSearchOverlay } from '@/src/store/ui';
import { useRouter } from 'next/router';

const useGlobalSearchParamIntent = () => {
  const router = useRouter();
  const { openSearch } = router.query;
  const { openOverlay } = useSearchOverlay();

  useClientLayoutEffect(() => {
    if (!openSearch || !router.isReady) return;

    const params = omit(router.query, ['openSearch']);
    router.replace({ query: params }, undefined, { shallow: true }).then(() => {
      if (typeof openSearch === 'string')
        openOverlay(openSearch === 'location' ? 'location' : 'global');
    });
  }, [openSearch, router.isReady]);
};

export { useGlobalSearchParamIntent };
