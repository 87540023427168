import { useAuthUser } from '@/src/hooks/auth';
import { createMixpanelProvider } from '@/src/modules/analytics/providers/mixpanelProvider';
import { captureException } from '@sentry/nextjs';
import { useEffect, useState } from 'react';
import { useAnalyticsStore } from '../analytics.store';
import { createJuneProvider } from '../providers/juneProvider';

/**
 * A custom hook for initializing analytics providers and identifying users.
 *
 * This hook performs two main functions:
 * 1. Initializes analytics providers (June and Mixpanel) when the component mounts.
 * 2. Identifies the user with the analytics providers when user data is available and providers are initialized.
 *
 */
export const useAnalyticsInitializer = () => {
  const { addProvider, identify } = useAnalyticsStore();
  const user = useAuthUser();
  const [providersInitialized, setProvidersInitialized] = useState(false);

  useEffect(() => {
    const initializeProviders = async () => {
      try {
        const juneProvider = createJuneProvider();
        await juneProvider.initialize();
        addProvider(juneProvider);

        const mixpanelProvider = createMixpanelProvider();
        await mixpanelProvider.initialize();
        addProvider(mixpanelProvider);

        setProvidersInitialized(true);
      } catch (error) {
        captureException(error);
      }
    };

    initializeProviders();
  }, [addProvider]);

  useEffect(() => {
    if (user && providersInitialized) {
      identify({
        id: user.id,
        email: user.email,
        name: user.name,
        avatar_url: user.pictureUrlCdn || undefined,
        groups: user.groups,
      });
    }
  }, [identify, user, providersInitialized]);
};
