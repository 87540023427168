import { ApiExportRequestListFilter } from '@/src/modules/export/export.types';

export const exportQueryKeys = {
  exportRequests: (filter?: ApiExportRequestListFilter) => {
    if (!filter || Object.keys(filter).length === 0) {
      return ['data-exports', null];
    }

    return ['data-exports', filter];
  },
};
