import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

interface HistoryItem {
  pathname: string;
  asPath: string;
}

interface AsPathStoreType {
  history: HistoryItem[];
  lastState: HistoryItem | undefined;
}

export const userNavigationHistoryStore = create<AsPathStoreType>()(
  persist(
    () =>
      ({
        lastState: undefined,
        history: [],
      }) as AsPathStoreType,
    {
      name: 'safe-navigation-history',
      // use sesssion storage to store the history this way it specific to the tab
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export const getUserNavigationHistory = () => {
  return userNavigationHistoryStore.getState();
};
