import styles from './Tooltip.module.scss';

import {
  arrow,
  autoUpdate,
  flip,
  offset,
  Placement,
  safePolygon,
  shift,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useRole,
} from '@floating-ui/react-dom-interactions';
import clsx from 'clsx';
import {
  cloneElement,
  createContext,
  CSSProperties,
  ReactNode,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { mergeRefs } from 'react-merge-refs';
import inNextServer from '../utils/next';

interface Props {
  label?: ReactNode;
  children: JSX.Element;
  placement?: Placement;
  portal?: boolean;
  maxSize?: {
    width: string;
    height: string;
  };
  forceShow?: boolean;
  error?: boolean;
  delay?: number;
  labelStyles?: CSSProperties;
  allowTooltipHover?: boolean;
}

const placementRotationMap: {
  [key in string]: string;
} = {
  top: '45deg',
  right: '135deg',
  bottom: '-135deg',
  left: '-45deg',
};

type TooltipContext = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const TooltipContext = createContext<TooltipContext>({
  open: false,
  setOpen: () => {},
});

export const useInsideTooltip = () => useContext(TooltipContext);

const Tooltip = ({
  children,
  placement = 'bottom',
  label,
  portal = true,
  maxSize = { width: '50vw', height: '50vh' },
  forceShow = false,
  error = false,
  delay = 1000,
  labelStyles = {},
  allowTooltipHover = false,
}: Props) => {
  const [open, setOpen] = useState(false);
  const arrowRef = useRef<HTMLElement | null>(null);

  const {
    x,
    y,
    reference,
    floating,
    strategy,
    context,
    middlewareData,
    placement: realPlacement,
  } = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    middleware: [
      offset(20),
      flip(),
      shift({ padding: 8 }),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      delay: { open: delay, close: 0 },
      handleClose: allowTooltipHover ? safePolygon() : undefined,
    }),
    useFocus(context),
    useRole(context, { role: 'tooltip' }),
    useDismiss(context),
  ]);

  const ref = useMemo(() => {
    // we assume here that children is a valid react element, we just check for ref being present
    // this avoids issues when the given children has no ref
    if (!('ref' in children)) {
      console.warn('Tooltip: children has no ref, the tooltip might not work as expected.', this);
      return reference;
    }

    return mergeRefs([reference, children.ref as React.MutableRefObject<unknown>]);
  }, [reference, children]);

  const staticSide = {
    top: 'bottom',
    right: 'left',
    bottom: 'top',
    left: 'right',
  }[realPlacement.split('-')[0]];

  const arrowDeg = placementRotationMap[staticSide ?? 'top'];

  const tooltip = (
    <TooltipContext.Provider
      value={{
        open,
        setOpen,
      }}
    >
      {(open || forceShow) && label && (
        <span
          {...getFloatingProps({
            ref: floating,
            className: clsx(styles.tooltip, error && styles.error),
            style: {
              position: strategy,
              top: y ?? 0,
              left: x ?? 0,
              maxWidth: maxSize.width,
              maxHeight: maxSize.height,
              ...labelStyles,
            },
          })}
        >
          {label}

          <span
            ref={(ref) => (arrowRef.current = ref)}
            className={styles.tooltip__arrow}
            style={{
              left: middlewareData.arrow?.x ?? 'auto',
              top: middlewareData.arrow?.y ?? 'auto',
              [staticSide ?? 'top']: '-5px',
              transform: `rotate(${arrowDeg})`,
            }}
          />
        </span>
      )}
    </TooltipContext.Provider>
  );

  if (inNextServer()) return children;

  const portalTarget = document.getElementById('tooltip-portal');

  return (
    <>
      {cloneElement(children, getReferenceProps({ ref, ...children.props }))}
      {portal && portalTarget ? createPortal(tooltip, portalTarget) : tooltip}
    </>
  );
};

export default Tooltip;
