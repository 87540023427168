import { Fdoc } from '@/src/types/api';

export const mutateResourceMeta = (resource: Fdoc, meta: Partial<Fdoc['_meta']>): Fdoc => {
  return {
    ...resource,
    _meta: {
      ...resource._meta,
      ...meta,
    },
  };
};
