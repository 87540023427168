import styled, { keyframes } from 'styled-components';
import { cssVar } from '../theme/variables';

const SpinnerAnimation = keyframes`
    100% {
        transform: rotate(0deg);
    }
    0% {
        transform: rotate(360deg);
    }
`;

export const Spinner = styled.div<{
  color?: string;
  size?: number;
  opacity?: number;
  thickness?: number;
}>`
  --spinner-color: ${(props) => props.color ?? cssVar['color-app-primary']};

  position: relative;

  width: ${(props) => props.size ?? 48}px;
  height: ${(props) => props.size ?? 48}px;

  border-radius: 50%;

  border: ${(props) => props.thickness ?? (props.size ?? 48) * 0.2}px solid var(--spinner-color);
  mask: conic-gradient(from 160.71deg at 50% 50%, #ffffff 0deg, rgba(0, 0, 0, 0) 300deg);
  display: inline-block;
  box-sizing: border-box;
  animation: ${SpinnerAnimation} 1s linear infinite;
`;
