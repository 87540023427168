import { P } from '@/src/modules/ui/components/Typography';
import styled, { css } from 'styled-components';
import { cssVar } from '../theme/variables';

const variants = {
  default: css`
    background: ${cssVar['color-bg-section']};
    padding: 30px;
    border-radius: 24px;
  `,
  clean: css`
    background: transparent;
    padding: 30px 0;
    border-radius: 0;
  `,
  primary: css`
    background-color: ${cssVar['color-bg-section-primary']};
    padding: 30px;
    border-radius: 24px;
  `,
};

type SectionProps = {
  variant?: keyof typeof variants;
  disabled?: boolean;
};

const Section = styled.section<SectionProps>`
  ${(p) => variants[p.variant || 'default']}
  ${(p) =>
    p.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`;

const SectionTitle = styled(P).attrs((props) => {
  return {
    ...props,
    as: 'h2',
    weight: 600,
  };
})`
  font-size: 24px;
  font-style: normal;
  line-height: 32px;
  letter-spacing: -0.48px;
`;

export const SectionCard = styled.div`
  padding: 15px;
  background: linear-gradient(
    105.13deg,
    ${cssVar['color-bg-section']} 39.36%,
    ${cssVar['color-bg-tertiary']} 110.33%
  );
  border-radius: 10px;
`;

export default Object.assign(Section, { Title: SectionTitle });
