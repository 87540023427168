import clsx from 'clsx';
import { FC } from 'react';
import { IconProps } from './types';

const GlobeIcon: FC<
  IconProps & {
    bgColor?: string;
  }
> = ({ color = 'currentColor', bgColor = '#E4E4E4', size = 18, className, ...props }) => {
  return (
    <svg
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      className={clsx('new_icon', className)}
      {...props}
    >
      <rect y="0.5" width="18" height="18" rx="9" fill={bgColor} />
      <path
        d="M3.57773 7.77734L3.83477 8.22578C4.06172 8.62227 4.43359 8.91484 4.87383 9.04062L6.45703 9.4918C6.92734 9.62578 7.25 10.0551 7.25 10.5445V11.6355C7.25 11.9363 7.41953 12.2098 7.6875 12.3438C7.95547 12.4777 8.125 12.7512 8.125 13.052V14.1184C8.125 14.5449 8.53242 14.8539 8.94258 14.7363C9.38281 14.6105 9.72461 14.2578 9.83672 13.8121L9.91328 13.5059C10.0281 13.0438 10.3289 12.6473 10.7418 12.4121L10.9633 12.2863C11.3734 12.0539 11.625 11.6164 11.625 11.1461V10.9191C11.625 10.5719 11.4855 10.2383 11.2395 9.99219L11.1328 9.88555C10.8867 9.63945 10.5531 9.5 10.2059 9.5H9.02734C8.72383 9.5 8.42305 9.4207 8.15781 9.27031L7.21445 8.73164C7.09687 8.66328 7.00664 8.55391 6.96289 8.42539C6.87539 8.16289 6.99297 7.87852 7.2418 7.75547L7.40313 7.67344C7.58359 7.5832 7.79414 7.5668 7.98555 7.63242L8.61992 7.84297C8.84414 7.9168 9.09023 7.83203 9.21875 7.63789C9.34727 7.44648 9.33359 7.19219 9.18594 7.01445L8.81406 6.56875C8.54062 6.24063 8.54336 5.76211 8.82227 5.43945L9.25156 4.93906C9.49219 4.65742 9.53047 4.25547 9.34727 3.93555L9.28164 3.8207C9.18594 3.81523 9.09297 3.8125 8.99727 3.8125C6.45977 3.8125 4.30781 5.47773 3.57773 7.77734ZM14.6875 9.5C14.6875 8.49375 14.425 7.54766 13.9656 6.72461L13.2656 7.00625C12.8363 7.17852 12.6148 7.65703 12.7598 8.09453L13.2219 9.48086C13.3176 9.76523 13.55 9.98125 13.8398 10.0523L14.6355 10.252C14.6684 10.0059 14.6848 9.7543 14.6848 9.5H14.6875ZM2 9.5C2 7.64348 2.7375 5.86301 4.05025 4.55025C5.36301 3.2375 7.14348 2.5 9 2.5C10.8565 2.5 12.637 3.2375 13.9497 4.55025C15.2625 5.86301 16 7.64348 16 9.5C16 11.3565 15.2625 13.137 13.9497 14.4497C12.637 15.7625 10.8565 16.5 9 16.5C7.14348 16.5 5.36301 15.7625 4.05025 14.4497C2.7375 13.137 2 11.3565 2 9.5Z"
        fill={color}
      />
    </svg>
  );
};

export default GlobeIcon;
