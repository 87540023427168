import { ApiUserMe, UserGroup } from '../modules/user/user.types';
import { oneOfGroup } from '../modules/user/utils/group';
import { useAuthUser } from './auth';

const defaultGetIsEnabledForUser = (user?: ApiUserMe | null) => {
  if (!user) {
    return false;
  }

  if (oneOfGroup(user, [UserGroup.ADMIN])) {
    return true;
  }

  if (user.email.endsWith('@fabric.so')) {
    return true;
  }

  return false;
};

type UseDevelopmentFlagOptions = {
  /**
   * by default, feature is enabled for all admins and @fabric.so users
   */
  getIsEnabledForUser: typeof defaultGetIsEnabledForUser;
};

const defaultOptions = {
  getIsEnabledForUser: defaultGetIsEnabledForUser,
};

/**
 * Hook to check if the current user has access to development features
 * @param options
 * @returns
 */
export const useDevelopmentFlag = (options?: Partial<UseDevelopmentFlagOptions>) => {
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };

  const { getIsEnabledForUser } = mergedOptions;

  const user = useAuthUser();

  if (process.env.REACT_APP_ENV === 'develop') {
    return true;
  }

  return Boolean(getIsEnabledForUser(user));
};
