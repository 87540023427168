import { FabricSocket } from '@/src/lib/socket.io/types';
import React from 'react';

interface SocketIOContextProps {
  socket: FabricSocket;
  isConnected: boolean;
}

export const SocketIOContext = React.createContext<SocketIOContextProps>(
  {} as SocketIOContextProps,
);
