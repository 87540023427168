import { omitUndefined } from '@/src/lib/store';
import { HalList } from '@fabric/woody-client';
import { Space, SpaceRole } from '../spaces.types';
import { rootTypeGuard } from './rootTypeGuard';

export const halListToSpace = (list: HalList, role?: SpaceRole): Space => {
  return omitUndefined({
    id: list.id,
    title: list.title,
    type: rootTypeGuard(list.type) ? list.type : 'SPACE',
    integration: list.integration,
    createdAt: list.createdAt,
    modifiedAt: list.modifiedAt,
    isPublic: list.isPublic,
    labels: list._embedded?.labels.map((label) => ({
      id: label.id,
      name: label.name,
      hexColor: label.color,
    })),
    owner: list._embedded?.owner,
    role,
  });
};
