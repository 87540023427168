import {
  AssistantContextSubType,
  ChatbotConversationMessage,
  ChatbotConversationMessageAssistant,
} from '@/src/modules/assistant/types';
import { create } from 'zustand';

interface AssistantStore {
  summarizeText?: string;

  chatAssistantOpen: boolean;
  chatAssistantFullscreen: boolean;
  lastContextSubtype: AssistantContextSubType | null;
  assistantSelectedIds: string[];

  messages: ChatbotConversationMessage[];
  setMessages: (
    param:
      | ChatbotConversationMessage[]
      | ((state: ChatbotConversationMessage[]) => ChatbotConversationMessage[]),
  ) => void;

  setLastContextSubtype: (contextSubtype: AssistantContextSubType | null) => void;
  setChatAssistantOpen: (open: boolean) => void;
  setChatAssistantFullscreen: (open: boolean) => void;
  setAssistantSelectedIds: (ids: string[]) => void;
  setSummarizeText: (text: string | undefined) => void;
  hydratedKey: string | undefined;
  setHydratedKey: (key: string) => void;
  reset: VoidFunction;
}

const initialMessages: ChatbotConversationMessageAssistant[] = [];

const initialState = {
  summarizeText: undefined,
  chatAssistantOpen: false,
  chatAssistantFullscreen: false,
  lastContextSubtype: null,
  assistantSelectedIds: [],
  hydratedKey: undefined,
  messages: initialMessages,
};

export const useAssistantStore = create<AssistantStore>()((set) => ({
  ...initialState,
  setSummarizeText: (text: string | undefined) => set({ summarizeText: text }),
  setLastContextSubtype: (contextSubtype: AssistantContextSubType | null) =>
    set({ lastContextSubtype: contextSubtype }),
  setChatAssistantOpen: (open: boolean) => set({ chatAssistantOpen: open }),
  setChatAssistantFullscreen: (open: boolean) => set({ chatAssistantFullscreen: open }),
  setMessages: (param) => {
    typeof param === 'function'
      ? set((state) => ({ messages: param(state.messages) }))
      : set({ messages: param });
  },
  setAssistantSelectedIds: (ids: string[]) => set({ assistantSelectedIds: ids }),
  setHydratedKey: (key: string) => set({ hydratedKey: key }),
  reset: () => set(initialState),
}));
