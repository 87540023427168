import useAuthStore from '@/src/hooks/auth';
import { useRunOnce } from '@/src/hooks/useRunOnce';
import { SplashScreen } from '@capacitor/splash-screen';

/**
 * hides capacitor splash screen, currently autohide set to off in the capacitor.config.ts in favor of
 * programatic control
 */
export const useHideSplashScreen = () => {
  const authStatus = useAuthStore((state) => state.authStatus);
  useRunOnce(SplashScreen.hide, authStatus !== 'loading');
};
