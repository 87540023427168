import { useQueryFolderLabels } from '@/src/modules/labels/queries/useQueryFolderLabels';
import { useQueryResourceRootList } from '@/src/modules/resource-roots/queries/useQueryResourceRootList';
import { Space } from '@/src/modules/spaces/spaces.types';
import { convertResourceRootToSpace } from '@/src/modules/spaces/utils/convertSpaceRootToSpace';
import { useMemo } from 'react';

/**
 * @TODO
 * BE shape should be updated to match the /v2/resource-roots/:resourceRootId once the endpoint is updated
 * https://linear.app/futurebrowser/issue/FUT-4781/update-shape-of-v2resource-rootsresourcerootid-to-newer-v2resource
 */
export const useQueryResourceRoot = (resourceRootId?: string | null) => {
  const query = useQueryResourceRootList();

  const { data: labels } = useQueryFolderLabels(resourceRootId);
  // const { client } = useWoody()
  // const enabled = !!resourceRootId
  // const q = useQuery({
  //   queryKey: rootQueryKeys.resourceRoot(resourceRootId),
  //   queryFn: async () => {
  //     const res = await client.v2({
  //       endpoint: '/v2/resource-roots/{resourceRootId}',
  //       params: {
  //         resourceRootId: resourceRootId!
  //       }
  //     })

  //     return res;
  //   },
  //   enabled
  // })

  const resourceRoot = useMemo(() => {
    if (!resourceRootId) {
      return undefined;
    }
    return query.roots.find((root) => root.id === resourceRootId);
  }, [resourceRootId, query.roots]);

  return {
    isLoading: query.isLoading,
    error: query.error,
    resourceRoot,
    /**
     * helper shape to transition to new resource root
     * @deprecated use `resourceRoot` shape directly
     */
    asLegacySpace: useMemo(() => {
      if (resourceRoot) {
        const result: Space = {
          ...convertResourceRootToSpace(resourceRoot),
          labels,
        };

        return result;
      }

      return undefined;
    }, [resourceRoot, labels]),
  };
};
