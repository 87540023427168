import { createLogger } from '@/src/lib/logger/createLogger';
import { promiseDelay } from '@/src/utils/promiseDelay';

const DEFAULT_MIN_DELAY = 300;
const DEFAULT_DELAY = 3000;
const DEFAULT_MAX_RETRIES = 5;

type DelayFn = (attempt: number) => number;

interface RetryOptions {
  maxRetries?: number;
  /**
   * in ms. Ignored when delayFn is provided
   * default is 3000ms
   */
  delay?: number;
  /**
   * minimum delay to guard the delayFn
   * default is 300ms
   */
  minDelay?: number;
  /**
   *
   * @param attempt
   * @returns number in ms
   */
  delayFn?: null | DelayFn;

  loggerKey?: string;
}

const promiseRetryFn = async <T>(fn: () => Promise<T>, options?: RetryOptions): Promise<T> => {
  const logger = createLogger(
    `Promise retry${options?.loggerKey ? ` (${options.loggerKey})` : ''}`,
    true,
  );

  const maxRetries = options?.maxRetries ?? DEFAULT_MAX_RETRIES;
  const delay = options?.delay ?? DEFAULT_DELAY;
  const minDelay = options?.minDelay || DEFAULT_MIN_DELAY;

  for (let i = 0; i <= maxRetries; i += 1) {
    const attempt = i + 1;
    try {
      const res = await fn();
      logger.debug(`OK. Attempts: ${attempt}`);
      return res;
    } catch (error) {
      if (i === maxRetries) {
        logger.error(`ABORT. Attempts ${attempt}`, error);
        return Promise.reject(error);
      }

      const calculatedDelay = Math.max(options?.delayFn?.(attempt) ?? delay, minDelay);
      logger.warn(`Fail. Attempts ${attempt}, retry in ${calculatedDelay}ms`, error);
      await promiseDelay(calculatedDelay, undefined);
    }
  }

  return Promise.reject(new Error('This should never happen, satisfying TS'));
};

const increasedDelayWithEachAttempt =
  (increase: number): DelayFn =>
  (attempt: number) =>
    increase * attempt;

export const promiseRetry = Object.assign(promiseRetryFn, {
  DEFAULT_DELAY,
  DEFAULT_MAX_RETRIES,
  increasedDelayWithEachAttempt,
});
