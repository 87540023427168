import { useOptimisticallyUpdateQueryDetailData } from '@/src/lib/react-query/utilities';
import { userQueryKeys } from '@/src/modules/user/queries/userQueryKeys';
import { UserPreferences } from '@/src/modules/user/user.types';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import { components } from '@fabric/woody-client';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface MutationVariables {
  data: Partial<UserPreferences>;
  showToast?: boolean;
}

export const useMutationUserPreferencesUpdate = () => {
  const queryClient = useQueryClient();

  const optimisticallyUpdateQueryDetailData =
    useOptimisticallyUpdateQueryDetailData<UserPreferences>();
  const { client } = useWoody();

  return useMutation({
    mutationFn: async ({ data }: MutationVariables) => {
      return await client.v2('/v2/user/preferences', {
        method: 'put',
        body: data as components['schemas']['UserPreferences'],
      });
    },
    onMutate: ({ data }) => {
      queryClient.cancelQueries({
        queryKey: userQueryKeys.userPreferences(),
      });

      return optimisticallyUpdateQueryDetailData(userQueryKeys.userPreferences(), data);
    },
    onSuccess: (_, { showToast = true }) => {
      if (!showToast) return;

      toast({
        content: 'Preferences have been updated',
      });
    },
    onError: (_error, { showToast = true }, context) => {
      context?.resetDetailData();
      context?.invalidateQueries();

      if (!showToast) return;

      toast({
        content: 'Failed to update user preferences',
      });
    },
  });
};
