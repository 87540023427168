import useDebounce from '@/src/hooks/debounce';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { useMutationCheckListItemState } from '@/src/modules/onboarding/mutations/useMutationCheckListItemState';
import { OnboardingCheckListActionId } from '@/src/modules/onboarding/onboarding.config';
import useFdocSwitcher from '@/src/store/fdocSwitcher';
import { FabricQueryMode } from '@fabric/woody-client';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { SplitFdocs } from '../components/FdocList/FdocList';
import { OfViewMode } from '../components/ViewModeSwitcher/ViewModeSwitcher';
import { useQuerySearchResources } from '../modules/resources/queries/useQuerySearchResources';
import { SearchBarValue } from '../modules/searchBar/searchbar.types';
import { searchBarValueToFabricFilters } from '../modules/searchBar/utils/converters';
import { Fdoc } from '../types/api';
import { OptimisticDraft } from '../types/draftable';
import { useAuthUser } from './auth';
import { useResponsive } from './responsive';

interface IGlobalSearchContext {
  searchBarValue: SearchBarValue;
  setSearchBarValue: React.Dispatch<React.SetStateAction<SearchBarValue>>;
  viewMode: OfViewMode;
  setViewMode: React.Dispatch<React.SetStateAction<OfViewMode>>;
  selectedIndex: number | null;
  setSelectedIndex: React.Dispatch<React.SetStateAction<number | null>>;
  zoomLevel: number;
  setZoomLevel: React.Dispatch<React.SetStateAction<number>>;
  scrollPosition: number;
  setScrollPosition: React.Dispatch<React.SetStateAction<number>>;
  storedScrollPosition: number | null;
  setStoredScrollPosition: React.Dispatch<React.SetStateAction<number | null>>;
  lastInteraction: number;
  setLastInteraction: React.Dispatch<React.SetStateAction<number>>;
}

const defaultValues: IGlobalSearchContext = {
  searchBarValue: { query: '', filters: [] },
  setSearchBarValue: () => {},
  viewMode: 'List',
  setViewMode: () => {},
  selectedIndex: 0,
  setSelectedIndex: () => {},
  zoomLevel: 0.9,
  setZoomLevel: () => {},
  scrollPosition: 0,
  setScrollPosition: () => {},
  storedScrollPosition: null,
  setStoredScrollPosition: () => {},
  lastInteraction: 0,
  setLastInteraction: () => {},
};

const GlobalSearchContext = React.createContext<IGlobalSearchContext>(defaultValues);

export const GlobalSearchProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [searchBarValue, setSearchBarValue] = useState<SearchBarValue>({
    query: '',
    filters: [],
  });
  const [viewMode, setViewMode] = useState<OfViewMode>('Grid');
  const [selectedIndex, setSelectedIndex] = useState<number | null>(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [storedScrollPosition, setStoredScrollPosition] = useState<null | number>(null);
  const [lastInteraction, setLastInteraction] = useState(0);

  useEffect(() => {
    setLastInteraction(Date.now());
  }, [searchBarValue, viewMode, selectedIndex, zoomLevel, scrollPosition]);

  const value = useMemo(
    () => ({
      searchBarValue,
      setSearchBarValue,
      viewMode,
      setViewMode,
      selectedIndex,
      setSelectedIndex,
      zoomLevel,
      setZoomLevel,
      scrollPosition,
      setScrollPosition,
      storedScrollPosition,
      setStoredScrollPosition,
      lastInteraction,
      setLastInteraction,
    }),
    [
      searchBarValue,
      setSearchBarValue,
      viewMode,
      selectedIndex,
      zoomLevel,
      scrollPosition,
      storedScrollPosition,
      lastInteraction,
    ],
  );

  return <GlobalSearchContext.Provider value={value}>{children}</GlobalSearchContext.Provider>;
};

export const splitFunctionsByFolder: (fdocs: OptimisticDraft<Fdoc>[]) => SplitFdocs[] = (fdocs) => {
  const splitFdocs: SplitFdocs[] = [];

  fdocs.forEach((fdoc, index, all) => {
    const previousFdoc = all[index - 1];
    // To avoid problems with infinite loading we will do it like iMessages where they clump together if they are right next to each other
    // Otherwise we just create a new split even if it was already present in the array

    let splitFdoc = splitFdocs[splitFdocs.length - 1];
    if (previousFdoc?.parentResourceId !== fdoc.parentResourceId || !splitFdocs.length) {
      let title;

      if (parent?.name.startsWith('user')) {
        title = 'Inbox';
      }

      if (fdoc.list && fdoc.parentResourceId === fdoc.list) {
        title = fdoc.listData?.title ?? 'Untitled Space';
      }

      splitFdoc = {
        id: `split-${fdoc.list}-${index}`, // avoid duplicate keys
        space: fdoc.listData,
        resourceId: fdoc.parentResourceId ?? undefined,
        title,
        fdocs: [],
      };

      splitFdocs.push(splitFdoc);
    }

    splitFdoc.fdocs.push(fdoc);
  });

  return splitFdocs;
};

const useGlobalSearchStore = (
  _id: string,
  listParentRef: HTMLElement | null,
  disabled: boolean = false,
) => {
  const {
    searchBarValue: _searchBarValue,
    setSearchBarValue,
    viewMode,
    setViewMode,
    selectedIndex,
    setSelectedIndex,
    zoomLevel,
    setZoomLevel,
    scrollPosition,
    setScrollPosition,
    storedScrollPosition,
    setStoredScrollPosition,
    lastInteraction,
    setLastInteraction,
  } = useContext(GlobalSearchContext);

  const searchBarValue = useDebounce(_searchBarValue, 500);

  const isSearchBarEmpty =
    searchBarValue.query.length === 0 &&
    (searchBarValue.filters.length === 0 || searchBarValue.filters.every((f) => f.value === null));

  const user = useAuthUser();
  const { track } = useAnalytics();
  const storedQuery = useRef<string>(searchBarValue.query);
  const { mutate: mutateCheckListItemState } = useMutationCheckListItemState();

  useEffect(() => {
    if (searchBarValue.query === storedQuery.current) return;

    const debounce = setTimeout(() => {
      storedQuery.current = searchBarValue.query;

      track(AnalyticsEvents.Search, {
        has_query: true,
        action: 'global_search',
      });

      mutateCheckListItemState({
        actionId: OnboardingCheckListActionId.TRY_AI_SEARCH,
        state: true,
      });
    }, 3000);

    return () => clearTimeout(debounce);
  }, [mutateCheckListItemState, searchBarValue, track, user]);

  const filters = useMemo(
    () => ({ ...searchBarValueToFabricFilters(searchBarValue), hasSlug: false }),
    [searchBarValue],
  );

  const { resources, fetchNextPage, isLoading, total } = useQuerySearchResources(
    {
      mode: FabricQueryMode.Hybrid,
      text: searchBarValue.query,
      filters: filters,
    },
    {
      enabled: !disabled && !isSearchBarEmpty,
    },
  );

  const setSwitcherFdocIds = useFdocSwitcher((state) => state.setSwitcherFdocIds, shallow);

  useEffect(() => {
    if (disabled) return;
    setSwitcherFdocIds(
      resources.map((f) => f.id),
      false,
    );
  }, [disabled, resources, setSwitcherFdocIds]);

  const onSearchKeyDown = (e: React.KeyboardEvent) => {
    // if it's space and we have a selection we ignore, but if it's ESC clear the selection
    if (e.key === ' ' && selectedIndex !== null) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }

    // if the key is down or up we will move the selection
    if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') return;

    e.preventDefault();
    e.stopPropagation();

    if (!resources.length) return;

    if (selectedIndex === null) {
      setSelectedIndex(e.key === 'ArrowDown' ? 0 : resources.length - 1);
      return;
    }

    const newIndex =
      ((e.key === 'ArrowDown' ? selectedIndex + 1 : selectedIndex - 1) + resources.length) %
      resources.length;

    setSelectedIndex(newIndex);
  };

  // when the query changes we want to reset the selected index
  useEffect(() => {
    setSelectedIndex(null);
  }, [searchBarValue.query, setSelectedIndex]);

  const { isMobileView } = useResponsive();

  useEffect(() => {
    if (!isMobileView) return;

    setViewMode('Grid');
  }, [isMobileView, setViewMode]);

  const scrollPositionRef = useRef(scrollPosition);
  useEffect(() => {
    scrollPositionRef.current = scrollPosition;
  }, [scrollPosition]);

  useEffect(() => {
    return () => {
      setStoredScrollPosition(scrollPositionRef.current);
    };
  }, [setStoredScrollPosition]);

  useEffect(() => {
    if (!listParentRef || isLoading || storedScrollPosition === null) return;

    let lastTimeout: number;

    const scrollTo = () => {
      const list = listParentRef.querySelector('.dashboard_scrollbar');
      if (!list) return;

      list.scrollTo({ top: storedScrollPosition });

      // now we need to check if the scroll position is the same as the stored one
      // if it is we can clear it otherwise we keep trying to scroll to it

      lastTimeout = window.setTimeout(() => {
        if (list.scrollTop === storedScrollPosition) {
          setStoredScrollPosition(null);
        }

        // if it's not the same we try again
        else scrollTo();
      }, 100);
    };

    scrollTo();

    return () => clearInterval(lastTimeout);
  }, [isLoading, listParentRef, setStoredScrollPosition, storedScrollPosition]);

  useEffect(() => {
    // if the last interaction was more than 10 minutes ago we reset the search
    if (!lastInteraction || Date.now() - lastInteraction < 10 * 60 * 1000) return;

    setSearchBarValue({ query: '', filters: [] });
    setSelectedIndex(null);
    setZoomLevel(0.9);
    setScrollPosition(0);
    setStoredScrollPosition(null);
    setLastInteraction(0);
  }, [
    lastInteraction,
    setSearchBarValue,
    setSelectedIndex,
    setZoomLevel,
    setScrollPosition,
    setStoredScrollPosition,
    setLastInteraction,
  ]);

  return {
    searchBarValue: _searchBarValue,
    setSearchBarValue,
    viewMode,
    setViewMode,
    resources,
    selectedIndex,
    setSelectedIndex,
    fetchNextPage,
    onSearchKeyDown,
    zoomLevel,
    setZoomLevel,
    storedScrollPosition,
    scrollPosition,
    setScrollPosition,
    isLoading,
    isSearchBarEmpty,
    total,
  };
};

export default useGlobalSearchStore;
