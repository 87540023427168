/**
 * Checks if the given element is an input element, will also make sure to return false if the element is
 * a textarea or input that is empty.
 * @param element Any HTML element
 * @returns True if the element is an input element, false otherwise
 */
export const isInputElement = (element?: Element | EventTarget | null, always = false): boolean => {
  return (
    (element instanceof HTMLInputElement &&
      (always || (element.type !== 'hidden' && element.value !== ''))) ||
    (element instanceof HTMLTextAreaElement && (always || element.value !== '')) ||
    element instanceof HTMLSelectElement
  );
};

/**
 * Checks if the element is either an anchor, button, or one of the isInputElement types.
 * @param element Any HTML element
 * @returns True if the element is an interactive element, false otherwise
 */
export const isInteractiveElement = (
  element?: Element | EventTarget | null,
  always = false,
): boolean => {
  return (
    element instanceof HTMLAnchorElement ||
    element instanceof HTMLButtonElement ||
    isInputElement(element, always)
  );
};

/**
 * Checks if a given element is a input element or contenteditable element.
 * @param element Any HTML element
 * @returns True if the element is an input element or contenteditable element, false otherwise
 */
export const isEditableElement = (element?: Element | null): boolean => {
  return isInputElement(element) || (element instanceof HTMLElement && element.isContentEditable);
};

/**
 * Similar to isInteractiveElement, but also checks if there is a closest parent that is interactive.
 * @param element Any HTML element
 * @returns True if the element is an interactive element or has an interactive parent, false otherwise
 */
export const isInteractiveElementOrHasInteractiveParent = (
  element?: Element | EventTarget | null,
  always = false,
): boolean => {
  return (
    isInteractiveElement(element, always) ||
    (element instanceof HTMLElement &&
      isInteractiveElement(element.closest('a, button, input'), always))
  );
};
