import { rootQueryKeys } from '@/src/modules/resource-roots/queries/rootQueryKeys';
import { Query } from '@tanstack/react-query';
import { spaceQueryKeys } from './spacesQueryKeys';

export const spaceQueryPredicates = {
  space: (query: Query, spaceId: string) => {
    const resourceKey = spaceQueryKeys.space(spaceId);
    return query.queryKey[0] === resourceKey[0] && query.queryKey[1] === resourceKey[1];
  },
  spacesAll: (query: Query) => {
    return rootQueryKeys.resourceRootList(undefined)[0] === query.queryKey[0];
  },
};
