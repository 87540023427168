import { useDevelopmentFlag } from '@/src/hooks/useDevelopmentFlag';
import { toast } from '@/src/store/alerts';
import { useMemo } from 'react';
import { share } from 'shared-zustand';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';

export type AppColorScheme = 'light' | 'dark' | 'system';

type AppColorSchemeStore = {
  colorScheme: AppColorScheme;
  setColorScheme: (scheme: AppColorScheme) => void;
  toggleColorScheme: (toast?: boolean) => void;
};

const toastThemeChange = (scheme: AppColorScheme) => {
  toast({
    id: 'color-theme-changed',
    content: `App theme set to ${scheme !== 'system' ? `${scheme} mode` : 'system default'}.`,
    replace: true,
  });
};

const useAppColorSchemeStore = create<AppColorSchemeStore>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        colorScheme: 'light',
        setColorScheme: (scheme: AppColorScheme) => {
          return set({ colorScheme: scheme });
        },
        toggleColorScheme: (toast = false) =>
          set((state) => {
            // cycle light, dark, system
            const nextColorScheme =
              state.colorScheme === 'light'
                ? 'dark'
                : state.colorScheme === 'dark'
                  ? 'system'
                  : 'light';

            if (toast) toastThemeChange(nextColorScheme);

            return { colorScheme: nextColorScheme };
          }),
      }),
      {
        name: 'app-color-scheme',
        version: 1,
      },
    ),
  ),
);

if ('BroadcastChannel' in globalThis) {
  share('colorScheme', useAppColorSchemeStore);
}

const getSystemColorScheme = () => {
  const systemColorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches
    ? 'dark'
    : 'light';
  return systemColorScheme;
};

export const useColorScheme = () => {
  const { colorScheme, setColorScheme, toggleColorScheme } = useAppColorSchemeStore();

  const schemeChangeEnabled = useDevelopmentFlag({
    // enabled for any logged in user
    getIsEnabledForUser: (user) => !!user,
  });

  return {
    schemeChangeEnabled,
    colorScheme: schemeChangeEnabled ? colorScheme : 'light',
    parsedColorScheme: useMemo(
      () => (colorScheme === 'system' ? getSystemColorScheme() : colorScheme),
      [colorScheme],
    ),
    setColorScheme,
    toggleColorScheme,
  };
};
