import { FabricHitCommentNested } from '@fabric/woody-client';
import { UserPublicInfo } from '@fabric/woody-client/src/types/user';
import { Comment } from '../comments.types';

export const fabricHitCommentNestedToComment = (
  comment:
    | FabricHitCommentNested
    | {
        id: string;
        content: string;
        createdAt: Date;
        modifiedAt: Date;
        user: UserPublicInfo;
      }
    | null,
  resourceId: string,
): Comment | null => {
  if (!comment) return null;

  return {
    id: comment.id,
    content: comment.content,
    user: {
      id: comment.user.id,
      name: comment.user.name,
      pictureUrlCdn: comment.user.picture,
    },
    createdAt:
      typeof comment.createdAt === 'string' ? comment.createdAt : comment.createdAt.toISOString(),
    modifiedAt:
      typeof comment.modifiedAt === 'string'
        ? comment.modifiedAt
        : comment.modifiedAt.toISOString(),
    resourceId,
  };
};
