import {
  AssistantContextOption,
  AssistantContextSubType,
  AssistantContextType,
  ChatbotConversationMessageAssistant,
} from '@/src/modules/assistant/types';
import { ChatbotMessageRole } from '@fabric/woody-client';

export const EVERYTHING_CONTEXT: AssistantContextOption = {
  subType: AssistantContextSubType.Everything,
  name: 'Everything',
  value: 'everything',
  type: AssistantContextType.None,
};

export const WELCOME_MESSAGE: ChatbotConversationMessageAssistant = {
  id: 'welcome-message',
  role: ChatbotMessageRole.assistant,
  streaming: false,
  content: 'Hi, How can I help you today?',
  resourceIds: [],
  resources: [],
  context: EVERYTHING_CONTEXT,
};
