import mixpanel, { Mixpanel } from 'mixpanel-browser';
import { AnalyticsProvider, AnalyticsUser } from '../analytics.types';

/**
 * Creates and returns an AnalyticsProvider instance for Mixpanel.
 * @returns {AnalyticsProvider}
 */
export const createMixpanelProvider = (): AnalyticsProvider => {
  let analytics: Mixpanel | null = null;

  return {
    initialize: async () => {
      if (!process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) return;
      analytics = mixpanel.init(
        process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
        {
          debug: process.env.REACT_APP_ENV === 'develop',
          track_pageview: true,
          persistence: 'localStorage',
        },
        'default-mix',
      );
    },
    track: async (event, properties) => {
      if (!analytics) return;
      await analytics.track(event, properties);
    },
    identify: (user: AnalyticsUser | null) => {
      if (!analytics) return;

      if (!user) {
        analytics.reset();
        return;
      }

      analytics.identify(user.id);

      analytics.people.set({
        $name: user.name ?? null,
        $email: user.email ?? null,
        $avatar: user.avatar_url ?? null,
        $groups: user.groups ?? null,
      });
    },
  };
};
