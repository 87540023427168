import { useRef } from 'react';

/**
 * Given a function it will return a reference to that function (the .current property of the ref)
 * This allows you to use the function without causing a re-render.
 *
 * Make sure you use this in functions where you don't care if the function changed, or is not valid anymore.
 * (e.g. don't use for a function that would cause a change in variables, since it could be stale)
 */
export const useReferencedFn = <
  T extends (...args: Parameters<T>) => ReturnType<T>,
  F extends T | undefined,
>(
  fn: F,
) => {
  const ref = useRef<F>(fn);
  ref.current = fn;
  return ref;
};
