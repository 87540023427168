import { useClientLayoutEffect } from '@/src/hooks/useClientLayoutEffect';
import { QueryKey, useQueryClient } from '@tanstack/react-query';
import { useRef } from 'react';
import { v4 } from 'uuid';

type PendingMutation = {
  id: string;
  queryKeys: QueryKey[];
};

const PENDING_MUTATIONS_KEY = 'pendingMutations';

const getPendingMutations = (): PendingMutation[] => {
  const pendingMutations = localStorage.getItem(PENDING_MUTATIONS_KEY);
  return pendingMutations ? JSON.parse(pendingMutations) : [];
};

const setPendingMutations = (pendingMutations: PendingMutation[]) => {
  localStorage.setItem(PENDING_MUTATIONS_KEY, JSON.stringify(pendingMutations));
};

/**
 * Add a pending mutation to the list of pending mutations, this is to be used to invalidate queries if a mutation is pending when
 * the page is refreshed, and has no opportunity to invalidate the queries itself, prevents optimistic data from remaining in the cache
 */
export const addPendingMutation = (queryKeys: QueryKey[]) => {
  const mutationId = v4();

  const pendingMutations = getPendingMutations();

  const newPendingMutation = {
    id: mutationId,
    queryKeys,
  };

  setPendingMutations([...pendingMutations, newPendingMutation]);

  return () => {
    setTimeout(() => {
      const updatedPendingMutations = getPendingMutations().filter(
        (mutation: PendingMutation) => mutation.id !== mutationId,
      );

      setPendingMutations(updatedPendingMutations);
    }, 500);
  };
};

/**
 * Hook that will invalidate all queries associated with pending mutations, should be run on page load
 */
export const useInvalidatePendingMutations = () => {
  const ranOnce = useRef(false);
  const queryClient = useQueryClient();

  useClientLayoutEffect(() => {
    if (ranOnce.current) return;
    ranOnce.current = true;

    const onFullyLoaded = () => {
      const pendingMutations = getPendingMutations();
      for (const mutation of pendingMutations) {
        for (const queryKey of mutation.queryKeys) {
          queryClient.invalidateQueries({
            queryKey,
            exact: false,
            type: 'active',
          });
        }
      }

      setPendingMutations([]);
    };

    window.addEventListener('load', onFullyLoaded);
    return () => window.removeEventListener('load', onFullyLoaded);
  }, [queryClient]);
};
