import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useWoody } from '../services/woody/woody';
import { getCookie } from '../utils/cookies';
import { useAuthIsLoggedIn, useAuthUser } from './auth';

const REWARDFUL_TRACK_URL = 'https://api.getrewardful.com/referrals/track';
const REWARDFUL_API_KEY = '355963';

export const useRewardful = () => {
  const router = useRouter();
  const isLoggedIn = useAuthIsLoggedIn();
  const user = useAuthUser();
  const { client } = useWoody();

  useEffect(() => {
    const { via } = router.query;
    if (!via || !isLoggedIn || !user || typeof via !== 'string') {
      return;
    }

    // if the user was created at more than 7 days ago, don't track
    const createdAt = new Date(user?.createdAt);
    if (createdAt.getTime() < Date.now() - 7 * 24 * 60 * 60 * 1000) {
      // remove the via from the query params
      const { via: _, ...query } = router.query;
      router.replace({
        pathname: router.pathname,
        query,
      });
      return;
    }

    const track = async () => {
      const sourceUrl = new URL(window.location.href);

      const res = await fetch(REWARDFUL_TRACK_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api_key: REWARDFUL_API_KEY,
          link_token: via,
          location: sourceUrl.origin,
          referrer: '',
          title: document.title,
        }),
      });

      if (!res.ok) {
        console.error('Failed to track referral', res);
      }

      const data = await res.json();

      const id = data?.referral?.id;
      if (!id) {
        console.error('Failed to track referral', data);
      }

      await client.v2('/v2/user/referral', {
        method: 'put',
        body: {
          referral: id,
        },
      });

      // remove via from query params
      const { via: _, ...query } = router.query;

      router.replace({
        pathname: router.pathname,
        query,
      });
    };

    track();
  }, [router.query, isLoggedIn, client, router, user]);

  useEffect(() => {
    // check for the rewardful.referral cookie and alo send the referral id to woody
    // same rules as above
    const referralCookie = getCookie(document.cookie, 'rewardful.referral')?.value;
    if (!referralCookie || !isLoggedIn || !user) {
      return;
    }

    // if the user was created at more than 7 days ago, don't track
    const createdAt = new Date(user?.createdAt);
    if (createdAt.getTime() < Date.now() - 7 * 24 * 60 * 60 * 1000) {
      document.cookie = 'rewardful.referral=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      return;
    }

    try {
      const data = JSON.parse(decodeURIComponent(referralCookie));
      const id = data?.id;
      if (!id) {
        return;
      }
      const track = async () => {
        await client.v2('/v2/user/referral', {
          method: 'put',
          body: {
            referral: id,
          },
        });

        document.cookie = 'rewardful.referral=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      };

      track();
    } catch (e) {
      return;
    }
  }, [isLoggedIn, user, client]);
};
