import { useEffect } from 'react';

const FABRIC_STORAGE_VERSION_KEY: Readonly<string> = 'FABRIC_STORAGE_VERSION';
const FABRIC_STORAGE_VERSION: Readonly<string> = '0.1.1';

const deprecatedStorageKeys = [
  'FdocStore',
  'ListStore',
  'MemberStore',
  'offline-store',
  'WebnoteMetadataStore',
  'REACT_QUERY_OFFLINE_CACHE',
];

/**
 * This hook will run a migration/cleanup function to remove deprecated keys on local storage.
 * It will store the `FABRIC_STORAGE_VERSION` on local storage to keep track of the version of the storage,
 * this should prevent the migration/cleanup function from running multiple times unnecessarily.
 * If the version on local storage is different from the current version, the migration/cleanup function will run.
 */
export const useClearDeprecatedStorageKeys = () => {
  useEffect(() => {
    const storageVersion = localStorage.getItem(FABRIC_STORAGE_VERSION_KEY);
    if (storageVersion === FABRIC_STORAGE_VERSION) return;

    deprecatedStorageKeys.forEach((key) => {
      localStorage.removeItem(key);
    });

    localStorage.setItem(FABRIC_STORAGE_VERSION_KEY, FABRIC_STORAGE_VERSION);
  }, []);
};
