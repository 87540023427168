import EnterIcon from '@/public/images/icons/Enter.svg';
import gsap from 'gsap';
import React, { SyntheticEvent, useCallback, useEffect } from 'react';

import { isInMobile } from '@/src/hooks/mobile';
import useSwipe from '@/src/hooks/useSwipe';
import { getModalPortal } from '@/src/lib/portals';
import { Button } from '@/src/modules/ui/components/Button';
import { Flex } from '@/src/modules/ui/components/Flex';
import { hasMacOSKbd } from '@/src/types/global';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { isInputElement } from '../../utils/elements';
import styles from './Modal.module.scss';

import { Kbd } from '@/src/modules/ui/components/Kbd';
import ModalNew from '@/src/modules/ui/components/Modal';

interface Props {
  usePortal?: boolean;
  title?: React.ReactNode;
  showTitleOutside?: boolean;
  titleStyle?: React.CSSProperties;

  children: React.ReactNode;
  preExtra?: React.ReactNode;
  extra?: React.ReactNode;

  onConfirm?: (e?: SyntheticEvent) => Promise<void> | void;
  confirmText?: React.ReactNode;
  confirmDisabled?: boolean;
  ctrlOrCommandModifierConfirm?: boolean;

  showCancel?: boolean;
  showConfirm?: boolean;
  showX?: boolean;
  closeOnConfirm?: boolean;
  onlyBlurOnEscape?: boolean;
  onCancel?: (e?: SyntheticEvent) => void;
  cancelText?: React.ReactNode;
  lowPriorityEvents?: boolean;
  handleSwipeEvents?: boolean;

  confirmButtonProps?: Omit<React.ComponentProps<typeof Button>, 'color'>;
  cancelButtonProps?: React.ComponentProps<typeof Button>;
  xButtonProps?: React.ComponentProps<typeof Button>;

  wrapperStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  className?: string;
  loading?: boolean;
  scrollWithContent?: boolean;

  size?: 'normal' | 'small';

  onClose: () => void;
}

/**
 * @deprecated
 */
const Modal: React.FC<Props> = ({
  usePortal = false, // would be true, but initial implementations didn't use it so it's best to keep it false for reducing regression risk
  title,
  showTitleOutside = false,
  titleStyle = {},
  children,
  preExtra,
  extra,
  onClose = () => {},
  onConfirm = onClose,
  confirmText = 'Confirm',
  confirmDisabled = false,
  ctrlOrCommandModifierConfirm = false,
  showCancel = false,
  showConfirm = true,
  showX = true,
  onCancel = onClose,
  handleSwipeEvents = false,
  onlyBlurOnEscape = true,
  closeOnConfirm = true,
  lowPriorityEvents = false,
  cancelText = 'Cancel',
  loading = false,
  wrapperStyle = {},
  contentStyle = {},
  style = {},
  className = '',
  size = 'normal',
  scrollWithContent = false,

  confirmButtonProps = {},
  cancelButtonProps = {},
  xButtonProps = {},
}) => {
  const handleConfirm = useCallback(async () => {
    await onConfirm();
    if (closeOnConfirm) onClose();
  }, [onConfirm, closeOnConfirm, onClose]);

  const handleCancel = useCallback(() => {
    onCancel();
  }, [onCancel]);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    // blur out any focused elements
    const focusedElement = document.activeElement as HTMLElement;
    if (focusedElement) focusedElement.blur();
  }, []);

  useEffect(() => {
    // on escape, cancel or close
    // on enter, confirm or close
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        e.stopPropagation();

        // if it's an interactive element, don't close unfocus it instead
        if ((onlyBlurOnEscape && isInputElement(e.target as HTMLElement)) || loading) {
          (e.target as HTMLElement).blur();
          return;
        }

        handleCancel();
      } else if (e.key === 'Enter') {
        if (hasMacOSKbd() && !e.metaKey && ctrlOrCommandModifierConfirm) return;
        if (!hasMacOSKbd() && !e.ctrlKey && ctrlOrCommandModifierConfirm) return;
        if (loading || confirmDisabled) return;

        // if it's an interactive element ignore if shift is being held
        if (
          isInputElement(e.target as HTMLElement, true) &&
          e.shiftKey &&
          !ctrlOrCommandModifierConfirm
        ) {
          return;
        }

        e.preventDefault();
        e.stopPropagation();
        handleConfirm();
      }
    };

    document.addEventListener('keydown', handleKeyDown, !lowPriorityEvents);
    return () => document.removeEventListener('keydown', handleKeyDown, !lowPriorityEvents);
  }, [
    handleCancel,
    handleConfirm,
    onCancel,
    onClose,
    onlyBlurOnEscape,
    lowPriorityEvents,
    loading,
    confirmDisabled,
    ctrlOrCommandModifierConfirm,
  ]);

  useEffect(() => {
    function fadeInModal() {
      const tm = gsap.timeline();

      tm.fromTo(
        `.${styles.modal__background}`,
        { opacity: 0 },
        {
          opacity: 1,
          duration: 0.2,
        },
        '+=0',
      );

      tm.fromTo(
        `.${styles.modal}`,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.2,
        },
        '+=0',
      );

      tm.fromTo(
        `.${styles.modal__extra}`,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.2,
        },
        '+=0',
      );
    }

    fadeInModal();
  }, []);

  const [headerRef, setHeaderRef] = React.useState<HTMLDivElement | null>(null);
  const [closing, setClosing] = React.useState(false);
  const { offsetY, touchUpOffsetY } = useSwipe(headerRef, {
    onSwipeDown: () => {
      if (!handleSwipeEvents) return;
      setClosing(true);

      gsap.fromTo(
        `.${styles.modal}`,
        { y: touchUpOffsetY.current, opacity: 1 },
        {
          y: touchUpOffsetY.current + 100,
          opacity: 0,
          duration: 0.2,
          ease: 'power1.in',
          onComplete: handleClose,
        },
      );
    },
  });

  const element = (
    <div
      className={clsx(
        styles.modal__wrapper,
        scrollWithContent && styles.modal__wrapper__scroll_with_content,
      )}
      style={wrapperStyle}
    >
      {!scrollWithContent && <div className={styles.modal__background} onClick={handleClose} />}
      {showTitleOutside && !scrollWithContent && (
        <h2 className={clsx(styles.modal__title__outside)} style={titleStyle}>
          {title}
        </h2>
      )}
      {preExtra && !scrollWithContent && preExtra}
      <div
        className={clsx(styles.modal, size === 'small' && styles.modal_small, className)}
        style={{
          ...style,
          top: offsetY > 0 ? offsetY : closing ? touchUpOffsetY.current : 0,
        }}
      >
        {((title && !showTitleOutside) || showX) && (
          <div className={styles.modal__header} ref={setHeaderRef}>
            {!showTitleOutside && (
              <h2 className={styles.modal__title} style={titleStyle}>
                {title}
              </h2>
            )}
            {showX && <ModalNew.Close as="button" onClick={handleClose} {...xButtonProps} />}
          </div>
        )}
        <div className={styles.modal__content} style={contentStyle}>
          {children}
        </div>
        {(showCancel || showConfirm) && (
          <div className={styles.modal__footer}>
            {showCancel && (
              <ModalNew.Button
                onClick={handleCancel}
                variant="bg-tertiary"
                disabled={loading}
                {...cancelButtonProps}
              >
                {cancelText} {!isInMobile() && <Kbd>ESC</Kbd>}
              </ModalNew.Button>
            )}
            {showConfirm && (
              <ModalNew.Button
                onClick={onConfirm}
                color="primary"
                disabled={loading || confirmDisabled}
                data-testid="modal-confirm-button"
                {...confirmButtonProps}
              >
                {confirmText}
                {!isInMobile() && (
                  <Flex alignItems="center" style={{ gap: '2px' }}>
                    {ctrlOrCommandModifierConfirm && (
                      <>
                        <Kbd
                          style={{
                            fontSize: hasMacOSKbd() ? 14 : 10,
                          }}
                        >
                          {hasMacOSKbd() ? '⌘' : 'Ctrl'}
                        </Kbd>
                        <span>+</span>
                      </>
                    )}
                    <Kbd>
                      <EnterIcon />
                    </Kbd>
                  </Flex>
                )}
              </ModalNew.Button>
            )}
          </div>
        )}
      </div>

      {extra && !scrollWithContent && <div className={styles.modal__extra}>{extra}</div>}
    </div>
  );

  return usePortal ? createPortal(element, getModalPortal()) : element;
};

/**
 * @deprecated
 */
export default Modal;
