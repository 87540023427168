// This is for the sessions extension that will eventually be merged into

import { compareVersions } from 'compare-versions';
import { useEffect } from 'react';
import { useAuthIsLoggedIn } from '../hooks/auth';
import { warning } from '../store/alerts';

export const fabricAppExtensionId = new Proxy(
  {
    value: process.env.NEXT_PUBLIC_EXTENSION_ID ?? '',
  },
  {
    get: (target, prop) => {
      if (prop !== 'value') return null;
      if (process.env.REACT_APP_ENV === 'production') return target[prop];
      if (typeof window === 'undefined') return target[prop];
      return document.getElementById('fabric-extension-id')?.textContent ?? target[prop];
    },
  },
);

export const getFabricAppExtensionInstalled = async (): Promise<boolean> => {
  // send a message to the extension to see if it's installed
  return new Promise((resolve) => {
    if (!('chrome' in window)) return resolve(false);

    try {
      const timeout = window.setTimeout(() => {
        resolve(false);
      }, 500);

      chrome?.runtime?.sendMessage(fabricAppExtensionId.value, { type: 'ping' }, (response) => {
        resolve(response?.type === 'pong');
        window.clearTimeout(timeout);
      });
    } catch (e) {
      resolve(false);
    }
  });
};

export const sendRequestOpenMultipleTabs = async (
  urls: string[],
  newWindow = false,
): Promise<boolean> => {
  if (!('chrome' in window)) return false;

  // send a message to the extension to see if it's installed
  return new Promise((resolve, reject) => {
    const timeout = window.setTimeout(() => {
      reject('timeout');
    }, 1000);

    chrome?.runtime?.sendMessage(
      fabricAppExtensionId.value,
      { type: 'openMultipleTabs', urls, newWindow },
      (response: { type: 'success' | 'error'; message?: string }) => {
        window.clearTimeout(timeout);

        if (response.type === 'error') {
          reject(response.message);
        }

        resolve(response.type === 'success');
      },
    );
  });
};

interface CustomEventMap {
  'fabric-extension-id': CustomEvent<string>;
}

declare global {
  interface Window {
    addEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;
    removeEventListener<K extends keyof CustomEventMap>(
      type: K,
      listener: (this: Document, ev: CustomEventMap[K]) => void,
    ): void;

    listenerSet: boolean;
  }
}
export const useExtensionVersionVerification = (
  minimumExtensionVersion?: string,
  lastVersionCheck?: number,
) => {
  const isLoggedIn = useAuthIsLoggedIn();

  useEffect(() => {
    if (!('chrome' in window) || !chrome?.runtime || !isLoggedIn || !minimumExtensionVersion)
      return;

    getFabricAppExtensionInstalled().then(async (installed) => {
      if (!installed) return;

      console.info('%cExtension > detected, checking version.', 'color: #00aaff');

      try {
        await new Promise((resolve, reject) => {
          const t = setTimeout(() => {
            reject(new Error('Timeout'));
          }, 2000);

          chrome.runtime.sendMessage(
            fabricAppExtensionId.value,
            {
              type: 'checkVersion',
            },
            (response?: { version: string }) => {
              if (chrome.runtime.lastError) reject(chrome.runtime.lastError);

              if (!response) return reject(new Error('No response'));
              if (!response?.version) return reject(new Error('No response version'));

              if (compareVersions(minimumExtensionVersion, response.version) > 0) {
                reject(new Error('Out of date'));
              }

              resolve(undefined);
              clearTimeout(t);
            },
          );
        });
      } catch (e) {
        console.error('Caught error', e);
        warning({
          id: 'extension-out-of-date-warning',
          content: (
            <p>
              Your extension is out of date –{' '}
              <a
                href={`https://chrome.google.com/webstore/detail/fabric/${fabricAppExtensionId.value}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'underline',
                }}
              >
                click here to update
              </a>
            </p>
          ),
        });
      }
    });
  }, [isLoggedIn, lastVersionCheck, minimumExtensionVersion]);
};
