import { ErrorType } from '@/src/core/types/errors';
import { useAuthUser } from '@/src/hooks/auth';
import { useMutationCreateResource } from '@/src/modules/resources/mutations/useMutationCreateResource';
import { useMutationCreateSubFolder } from '@/src/modules/resources/mutations/useMutationCreateSubFolder';
import { useWoody } from '@/src/services/woody/woody';
import { MAX_UPLOAD_SIZE_FREE } from '@/src/store/fileUploadStore';
import { Fdoc } from '@/src/types/api';
import { isSubscribedPlan } from '@/src/types/pricing';
import { isWoodyError } from '@/src/utils/error';
import { FabricResourceTypes, PrivateTag } from '@fabric/woody-client';
import { useEffect, useRef } from 'react';
import { share } from 'shared-zustand';
import { v4 } from 'uuid';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { omit } from '../store';
import BulkUploader, {
  FileStatus,
  FileUploadError,
  FileUploadErrorCodes,
  IUploadFile,
} from './uploader';

export type FileUploadMetadata = {
  parentResourceId: string;
  path?: string;
  comment?: string;
  fdocId?: string; // after upload, the fdoc id
  tags: PrivateTag[];
};

interface BulkUploaderStore<T, K> {
  // singleton source for the bulk uploader class
  bulkUploader?: BulkUploader<T, K>;
  setBulkUploader: (bulkUploader: BulkUploader<T, K>) => void;

  // Tab id, this should be unique between other opened tabs
  tabId?: string;

  uploadSpeed: number;
  setUploadSpeed: (speed: number) => void;

  // The current files being uploaded, by tab id (shared)
  sharedFiles: { [key: string]: IUploadFile<T>[] };
  setSharedFiles: (files: IUploadFile<T>[]) => void;

  // The current files being uploaded in local tab
  localFiles: IUploadFile<T>[];
  setLocalFiles: (files: IUploadFile<T>[]) => void;
  setLocalFile: (file: IUploadFile<T>) => void;

  clear: () => void;
}

const useBulkUploaderStore = create<BulkUploaderStore<FileUploadMetadata, Fdoc>>()(
  subscribeWithSelector((set, get) => ({
    bulkUploader: undefined,
    setBulkUploader: (bulkUploader: BulkUploader<FileUploadMetadata, Fdoc>) => {
      set({ bulkUploader });
    },
    tabId: v4(),
    uploadSpeed: 0,
    setUploadSpeed: (speed: number) => {
      set({ uploadSpeed: speed });
    },
    sharedFiles: {},
    setSharedFiles: (files: IUploadFile<FileUploadMetadata>[]) => {
      set({ sharedFiles: { ...get().sharedFiles, [get().tabId!]: files } });
    },
    localFiles: [],
    setLocalFiles: (files: IUploadFile<FileUploadMetadata>[]) => {
      set({ localFiles: files });
    },
    setLocalFile: (file: IUploadFile<FileUploadMetadata>) => {
      const localFiles = get().localFiles;
      const index = localFiles.findIndex((f) => f.id === file.id);
      if (index === -1) {
        set({ localFiles: [...localFiles, file] });
      } else {
        set({
          localFiles: localFiles.map((f) => (f.id === file.id ? file : f)),
        });
      }
    },
    clear: () => {
      set({ localFiles: [], sharedFiles: {} });
    },
  })),
);

if ('BroadcastChannel' in globalThis) {
  share('sharedFiles', useBulkUploaderStore);
}

export const useSetupBulkUploader = () => {
  const { client } = useWoody();
  const { tabId, files, setBulkUploader, setFile, setSharedFiles, setUploadingSpeed } =
    useBulkUploaderStore(
      (state) => ({
        tabId: state.tabId,
        files: state.localFiles,
        setBulkUploader: state.setBulkUploader,
        setFile: state.setLocalFile,
        setSharedFiles: state.setSharedFiles,
        setUploadingSpeed: state.setUploadSpeed,
      }),
      shallow,
    );

  const user = useAuthUser();
  const userRef = useRef(user);
  const filesRef = useRef(files);

  useEffect(() => {
    userRef.current = user;
  }, [user]);

  useEffect(() => {
    filesRef.current = files;
  }, [files, tabId]);

  useEffect(() => {
    setSharedFiles(
      files.map((f) => {
        // Abort controller is not serializable, so we remove it
        return omit(f, ['abortController']);
      }),
    );
  }, [files, setSharedFiles]);

  useEffect(() => {
    // listen for attempts at closing the tab, if there are pending uploads,
    // alert the user with a confirmation dialog
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (
        filesRef.current?.some(
          (f) => f.status === FileStatus.Uploading || f.status === FileStatus.Ready,
        )
      ) {
        e.preventDefault();
        e.returnValue = '';
      }

      return undefined;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const mutationCreateResource = useMutationCreateResource();
  const mutationCreateFolder = useMutationCreateSubFolder();

  const bulkUploaderRef = useRef<BulkUploader<FileUploadMetadata, Fdoc>>(
    new BulkUploader<FileUploadMetadata, Fdoc>({
      concurrency: 3,
      onFilesAdded: async (items, updateFile) => {
        // Need to create the subfolders first and update the path
        // To make this simpler we will do it following the next steps:
        // 1. Create an hashset with the paths (strings) so they are unique
        // 2. Sort the hashset by the length of the path, so /a/ would show up before /a/b/
        // 3. Create the folders in order, so /a/ would be created before /a/b/
        // 4. Update the parentResourceId of the files to the folder id
        const paths = new Set<string>();

        items.forEach((item) => {
          if (!item.metadata.path || item.metadata.path === '/') return;
          let iteratePath = item.metadata.parentResourceId + '/' + item.metadata.path;

          while (iteratePath.split('/').length >= 3) {
            paths.add(iteratePath);
            // pop the last element
            iteratePath = iteratePath.split('/').slice(0, -2).join('/') + '/';
          }
        });

        // sort by the amount of slashes
        const sortedPaths = Array.from(paths).sort((a, b) => {
          const aSlashes = a.split('/').length;
          const bSlashes = b.split('/').length;

          return aSlashes - bSlashes;
        });

        const folders: { [key: string]: string } = {};

        for (const path of sortedPaths) {
          // if there is only 3 slashes, it means the parent is the root, so we don't look for it in folders
          // we just use the root id from before the first /
          // e.g. some-id-aaa/b/ => some-id-aaa
          // not e.g. some-id-aaa/a/b/ => get id for some-id-aaa/a

          const splitPath = path.split('/');

          const parentPath = splitPath.slice(0, -2).join('/') + '/';

          const parentFolderId = path.split('/').length === 3 ? splitPath[0] : folders[parentPath];

          const baseName = path.split('/').slice(-2, -1)[0];

          const resource = await mutationCreateFolder.mutateAsync({
            folderName: baseName,
            parentResourceId: parentFolderId,
            disableToast: true,
            action: 'bulk-upload',
          });

          folders[path] = resource.id;
        }

        const newItems = items.map((item) => {
          if (!item.metadata.path || item.metadata.path === '/') return item;

          const fullPath = item.metadata.parentResourceId + '/' + item.metadata.path;
          const parentFolderId = folders[fullPath];

          // Fail-safe, if the folder was not created, we just return the item
          if (!parentFolderId) return item;

          return {
            ...item,
            metadata: {
              ...item.metadata,
              parentResourceId: parentFolderId,
            },
            processed: true,
          };
        });

        newItems.forEach((item) => updateFile(item));
      },
      uploadFunction: async (item, onProgressUpdate, toggleFinalizing, signal) => {
        try {
          if (
            item.file.size > MAX_UPLOAD_SIZE_FREE &&
            !isSubscribedPlan(userRef.current?.subscription.tier)
          )
            throw new FileUploadError(FileUploadErrorCodes.FileTooLarge, 'This file is too big!');

          const presignedPost = await client.v2('/v2/upload', {
            query: {
              filename: encodeURIComponent(item.file.name),
              size: item.file.size,
            },
          });

          signal.addEventListener('abort', () => {
            throw new FileUploadError(
              FileUploadErrorCodes.FileUploadCancelled,
              'File upload cancelled',
            );
          });

          const startTime = Date.now();
          const key = await client.uploadFileR2(
            presignedPost,
            item.file,
            (e) => {
              if (e.lengthComputable) {
                const progress = (e.loaded / e.total) * 100;
                const timeElapsed = Date.now() - startTime;
                const uploadSpeed = e.loaded / (1024 * 1024) / (timeElapsed / 1000); // Speed in MB/s

                onProgressUpdate(progress, uploadSpeed);
              }
            },
            signal,
          );

          toggleFinalizing();

          const { parentResourceId, comment, tags } = item.metadata;

          const isImage = item.file.type.includes('image');
          const optimisticUrl = !isImage
            ? URL.createObjectURL(item.file)
            : // for image we do base64, that way we don't have to worry about revoking the url and can use it as a placeholder
              await new Promise<string>((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => resolve(reader.result as string);
                reader.onerror = reject;
                reader.readAsDataURL(item.file);
              });

          const resource = await mutationCreateResource.mutateAsync({
            createResourceData: {
              type: FabricResourceTypes.STORED_FILE,
              key,
              optimistic: {
                contentLength: item.file.size,
                contentType: item.file.type,
                extension: item.file.name.split('.').pop() ?? '',
                thumbnail: isImage
                  ? {
                      lg: optimisticUrl,
                      sm: optimisticUrl,
                      md: optimisticUrl,
                      xl: optimisticUrl,
                    }
                  : null,
                url: optimisticUrl,
                isBlobUrl: !isImage,
              },
              parentResourceId,
              title: item.file.name,
            },
            comment,
            tags,
            disableToast: true,
            action: 'bulk-upload',
          });

          return resource;
        } catch (error: unknown) {
          if (error instanceof FileUploadError) {
            throw error;
          }

          if (isWoodyError(error) && error.detail === ErrorType.EXCEEDS_STORAGE_LIMIT) {
            throw new FileUploadError(
              FileUploadErrorCodes.QuotaExceeded,
              'You have exceeded your storage limit.',
              false,
            );
          } else if (isWoodyError(error) && error.detail === ErrorType.EXCEEDS_ITEM_LIMIT) {
            throw new FileUploadError(
              FileUploadErrorCodes.QuotaExceeded,
              'You have exceeded your item limit.',
              false,
            );
          }

          throw new FileUploadError(
            FileUploadErrorCodes.FileUploadFailed,
            'Unable to upload file. Please try again.',
            true,
          );
        }
      },
      onFileUpdate: (file) => {
        setFile(file);
      },
      onUploadComplete: (file, response, updateFile) => {
        const newFile: IUploadFile<FileUploadMetadata> = {
          ...file,
          metadata: {
            ...file.metadata,
            fdocId: response.id,
          },
        };

        updateFile(newFile);
        setFile(newFile);
      },
      onSpeedUpdate: (speed) => {
        setUploadingSpeed(speed);
      },
    }),
  );

  useEffect(() => {
    setBulkUploader(bulkUploaderRef.current);
  }, [setBulkUploader]);
};

export default useBulkUploaderStore;
