// For a safe margin set the maximum size to something
// slightly lower than the actual limit.

import { useEffect, useRef } from 'react';
import {
  logTrackerEvent,
  logTrackerIssue,
  TrackerEventKind,
  TrackerIssueKind,
  useTracker,
} from '../lib/or';

// e.g. 4.5MB for a 5MB limit
const MAX_SIZE = 4.5 * 1024 * 1024;
const IMMUNTE_KEYS = [
  'UIStore',
  'alerts',
  'assistant-lastInteraction',
  'assistant-messages',
  'fabric-auth-storage',
  'fabric-mobile-screenshots-plugin',
  'invite',
  'or-uuid',
];

const sizeToHumanReadable = (size: number) => {
  if (size < 1024) return `${size}B`;
  if (size < 1024 * 1024) return `${(size / 1024).toFixed(2)}KB`;
  return `${(size / 1024 / 1024).toFixed(2)}MB`;
};

function calculateObjectSize(obj: { [key: string]: string }) {
  let totalSize = 0;
  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    const value = obj[key];
    // Assuming the value is a string. If your objects can have non-string values, consider stringifying them.
    const itemSize = (key.length + value.length) * 2; // *2 for UTF-16
    totalSize += itemSize;
  }
  return totalSize;
}

function calculateObjectSizePerKey(obj: { [key: string]: string }): [string, number][] {
  const result: [string, number][] = [];
  for (const key in obj) {
    if (!obj.hasOwnProperty(key)) continue;

    const value = obj[key];
    // Assuming the value is a string. If your objects can have non-string values, consider stringifying them.
    const itemSize = (key.length + value.length) * 2; // *2 for UTF-16
    result.push([key, itemSize]);
  }
  return result;
}

type StorageKeySizes = { [key: string]: string };

const forceCap = (obj: { [key: string]: string } | Storage = localStorage) => {
  let currentSize = calculateObjectSize(obj);
  if (currentSize < MAX_SIZE) return;

  const previousSize = currentSize;
  const previousSizePerKey = calculateCurrentSizePerKey();

  // We remove all the keys that are not immune, until we are below the limit
  const keys = Object.keys(obj).filter((key) => !IMMUNTE_KEYS.includes(key));
  const sizes = calculateObjectSizePerKey(obj).map(([, size]) => size);
  const sortedKeys = keys.sort((a, b) => sizes[keys.indexOf(b)] - sizes[keys.indexOf(a)]);
  for (const key of sortedKeys) {
    if ('removeItem' in obj && typeof obj.removeItem === 'function') {
      obj.removeItem(key);
    } else {
      delete obj[key];
    }

    currentSize = calculateObjectSize(obj);
    if (currentSize < MAX_SIZE) break;
  }

  let hadToClear = false;

  // if after removing all the non-immune keys we are still above the limit
  // we have to clear the whole localstorage
  if (currentSize >= MAX_SIZE) {
    hadToClear = true;
    localStorage.clear();
  }

  console.warn(
    "LocalStorage size limit hit, cleanups were made. If you're seeing this message, please report it to Fabric.",
    {
      fullClear: hadToClear,
      before: {
        total: sizeToHumanReadable(previousSize),
        perKey: previousSizePerKey,
      },
      after: {
        total: sizeToHumanReadable(currentSize),
        perKey: calculateCurrentSizePerKey(),
      },
    },
  );

  logTrackerIssue({
    kind: TrackerIssueKind.LOCAL_STORAGE_LIMIT_HIT,
    payload: {
      hadToClear,
      before: {
        total: sizeToHumanReadable(previousSize),
        perKey: previousSizePerKey,
      },
      after: {
        total: sizeToHumanReadable(currentSize),
        perKey: calculateCurrentSizePerKey(),
      },
    },
  });
};

interface LocalStorageEventMap {
  localstorage: unknown;
}

declare global {
  interface Window {
    addEventListener<K extends keyof LocalStorageEventMap>(
      type: K,
      listener: (this: Window, ev: LocalStorageEventMap[K]) => unknown,
      options?: boolean | AddEventListenerOptions,
    ): void;

    removeEventListener<K extends keyof LocalStorageEventMap>(
      type: K,
      listener: (this: Window, ev: LocalStorageEventMap[K]) => unknown,
      options?: boolean | EventListenerOptions,
    ): void;
  }
}

const calculateCurrentSizePerKey = (
  obj: { [key: string]: string } = localStorage,
): StorageKeySizes => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    acc[key] = sizeToHumanReadable(key.length + value.length);
    return acc;
  }, {} as StorageKeySizes);
};

// for an object [key string to value string] we replace the localstorage with the object
// useful for after clearing a clone of the localstorage used for the forceCap
const replaceLocalStorage = (obj: { [key: string]: string }) => {
  localStorage.clear();

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && typeof obj[key] === 'string') {
      localStorage.setItem(key, obj[key]);
    }
  }
};

// This function will replace the localstorage object with a proxy that will
// send an event when changes are made to the localstorage
const patchLocalStorage = () => {
  localStorage.setItem = new Proxy(localStorage.setItem, {
    apply: (target, thisArg, args) => {
      // using thisArg we can calculate the size of the localstorage
      // we will clone it and set the new arg in the clone
      // and calculate the new full size
      const clone = { ...thisArg } as {
        [key: string]: string;
      };

      const fullSize = calculateObjectSize(clone);
      if (fullSize > MAX_SIZE) {
        // force cap on the localstorage
        forceCap(clone);
        replaceLocalStorage(clone);
      }

      const result = Reflect.apply(target, thisArg, args);
      window.dispatchEvent(new Event('localstorage'));
      return result;
    },
  });
};

const useLocalstorageForceCap = () => {
  const tracker = useTracker();
  const isPatched = useRef(false);

  useEffect(() => {
    if (isPatched.current) return;

    patchLocalStorage();
    isPatched.current = true;
  }, []);

  useEffect(() => {
    // Always run the forceCap once at the beggining to prevent the app from crashing
    // if it exceeds the limit
    forceCap();
  }, []);

  useEffect(() => {
    if (!tracker) return;

    logTrackerEvent({
      kind: TrackerEventKind.INIT_LOCAL_STORAGE_INFO,
      payload: {
        max: sizeToHumanReadable(MAX_SIZE),
        current: sizeToHumanReadable(calculateObjectSize(localStorage)),
        perKey: calculateCurrentSizePerKey(),
      },
    });
  }, [tracker]);
};

export default useLocalstorageForceCap;
