import { getWoodyResponseData } from '@/src/services/woody/utils';
import WoodyClient, { CreateResourceTypes, FabricResourceTypes } from '@fabric/woody-client';

export const populatePageTitle = async (
  createResourceData: CreateResourceTypes,
  client: WoodyClient,
): Promise<CreateResourceTypes> => {
  if (createResourceData.type !== FabricResourceTypes.PAGE) return createResourceData;

  const linkPreview = getWoodyResponseData(await client.getLinkPreview(createResourceData.pageUrl));

  return {
    ...createResourceData,
    pageTitle: linkPreview.data?.title ?? createResourceData.pageTitle ?? undefined,
  };
};
