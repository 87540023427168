import { AnalyticsBrowser } from '@june-so/analytics-next';
import { AnalyticsProvider, AnalyticsUser } from '../analytics.types';

/**
 * Creates and returns an AnalyticsProvider for June analytics.
 *
 * @returns {AnalyticsProvider}
 */
export const createJuneProvider = (): AnalyticsProvider => {
  let analytics: AnalyticsBrowser | null = null;

  return {
    initialize: async () => {
      if (!process.env.NEXT_PUBLIC_JUNE_ACCESS_KEY) return;
      analytics = AnalyticsBrowser.load({
        writeKey: process.env.NEXT_PUBLIC_JUNE_ACCESS_KEY,
      });
    },
    track: async (event, properties) => {
      if (!analytics) return;
      await analytics.track({ type: 'track', event, properties });
    },
    identify: (user: AnalyticsUser | null) => {
      if (!analytics) return;

      if (!user) {
        analytics.reset();
        return;
      }

      analytics.identify(user.id, {
        email: user.email,
        name: user.name,
        avatar_url: user.avatar_url,
        groups: user.groups,
      });
    },
  };
};
