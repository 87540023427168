import { useAuthUser } from '@/src/hooks/auth';
import { AnalyticsEvents } from '@/src/modules/analytics/analytics.types';
import { useAnalytics } from '@/src/modules/analytics/hooks/useAnalytics';
import { createOptimisticComment } from '@/src/modules/comments/utils/createOptimisticComment';
import { useMutationCheckListItemState } from '@/src/modules/onboarding/mutations/useMutationCheckListItemState';
import { OnboardingCheckListActionId } from '@/src/modules/onboarding/onboarding.config';
import { populatePageTitle } from '@/src/modules/resource-detail/utils/populatePageTitle';
import { removeFileExtensionFromCreate } from '@/src/modules/resource-detail/utils/removeFileExtension';
import { resourceMutationKeys } from '@/src/modules/resources/mutations/resourceMutationKeys';
import { createOptimisticResource } from '@/src/modules/resources/utils/createOptimisticResource';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { toast } from '@/src/store/alerts';
import {
  cleanupResourceData,
  convertWoodyResourceToFdoc,
  CreateResourceData,
  Fdoc,
} from '@/src/types/api';
import { FabricResourceTypes, PrivateTag } from '@fabric/woody-client';
import { useMutation } from '@tanstack/react-query';
import { getResourceTypeName } from '../utils/getResourceTypeName';
import { useQueryCacheResourceHelpers } from '../utils/useQueryCacheResourceHelpers';

export type ResourceCreationActions =
  | 'new-modal'
  | 'desktop-quick-note'
  | 'sidebar'
  | 'folder-header'
  | 'inbox-header'
  | 'space-header'
  | 'new-folder-modal'
  | 'list-context-menu'
  | 'bulk-upload';

type MutationVariables = {
  createResourceData: CreateResourceData;
  comment?: string;
  disableToast?: boolean;
  action?: ResourceCreationActions;
  tags?: PrivateTag[];
};

const resourceTypeToChecklistActionId = (type: Fdoc['type']) => {
  switch (type) {
    case 'image':
    case 'stored_file':
      return OnboardingCheckListActionId.UPLOAD_FIRST_FILE;
    case 'notepad':
      return OnboardingCheckListActionId.CREATE_FIRST_NOTEPAD;
    case 'page':
      return OnboardingCheckListActionId.SAVE_FIRST_LINK;
    default:
      return null;
  }
};

export const useMutationCreateResource = () => {
  const user = useAuthUser();
  const { client } = useWoody();
  const { track } = useAnalytics();

  const { addNewResourcesToCache, replaceResourceInCache } = useQueryCacheResourceHelpers();
  const { mutate: mutateCheckListItemState } = useMutationCheckListItemState();

  const mutation = useMutation({
    mutationKey: resourceMutationKeys.createResource,
    mutationFn: async ({ createResourceData, comment, tags }: MutationVariables): Promise<Fdoc> => {
      const newResource = getWoodyResponseData(
        await client.createResource(
          removeFileExtensionFromCreate(
            await populatePageTitle(cleanupResourceData(createResourceData), client),
          ),
        ),
      );

      const newComment = comment
        ? await client.v2(
            {
              endpoint: '/v2/resources/{resourceId}/comments',
              params: {
                resourceId: newResource.id,
              },
            },
            {
              query: {
                accessToken: undefined,
              },
              method: 'post',
              body: {
                content: comment,
              },
            },
          )
        : null;

      if (tags?.length) {
        for (const tag of tags) {
          await client.v2(
            {
              endpoint: '/v2/tags/{tagId}/resources',
              params: {
                tagId: tag.id,
              },
            },
            {
              method: 'put',
              body: {
                resourceIds: [newResource.id],
              },
            },
          );
        }
      }

      if (createResourceData.type === FabricResourceTypes.NOTEPAD && createResourceData.state) {
        getWoodyResponseData(await client.setYDocState(newResource.id, createResourceData.state));
      }

      return {
        ...convertWoodyResourceToFdoc(newResource),
        commentCount: newComment ? 1 : 0,
        commentPinned: newComment,
        _meta: {
          placeholderImage:
            createResourceData.type === FabricResourceTypes.STORED_FILE &&
            !createResourceData.optimistic.isBlobUrl
              ? createResourceData.optimistic.url
              : undefined,
        },
        personal: {
          tags: tags ?? [],
        },
      };
    },
    onMutate: ({ createResourceData, comment, tags = [] }) => {
      if (!user) return;

      const optimisticComment = comment
        ? createOptimisticComment(user, comment, createResourceData.parentResourceId)
        : undefined;

      const optimisticResource = createOptimisticResource(
        user,
        createResourceData,
        optimisticComment,
        undefined,
        tags,
      );

      return { ...addNewResourcesToCache([optimisticResource]), optimisticResource };
    },
    onError: (_, { disableToast }, context) => {
      context?.resetCacheToPreOptimisticState();
      context?.invalidateQueries();

      if (!disableToast && context?.optimisticResource)
        toast({
          content: `Could not create ${getResourceTypeName(context?.optimisticResource)}`,
        });
    },
    onSuccess: (newResource, { disableToast, action = 'new-modal' }, context) => {
      track(AnalyticsEvents.CreatedFdoc, {
        type: newResource.type,
        action,
      });

      const actionId = resourceTypeToChecklistActionId(newResource.type);
      if (actionId) {
        mutateCheckListItemState({
          actionId,
          state: true,
        });
      }

      const { invalidateQueries } = replaceResourceInCache(
        context?.optimisticResource,
        newResource,
        {
          createIfNotFound: true,
        },
      );

      if (!disableToast)
        toast({
          content: `${getResourceTypeName(newResource)} created`,
        });

      window.dispatchEvent(
        new CustomEvent(
          newResource.type === 'folder' ? 'fabric-folder-created' : 'fabric-resource-created',
          {},
        ),
      );

      /**
       * Lots of potential cases where we need to invalidate queries.
       * E.g.
       * - User creates a resource and moves to a different folder which wasn't fetched yet,
       * there is a chance for a race condition where the new resource is not shown in the new folder.
       * - Similar to above but between pages, e.g. settings > space | timeline > space
       *
       * Something to explore in the future.
       */
      invalidateQueries();
    },
    onSettled: (_r, _e, { createResourceData }, ctx) => {
      ctx?.clearPendingState();

      if (
        createResourceData.type === FabricResourceTypes.STORED_FILE &&
        createResourceData.optimistic.isBlobUrl
      ) {
        URL.revokeObjectURL(createResourceData.optimistic.url);
      }
    },
  });

  return mutation;
};
