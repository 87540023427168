import { useEffect, useRef, useState } from 'react';

const useSwipe = (
  element: HTMLElement | null,
  events: {
    onSwipeLeft?: () => void;
    onSwipeRight?: () => void;
    onSwipeUp?: () => void;
    onSwipeDown?: () => void;
  },
  options: {
    sensitivity?: number;
    sensitivityPreventPropagation?: number;
    disabled?: boolean;
  } = {
    sensitivity: 50,
    sensitivityPreventPropagation: Infinity,
    disabled: false,
  }
) => {
  const startX = useRef(0);
  const startY = useRef(0);
  const endX = useRef(0);
  const endY = useRef(0);

  const [dragging, setDragging] = useState(false);
  const [willSwipe, setWillSwipe] = useState(false);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const touchUpOffsetX = useRef(0);
  const touchUpOffsetY = useRef(0);

  useEffect(() => {
    if (options.disabled || !element || !options.sensitivity) return;

    const handleTouchStart = (event: TouchEvent) => {
      startX.current = event.touches[0].clientX;
      startY.current = event.touches[0].clientY;

      endX.current = event.touches[0].clientX;
      endY.current = event.touches[0].clientY;

      setOffsetX(0);
      setOffsetY(0);

      setDragging(true);
      setWillSwipe(true);
    };

    const handleTouchMove = (event: TouchEvent) => {
      if (!dragging || !options.sensitivity) return;

      endX.current = event.touches[0].clientX;
      endY.current = event.touches[0].clientY;

      const offsetX = endX.current - startX.current;
      const offsetY = endY.current - startY.current;

      setOffsetX(offsetX);
      setOffsetY(offsetY);
      touchUpOffsetX.current = offsetX;
      touchUpOffsetY.current = offsetY;

      // if above sensitivity we set willSwipe to true
      if (Math.abs(offsetX) > options.sensitivity || Math.abs(offsetY) > options.sensitivity) {
        setWillSwipe(true);
      } else {
        setWillSwipe(false);
      }
    };

    const handleTouchEnd = (e: TouchEvent) => {
      if (!dragging) return;

      setDragging(false);
      setWillSwipe(false);

      if (!options.sensitivity) return;

      const horizontalSwipeAmount = startX.current - endX.current;
      const verticalSwipeAmount = startY.current - endY.current;

      const offsetX = endX.current - startX.current;
      const offsetY = endY.current - startY.current;
      touchUpOffsetX.current = offsetX;
      touchUpOffsetY.current = offsetY;

      setOffsetX(0);
      setOffsetY(0);

      if (
        options.sensitivityPreventPropagation &&
        (Math.abs(offsetX) > options.sensitivityPreventPropagation ||
          Math.abs(offsetY) > options.sensitivityPreventPropagation)
      ) {
        e.stopPropagation();
        e.preventDefault();
      }

      if (
        Math.abs(horizontalSwipeAmount) < options.sensitivity &&
        Math.abs(verticalSwipeAmount) < options.sensitivity
      ) {
        return;
      }

      if (horizontalSwipeAmount > 0) {
        events.onSwipeLeft?.();
      } else {
        events.onSwipeRight?.();
      }

      if (verticalSwipeAmount > 0) {
        events.onSwipeUp?.();
      } else {
        events.onSwipeDown?.();
      }
    };

    element.addEventListener('touchstart', handleTouchStart);
    element.addEventListener('touchmove', handleTouchMove);
    element.addEventListener('touchend', handleTouchEnd);

    return () => {
      element.removeEventListener('touchstart', handleTouchStart);
      element.removeEventListener('touchmove', handleTouchMove);
      element.removeEventListener('touchend', handleTouchEnd);
    };
  }, [
    options.disabled,
    element,
    events,
    options.sensitivity,
    options.sensitivityPreventPropagation,
    dragging,
  ]);

  useEffect(() => {
    if (!options.disabled) return;

    // if the options become disabled we reset state to prevent any weird behavior
    setDragging(false);
    setOffsetX(0);
    setOffsetY(0);
  }, [options.disabled]);

  return {
    offsetX,
    offsetY,
    touchUpOffsetX,
    touchUpOffsetY,
    dragging,
    willSwipe,
  };
};

export default useSwipe;
