import {
  CheckListItem,
  CheckListItemLink,
  CheckListItemTask,
} from '@/src/modules/onboarding/onboarding.types';

export const checkListItemLinkFactory = (item: CheckListItemLink): CheckListItem => {
  return item;
};

export const checkListItemTaskFactory = (item: CheckListItemTask): CheckListItem => {
  return item;
};

export const isCheckListItemLink = (item: CheckListItem): item is CheckListItemLink => {
  return item.type === 'link';
};

export const isCheckListItemTask = (item: CheckListItem): item is CheckListItemTask => {
  return item.type === 'task';
};
