import { useAuthIsLoggedIn } from '@/src/hooks/auth';
import { isQueryEnabled } from '@/src/lib/react-query/isQueryEnabled';
import { getWoodyResponseData } from '@/src/services/woody/utils';
import { useWoody } from '@/src/services/woody/woody';
import { convertFabricSearchHitToFdoc, Fdoc } from '@/src/types/api';
import { WoodyError } from '@fabric/woody-client';
import {
  InfiniteData,
  InfiniteQueryObserverOptions,
  QueryKey,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { defaultResourceSearchOptions } from '../resources.config';
import { ResourceSearchOptions, SearchedFdocs } from '../resources.types';
import { resourceQueryKeys } from './resourceQueryKeys';

// These make the empty values unique so they don't cause re-renders and we don't need to memoize them
const emptyResources: Fdoc[] = [];
const emptyTotal = 0;

const searchSelector = (data: InfiniteData<SearchedFdocs>) => {
  return {
    total: data.pages[0].total,
    pages: data.pages.map((page) => ({
      pageTotal: page.results.length,
    })),
    resources: data.pages.flatMap((page) => page.results),
  };
};

/**
 * search resources based on the provided options
 *
 * @param queryOptions
 * @returns
 */
export const useQuerySearchResources = (
  options?: ResourceSearchOptions,
  queryOptions?: Partial<
    InfiniteQueryObserverOptions<
      SearchedFdocs,
      WoodyError,
      InfiniteData<SearchedFdocs, number>,
      SearchedFdocs,
      QueryKey,
      number
    >
  >,
) => {
  const { client } = useWoody();
  const isLoggedIn = useAuthIsLoggedIn();

  const mergedOptions: ResourceSearchOptions = {
    ...defaultResourceSearchOptions,
    ...options,
  };

  const query = useInfiniteQuery({
    queryKey: resourceQueryKeys.search(mergedOptions),
    queryFn: async ({ pageParam }) => {
      if ('item' in mergedOptions && mergedOptions.item === 'draft') {
        throw new Error('Drafts are not searchable');
      }

      const data = getWoodyResponseData(
        await client[mergedOptions.guest ? 'searchV2AsGuest' : 'searchV2']({
          ...mergedOptions,
          pagination: {
            page: pageParam,
            pageSize: mergedOptions?.pagination?.pageSize ?? 30,
          },
        }),
      );

      return {
        ...data,
        results: data.hits.map(convertFabricSearchHitToFdoc),
      };
    },
    staleTime: 15000,
    ...queryOptions,
    select: searchSelector,
    enabled: isQueryEnabled([isLoggedIn, queryOptions?.enabled]),
    initialPageParam: 1,
    getNextPageParam: (lastPage, _, lastPageParam) => {
      return lastPage.hasMore ? lastPageParam + 1 : undefined;
    },
  });

  return {
    ...query,
    resources: query.data?.resources ?? emptyResources,
    total: query.data?.total ?? emptyTotal,
  };
};
